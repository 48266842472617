import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const HeadingOneIcon: CreateIconType = createIcon({
  displayName: 'HeadingOneIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path
      id="Vector 1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.708008 11.9962V1.50128M7.70459 1.50128V11.9962M0.708008 6.31143H7.70459"
    />,
    <path
      id="Vector 2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.8525 8.18134L11.8525 12.4987"
    />,
    <path
      id="Vector 3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.4134 9.26068L10.7732 9.26068C11.3693 9.26068 11.8525 8.77744 11.8525 8.18134V8.18134"
    />,
    <path
      id="Vector 1_2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.2917 12.4987L10.4135 12.4987"
    />,
  ],
});
