import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Flex, Button, Heading, Image, Text, Box, ArrowRightIcon } from 'ui';
import { LoadingOverlay } from 'components/common';
import { useFetchProjectsByTeamQuery } from 'generated/graphql';
import { useConnectedClient } from 'context/ConnectedClientContext';

// Styles
import './styles/createBookingStyles.scss';

function RecruitmentType() {
  const { details: clientDetails } = useConnectedClient();
  const navigate = useNavigate();

  const teamProjects = useFetchProjectsByTeamQuery({
    variables: {
      _team_id: clientDetails?.team?.id,
      type: [1],
      sortBy: 'created',
      order: -1,
      _user_id: clientDetails?.id,
    },
  });
  const askablePlusProjectsByUser = teamProjects.data?.projectsByTeam || [];

  const onClickNext = (type: 'askable_plus' | 'recruitment_only') => {
    const redirectTo = (() => {
      if (type === 'recruitment_only') {
        return '/booking-setup/create';
      }
      if (askablePlusProjectsByUser.length <= 1) {
        return '/askable-plus/onboarding/welcome';
      }
      return '/askable-plus/create';
    })();

    navigate({ pathname: redirectTo });
  };

  return (
    <Flex direction="column" maxWidth="3xl" marginX="auto" paddingY={10}>
      {teamProjects.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <Heading size="lg" mb={10} textAlign="center" color="gray.800" fontWeight="semibold">
        Start a new research study
      </Heading>
      <Flex bgColor="blue.100" p={12} borderRadius="xl">
        <Box pr={10}>
          <Image src="/askable-plus-logo-complete-reverse.svg" mb={6} width="150px" />
          <Heading color="blue.800" fontWeight="semibold" size="md" mb={2}>
            Need us to run your research?
          </Heading>
          <Text maxW="xl" color="blue.800" pr={4}>
            Brief us in, and we’ll conduct unbiased research and deliver an actionable insights report so you can move
            forward with confidence.
          </Text>
        </Box>
        <Button colorScheme="blue" onClick={() => onClickNext('askable_plus')} alignSelf="center">
          Let’s go
        </Button>
      </Flex>
      <Link m={12} onClick={() => onClickNext('recruitment_only')} fontSize="xl" fontFamily="Wigrum">
        I've got this, I just need the right participants <ArrowRightIcon ml={2} />
      </Link>
    </Flex>
  );
}

export default RecruitmentType;
