import { gql } from '@apollo/client';
import { eSignatureTemplateFieldsFragment } from 'data/fragments/esignature_templates/eSignatureTemplateFields';

export default gql`
  query getBookingESignatureTemplates($_booking_id: ID!) {
    getBookingESignatureTemplates(_booking_id: $_booking_id) {
      ...eSignatureTemplateFields
    }
  }
  ${eSignatureTemplateFieldsFragment}
`;
