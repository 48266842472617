import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ParagraphIcon: CreateIconType = createIcon({
  displayName: 'ParagraphIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 0.919922V13.0799" />,
    <path
      id="Vector_2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.11719 13.0801H9.88265"
    />,
    <path
      id="Vector_3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.85535 3.25838V1.8553C1.85535 1.60723 1.9539 1.36931 2.12931 1.19389C2.30473 1.01847 2.54265 0.919922 2.79073 0.919922H11.2092C11.4573 0.919922 11.6952 1.01847 11.8706 1.19389C12.046 1.36931 12.1446 1.60723 12.1446 1.8553V3.25838"
    />,
  ],
});
