import { defineStyle, defineStyleConfig, cssVar } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const _default = defineStyle({
  _light: {
    [$startColor.variable]: 'colors.gray.100',
    [$endColor.variable]: 'colors.gray.200',
  },
  _dark: {
    [$startColor.variable]: 'colors.whiteAlpha.300',
    [$endColor.variable]: 'colors.whiteAlpha.200',
  },
});

export const skeletonTheme = defineStyleConfig({
  variants: { default: _default },
  defaultProps: {
    variant: 'default',
  },
}) as any;
