import { ReactNode } from 'react';

export type TableNoDataToDisplayProps = {
  title: string;
  description1?: string;
  description2?: string;
  footer?: ReactNode;
};

export const TableNoDataToDisplay = ({ title, description1, description2, footer }: TableNoDataToDisplayProps) => {
  return (
    <div className="flex min-h-[480px] w-full items-center justify-center">
      <div
        className="flex max-w-[480px] flex-col items-center gap-3 text-pretty p-4 text-center text-sm text-muted-foreground sm:items-start
          sm:justify-start sm:text-start sm:text-base"
      >
        <p className="text-lg font-bold text-foreground">{title}</p>
        {description1 ? <p>{description1}</p> : null}
        {description2 ? <p>{description2}</p> : null}
        {footer}
      </div>
    </div>
  );
};
