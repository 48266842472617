import { AppContextType } from 'components/common/Askable/Providers/appProvider';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Project } from 'generated/graphql';

type Props = {
  context: AppContextType;
  askablePlusBrief: Project;
  team?: { _id: string | null } | null;
};
const ParticipantLocationRightContent = (props: Props) => {
  return (
    <>
      <p className="cardContainerTitle">Pricing</p>
      <PriceCardContainer askablePlusBrief={props.askablePlusBrief} team={props.team} context={props.context} />
    </>
  );
};

export default ParticipantLocationRightContent;
