import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { ChevronLeft } from 'lucide-react';
import { Progress } from '@askable/ui/components/ui/progress';
import { Button } from '@askable/ui/components/ui/button';

function MatchResearcher(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/meet-brief' });
  };

  const onClickBack = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/research-plan' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerBack">
          <ChevronLeft className="backIcon h-7 w-7" onClick={onClickBack} />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="contentTextData">
            <span className="textTitle font--title1">Get matched to a researcher</span>
            <span className="descriptionText">
              Askable+ will match your project to the ideal researcher based on expertise and availability.
            </span>
            <span className="descriptionText">
              <br />
              <b>The Askable+ network:</b>
            </span>
            <span className="descriptionText">
              <br />
              &emsp;&bull;&ensp;100+ certified researchers
            </span>
            <span className="descriptionText">&emsp;&bull;&ensp;5+ years research experience</span>
            <span className="descriptionText">&emsp;&bull;&ensp;Researchers based all over the world</span>
          </div>
          <div className="contentImageData">
            <div className="illustrationContainer">
              <img className="illustration" src="/askablePlus/illustrations/searchingPerson.png" alt="personOnLaptop" />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={4} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(MatchResearcher);
