import { gql } from '@apollo/client';
import { eSignatureTemplateFieldsFragment } from 'data/fragments/esignature_templates/eSignatureTemplateFields';

export default gql`
  mutation createESignatureTemplate($template: ESignatureTemplateInput!) {
    createESignatureTemplate(template: $template) {
      ...eSignatureTemplateFields
    }
  }
  ${eSignatureTemplateFieldsFragment}
`;
