import { gql } from '@apollo/client';
import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';

export default gql`
  mutation updateOnlineTaskLink($booking_id: ID!, $link_id: ID!, $link: BookingInputConfigOnlineTaskLink!) {
    updateOnlineTaskLink(booking_id: $booking_id, link_id: $link_id, link: $link) {
      ...bookingFields
    }
  }
  ${bookingFieldsFragment}
`;
