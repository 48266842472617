import { graphql } from 'src/graphql';

export const RemainingCreditsQuery = graphql(`
  query BillingRemainingCredits {
    viewer {
      ConnectedTeam {
        _id
        settings {
          billing {
            subscription {
              end
              credit {
                remaining
              }
            }
          }
        }
      }
    }
  }
`);
