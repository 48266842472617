import { gql } from '@apollo/client';
import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

export default gql`
  subscription messagesSubscription($filter: MessageSearchInput!) {
    messagesSubscription(filter: $filter) {
      ...messagesFields
    }
  }
  ${messagesFieldsFragment}
`;
