import { checkboxAnatomy, popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle({
  content: {
    boxShadow: 'xl',
  },
});

export const popOverTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    scrollablePopover: {
      popper: {
        display: 'flex',
      },
    },
  },
}) as any;
