import { graphql } from 'src/graphql';

export default graphql(`
  query getLocationAutocomplete($search: LocationAutoCompleteSearch!) {
    locationAutocomplete(search: $search) {
      description
      placeId
      descriptionMatches {
        text
        match
      }
      sessionToken
    }
  }
`);
