import { useCallback } from 'react';
import copy from 'copy-to-clipboard';

export const useCopyToClipboard = () => {
  const onCopy = useCallback((text: Parameters<typeof copy>[0], options?: Parameters<typeof copy>[1]) => {
    return copy(text, options);
  }, []);

  return onCopy;
};
