import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const SpeakerIcon: CreateIconType = createIcon({
  displayName: 'SpeakerIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      // eslint-disable-next-line max-len
      d="M1.773 3.23c-.187 2.258-.197 4.937-.03 7.406.102 1.5 1.38 2.614 2.884 2.614h4.746c1.504 0 2.782-1.113 2.884-2.614.167-2.469.157-5.148-.03-7.406-.099-1.204-.995-2.151-2.196-2.289A27.674 27.674 0 0 0 7 .75c-1.011 0-2.108.085-3.032.191-1.2.138-2.096 1.085-2.195 2.29Z"
    />,
    <circle cx="7" cy="8.5" r="2.5" stroke="currentColor" />,
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6.323 3.375h1.35" />,
  ],
});
