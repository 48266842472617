import type { ComponentStyleConfig } from '@chakra-ui/react';

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
export const Link: ComponentStyleConfig = {
  sizes: {
    sm: {
      fontSize: '14px',
    },
    md: {
      fontSize: '16px',
    },
    lg: {
      fontSize: '18px',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
  variants: {
    // you can name it whatever you want
    default: ({ colorScheme = 'blue', colorMode }) => ({
      fontSize: 'inherit',
      fontWeight: 'semibold',
      color: colorMode === 'light' ? `${colorScheme}.500` : `${colorScheme}.300`,
      textDecoration: 'none',
      _hover: {
        textDecoration: 'underline',
      },
    }),
    subtle: ({ colorScheme = 'blue', colorMode }) => ({
      fontSize: 'inherit',
      fontWeight: 'inherit',
      color: 'inherit',
      textDecoration: 'underline',
      _hover: {
        textDecoration: 'underline',
        color: colorMode === 'light' ? `${colorScheme}.500` : `${colorScheme}.300`,
      },
    }),
  },
};
