import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
// import { useIntercom } from 'react-use-intercom';
import { LoadingOverlay, Modal, Button } from 'components/common';
import { Textarea } from '@askable/ui/components/ui/textarea';

// Queries
import fetchBookingSessionsWithParticipants from 'data/queries/booking/fetchBookingSessionsWithParticipants';

// Mutations
import markParticipantSessionIssueMutation from 'data/mutations/bookingSubmission/markParticipantSessionIssue';

// Styles
import './participantIssueDialog.scss';
import { bookingUtils } from '../../../../lib/booking';

function ParticipantIssueDialog(props: any) {
  // const intercom = useIntercom();

  const [loading, setLoading] = useState(false);
  const [issueMessage, setIssueMessage] = useState('');
  const [issueRequest, setIssueRequest] = useState(null);

  const [markParticipantSessionIssue] = useMutation(markParticipantSessionIssueMutation, {
    client: props.client,
  });

  const onCloseModal = () => {
    setLoading(false);
    setIssueRequest(null);
    setIssueMessage('');
    props.onClose();
  };

  const refundCredits = (booking: any) => {
    // round the number of credits up to the nearest whole number
    const amount = Math.ceil(_.get(booking, 'total_amount.creditsPerParticipant', 1));
    return amount === 1 ? '1 credit' : `${amount} credits`;
  };

  const renderConfirmationMessage = () => {
    return (
      <Fragment>
        <div className="actionMessage">
          {issueRequest === 1 && (
            <p>
              <strong>{refundCredits(props.booking)}</strong> will be refunded back into your team credit balance.
            </p>
          )}
          {issueRequest === 2 && (
            <p>
              An <strong>additional slot</strong> will be opened up for you to invite a another person.
            </p>
          )}
        </div>
        <p className="messageLabel">
          <strong>Please</strong> take a second to give us some feedback on why there was an issue with{' '}
          {_.get(props.participant, 'user.meta.identity.firstname')}{' '}
          {_.get(props.participant, 'user.meta.identity.lastname')}:
        </p>
        <Textarea id="issueMessage" onChange={(e: any) => setIssueMessage(e.target.value)} rows={8} />
        <Button
          label="Submit Feedback"
          onClick={() => {
            if (issueRequest) {
              setLoading(true);

              markParticipantSessionIssue({
                variables: {
                  booking_id: props.booking._id,
                  participant_session_id: props.participant._id,
                  issue_request: issueRequest,
                  issue_reason: issueMessage,
                },
                refetchQueries: [
                  {
                    query: fetchBookingSessionsWithParticipants,
                    variables: { booking_id: _.get(props, 'booking._id') },
                  },
                ],
              })
                .then(() => {
                  setLoading(false);
                  if (props.onConfirmIssue) props.onConfirmIssue();
                  props.onClickDone();
                })
                .catch(() => {
                  setLoading(false);
                });
            }
          }}
          className="doneButton"
          disabled={!issueMessage}
          size="default"
        />
        {/* <p className="chatOption">
          Prefer to talk to a person? <a onClick={() => intercom.showNewMessages()}>Chat to us</a>
        </p> */}
      </Fragment>
    );
  };

  return (
    <Modal
      className="participantIssueDialogComponent"
      bodyClassName="content"
      open={props.open}
      onClose={onCloseModal}
      styles={{ zIndex: 2000 }}
    >
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {!issueRequest ? (
        <Fragment>
          <div className="topContainer">
            <div className="contentTop">
              <h2>
                We're sorry to hear that there was an issue with{' '}
                {_.get(props.participant, 'user.meta.identity.firstname')}. You can{' '}
                {!bookingUtils.isBYO(props.booking) ? 'choose one of the options below:' : 'replace this participant.'}
              </h2>
            </div>
          </div>
          <div className="actionContainer">
            <div className="replacement">
              <div className="internalContent">
                <h3>Replace</h3>
                <p>
                  We'll open up a spot for you on this study and you&apos;ll be able to invite a different person, free
                  of charge. If {_.get(props.participant, 'user.meta.identity.firstname')} was your last session, you
                  may need to create some additional timeslots before inviting a replacement.
                </p>
              </div>
              <Button
                label="Replace"
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '2' is not assignable to paramete... Remove this comment to see the full error message
                onClick={() => setIssueRequest(2)}
                className="actionButton"
                size="default"
              />
            </div>
            {!bookingUtils.isBYO(props.booking) && (
              <div className="refund">
                <div className="internalContent">
                  <h3>Credit refund</h3>
                  <p>
                    We&apos;ll immediately put the credits back into your team&apos;s credit balance, which you&apos;ll
                    be able to use for a future study.
                  </p>
                </div>
                <Button
                  label="Refund"
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '1' is not assignable to paramete... Remove this comment to see the full error message
                  onClick={() => setIssueRequest(1)}
                  className="actionButton"
                  size="default"
                />
              </div>
            )}
          </div>
        </Fragment>
      ) : (
        <div className="confirmationMessageContainer">{renderConfirmationMessage()}</div>
      )}
    </Modal>
  );
}

export default ParticipantIssueDialog;
