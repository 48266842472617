import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const MessageBubbleIcon: CreateIconType = createIcon({
  displayName: 'MessageBubbleIcon',
  defaultProps: defaultOptions,
  path: (
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.02742 1.64649C4.9726 1.08033 6.05365 0.781152 7.15543 0.780807C8.0721 0.780267 8.97708 0.986638 9.8029 1.38454C10.6287 1.78244 11.3541 2.3616 11.9249 3.07886C12.4957 3.79611 12.8973 4.63296 13.0997 5.52702C13.3021 6.42108 13.3001 7.34929 13.0938 8.24246C12.8875 9.13562 12.4823 9.97071 11.9083 10.6855C11.3344 11.4002 10.6065 11.9762 9.77894 12.3705C8.9514 12.7648 8.04553 12.9672 7.12886 12.9627C6.36378 12.9589 5.60765 12.811 4.89964 12.5282L1.57046 13.0826C1.19849 13.1445 0.89444 12.7881 1.01424 12.4305L1.8659 9.88856C1.3915 9.05647 1.11902 8.12234 1.07324 7.16168C1.02081 6.06115 1.26786 4.96701 1.78809 3.99579C2.30831 3.02457 3.08223 2.21265 4.02742 1.64649Z"
    />
  ),
});
