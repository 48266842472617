import { gql } from '@apollo/client';
import { participantSessionsFieldsFragment } from 'data/fragments/bookingSubmission/participantSessionsFields';

export default gql`
  mutation clientSaveParticipantAvailability($booking_id: ID!, $user_id: ID!, $session_id: ID!, $created_context: Int) {
    clientSaveParticipantAvailability(
      booking_id: $booking_id
      user_id: $user_id
      session_id: $session_id
      created_context: $created_context
    ) {
      ...participantSessionsFields
    }
  }
  ${participantSessionsFieldsFragment}
`;
