import { ReactNode } from 'react';
import { DataTestId } from 'shared-utils';

type Props = {
  Icon: ReactNode;
  title: string;
};

export const CellStatus = ({ Icon, title }: Props) => {
  return (
    <div className="flex items-center" data-testid={DataTestId.cellStatus}>
      {Icon}
      <span className="ml-2 text-muted-foreground">{title}</span>
    </div>
  );
};
