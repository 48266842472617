import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { bookingUtils } from 'lib/booking';
import { Booking, Location, LocationCriteria } from 'generated/graphql';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useLocationCriteria } from 'components/common/createBooking/hooks/useLocationCriteria';
import { useGoogleMap } from 'components/common/createBooking/hooks/useGoogleMap';
import { useGoogleMapMarkers } from 'components/common/createBooking/hooks/useGoogleMapMarkers';
import { useGetLocationFromCountryCode } from 'components/common/createBooking/hooks/useGetLocationFromCountryCode';

// External components
import { Button } from '@askable/ui/components/ui/button';
import { AppContextType } from 'components/common/Askable/Providers/appProvider';
import { LoadingOverlay, GoogleMapsMultiRegion, LocationAutocomplete } from 'components/common';
import ParticipantLocationsList from 'components/common/createBooking/participantLocationsList';
import PriceCardRightContent from './priceCardRightContent';

// Styles
import './styles/participantLocationStyles.scss';

type Props = {
  booking: Booking;
  bookingSteps: Booking['steps'];
  context: AppContextType;
  updateLastStep: (data: any) => void;
  renderRightAppPreview: (content: any) => void;
  renderRightContent: (content: any) => void;
  updateBooking: (bookingState: Booking, objectToUpdate: Partial<Booking>) => void;
};

function ParticipantLocationsMultiRegion(props: Props) {
  const { details } = useConnectedClient();
  const booking = props.booking;
  const [isLoading, setIsLoading] = useState(false);

  const { locationCriteria, states, bounds, countries, allLocations, addLocation, removeLocation, isState, isCountry } =
    useLocationCriteria(booking.config?.criteria?.locations ?? null);

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(allLocations[0]);
  const googleMapEl = useRef<HTMLDivElement | null>(null);
  const googleMap = useGoogleMap(googleMapEl);
  const { addMarker, resetMarkers } = useGoogleMapMarkers(googleMap);
  const getLocationFromCountryCode = useGetLocationFromCountryCode();

  const navigate = useNavigate();
  const location = useLocation();

  // set a default location
  useEffect(() => {
    // if there is a selected location or there are already locations, do nothing
    if (selectedLocation || allLocations.length > 0) {
      return;
    }

    const defaultCountry = booking.team?.settings?.billing?.location?.country ?? details?.location?.country;
    if (!defaultCountry) {
      console.warn('Could not determine default country for location');
      return;
    }

    (async () => {
      const result = await getLocationFromCountryCode(defaultCountry);
      if (result?.data) {
        const _location = result.data.getLocationByAddress;
        onLocationAdded({ ..._location, location: _location?.formatted_address } as Location);
      }
    })();
  }, [allLocations]);

  // set markers
  useEffect(() => {
    if (!googleMap) {
      return;
    }
    resetMarkers();
    bounds.forEach((bound) => {
      if (bound.latitude && bound.longitude) {
        addMarker({ lat: bound.latitude, lng: bound.longitude }, bound.formatted_address!);
      }
    });

    [...states, ...countries].forEach(({ latitude, longitude, formatted_address, state, country }) => {
      if (latitude && longitude) {
        addMarker({ lat: latitude, lng: longitude }, formatted_address!, state ?? country);
      }
    });
  }, [states, bounds, countries, googleMap]);

  useEffect(() => {
    if (bookingUtils.isInPerson(booking)) {
      navigate(`/booking-setup/${booking._id}/audience/panel`);
      return;
    }
    props.updateLastStep({
      step: 'Audience',
      subStep: `/booking-setup/${props.booking._id}/audience/participant-locations`,
      stepId: 'audience_participant_locations',
    });
  }, []);

  useEffect(() => {
    props.renderRightContent(
      <PriceCardRightContent context={props.context} booking={booking} bookingSteps={props.bookingSteps} />,
    );
    props.renderRightAppPreview(null);
    if (_.isEqual(booking.config?.criteria?.locations, locationCriteria)) {
      return;
    }
    saveBookingData(locationCriteria);
  }, [locationCriteria]);

  const saveBookingData = (data: LocationCriteria | null) => {
    const dataToUpdate = {
      config: {
        criteria: {
          locations: {
            states: data?.states ?? [],
            bounds: data?.bounds ?? [],
            countries: data?.countries ?? [],
          },
        },
      },
    };
    navigate(location.pathname, { state: { booking: dataToUpdate, bookingState: booking } });
    props.updateBooking(booking, dataToUpdate);
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/audience/demographic-filters`;
    const dataToUpdate = {
      config: {
        criteria: {
          locations: {
            states: states ?? [],
            bounds: bounds ?? [],
            countries: countries ?? [],
          },
        },
      },
    };
    navigate(redirectTo, { state: { booking: dataToUpdate, bookingState: booking } });
  };

  const onLocationAdded = async (value: Location) => {
    // update to the new location
    setSelectedLocation(value);

    // add it to the list of criteria
    const updatedLocation = addLocation(value);
    if (updatedLocation) {
      saveBookingData(updatedLocation);
    }
  };

  const selectedLocationType = useMemo(() => {
    if (!selectedLocation) {
      return 'bounds';
    }
    if (isCountry(selectedLocation)) {
      return 'country';
    }
    if (isState(selectedLocation)) {
      return 'state';
    }
    return 'bounds';
  }, [selectedLocation, countries, states]);

  return (
    <div className="createBookingContent flex flex-col gap-4">
      <h1 id="__pageTitle" className="title">
        Participant location
      </h1>

      {isLoading ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      <div className="mapPreview rounded-md" id="mapPreviewContainer" ref={googleMapEl}>
        {googleMap ? (
          <GoogleMapsMultiRegion
            id="locationMap"
            map={googleMap}
            style={{ width: 548, height: 233 }}
            type={selectedLocationType}
            location={selectedLocation}
          />
        ) : null}
      </div>

      <ParticipantLocationsList
        locations={allLocations}
        currentLocation={selectedLocation}
        onSelectLocation={setSelectedLocation}
        onDeleteLocation={(_location) => {
          const nextLocation = allLocations.find((loc) => loc !== _location);
          setSelectedLocation(nextLocation ?? null);
          removeLocation(_location);
        }}
      />

      <LocationAutocomplete
        placeholder="Type to add more participant locations"
        onNewPlace={onLocationAdded}
        type="(regions)"
        styleWidth={548}
        clearValue
        setIsLoading={setIsLoading}
        canChangeCountry
      />

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default ParticipantLocationsMultiRegion;
