import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import { LoadingOverlay, Button, Modal } from 'components/common';
import { userUtils } from 'lib/user';
import { update, utils } from 'lib/utils';
import { cherryPickingUtils } from 'lib/cherryPicking';
import { ListPlus } from 'lucide-react';

// Styles
import './bulkInviteParticipantsToSessions.scss';

// External components
import ConfirmationMessageInvitationDialog from './confirmationMessage';
import InviteToSession from '../viewInviteToSession';

type Props = any;
type State = any;

class BulkInviteParticipantsToSessions extends Component<Props, State> {
  cachedSessionUniqueness: any;

  preAssignedSessions: any;

  timezone: any;

  constructor(props: any) {
    super(props);
    this.state = {
      assignedSessions: [],
      openInviteToSessionDropdown: false,
      autoSelect: false,
    };

    this.timezone = _.get(this.props.booking, 'config.timezone') || utils.getCurrentTimezone();
    this.cachedSessionUniqueness = [];
    this.preAssignedSessions = [];

    this.onAutoSelectSessions = this.onAutoSelectSessions.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderAvailableSessions = this.renderAvailableSessions.bind(this);
    this.onTimeSelectedForParticipant = this.onTimeSelectedForParticipant.bind(this);
    this.loadInvitedParticipantSession = this.loadInvitedParticipantSession.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentWillMount() {
    this.loadInvitedParticipantSession();
  }

  onAutoSelectSessions() {
    // Function to auto assign people to different sessions
    // First step: Order array of people by priority
    //   1.1) People with less availability
    // Second step: Loop through participants and assign them to the most unique time
    //   2.1) Define the uniqueness of a time slot
    //     2.1.1) Define a non-zero number of how many people have applied for that session
    //   2.2) Order uniqueness array by the most unique
    // Third step: Pick the most unique session that hasnt yet been picked
    //   3.1) Loop through array until it finds an unique session that hasn't been picked yet
    //   3.2) If it finds an available sessions not yet picked it should cache it
    //   3.3) If it doesn't find any session available, then pick the first one even though it has been selected and cache it. It will flag as a clash on the UI
    // Fourth Step: Save the pre assigned cached sessions into state to be reflected into the UI
    //   4.1) Normalise the data to be send to state
    //   4.2) Finally, sets the state

    // Reset the local variables
    this.resetState();

    // Get invited participants session
    this.loadInvitedParticipantSession();

    // Sets the auto select state to reflect what the user has clicked
    this.setState({ autoSelect: true });

    // Filter available participants
    const bulkAvailableParticipants = _.filter(
      this.props.bulkSession.participants,
      (participant: any) => participant.availableParticipant,
    );

    // Filter sessions to get only the ones in the future
    // It should also filters cancelled sessions
    const filteredParticipants = _.map(bulkAvailableParticipants, (participants: any) => {
      return {
        ...participants,
        sessions: _.filter(participants.sessions, (session: any) => {
          return !utils.isPast(session.session.start) && session.cancel === 0;
        }),
      };
    });

    // Step 1.1
    const orderedParticipantsByPriority = _.orderBy(
      filteredParticipants,
      (o: any) => {
        return o.sessions.length;
      },
      ['asc'],
    );

    // Second Step
    orderedParticipantsByPriority.forEach((participant: any) => {
      // Step 2.1
      const uniquenessRateSessionsArray = _.map(participant.sessions, (session: any) => {
        // Step 2.1.1
        return {
          ...session,
          uniquenessRate: this.getUniquenessRateForSession(session._session_id, orderedParticipantsByPriority),
          submission_id: participant.submission_id,
        };
      });

      // Test whether a participant has at least one available session
      if (_.size(uniquenessRateSessionsArray) > 0) {
        // Step 2.2
        const orderedByUniquenessRateArray = utils.orderArrayByField(
          uniquenessRateSessionsArray,
          'uniquenessRate',
          'asc',
        );

        // Step 3.1
        const uniqueSessionAvailable = this.getUniqueSessionNotYetPicked(orderedByUniquenessRateArray);
        // Step 3.2
        if (_.has(uniqueSessionAvailable, '_session_id')) {
          this.preAssignedSessions.push(uniqueSessionAvailable);
        } else {
          // Step 3.3
          this.preAssignedSessions.push(_.sample(orderedByUniquenessRateArray));
        }
      }
    });
    // Step 4.1
    const normalisedSessionsArray = this.normalisePreAssignedSessions(this.preAssignedSessions);

    // Step 4.2
    this.setState((prevState: any) => ({
      assignedSessions: update(prevState.assignedSessions, {
        $push: normalisedSessionsArray,
      }),
    }));
  }

  onSelectSession(event: any, participant: any) {
    if (event) event.preventDefault();
    this.setState({
      openInviteToSessionDropdown: true,
      anchorEl: event.currentTarget,
      participantSelected: {
        available_times: participant.sessions,
        user: participant.user,
        submission_id: participant.submission_id,
      },
    });
  }

  onTimeSelectedForParticipant(selectedTime: any) {
    const sessionSelectedForParticipant = _.findIndex(
      this.state.assignedSessions,
      (item: any) => item.submission_id === selectedTime.submission_id,
    );
    if (sessionSelectedForParticipant > -1) {
      this.setState({ openInviteToSessionDropdown: false }, () => {
        setTimeout(() => {
          this.setState((prevState: any) => ({
            assignedSessions: update(prevState.assignedSessions, {
              $auto: {
                [sessionSelectedForParticipant]: {
                  $set: selectedTime,
                },
              },
            }),
          }));
        }, 100);
      });
    } else {
      this.setState({ openInviteToSessionDropdown: false }, () => {
        setTimeout(() => {
          this.setState((prevState: any) => ({
            assignedSessions: update(prevState.assignedSessions, {
              $push: [selectedTime],
            }),
          }));
        }, 100);
      });
    }
  }

  getUniquenessRateForSession(session_id: any, dataSet: any) {
    // Method responsible for defining how unique a session is within a set data
    // Only defines a new rate for the session if it hasn't been defines yet in the cached momoized array

    // First thing -> Checks whether the session_id is not cached
    const cachedValue = _.find(this.cachedSessionUniqueness, (item: any) => item.session_id === session_id);
    if (_.has(cachedValue, 'uniquenessRate')) return cachedValue.uniquenessRate;

    // If no value was found cached, then it should define the uniqueness value of a session
    let uniquenessRate = 0;
    dataSet.forEach((participant: any) => {
      _.each(participant.sessions, (session: any) => {
        if (session._session_id === session_id) uniquenessRate += 1;
      });
    });
    this.cachedSessionUniqueness.push({ session_id, uniquenessRate });
    return uniquenessRate;
  }

  getUniqueSessionNotYetPicked(dataSet: any) {
    // Method responsible to test whether or not a session has been picked already
    // It should return the first object in the array that is not yet assigned
    let uniqueSessionToReturn = {};
    // First thing -> Loop the dataSet and get the first session
    _.each(dataSet, (session: any) => {
      // Test whether or not this session has been pre assigned already
      const cachedSession = _.find(this.preAssignedSessions, (item: any) => item._session_id === session._session_id);
      if (!cachedSession) {
        // If it's not cached yet, then it should return it
        uniqueSessionToReturn = session;
        // Once it finds a match it should return the value
        // Return false breaks the look on lodash
        return false;
      }
    });
    return uniqueSessionToReturn;
  }

  getInvitedSession(participant: any) {
    const session = _.filter(participant.sessions, (item: any) => item.status === 4);
    return session;
  }

  loadInvitedParticipantSession() {
    // Filter invited participants
    const bulkInvitedParticipants = _.filter(
      this.props.bulkSession.participants,
      (participant: any) => participant.invitedParticipant,
    );

    _.map(bulkInvitedParticipants, (participant: any) => {
      const invitedSession = this.getInvitedSession(participant);
      const normalisedSessionsArray = this.normalisePreAssignedSessions(invitedSession, true);

      this.setState((prevState: any) => ({
        assignedSessions: update(prevState.assignedSessions, {
          $push: normalisedSessionsArray,
        }),
      }));
    });
  }

  normalisePreAssignedSessions(preAssignedSessions: any, participantReinvitation = false) {
    // Method to normalise the pre assigned sessions array into something recognisable by the state
    return _.map(preAssignedSessions, (item: any) => {
      return {
        booking_participant_id: item._id,
        sessionSelected: {
          _id: item.session._id,
          start: item.session.start,
          end: item.session.end,
        },
        submission_id: item.submission_id,
        bookingParticipantSelected: item,
        participantReinvitation,
      };
    });
  }

  resetState() {
    this.cachedSessionUniqueness = [];
    this.preAssignedSessions = [];
    this.setState({
      assignedSessions: [],
      autoSelect: false,
    });
  }

  renderAvailableSessions(participant_sessions: any) {
    const groupedByDateTimes = _.groupBy(participant_sessions, (sess: any) =>
      momentTimezone(sess.session.start).tz(this.timezone).format('ddd Do MMM'),
    );
    return _.map(groupedByDateTimes, (date: any, key: any) => {
      return (
        <div key={`dateContainer-${key}`} className="dateContainer">
          <p className="date">{key}</p>
          <p className="times">
            {date.map((time: any, index: any) => {
              const isPast = utils.isPast(time.session.start);
              const cellContent = ` ${momentTimezone(time.session.start).tz(this.timezone).format('h:mma')}${date.length > index + 1 ? ',' : ''}`;
              return (
                <span className={`${time.cancel !== 0 ? 'cancelled' : ''} ${isPast ? 'past' : ''}`} key={time}>
                  {cellContent}
                </span>
              );
            })}
          </p>
        </div>
      );
    });
  }

  renderSelectedSessionTitle(session: any) {
    return <span>{momentTimezone(session).tz(this.timezone).format('h:mm A, ddd Do MMM')}</span>;
  }

  renderSessionData(participant: any) {
    if (participant.invitedParticipant) {
      const invitedSession = this.getInvitedSession(participant);
      return (
        <div className="selectedSessionContainer">
          <p>{this.renderSelectedSessionTitle(_.get(invitedSession, '[0].session.start'))}</p>
        </div>
      );
    }
    // Check whether or not a session has been assigned to this user
    // If no session has been assigned, it should show a link to assign
    const sessionSelectedForParticipant = _.filter(
      this.state.assignedSessions,
      (item: any) => item.submission_id === participant.submission_id,
    );
    if (_.size(sessionSelectedForParticipant) > 0) {
      // Test whether or not the selected session has a clash with some other session
      const hasClash =
        _.size(
          _.filter(
            this.state.assignedSessions,
            (item: any) => item.sessionSelected._id === _.get(sessionSelectedForParticipant, '[0].sessionSelected._id'),
          ),
        ) > 1;
      return (
        <div className={`selectedSessionContainer ${hasClash ? 'hasClash' : ''}`}>
          <p>{this.renderSelectedSessionTitle(_.get(sessionSelectedForParticipant, '[0].sessionSelected.start'))}</p>
          <a onClick={(event) => this.onSelectSession(event, participant)}>Edit</a>
        </div>
      );
    }
    return <a onClick={(event) => this.onSelectSession(event, participant)}>Select Session</a>;
  }

  renderTable(participant: any) {
    const orderedSessions = utils
      .orderArrayByField(participant.sessions, 'session', 'asc', 'start')
      .map((ppt: any) => cherryPickingUtils.getAvailableTimes(ppt));
    const validSessions = _.map(orderedSessions, (item: any) => {
      if (momentTimezone(item.session.start).tz(this.timezone).diff(momentTimezone(), 'minutes') > 0) return item;
    }).filter(Boolean);

    return (
      <div className="tableRow" key={participant.submission_id}>
        <div className="firstColumn cell">
          <p>{userUtils.getFullName(participant.user)}</p>
        </div>
        <div className="secondColumn cell">{this.renderSessionData(participant)}</div>
        <div className="thirdColumn cell available_times_container">
          {participant.availableParticipant && this.renderAvailableSessions(validSessions)}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        modal={false}
        onClose={() => {
          this.props.onCloseInvitationDialog();
          this.resetState();
        }}
        className={`bulkInviteParticipantsToSessionsContainer ${this.props.showConfirmationMessage ? 'confirmationMessage' : ''}`}
        repositionOnUpdate
      >
        <>
          {this.props.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
          {this.props.showConfirmationMessage ? (
            <ConfirmationMessageInvitationDialog
              {...this.props}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ confirmationMessage: string; }' is not ass... Remove this comment to see the full error message
              confirmationMessage="Participants have been invited successfully"
            />
          ) : (
            <>
              <h3>{`Invite ${_.size(this.props.bulkSession.participants)} selected applicants`}</h3>
              <div className="participantsTable">
                <div className="header">
                  <div className="headerRow">
                    <div className="firstColumn cell">
                      <p className="font--bold">Name</p>
                    </div>
                    <div className="secondColumn cell">
                      <p className="font--bold">Session</p>
                      <div className="autoSelectContainer">
                        <ListPlus color="#3b99f0" className="h-4 w-4" />
                        <a onClick={this.onAutoSelectSessions}>Auto select</a>
                      </div>
                    </div>
                    <div className="thirdColumn cell">
                      <p className="font--bold">Availability</p>
                    </div>
                  </div>
                </div>
                <div className="content">{_.map(this.props.bulkSession.participants, this.renderTable)}</div>
              </div>
              <div className="buttonsContainer">
                <Button
                  label="Send Invites"
                  size="default"
                  variant="default"
                  className="btnSendInvite"
                  onClick={() => {
                    this.props.onConfirm(this.state.assignedSessions);
                    this.resetState();
                  }}
                  disabled={_.size(this.state.assignedSessions) === 0}
                />
                {this.state.autoSelect === false && (
                  <p>
                    Please select session times for all participants.{' '}
                    <a onClick={this.onAutoSelectSessions}>Select automatically</a>?
                  </p>
                )}
              </div>
            </>
          )}
          <InviteToSession
            client={this.props.client}
            open={this.state.openInviteToSessionDropdown}
            anchor={this.state.anchorEl}
            submissions={_.get(this.props.data, 'findAllBookingSubmissions')}
            participant={this.state.participantSelected}
            allParticipantsSessions={this.props.allParticipantsSessions}
            booking={this.props.booking}
            onTimeSelected={this.onTimeSelectedForParticipant}
            onClose={() => this.setState({ openInviteToSessionDropdown: false })}
            onCloseAll={() => {
              this.setState({ openInviteToSessionDropdown: false });
              if (this.props.onClose) this.props.onClose();
            }}
          />
        </>
      </Modal>
    );
  }
}

export default BulkInviteParticipantsToSessions;
