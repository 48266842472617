import _ from 'lodash';
import moment from 'moment';
import { bookingParticipantUtils } from 'lib/bookingParticipant';
import { bookingSubmissionUtils } from 'lib/bookingSubmission';
import { userUtils } from 'lib/user';
import { utils } from './utils';
import { bookingUtils } from './booking';

const csv = {
  getTemplate(entity: any, data: any) {
    switch (entity) {
      case 'Applicants':
        return csv.formatApplicationsToExport(data);
      case 'Sessions':
        return csv.formatSessionsToExport(data);
      default:
        return csv.formatParticipantsToExport(data);
    }
  },
  formatParticipantsToExport(data: any) {
    // This function should return an array of plain objects based on participants data
    // Fields that will return:
    // First name
    // Last name
    // Email
    // Phone Number
    // Post Code
    // Gender
    // Age
    const participantsArray: any = [];
    if (data.length > 0) {
      data.forEach((participant: any) => {
        participantsArray.push({
          id: participant._id ? participant._id : '',
          firstname:
            participant.meta && participant.meta.identity && participant.meta.identity.firstname
              ? participant.meta.identity.firstname
              : '',
          lastname:
            participant.meta && participant.meta.identity && participant.meta.identity.lastname
              ? participant.meta.identity.lastname
              : '',
          email: participant.email ? participant.email : '',
          phone_number:
            participant.contact && participant.contact.phone && participant.contact.phone.mobile
              ? participant.contact.phone.mobile
              : '',
          postal_code: participant.location && participant.location.postal_code ? participant.location.postal_code : '',
          gender:
            participant.meta && participant.meta.identity && participant.meta.identity.gender
              ? participant.meta.identity.gender
              : '',
          age:
            participant.meta &&
            participant.meta.identity &&
            participant.meta.identity.birthday &&
            participant.meta.identity.birthday.year
              ? utils.getCurrentYear() - participant.meta.identity.birthday.year
              : '',
        });
      });
    }
    return participantsArray;
  },
  formatApplicationsToExport({ header, content, booking }: any) {
    // Fields to be exported on Face to face and Remote jobs
    // User Id
    // Status
    // Participant Name
    // Eligible
    // Allocated Session
    // Application Time
    // Age
    // Gender
    // Marital Status
    // English Level
    // Employment status
    // Executive Level
    // Industry
    // Job Title
    // Location
    // Dynamic questions
    // Available Times
    // Fields to be exported on quant jobs
    // User Id
    // Status
    // Participant Name
    // Eligible
    // Registered
    // Age
    // Gender
    // Marital Status
    // English Level
    // Employment status
    // Executive Level
    // Industry
    // Job Title
    // Location
    // Dynamic questions
    // Duration
    const dynamicHeader = header.filter((item: any) => utils.isObjectId(item.id));
    const applicantsArray: any = [];
    let mainArray = {};

    if (content.length > 0) {
      content.forEach((submission: any) => {
        const dynamicContent = _.filter(submission, (item: any, key: any) => utils.isObjectId(key));
        // if (_.size(dynamicContent) === 0) return null;

        mainArray = {
          'User Id': _.get(submission, 'user._id'),
          Status: submission.status,
          'Participant Name': submission.name,
          'Participant Email': _.get(submission.user, 'email'),
          'Participant Phone': utils.formatPhoneNumber(_.get(submission.user, 'contact.phone.mobile')),
        };

        if (!bookingUtils.isBookingAutomated(booking)) {
          Object.assign(mainArray, { Eligible: `${submission.eligibility * 100} %` });
        }

        // Return different fields for quant jobs
        if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
          Object.assign(mainArray, { 'Allocated Session': _.get(submission, 'session.label', '') });
          Object.assign(mainArray, { 'Application Time': moment(submission.created).format('DD/MM/YYYY HH:mm') });
        } else {
          Object.assign(mainArray, { Registered: moment(submission.created).format('hh:mm A dddd Do MMM') });
        }

        if (_.has(submission, 'age')) {
          Object.assign(mainArray, { Age: submission.age });
        }
        if (_.has(submission, 'gender')) {
          Object.assign(mainArray, { Gender: submission.gender });
        }
        if (_.has(submission, 'marital_status')) {
          Object.assign(mainArray, { 'Marital Status': submission.marital_status });
        }

        if (_.has(submission, 'english_level')) {
          Object.assign(mainArray, { 'English Level': submission.english_level });
        }
        if (_.has(submission, 'employment')) {
          Object.assign(mainArray, { 'Employment Status': submission.employment });
        }

        if (bookingUtils.isBookingForProfessionals(booking)) {
          Object.assign(mainArray, { 'Executive Level': _.get(submission, 'executive_level') });
          Object.assign(mainArray, { Industry: _.get(submission, 'industry_details') });
          Object.assign(mainArray, { 'Job Title': _.get(submission, 'job_title') });
        }

        Object.assign(mainArray, { Location: submission.location });

        dynamicHeader.forEach((item: any, index: any) => {
          // It should merge all answers to display it
          // This happens when a question is marked as "Allow multiple selections"

          // Mutate the header to get rid of the '.' on the header. It is breaking the export because we use the
          // header as a index on the array and when you use a dot in a index it gets compiled as a dot notation.
          // Remove ',' because csv uses this as a delimiter, remove break lines and replace double quotes to
          // single quotes

          const normalizedHeader = item.header
            .replaceAll('.', ';')
            .replaceAll(',', '')
            .replaceAll('\n', ' ')
            .replaceAll('"', "'");
          const mutatedHeader = `${index + 1}. ${normalizedHeader}`;
          const cell = {
            [mutatedHeader]: dynamicContent[index]
              .map((answer: any) => ` ${answer.screen_in ? '✅' : '❌'} ${answer.label}`)
              .toString(),
          };
          Object.assign(mainArray, cell);
        });

        if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
          // Filter down non cancelled available times
          const filteredAvailableTimes =
            _.filter(submission.available_times, (time: any) => time.cancel === 0 && time.session !== null) || [];
          const availableTimes =
            filteredAvailableTimes.length > 0 &&
            filteredAvailableTimes
              .map((time: any) => ` ${moment(time.session.start).format('ddd Do MMM h:mma')}`)
              .toString();
          Object.assign(mainArray, { 'Available Times': availableTimes });
        } else {
          Object.assign(mainArray, {
            Duration: `${submission.duration} ${_.get(submission, 'duration') > 0 ? 'minutes' : ''}`,
          });
        }

        // Object.assign(mainArray, { 'Participant Email': _.get(submission, 'user.email') });

        applicantsArray.push(mainArray);
      });
    }
    return applicantsArray;
  },
  formatSessionsToExport(exportData: any) {
    // Fields that will return:
    // Session
    // Status
    // Participant
    // Gender
    // Age
    // Location
    // Family Status
    // Employment status
    // Quetions Answers
    // Dynamic questions
    const upcomingSessionsArray: any = [];

    exportData.sessions.forEach((session: any) => {
      session.participants.forEach((participant: any) => {
        const mainArray = {
          'User Id': participant._user_id,
          Session: `${moment(session.start).format('ddd Do MMM hh:mm')}-${moment(session.end).format('hh:mma')}`,
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          Status: bookingParticipantUtils
            .getStatus(exportData.booking)
            .find((item: any) => item.value === participant.status).label,
          Participant: `${_.get(participant.user, 'meta.identity.firstname')} ${_.get(participant.user, 'meta.identity.lastname')}`,
          'Participant Email': _.get(participant.user, 'email'),
          'Participant Phone': utils.formatPhoneNumber(_.get(participant.user, 'contact.phone.mobile')),
          Gender: _.get(participant.user, 'meta.identity.gender'),
          Age: userUtils.getAge(participant.user),
          Location: `${_.get(participant.user, 'location.city')} ${_.get(participant.user, 'location.postal_code', '')}`,
          'Marital Status': userUtils.getMaritalStatus(participant.user),
          'Employment Status': userUtils.getEmploymentStatus(participant.user),
          'English Level': userUtils.getEnglishLevel(participant.user),
          'Answers ->': '',
        };
        // Loop through questions
        _.each(exportData.booking.config.question, (question: any) => {
          let answer;
          const data = _.get(participant.Submission, 'data') || [];
          // Short answer
          if (question.config.type === 2) {
            answer = _.get(
              data.find((item: any) => item._question_id === question._id),
              'value',
            );
          } else {
            const answers = bookingSubmissionUtils.getAnswersForDynamicQuestions(data, question);
            answer = answers.map((item: any) => ` ${item.screen_in ? '✅' : '❌'} ${item.label}`).toString();
          }
          const cell = { [question.title]: answer };
          Object.assign(mainArray, cell);
        });
        upcomingSessionsArray.push(mainArray);
      });
    });
    return upcomingSessionsArray;
  },
};

export { csv };
