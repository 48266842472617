import { graphql } from 'src/graphql';

export const JoinNufpMutation = graphql(`
  mutation joinNUFP($_id: ID!) {
    joinNUFP(_id: $_id) {
      _id
      name
      created
      parent_team
      settings {
        billing {
          registration
          company_name
          contact_first_name
          contact_last_name
          contact_email
          preferred
          stripe {
            customer_id
          }
          location {
            street1
            level
            city
            state
            postal_code
            country
          }
          subscription {
            end
            credit {
              remaining
            }
          }
          xero_id
        }
        ignore_tracking
        sso {
          company_domains
          team_sso_url
          team_sso_id
          idp_sso_url
          idp_certificate
          idp_metadata
          idp_issuer
        }
        recruitment_page {
          logo
          title
          blurb
        }
      }
      users {
        _id
        added_date
        invited_by
        status
        role
        User {
          _id
          email
          meta {
            identity {
              firstname
              lastname
              middlename
              gender
            }
            social {
              google {
                id
              }
            }
            work {
              title
              company_name
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
      }
    }
  }
`);
