import { useTranslation } from 'react-i18next';

export const NoResults = () => {
  const { t } = useTranslation();

  return (
    <section className="flex flex-col gap-2 rounded-xl border border-solid border-border bg-background p-4 shadow-md dark:bg-accent">
      <h3 className="text-md font-semibold leading-tight">{t('sections.discover.noResultsTitle')}</h3>
      <p className="text-sm text-muted-foreground">{t('sections.discover.noResultsDescription')}</p>
    </section>
  );
};
