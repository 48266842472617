import React, { useEffect } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { ResearcherCertificationStatus } from 'generated/graphql';
import { userUtils } from 'lib/user';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useIsKinde } from './AuthWrapper';
import { useAddUTMToAppState } from 'src/hooks/useAddUTMToAppState';

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, login } = useKindeAuth();
  const isKinde = useIsKinde();
  const navigate = useNavigate();
  const { addUTMToAppState } = useAddUTMToAppState();

  const { authState, details: clientDetails } = useConnectedClient();

  const location = useLocation();

  const isDemoClientUser = userUtils.isDemoClient(clientDetails?.roles);
  // Demo clients shouldn't use any private route
  if (isDemoClientUser) localStorage.reset();
  // Scroll to top

  useEffect(() => {
    if (authState !== 'unauthenticated') {
      return;
    }

    let stateTo = { from: location };
    if (location?.search) {
      // @ts-expect-error
      stateTo = { from: { pathname: `${location.pathname}${location.search}` } };
    }

    if (isKinde) {
      if (isAuthenticated) {
        return;
      }

      const searchParams = new URLSearchParams(location.search);

      const userType = searchParams.get('user_type') as 'researcher' | 'client' | null;

      if (!userType) {
        if (import.meta.env.VITE_ENVIRONMENT !== 'PROD') {
          navigate('/login-select');
          return;
        }

        window.location.replace('https://www.askable.com/login');
        return;
      }

      login({
        app_state: addUTMToAppState({
          user_type: userType,
          redirect_uri: window.location.href,
        }),
      });

      return;
    }

    navigate('/login', {
      state: stateTo,
    });
  }, [authState]);

  // It should check whether it is a not certified researcher trying to sign in. If it is it should redirect to the certification process
  if (
    clientDetails?.type?.researcher &&
    clientDetails?.researcher?.certification?.status !== ResearcherCertificationStatus.Certified
  ) {
    return (
      <Navigate
        replace
        to={{
          pathname: '/researcher/certification',
        }}
      />
    );
  }

  if (!clientDetails?.type?.researcher && clientDetails?.Teams?.length === 0 && !location.pathname.includes('/teams')) {
    return (
      <Navigate
        replace
        to={{
          pathname: '/teams',
        }}
      />
    );
  }

  if (authState !== 'authenticated') {
    return null;
  }

  return children;
};
