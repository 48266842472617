import { gql } from '@apollo/client';
import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export default gql`
  subscription FetchTeamByIdSubscription($_id: ID!) {
    teamById(_id: $_id) {
      ...teamFields
    }
  }
  ${teamFieldsFragment}
`;
