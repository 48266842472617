import { gql } from '@apollo/client';
import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';
import { manageBookingPageCalendarFragment } from 'data/fragments/booking/manageBookingPageCalendar';

export default gql`
  mutation rescheduleSessionLiveBooking(
    $booking_id: ID!
    $session_id: ID!
    $newSession: BookingInputSession!
    $message: String
    $shouldMessage: Boolean
  ) {
    rescheduleSessionLiveBooking(
      booking_id: $booking_id
      session_id: $session_id
      newSession: $newSession
      message: $message
      shouldMessage: $shouldMessage
    ) {
      ...bookingFields
      ...ManageBookingPageCalendar
    }
  }
  ${bookingFieldsFragment}
  ${manageBookingPageCalendarFragment}
`;
