import { useEffect, useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { Textarea } from '@askable/ui/components/ui/textarea';

function ProjectBrief(props: any) {
  const askablePlusBriefSteps = _.get(props, 'askablePlusBriefSteps');
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [keyObjectives, setKeyObjectives] = useState(_.get(askablePlusBrief, 'askable_plus.objective'));
  const [keyObjectivesError, setKeyObjectivesError] = useState('');
  const [projectContext, setProjectContext] = useState(_.get(askablePlusBrief, 'askable_plus.description'));
  const [projectContextError, setProjectContextError] = useState('');

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/project-brief`,
        stepId: 'project_setup_project_brief',
      });
      props.renderRightContent();

      if (_.get(askablePlusBriefSteps, 'project_setup_key_objective') === 'error') setKeyObjectivesError('Required');
      if (_.get(askablePlusBriefSteps, 'project_setup_project_context') === 'error') setProjectContextError('Required');
    }
  }, []);

  const onChangeKeyObjectives = (value: any) => {
    if (!value) {
      setKeyObjectivesError('Required');
    } else {
      setKeyObjectivesError('');
    }

    setKeyObjectives(value);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        objective: value,
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onChangeProjectContext = (value: any) => {
    if (!value) {
      setProjectContextError('Required');
    } else {
      setProjectContextError('');
    }

    setProjectContext(value);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        description: value,
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/research-type`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h2 className="font-bold">Key objective</h2>

      <div className="flex flex-col gap-2">
        <Label htmlFor="key-objectives">
          What's the main outcome that you would like from completing this project?
        </Label>
        <Textarea
          id="key-objectives"
          autoFocus
          placeholder="Gain insights into consumer purchasing habits"
          required
          value={keyObjectives}
          rows={2}
          maxRows={10}
          onChange={(e) => onChangeKeyObjectives(e.target.value)}
          variant={keyObjectivesError ? 'error' : undefined}
        />
        {keyObjectivesError ? <div className="text-xs text-destructive">Required</div> : null}
      </div>

      <h2 className="title ptop40">Project context</h2>

      <div className="flex flex-col gap-2">
        <Label htmlFor="key-objectives">Tell us everything we need to know about the project.</Label>
        <Textarea
          id="project-context"
          placeholder={
            "• We're expanding our product offering and have designed a prototype to illustrate the new flow in our app\n• On top of usability testing the prototype, we would like to learn more about how our consumers spend their money"
          }
          required
          value={projectContext}
          rows={4}
          maxRows={12}
          onChange={(e) => onChangeProjectContext(e.target.value)}
          variant={projectContextError ? 'error' : undefined}
        />
        {projectContextError ? <div className="text-xs text-destructive">Required</div> : null}
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ProjectBrief);
