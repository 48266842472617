import { graphql } from 'src/graphql';

export const UploadProfileImageMutation = graphql(`
  mutation UploadProfileImage($input: NewUploadInput!) {
    createUpload(input: $input) {
      put_file_url
      upload {
        url
      }
    }
  }
`);
