import { gql } from '@apollo/client';

export default gql`
  mutation purchaseCredits($purchase_credits: purchaseCreditsInput!) {
    purchaseCredits(purchase_credits: $purchase_credits) {
      _id
      Transaction {
        _id
      }
    }
  }
`;
