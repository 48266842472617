import gql from 'graphql-tag';

export default gql`
  mutation revokeParticipantInvitation($participant_session_id: ID!, $sms_message: String!) {
    revokeParticipantInvitation(participant_session_id: $participant_session_id, sms_message: $sms_message) {
      _id
      status
      session {
        _id
      }
    }
  }
`;
