import { graphql } from 'src/graphql';

export default graphql(`
  query getLocationByAddress($address: String, $country: String, $types: [String]) {
    getLocationByAddress(address: $address, country: $country, types: $types) {
      formatted_address
      country
      state
      latitude
      longitude
      google_location_types
      google_location {
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
    }
  }
`);
