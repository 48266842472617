import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@askable/ui/components/ui/dialog';

type Props = {
  title?: string;
  modal?: boolean;
  open: boolean;
  onClose?: () => void;
  onRequestClose?: (_: any) => void;
  className?: string;
  bodyClassName?: string;
  titleClassName?: string;
  styles?: any;
  repositionOnUpdate?: boolean;
  ignorePaddingOnUpdate?: boolean;
  children?: any;
};

/**
 * @deprecated Use new AskableUI Modal
 */
const Modal = (props: Props) => {
  let customStyles = {};
  if (props.repositionOnUpdate === false) {
    if (!props.ignorePaddingOnUpdate) {
      customStyles = { paddingTop: 10 };
    }
  }
  if (props.styles) Object.assign(customStyles, props.styles);

  return (
    <Dialog open={props.open} modal={props.modal} onOpenChange={(val) => !val && props?.onClose?.()}>
      <DialogContent className={props.className || ''} style={customStyles}>
        <DialogHeader>
          <DialogTitle className={props.titleClassName || ''}>{props.title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className={props.bodyClassName || ''}>{props.children}</DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
