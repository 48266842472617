import { gql } from '@apollo/client';
import { eSignatureTemplateFieldsFragment } from 'data/fragments/esignature_templates/eSignatureTemplateFields';

export default gql`
  query eSignatureTemplatesByTeam($_team_id: ID!) {
    eSignatureTemplatesByTeam(_team_id: $_team_id) {
      ...eSignatureTemplateFields
      AgreementBookings {
        _id
        status
        name
      }
    }
  }
  ${eSignatureTemplateFieldsFragment}
`;
