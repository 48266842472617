import React, { useEffect, useState, Fragment } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Card } from 'components/common';
import { Button } from '@askable/ui/components/ui/button';
import { askablePlusUtils } from 'lib/askablePlus';

// External components
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';

// Styles
import '../styles/sessionFormatStyles.scss';

function DiscoveryInterviewSessionFormat(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [sessionFormat, setSessionFormat] = useState(
    _.get(askablePlusBrief, 'askable_plus.research_type.discovery.session.type'),
  );

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected <= 1) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`);
    } else if (!hasDiscoveryInterviewType) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/usability-testing/quota`);
    } else {
      props.updateLastStep({
        step: 'Discovery Interview',
        subStep: `/askable-plus/${askablePlusBrief._id}/discovery-interview/session-format`,
        stepId: 'discovery_interview_session_format',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onClickCard = (value: any) => {
    setSessionFormat(value);
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          discovery: {
            ...askablePlusBriefState.askable_plus.research_type.discovery,
            session: {
              ...askablePlusBriefState.askable_plus.research_type.discovery.session,
              type: value,
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/usability-testing/quota`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Session format</h1>
      <p className="label">What format would you like the research to be done in?</p>
      <div className="sessionFormatTypes">
        <Card
          title="Individual Interviews"
          className="sessionFormatCard"
          content={[
            {
              label:
                'One participant in a classic interview style usability test. This session can include multiple facilitators, observers and note takers.',
            },
          ]}
          selected={sessionFormat === 1}
          onClickCard={() => onClickCard(1)}
        />
        <Card
          title="Focus Group"
          className="sessionFormatCard"
          content={[
            {
              label:
                'Have up to 50 participants all together in one group session. Or multiple focus groups can be scheduled.',
            },
          ]}
          selected={sessionFormat === 2}
          onClickCard={() => onClickCard(2)}
        />
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(DiscoveryInterviewSessionFormat);
