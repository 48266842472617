import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ClockIcon: CreateIconType = createIcon({
  displayName: 'ClockIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path d="M8 1.57a6.43 6.43 0 100 12.86A6.43 6.43 0 008 1.57zM0 8a8 8 0 1116 0A8 8 0 010 8z" fill="currentColor" />,
    <path
      d="M8 4.343c.433 0 .785.352.785.785v2.561l1.502 1.502a.785.785 0 11-1.11 1.11L7.445 8.569a.785.785 0 01-.23-.555V5.128c0-.433.352-.785.785-.785z"
      fill="currentColor"
    />,
  ],
});
