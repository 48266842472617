import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const OpenRightIcon: CreateIconType = createIcon({
  displayName: 'OpenRightIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M1 8L10.8929 8M7.57141 11.2813C9.01114 10.1017 9.77945 9.38242 10.9018 7.9998C9.77945 6.61719 9.01114 5.89794 7.57141 4.71836M14.5714 1L14.5714 15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
