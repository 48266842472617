import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ArrowRightIcon: CreateIconType = createIcon({
  displayName: 'ArrowRightIcon',
  defaultProps: defaultOptions,
  path: [
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M13 7L1 7" />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.00388 3.00391C10.7572 4.44038 11.6332 5.31628 13 7C11.6332 8.68372 10.7572 9.55961 9.00388 10.9961"
    />,
  ],
});
