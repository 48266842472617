import { ReactNode } from 'react';
import { X } from 'lucide-react';
import { Input } from '@askable/ui/components/ui/input';
import { Button } from '@askable/ui/components/ui/button';

type Props<TData> = {
  pageCount: number;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onResetColumnFilters: () => void;
  isFiltered: boolean;
  children: ReactNode;
};

export const Toolbar = <TData extends unknown>({
  isFiltered,
  children,
  onResetColumnFilters,
  onSearchChange,
  pageCount,
  searchValue,
}: Props<TData>) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          disabled={pageCount === 0 && !isFiltered}
          placeholder="Search"
          value={searchValue}
          onChange={(event) => onSearchChange(event.target.value)}
          className="h-8 w-[150px] sm:w-[288px]"
        />
        {children}
        {isFiltered && (
          <Button variant="ghost" onClick={onResetColumnFilters} className="hidden h-8 px-2 sm:inline-flex lg:px-3">
            Reset
            <X className="ml-2" size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};
