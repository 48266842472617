import { Button } from '@askable/ui/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@askable/ui/components/ui/form';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { SettingsContainer } from 'containers/Settings/components/SettingsContainer';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { z } from 'zod';
import { UpdateTeamResearch } from './data/UpdateTeamResearch.mutation';
import invariant from 'tiny-invariant';
import { toast } from '@askable/ui/components/ui/sonner';

const formSchema = z.object({
  researchContext: z.string(),
});

export const TeamResearchContainer = () => {
  const { t } = useTranslation();
  const { details, actions } = useConnectedClient();
  const [{ fetching }, updateResearch] = useMutation(UpdateTeamResearch);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      researchContext: details?.ConnectedTeam?.research?.context ?? '',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    invariant(details?.ConnectedTeam?._id, 'Team ID is required');

    const { error } = await updateResearch({
      teamId: details.ConnectedTeam._id,
      update: {
        research: {
          context: values.researchContext,
        },
      },
    });

    if (error) {
      toast.error(error.message);
      return;
    }

    // @TODO - remove this when we move from apollo to urql. It should be updated automatically once this happens
    actions.updateClientDetails({
      ConnectedTeam: {
        ...details.ConnectedTeam,
        research: {
          context: values.researchContext,
        },
      },
    });

    toast.success(t('confirmations.updated', { title: 'Team research' }));
  };

  return (
    <SettingsContainer title={t('sections.settings.team.research.title')}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="researchContext"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('sections.settings.team.research.contextLabel')}</FormLabel>
                <FormControl>
                  <Textarea {...field} className="min-h-28" />
                </FormControl>
                <FormDescription>{t('sections.settings.team.research.contextDescription')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" variant="primary" disabled={fetching} isLoading={fetching}>
            {t('global.save')}
          </Button>
        </form>
      </Form>
    </SettingsContainer>
  );
};
