import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const DarkModeIcon: CreateIconType = createIcon({
  displayName: 'DarkModeIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M14 10.2605C13.0733 12.4537 10.8384 14 8.22857 14C4.78862 14 2 11.3137 2 8.00001C2 4.68629 4.78862 2 8.22857 2C9.04752 2 9.82957 2.15226 10.5459 2.42898C8.77262 2.98705 7.49152 4.59338 7.49152 6.48794C7.49152 8.84576 9.47571 10.7571 11.9233 10.7571C12.6737 10.7571 13.3806 10.5775 14 10.2605Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
