import { extendTheme, PinInputField } from '@chakra-ui/react';
import { Alert } from './components/Alert/Alert';
import { ButtonBadgeGroup } from './components/ButtonBadgeGroup/ButtonBadgeGroupConfig';
import { Button } from './components/Button/Button';
import { Input } from './components/Form/Input';
import { Select } from './components/Form/Select';
import { Slider } from './components/Form/Slider';
import { Heading } from './components/Heading/Heading';
import { Link } from './components/Link/Link';
import { MessageComponent } from './components/Message/Message';
import { PageHeader } from './components/PageHeader/PageHeaderConfig';
import { Progress } from './components/Progress/Progress';
import { Text } from './components/Text/Text';
import { tableTheme } from './components/Table/Table';
import { Tag } from './components/Tag/Tag';
import { Tutorial } from './components/Tutorial/TutorialConfig';
import { checkboxTheme } from './components/Checkbox/Checkbox';
import { drawerTheme } from './components/Drawer/Drawer';
import { breadcrumbTheme } from './components/Breadcrumb/Breadcrumb';
import { PaginationTheme } from './components/Pagination/Pagination';
import { TextareaGroup } from './components/Textarea/TextareaGroupConfig';
import { textareaTheme } from './components/Textarea/Textarea';
import { popOverTheme } from './components/Popover/Popover';
import { menuTheme } from './components/Menu/Menu';
import { skeletonTheme } from './components/Skeleton/Skeleton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const theme: any = extendTheme({
  fonts: {
    heading: 'Wigrum, sans-serif',
    body: "'inter', sans-serif",
  },
  styles: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    global: (props: any) => {
      return {
        body: {
          bg: props.colorMode === 'dark' ? 'hsl(223 10% 14%)' : 'hsl(0 0% 100%)',
        },
      };
    },
  },
  components: {
    Alert,
    Button,
    Breadcrumb: breadcrumbTheme,
    Table: tableTheme,
    Checkbox: checkboxTheme,
    Tag,
    Link,
    Popover: popOverTheme,
    Heading,
    Menu: menuTheme,
    MessageComponent,
    PageHeader,
    Progress,
    Skeleton: skeletonTheme,
    Slider,
    PinInputField,
    Text,
    Input,
    ButtonBadgeGroup,
    Textarea: textareaTheme,
    TextareaGroup,
    Tutorial,
    Pagination: PaginationTheme,
    Select,
    Drawer: drawerTheme,
  },
  semanticTokens: {
    colors: {
      background: {
        default: 'white',
        _dark: 'gray.800',
      },
      text: {
        default: 'gray.800',
        _dark: 'white',
      },
      textSubdued: {
        default: 'gray.600',
        _dark: 'whiteAlpha.700',
      },
      border: {
        default: 'gray.200',
        _dark: 'whiteAlpha.300',
      },
    },
  },
  colors: {
    white: '#ffffff',
    black: '#000000',
    whiteAlpha: {
      50: 'rgba(255,255,255,0.04)',
      100: 'rgba(255,255,255,0.06)',
      200: 'rgba(255,255,255,0.08)',
      300: 'rgba(255,255,255,0.16)',
      400: 'rgba(255,255,255,0.24)',
      500: 'rgba(255,255,255,0.36)',
      600: 'rgba(255,255,255,0.48)',
      700: 'rgba(255,255,255,0.64)',
      800: 'rgba(255,255,255,0.80)',
      900: 'rgba(255,255,255,0.92)',
    },
    blackAlpha: {
      50: 'rgba(0,0,0,0.04)',
      100: 'rgba(0,0,0,0.06)',
      200: 'rgba(0,0,0,0.08)',
      300: 'rgba(0,0,0,0.16)',
      400: 'rgba(0,0,0,0.24)',
      500: 'rgba(0,0,0,0.36)',
      600: 'rgba(0,0,0,0.48)',
      700: 'rgba(0,0,0,0.64)',
      800: 'rgba(0,0,0,0.80)',
      900: 'rgba(0,0,0,0.92)',
    },
    gray: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
    red: {
      50: '#FFF5F5',
      100: '#FED7D7',
      200: '#FEB2B2',
      300: '#FC8181',
      400: '#F56565',
      500: '#E53E3E',
      600: '#C53030',
      700: '#9B2C2C',
      800: '#822727',
      900: '#63171B',
    },
    green: {
      50: '#F2F8F7',
      100: '#D8E9E6',
      200: '#B1D3CD',
      300: '#69ADA8',
      400: '#3C978F',
      500: '#38A169',
      600: '#00665E',
      700: '#07534D',
      800: '#053834',
      900: '#012421',
    },
    orange: {
      50: '#FFFAF0',
      100: '#FEEBC8',
      200: '#FBD38D',
      300: '#F6AD55',
      400: '#ED8936',
      500: '#DD6B20',
      600: '#C05621',
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
    yellow: {
      50: '#FFF8EB',
      100: '#FFEAC2',
      200: '#FFD98F',
      300: '#FFCE70',
      400: '#FFC75C',
      500: '#FFBC3B',
      600: '#FFAB0A',
      700: '#F5A100',
      800: '#7A5100',
      900: '#523600',
    },
    blue: {
      50: '#EBF8FF',
      100: '#D6F0FF',
      200: '#99DAFF',
      300: '#52A8FF',
      400: '#3D8BFF',
      500: '#004BE0',
      600: '#004BE0',
      700: '#002B80',
      800: '#002160',
      900: '#00143D',
    },
    brand: {
      50: '#FFF8F9',
      100: '#FFD6DB',
      200: '#FFB3BB',
      300: '#FF808E',
      400: '#FF6678',
      500: '#FF5065',
      600: '#FF334B',
      700: '#E71F36',
      800: '#C51329',
      900: '#850010',
    },
    yellowAlpha: {
      300: 'rgba(255,217,143,0.16)',
      400: 'rgba(255,217,143,0.24)',
      500: 'rgba(255,217,143,0.32)',
      800: 'rgba(255,188,59,0.16)',
      900: 'rgba(255,188,59,0.24)',
    },
    purple: {
      50: '#F0EFFF',
      100: '#CECDFF',
      200: '#ACAAFF',
      300: '#9997FF',
      400: '#817FFF',
      500: '#6865FF',
      600: '#514FDD',
      700: '#3E3BBB',
      800: '#2C2A99',
      900: '#1E1C77',
    },
  },
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
    '3xl': '120em',
  },
});
