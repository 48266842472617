import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderColor: 'gray.500',
  },
  // There is no way to respectively override label from Checkbox component itself instead of using variants
  // We need overflow hidden to enable text-overflow ellipsis in label children
  label: {
    overflow: 'hidden',
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    scrollablePopover: {
      // For the use case of checkboxes within scrollable popover we need the label to
      // be able to cover the full width of the checkbox
      label: {
        overflow: 'visible',
        minW: '64',
        maxW: '64',
      },
    },
  },
}) as any;
