import moment from 'moment';
import momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { utils } from 'lib/utils';
import { Project, ProjectAskablePlusDeliverables, ProjectUserStatus } from 'generated/graphql';
import { BOOKING_STATUS } from './constants';

const askablePlusUtils = {
  isProjectForProfessionals(askablePlusBrief: any) {
    // 1 - General, 2 - Professionals
    return _.get(askablePlusBrief, 'askable_plus.audience.booking_config.participant_category') === 2;
  },
  getCriteriaValue(params: any, askablePlusBrief: any) {
    const criteria = _.get(askablePlusBrief, 'askable_plus.audience.booking_config.criteria');
    if (criteria && criteria[params.parent]) {
      const result = criteria[params.parent].filter((item: any) => {
        if (params.operator) return item.field === params.field && item.operator === params.operator;
        if (params.value) return item.field === params.field && item.value === params.value;
        return item.field === params.field;
      })[0];
      if (result) return result.value;
    }
    return null;
  },
  getAgeRange(askablePlusBrief: any) {
    const askablePlusBriefMaxAge =
      askablePlusUtils.getCriteriaValue(
        { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'gt' },
        askablePlusBrief,
      ) || moment().subtract(199, 'y').valueOf();
    const askablePlusBriefMinAge =
      askablePlusUtils.getCriteriaValue(
        { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'lt' },
        askablePlusBrief,
      ) || moment().subtract(18, 'y').valueOf();
    const minAge = utils.getCurrentYear() - utils.getYearFromTimestamp(askablePlusBriefMinAge);
    let maxAge = askablePlusBriefMaxAge
      ? utils.getCurrentYear() - utils.getYearFromTimestamp(askablePlusBriefMaxAge)
      : 100;
    if (maxAge === 199) maxAge = 100;
    const ageRange = utils.getRangeNumbers(minAge, maxAge);
    ageRange.push('100+');
    return ageRange;
  },
  getParticipantsGender(askablePlusBrief: any) {
    const maleGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'male' },
      askablePlusBrief,
    );
    const femaleGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'female' },
      askablePlusBrief,
    );
    const nonBinaryGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'non-binary' },
      askablePlusBrief,
    );
    if (maleGender && femaleGender) return 1;
    if (maleGender) return 2;
    if (femaleGender) return 3;
    if (nonBinaryGender) return 0;
    return 1;
  },
  getAgesTitle(askablePlusBrief: any) {
    const currentYear = utils.getCurrentYear();
    const askablePlusBriefMaxAge = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'gt' },
      askablePlusBrief,
    );
    const askablePlusBriefMinAge = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_birthday_year', field: 'user.meta.identity.birthday.timestamp', operator: 'lt' },
      askablePlusBrief,
    );
    const minAge = askablePlusBriefMinAge ? currentYear - utils.getYearFromTimestamp(askablePlusBriefMinAge) : '18';
    const maxAge = askablePlusBriefMaxAge ? currentYear - utils.getYearFromTimestamp(askablePlusBriefMaxAge) : '100+';
    return <p className="formField">{`Ages ${minAge} - ${Number(maxAge) > 100 ? '100+' : maxAge}`}</p>;
  },
  getGenderTitle(askablePlusBrief: any) {
    const maleGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'male' },
      askablePlusBrief,
    );
    const femaleGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'female' },
      askablePlusBrief,
    );
    const nonBinaryGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'non-binary' },
      askablePlusBrief,
    );

    if (maleGender && femaleGender) return <p className="formField">All genders</p>;
    if (maleGender) {
      return (
        <p className="formField">
          Males <strong>only</strong>
        </p>
      );
    }
    if (femaleGender) {
      return (
        <p className="formField">
          Females <strong>only</strong>
        </p>
      );
    }
    if (nonBinaryGender) {
      return (
        <p className="formField">
          Non-binary <strong>only</strong>
        </p>
      );
    }
    return <p className="formField">All genders</p>;
  },
  getCurrentGenderValue(askablePlusBrief: any) {
    const maleGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'male' },
      askablePlusBrief,
    );
    const femaleGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'female' },
      askablePlusBrief,
    );
    const nonBinaryGender = askablePlusUtils.getCriteriaValue(
      { parent: 'meta_identity_gender', field: 'user.meta.identity.gender', value: 'non-binary' },
      askablePlusBrief,
    );
    if (maleGender && femaleGender) return 1;
    if (maleGender) return 2;
    if (femaleGender) return 3;
    if (nonBinaryGender) return 0;
    return 1;
  },
  totalResearchTypesSelected(askablePlusBrief: any) {
    let totalResearches = 0;
    _.map(askablePlusUtils.researchTypes(), (type: any) => {
      if (_.get(askablePlusBrief, `askable_plus.research_type.[${type.key}].quota`)) totalResearches += 1;
    });
    return totalResearches;
  },
  hasDiscoveryInterviewType(askablePlusBrief: any) {
    return _.get(askablePlusBrief, 'askable_plus.research_type.discovery.quota') > 0;
  },
  hasUsabilityTestingType(askablePlusBrief: any) {
    return _.get(askablePlusBrief, 'askable_plus.research_type.usability.quota') > 0;
  },
  hasCompetitiveAnalysisType(askablePlusBrief: any) {
    return _.get(askablePlusBrief, 'askable_plus.research_type.competitive_analysis.quota') > 0;
  },
  isContinuousDiscoverType(askablePlusBrief: any) {
    return _.get(askablePlusBrief, 'askable_plus.research_type.continuous_ai_moderated.quota') > 0;
  },
  hasSurveyType(askablePlusBrief: any) {
    return _.get(askablePlusBrief, 'askable_plus.research_type.survey.quota') > 0;
  },
  hasLongitudinalStudyType(askablePlusBrief: any) {
    return _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.quota') > 0;
  },
  researchTypes() {
    return [
      { key: 'discovery', label: 'Discovery interview' },
      { key: 'usability', label: 'Usability testing' },
      { key: 'competitive_analysis', label: 'Competitive analysis' },
      { key: 'survey', label: 'Survey' },
      { key: 'longitudinal', label: 'Longitudinal study' },
      { key: 'continuous_ai_moderated', label: 'Continuous AI moderated' },
      { key: 'continuous_researcher_moderated', label: 'Continuous researcher moderated' },
    ];
  },
  deliverables(): {
    key: keyof ProjectAskablePlusDeliverables;
    label: string;
    description: string;
    basicResearchDeliverable: boolean;
  }[] {
    return [
      {
        key: 'research_report',
        label: 'Research report',
        description:
          'In-depth report that includes customer segmentation, insights, quotes, summary of findings, references to best practice, and actionable feedback',
        basicResearchDeliverable: true,
      },
      {
        key: 'video_recordings',
        label: 'Video recordings',
        description: 'Video recordings from every research session with participants',
        basicResearchDeliverable: false,
      },
      {
        key: 'transcriptions',
        label: 'Transcriptions',
        description: 'Transcriptions from every research session with participants',
        basicResearchDeliverable: false,
      },
      {
        key: 'executive_summary',
        label: 'Executive summary',
        description: '1-page report on key objectives and findings, top 2 customer segments, short quotes',
        basicResearchDeliverable: true,
      },
      {
        key: 'highlight_reel',
        label: 'Highlight reel',
        description: '3-minute video of notable clips from the research sessions, quotes, statistics',
        basicResearchDeliverable: false,
      },
      // {
      //     key: 'other',
      //     label: 'Other',
      //     description: 'Other deliverables may incur additional costs'
      // }
    ];
  },
  getProjectLinkURL(project: any) {
    if (_.get(project, 'status') === 0) {
      return { url: `/askable-plus/${project._id}/project-setup/project-title` };
    }
    return { url: `/manage-askable-plus-project/${project._id}`, state: { project } };
  },
  getKeyDescription(category: any, key: any) {
    const dictionary = {
      'meta.education': {
        highschool: 'Highschool',
        some_university: 'University Degree',
        undergraduate: 'Undergraduate Degree',
        postgraduate: 'Postgraduate Degree',
        apprenticeship: 'Apprenticeship',
      },
      'meta.family.status': {
        single: 'Single',
        relationship: 'In a Relationship',
        married: 'Married / Defacto',
        divorced: 'Divorced',
        widowed: 'Widowed',
      },
      'meta.work.status': {
        fulltime: 'Full time work',
        parttime: 'Part time / casual work',
        fulltime_student: 'Full time student',
        parttime_student: 'Part time student',
        homeduties: 'Full time home duties',
        retired: 'Retired',
        unemployed: 'Unemployed',
      },
      'meta.identity.languages.english.speak': {
        native: 'Native',
        fluent: 'Fluent',
        conversational: 'Conversational',
        beginner: 'Beginner',
      },
      'meta.work.business.size': {
        1: 'Myself only',
        2: '2 - 10 employees',
        3: '11 - 50 employees',
        4: '51 - 200 employees',
        5: '201 - 1,000 employees',
        6: 'More than 1,000 employees',
      },
      'meta.work.business.turnover': {
        1: 'Less than $100K',
        2: '$100K - $1M',
        3: '$1M - $5M',
        4: '$5M - $25M',
        5: '$25M - $100M',
        6: 'More than $100M',
      },
    };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return dictionary[category] && dictionary[category][key];
  },
  getEligibilitySummary(criteria: any) {
    const eligibilitySummary = [];
    const ageConditions = [];

    const minAgeTs = _.find(criteria.meta_identity_birthday_year, ['operator', 'lt']);
    const maxAgeTs = _.find(criteria.meta_identity_birthday_year, ['operator', 'gt']);

    if (minAgeTs) {
      const years = moment().diff(parseInt(minAgeTs.value), 'years');
      ageConditions.push({ years, bound: 'min' });
    }
    if (maxAgeTs) {
      const years = moment().diff(parseInt(maxAgeTs.value), 'years');
      if (years <= 65) ageConditions.push({ years, bound: 'max' });
    }

    switch (ageConditions.length) {
      case 1:
        eligibilitySummary.push({
          key: 'meta_identity_birthday_year',
          description:
            ageConditions[0].bound === 'max'
              ? `Under ${ageConditions[0].years} years old`
              : `${ageConditions[0].years}+ years old`,
        });
        break;
      case 2:
        if (ageConditions[0].years === ageConditions[1].years) {
          eligibilitySummary.push({
            key: 'meta_identity_birthday_year',
            description: `${ageConditions[0].years} years old`,
          });
        } else {
          eligibilitySummary.push({
            key: 'meta_identity_birthday_year',
            description: `${ageConditions[0].years} - ${ageConditions[1].years} years old`,
          });
        }
        break;
      default:
    }

    if (criteria.meta_identity_gender && criteria.meta_identity_gender.length === 1) {
      const genderValue = criteria.meta_identity_gender[0].value;
      eligibilitySummary.push({
        key: 'meta_identity_gender',
        description: `${genderValue.substring(0, 1).toUpperCase()}${genderValue.substring(1)}`,
      });
    }

    if (criteria.meta_family_status && criteria.meta_family_status.length > 0) {
      const familyConditions = criteria.meta_family_status.map((condition: any) => {
        const key = condition.field.replace(/^.+\./, '');
        return {
          key,
          description: askablePlusUtils.getKeyDescription('meta.family.status', key),
        };
      });
      eligibilitySummary.push({
        key: 'meta_family_status',
        description: `Family Status: ${familyConditions.length === 1 ? familyConditions[0].description : ''}`,
        children: familyConditions.length > 1 ? familyConditions : [],
      });
    }

    if (criteria.meta_education && criteria.meta_education.length > 0) {
      const educationConditions = criteria.meta_education.map((condition: any) => {
        const key = condition.field.replace(/^.+\./, '');
        return {
          key,
          description: askablePlusUtils.getKeyDescription('meta.education', key),
        };
      });
      eligibilitySummary.push({
        key: 'meta_education',
        description: `Education Level: ${educationConditions.length === 1 ? educationConditions[0].description : ''}`,
        children: educationConditions.length > 1 ? educationConditions : [],
      });
    }

    if (criteria.meta_work_status && criteria.meta_work_status.length > 0) {
      const workStatusConditions = criteria.meta_work_status.map((condition: any) => {
        const key = condition.field.replace(/^.+\./, '');
        return {
          key,
          description: askablePlusUtils.getKeyDescription('meta.work.status', key),
        };
      });
      eligibilitySummary.push({
        key: 'meta_work_status',
        description: `Occupation Status: ${workStatusConditions.length === 1 ? workStatusConditions[0].description : ''}`,
        children: workStatusConditions.length > 1 ? workStatusConditions : [],
      });
    }

    return eligibilitySummary;
  },
  deliverySteps(isResearcher: boolean): {
    key: string;
    group: string;
    icon: string;
    label: string;
    description: string;
    stepNumber: number;
  }[] {
    const userTypeLabel = isResearcher ? 'client' : 'researcher';
    const deliveryStepsOptions = [];

    if (!isResearcher) {
      deliveryStepsOptions.push({
        key: 'receive_deliverables',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Receive deliverables',
        description:
          'You’ll be notified when all the deliverables are submitted and ready to view in **Deliverables**.',
        stepNumber: 8,
      });
    }

    if (isResearcher) {
      deliveryStepsOptions.push({
        key: 'receive_deliverables',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Get deliverables approved',
        description:
          'Attach the rest of the deliverables to **Deliverables**. We’ll review them and give feedback if necessary before handing them over to the client.',
        stepNumber: 9,
      });
    }

    deliveryStepsOptions.push(
      {
        key: 'schedule_debrief',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Schedule a debriefing call',
        description: `Schedule a call between you, the ${userTypeLabel}, and our Askable+ manager, then set the date. We recommend setting it within 1-2 business days.`,
        stepNumber: 10,
      },
      {
        key: 'debrief_scheduled',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Debrief call scheduled',
        description:
          'Let us know what times would work for you. We recommend having the call within 1-2 business days.',
        stepNumber: 11,
      },
      {
        key: 'attend_debrief',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Attend the debriefing call',
        description: '',
        stepNumber: 12,
      },
    );

    if (!isResearcher) {
      deliveryStepsOptions.push({
        key: 'project_completed',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Project completed',
        description:
          'We hope you find the reports insightful! Before wrapping this up, we’d appreciate it if you take your time to fill out the post-project survey #here#',
        stepNumber: 13,
      });
    }

    if (isResearcher) {
      deliveryStepsOptions.push({
        key: 'project_completed',
        group: 'Delivery',
        icon: 'sunsetIcon.svg',
        label: 'Project completed',
        description:
          'That’s a job well done! Ready to cash it in? Send an invoice to **plus@askable.com** for this amount: #projectAmount#',
        stepNumber: 14,
      });
    }

    return deliveryStepsOptions;
  },
  projectCompletedSteps(): { key: string; group: string; icon: string; stepNumber: number }[] {
    return [
      {
        key: 'projected_closed',
        group: 'Project completed',
        icon: 'checkIcon.svg',
        stepNumber: 15,
      },
    ];
  },
  getCurrentStep(project: Project, isResearcher: boolean): string {
    const researchers = project.users?.filter((user) => user?.User?.type?.researcher);
    const meetings = project.askable_plus?.meetings;

    const assignedResearcher = researchers?.find((r) => r?.status === ProjectUserStatus.Accepted);

    // Researcher not assigned
    if (!assignedResearcher) return 'assign_researcher';

    // Brief call not scheduled
    if (!meetings?.briefing?.meeting_date) return 'schedule_brief';

    if (meetings.debriefing?.meeting_date) {
      // Debriefing call is in the past, go to project complete
      if (
        momentTimezone(meetings.debriefing.meeting_date)
          .tz(meetings.debriefing.timezone || '')
          .isBefore()
      ) {
        return 'project_completed';
      }

      // Client not accepted the briefing call date
      if (!isResearcher && !meetings.debriefing.client_confirmed) return 'attend_debrief';

      // Client accepted the briefing call date
      if (!isResearcher && meetings.debriefing.client_confirmed) return 'debrief_scheduled';

      // Researcher not accepted the briefing call date
      if (isResearcher && !meetings.debriefing.client_confirmed) return 'attend_debrief';

      // Researcher accepted the briefing call date
      if (isResearcher && meetings.debriefing.client_confirmed) return 'debrief_scheduled';
    }

    const totalBookings = project.bookings?.length;
    const completedBookings = _.filter(project.bookings, (booking) => booking?.status === BOOKING_STATUS.COMPLETED);
    const activeBookings = _.filter(project.bookings, (booking) => booking?.status === BOOKING_STATUS.COMPLETED);

    // All bookings completed
    if (completedBookings.length === totalBookings) {
      // Brief call not scheduled
      if (!meetings.debriefing?.meeting_date) return 'schedule_debrief';

      return 'receive_deliverables';
    }

    // All bookings active
    if (activeBookings.length === totalBookings) return 'research_progress';

    if (meetings.briefing.meeting_date) {
      // Briefing call is in the past
      if (
        momentTimezone(meetings.briefing.meeting_date)
          .tz(meetings.briefing.timezone || '')
          .isBefore()
      ) {
        return 'review_guide';
      }

      // Client not accepted the briefing call date
      if (!isResearcher && meetings.briefing.client_confirmed) return 'attend_brief';

      // Client accepted the briefing call date
      if (!isResearcher && !meetings.briefing.client_confirmed) return 'brief_scheduled';

      // Researcher not accepted the briefing call date
      if (isResearcher && meetings.briefing.researcher_confirmed) return 'attend_brief';

      // Researcher accepted the briefing call date
      if (isResearcher && !meetings.briefing.researcher_confirmed) return 'brief_scheduled';
    }

    return '';
  },
};

export { askablePlusUtils };
