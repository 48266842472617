import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const AskableSessionsIcon: CreateIconType = createIcon({
  displayName: 'AskableSessionsIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 24 24',
    fill: 'blue.700',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.111 0c7.837.013 10.091 2.268 10.078 10.083-.005 3.15-.377 5.394-1.33 6.951-2.076 4.376-6.69 6.467-8.788 6.966l.007-3.867C4.24 20.119 1.987 17.865 2 10.049 2.013 2.235 4.274-.013 12.111 0ZM8.71 6.765a1.045 1.045 0 0 1 1.08-1.044c.175 0 .353.044.516.138l5.973 3.448a1.026 1.026 0 0 1 .014 1.77l-.05.031-5.98 3.453a1.025 1.025 0 0 1-1.481-.549 1.042 1.042 0 0 1-.072-.381V6.765Z"
    />,
  ],
});
