import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { utils } from 'lib/utils';
import { bookingUtils } from 'lib/booking';
import { Booking } from 'generated/graphql';

const bookingParticipantUtils = {
  getStatus(bookingType: any) {
    if (bookingType === 3 || bookingType === 4) {
      return [
        {
          label: 'Completed - Check submission',
          value: 1,
        },
        {
          label: 'Completed - Paused',
          value: 1,
        },
        {
          label: 'Completed - Paid',
          value: 1,
        },
        {
          label: 'Help requested',
          value: 1,
        },
        {
          label: 'Waitlisted',
          value: 2,
        },
        {
          label: 'Available',
          value: 3,
        },
        {
          label: 'Invited',
          value: 4,
        },
        {
          label: 'In progress',
          value: 5,
        },
        {
          label: 'Auto cancelled',
          value: 6,
        },
        {
          label: 'Cancelled',
          value: 7,
        },
      ];
    }
    return [
      {
        label: 'Completed',
        value: 1,
      },
      {
        label: 'Confirmed',
        value: 1,
      },
      {
        label: 'Happening now',
        value: 1,
      },
      {
        label: 'Waitlisted',
        value: 2,
      },
      {
        label: 'Available',
        value: 3,
      },
      {
        label: 'Invited',
        value: 4,
      },
      {
        label: 'Pending tech check',
        value: 4,
      },
      {
        label: 'Invite declined',
        value: 4,
      },
      {
        label: 'Cancelled',
        value: 7,
      },
    ];
  },
  getNoShowLabels() {
    return [
      {
        label: 'Refund',
        value: 1,
      },
      {
        label: 'Replace',
        value: 2,
      },
    ];
  },
  getStatusDescription(participant: any, session: any) {
    // Check if the user is marked as a NO SHOW
    if (participant.no_show_request > 0) return 'no show';

    // Check if the user is marked as an ISSUE
    if (participant.issue_request > 0) return 'issue';

    // Check if the session has been cancelled
    if (participant.cancel > 0) return 'cancelled';

    // Check for waitlisted
    if (participant.status === 2) return 'waitlisted';

    // Check if participant is available
    if (participant.status === 3) return 'available';

    // Check if participant is invited
    if (participant.status === 4) return 'invited';

    // Check if a session has been completed.
    if (utils.getDifferenceFromNow(session.end) < 0) return 'completed';
    return 'confirmed';
  },
  hasRating(participant: any) {
    return _.get(participant, 'rating.engagement') || _.get(participant, 'rating.overall');
  },
  getDurationTimeToCompleteQuantTask(bookingParticipant: any) {
    const startTime = _.get(bookingParticipant, 'history.last_quantitative_application_time');
    const finishTime = _.get(bookingParticipant, 'history.session_quant_completed');
    if (!startTime || !finishTime) return '';

    return moment(finishTime).diff(moment(startTime), 'minutes');
  },
  normaliseBookingParticipantToSave(participant: any) {
    return {
      _id: participant._id,
      _booking_id: participant._booking_id,
      _session_id: participant._session_id,
      _user_id: participant._user_id,
      cancel: participant.cancel,
      no_show_request: participant.no_show_request,
      issue_request: participant.issue_request,
      status: participant.status,
      created_context: participant.created_context,
    };
  },
  getSessionStatus(session_id: any, bookingParticipants: any) {
    if (!session_id) return;
    // const bookingParticipants = this.props.submissions.map(item => item.BookingParticipant);
    const bookingParticipantsForSession: any = [];
    bookingParticipants.forEach((bookingParticipant: any) => {
      bookingParticipant.forEach((participant: any) => {
        if (participant._session_id === session_id) {
          bookingParticipantsForSession.push(participant);
        }
      });
    });

    // It should check if there's someone confirmed for session
    const confirmedParticipants = bookingParticipantsForSession.filter(
      (participant: any) => participant.status === 1 && participant.cancel === 0,
    );
    if (confirmedParticipants.length > 0) {
      return {
        label: `(${confirmedParticipants.length} ${confirmedParticipants.length === 1 ? 'person' : 'people'} confirmed)`,
        qtd: confirmedParticipants.length,
        status: 'confirmed',
      };
    }
    // It should check how many participants got invited for session
    const invitedParticipants = bookingParticipantsForSession.filter(
      (participant: any) => participant.status === 4 && participant.cancel === 0,
    );
    if (invitedParticipants.length > 0) {
      return {
        label: `(${invitedParticipants.length} ${invitedParticipants.length === 1 ? 'person' : 'people'} invited)`,
        qtd: invitedParticipants.length,
        status: 'invited',
      };
    }

    return '';
  },
  historyTypes(user: any, submission: any, booking?: Booking) {
    const name = _.get(user, 'meta.identity.firstname');
    const gender = _.get(user, 'meta.identity.gender');
    const genderSufix = gender === 'female' ? 'her' : 'his';
    let timezone;
    let sessionStart;
    let facilitator;
    let bookingCurrencySymbol;
    if (booking) {
      timezone = _.get(booking, 'config.timezone');
      sessionStart = momentTimezone(_.get(submission, 'sessionToRender.session.start'))
        .tz(timezone)
        .format(`dddd, Do [of] MMMM [at] hh:mm a${timezone ? '' : ' z'}`);
      facilitator = _.get(booking.config, 'contact.UserFacilitator');
      bookingCurrencySymbol = submission?.incentive?.currency_symbol || '$';
    }

    return [
      {
        label: `${name} has confirmed ${gender === 'female' ? 'she is' : 'he is'} still good for the session`,
        key: 'session_click_confirm_button',
        type: 'status_update',
      },
      {
        label: `${name} applied to this opportunity`,
        key: 'session_opportunity_application',
        type: 'status_update',
      },
      {
        label: `${name} has been invited to attend a session`,
        key: 'session_invitation_notification',
        type: 'status_update',
      },
      {
        label: `${name} has accepted ${genderSufix} invitation`,
        key: 'session_invitation_accepted',
        type: 'status_update',
      },
      {
        label: `${name} has left feedback about the session`,
        key: 'session_completed_feedback_submitted',
        extraInfo: _.get(submission, 'ParticipantFeedback.participant'),
        type: 'status_update',
      },
      {
        label: `${name} has declined an invitation`,
        key: 'session_invitation_declined',
        type: 'status_update',
      },
      {
        label: `${name} has been paid`,
        key: 'session_paid',
        type: 'status_update',
      },
      {
        label: `${name} has cancelled ${genderSufix} attendance`,
        key: 'session_confirmed_cancellation',
        extraInfo: _.get(submission, 'sessionToRender.cancel_reason'),
        type: 'status_update',
      },
      {
        label: `You cancelled ${name}'s attendance`,
        key: 'session_participant_cancelled_by_client',
        type: 'status_update',
      },
      {
        label: `You invited ${name} to complete the online task`,
        key: 'session_quant_invitation_notification',
        type: 'status_update',
      },
      {
        label: `${name}'s is starting the online task`,
        key: 'last_quantitative_application_time',
        type: 'status_update',
      },
      {
        label: `${name} has completed the online task`,
        key: 'session_quant_completed',
        type: 'status_update',
      },
      {
        sms_template: bookingParticipantTemplates.EARLY_CONFIRMATION(
          name,
          sessionStart,
          '[LINK]',
          '[LINK]',
          `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}` ||
            '',
        ),
        key: 'early_confirmation_request',
        type: 'scheduled_message',
      },
      {
        sms_template: bookingParticipantTemplates.SESSION_START_REMINDER({
          participantName: name,
          bookingType: _.get(booking, 'type'),
          sessionStart,
          location: bookingUtils.formatBookingLocation(_.get(booking, 'config.location')),
          hasSpecialInstructions: !!_.get(booking, 'config.information.special_instructions'),
          messageLink: '[LINK]',
          bookingLink: '[LINK]',
          clientName:
            `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}` ||
            '',
        }),
        key: 'session_start_reminder_no_action',
        type: 'scheduled_message',
      },
      {
        sms_template: bookingParticipantTemplates.SESSION_START_REMINDER_ACTION_NEEDED({
          participantName: name,
          bookingType: _.get(booking, 'type'),
          sessionStart,
          location: bookingUtils.formatBookingLocation(_.get(booking, 'config.location')),
          hasSpecialInstructions: !!_.get(booking, 'config.information.special_instructions'),
          bookingLink: '[LINK]',
          clientName:
            `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}` ||
            '',
        }),
        key: 'session_start_reminder_action_needed',
        type: 'scheduled_message',
      },
      {
        sms_template: bookingParticipantTemplates.SESSION_START_REMINDER_ACTION_NEEDED_FOLLOWUP(
          booking?.type || 1,
          name,
          sessionStart,
          bookingUtils.formatBookingLocation(_.get(booking, 'config.location')),
          '[LINK]',
          bookingCurrencySymbol,
          submission?.incentive?.value,
        ),
        key: 'session_start_reminder_action_needed_followup',
        type: 'scheduled_message',
      },
      {
        sms_template:
          _.get(booking, 'type') === 1
            ? bookingParticipantTemplates.SESSION_START_REMINDER_2_ASKABLE_INCENTIVE_FACE_TO_FACE({
                participantName: name,
                sessionStart,
                location: bookingUtils.formatBookingLocation(_.get(booking, 'config.location')),
                hasSpecialInstructions: !!_.get(booking, 'config.information.special_instructions'),
                bookingLink: '[LINK]',
                incentiveAmount: `${bookingCurrencySymbol}${submission?.incentive?.value}`,
                payoutLink: '[LINK]',
                clientName:
                  `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}` ||
                  '',
              })
            : bookingParticipantTemplates.SESSION_START_REMINDER_2_ASKABLE_INCENTIVE_REMOTE({
                participantName: name,
                sessionStart,
                location: bookingUtils.formatBookingLocation(_.get(booking, 'config.location')),
                hasSpecialInstructions: !!_.get(booking, 'config.information.special_instructions'),
                bookingLink: '[LINK]',
                incentiveAmount: `${bookingCurrencySymbol}${submission?.incentive?.value}`,
                payoutLink: '[LINK]',
                clientName:
                  `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}` ||
                  '',
              }),
        key: 'session_start_reminder_2_askable_incentive_message',
        type: 'scheduled_message',
      },
      {
        sms_template: bookingParticipantTemplates.SESSION_COMPLETED_FEEDBACK_REQUEST(
          name,
          '[LINK]',
          `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}`,
        ),
        key: 'session_completed_feedback_request',
        type: 'scheduled_message',
      },
      {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 3.
        sms_template: bookingParticipantTemplates.SESSION_COMPLETED_CLIENT_FEEDBACK_REQUEST(
          name,
          '[LINK]',
          `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}`,
        ),
        key: 'session_completed_client_feedback_request',
        type: 'scheduled_message',
      },
      {
        sms_template: bookingParticipantTemplates.SESSION_INVITATION_FOLLOWUP(
          name,
          sessionStart,
          submission?.incentive?.value,
          bookingCurrencySymbol,
          '[LINK]',
          `${_.get(facilitator, 'meta.identity.firstname', '')} ${_.get(facilitator, 'meta.identity.lastname', '')}` ||
            '',
        ),
        key: 'session_invitation_notification_followup',
        type: 'scheduled_message',
      },
    ];
  },
  getHistoryStatusUpdate(key: any, user: any, submission: any) {
    const historyTypes = bookingParticipantUtils.historyTypes(user, submission);
    const filteredStatusUpdate = _.find(historyTypes, (item: any) => item.key === key);
    if (_.get(filteredStatusUpdate, 'type') !== 'status_update') return null;
    return filteredStatusUpdate;
  },
  getLinkVariationDetails(booking: any, link_id: any) {
    const linkDetails = _.find(_.get(booking, 'config.online_task.links'), (links: any) => links._id === link_id);
    if (!linkDetails) return '';
    return `${_.get(linkDetails, 'name')} - ${_.get(linkDetails, 'url')}`;
  },
};

const bookingParticipantTemplates = {
  EARLY_CONFIRMATION: (userName: any, sessionStart: any, payoutLink: any, messagesLink: any, clientName: any) =>
    `Hey ${userName}, just wanted to remind you that your session is on ${sessionStart}.\nDon't forget to set your preferred payout method here: ${payoutLink}. Any questions or dramas please send me a message by clicking here: ${messagesLink}\n- ${clientName}`,
  // -------------------------------------
  // ------- 24H MESSAGE (NO ACTION) ---------
  // ---------------------------------------
  SESSION_START_REMINDER: (params: any) => {
    let template;

    if (parseInt(params.bookingType, 10) === 1) {
      template = `Hey ${params.participantName}, just a quick reminder that your session is on tomorrow (${params.sessionStart}) at ${params.location}. If you have any dramas on the day, please contact me here: ${params.messageLink}`;
    } else {
      template = `Hey ${params.participantName}, just a quick reminder that your session is on tomorrow (${params.sessionStart}). If you have any dramas on the day, please contact me here: ${params.messageLink}`;
    }

    if (params.hasSpecialInstructions) {
      return `${template}\n\nAlso, make sure to read the special instructions at ${params.bookingLink}\n- ${params.clientName}`;
    }

    return `${template}\n- ${params.clientName}`;
  },
  // -------------------------------------
  // ------- 24H MESSAGE (ACTION NEEDED) ---------
  // ---------------------------------------
  SESSION_START_REMINDER_ACTION_NEEDED: (params: any) => {
    let template;

    if (parseInt(params.bookingType, 10) === 1) {
      template = `Hey ${params.participantName}, your session is on tomorrow (${params.sessionStart}) at ${params.location}. Please go to ${params.bookingLink} and confirm you're still good for your session.`;
    } else {
      template = `Hey ${params.participantName}, your session is on tomorrow (${params.sessionStart}). Please go to ${params.bookingLink} and confirm you're still good for the session.`;
    }

    if (params.hasSpecialInstructions) {
      return `${template}\n\nAlso, make sure to read the special instructions at ${params.bookingLink}\n- ${params.clientName}`;
    }

    return `${template}\n- ${params.clientName}`;
  },
  // -------------------------------------
  // ------- 19H MESSAGE FOLLOWUP ---------
  // ---------------------------------------
  SESSION_START_REMINDER_ACTION_NEEDED_FOLLOWUP: (
    bookingType: number,
    participantName: any,
    sessionStart: any,
    location: any,
    link: any,
    currency: any,
    amount: any,
  ) => {
    if (bookingType === 1) {
      return `${participantName}, you still haven't confirmed that you will attend your session at ${sessionStart} at ${location} paying ${currency}${amount}. Please go to ${link} and click the button to confirm you're still good for the session or your attendance may get cancelled and you may be banned from future opportunities for being unresponsive. \n- Askable Team`;
    }

    return `${participantName}, you still haven't confirmed that you will be ready for your session at ${sessionStart} paying ${currency}${amount}. Please go to ${link} and click the button to confirm you're still good for the session or you may get cancelled and you may be banned from future opportunities for being unresponsive. \n- Askable Team`;
  },
  // ---------------------------------------
  // ------- 2.5H MESSAGE FOLLOWUP ---------
  // ---------------------------------------
  SESSION_START_REMINDER_2_ASKABLE_INCENTIVE_FACE_TO_FACE: (params: any) => {
    let template = `${params.participantName}, your session is TODAY (${params.sessionStart}) at ${params.location}! Please make sure to arrive a few minutes earlier to leave enough time to sign in etc.`;

    if (params.hasSpecialInstructions) {
      template = `${template}\nAlso, make sure to read the special instructions at ${params.bookingLink}`;
    }

    return `${template}\nAfter your session is finished, you'll get another text asking you to confirm your attendance. Your payment of ${params.incentiveAmount} will get processed as soon as you've done that. You can set your payout preferences here: ${params.payoutLink}.\nTo contact the session facilitator (${params.clientName}) or check the full details, please click here: ${params.bookingLink}\n- Askable Team`;
  },
  // ---------------------------------------
  // ------- 2.5H MESSAGE FOLLOWUP ---------
  // ---------------------------------------
  SESSION_START_REMINDER_2_ASKABLE_INCENTIVE_REMOTE: (params: any) => {
    let template = `${params.participantName}, your session is starting at (${params.sessionStart})! Since it's a remote session, please make sure you're ready at least 5 mins beforehand.`;
    template = `${template} Make sure your ${
      params.requiredDevice ? 'laptop' : 'device'
    }'s webcam and microphone works and the battery charged and ready to go. You need to be in a quiet room with a good internet connection.`;

    if (params.hasSpecialInstructions) {
      template = `${template}\nAlso, make sure to read the special instructions at ${params.bookingLink}`;
    }

    return `${template}\nAfter your session is finished, you'll get another text asking you to confirm your attendance. Your payment of ${params.incentiveAmount} will get processed as soon as you've done that. You can set your payout preferences here: ${params.payoutLink}.\nTo contact the session facilitator (${params.clientName}) or check the full details, please click here: ${params.bookingLink}\n- Askable Team`;
  },
  // ---------------------------------------
  // ------- 15 MIN AFTER SESSION ----------
  // ---------------------------------------
  SESSION_COMPLETED_FEEDBACK_REQUEST: (userName: any, feedbackUrl: any, clientName: any) =>
    `${userName}, we hope you enjoyed your session with ${clientName}.\nPlease go to ${feedbackUrl} confirm that you completed your session. \n- Askable Team`,
  SESSION_COMPLETED_CLIENT_FEEDBACK_REQUEST: (
    participantName: any,
    feedbackUrl: any,
    clientName: any,
    facilitatorName: any,
    facilitatorPhone: any,
  ) => {
    if (facilitatorPhone) {
      return `Hi ${facilitatorName}, how did your session go with ${participantName}?\nPlease click here to leave them a rating ${feedbackUrl}\n- Askable Team`;
    }

    return `Hi ${clientName}, how did your session go with ${participantName}?\nPlease click here to leave them a rating ${feedbackUrl} (You are receiving this message because the facilitator ${facilitatorName} has not added their phone number to their profile).\n- Askable Team`;
  },
  SESSION_COMPLETED_CLIENT_FEEDBACK_REQUEST_GROUP: (
    feedbackUrl: any,
    clientName: any,
    facilitatorName: any,
    facilitatorPhone: any,
  ) => {
    if (facilitatorPhone) {
      return `Hi ${facilitatorPhone}, how did your group session go?\nPlease click here to leave a rating for the participants ${feedbackUrl}\n- Askable Team`;
    }

    return `Hi ${clientName}, how did your group session go?\nPlease click here to leave a rating for the participants ${feedbackUrl} (You are receiving this message because the facilitator ${facilitatorName} has not added their phone number to their profile).\n- Askable Team`;
  },
  // ---------------------------------------
  // ------- 4 HOURS AFTER INVITATION ------
  // ---------------------------------------
  SESSION_INVITATION_FOLLOWUP: (
    userName: any,
    sessionStart: any,
    incentive: any,
    currency: any,
    url: any,
    clientName: any,
  ) =>
    `Hey ${userName}, please confirm your spot for the ${sessionStart} (paying ${currency}${incentive}) by going to: ${url}.\nSpots are limited.\n- ${clientName}`,
};

export { bookingParticipantUtils, bookingParticipantTemplates };
