import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Input } from '@askable/ui/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import PhoneNumberInput, {
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from 'react-phone-number-input/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@askable/ui/components/ui/button';
import { useUpdateMeMutation } from 'generated/graphql';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Form,
  FormMessage,
} from '@askable/ui/components/ui/form';
import { CountryCode } from 'libphonenumber-js';

const formSchema = z.object({
  phone_number: z
    .string()
    .min(2, {
      message: 'Phone number is required',
    })
    .refine((data) => isPossiblePhoneNumber(data), {
      message: 'Invalid phone number',
    }),
  country_code: z.string(),
});

export const ResearcherAdditionalDetailsContainer = () => {
  const [updateMe, { loading: savingPhoneNumber }] = useUpdateMeMutation();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone_number: '',
      country_code: localStorage.getItem('countryByIp') ?? 'AU',
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateMe({
      variables: {
        user: {
          contact: {
            phone: {
              country_code: `+${getCountryCallingCode(values.country_code as CountryCode)}`,
              mobile: values.phone_number,
            },
          },
        },
      },
    });

    window.location.reload();
  };

  const watchedCountry = form.watch('country_code');

  return (
    <div className="mt-10 !max-w-lg py-10 md:container">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="space-y-6">
            <FormLabel>Mobile phone number</FormLabel>
            <div className="flex w-full space-x-2">
              <FormField
                control={form.control}
                name="country_code"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Select {...field} onValueChange={field.onChange}>
                          <SelectTrigger className="w-[125px]">
                            <SelectValue placeholder="AU" />
                          </SelectTrigger>
                          <SelectContent>
                            {getCountries().map((country) => {
                              return (
                                <SelectItem key={country} value={country}>
                                  {country} +{getCountryCallingCode(country)}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="phone_number"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <PhoneNumberInput
                        country={watchedCountry as CountryCode}
                        defaultCountry="AU"
                        withCountryCallingCode
                        inputComponent={Input}
                        autoFocus
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            {form.formState.errors.phone_number?.message && (
              <FormMessage variant="error">{form.formState.errors.phone_number?.message}</FormMessage>
            )}
            <FormDescription>This is where we will send research opportunities</FormDescription>
          </div>
          <Button isLoading={savingPhoneNumber} type="submit" variant="primary" className="ml-auto w-full">
            Save
          </Button>
        </form>
      </Form>
    </div>
  );
};
