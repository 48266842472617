import { gql } from '@apollo/client';
import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export default gql`
  query fetchTeamsBySsoDomain($email: String) {
    teamsBySsoDomain(email: $email) {
      ...teamFields
    }
  }
  ${teamFieldsFragment}
`;
