import {
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Modal,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Select,
  FormErrorMessage,
} from 'ui';
import { toast } from '@askable/ui/components/ui/sonner';
// Mutations
import { Maybe } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE, DEFAULT_QUOTA } from 'lib/constants';
import { ChangeEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { RequestCreditsMutation } from './data/RequestCredits.mutation';

type RequestCreditsProps = {
  isOpen: boolean;
  onClose: () => void;
  connectedTeamId?: Maybe<string>;
};

type FormDetails = {
  bookingValue: number;
  totalParticipants: number;
  bookingType: number;
  bookingSubType: number;
  remoteVideoCallTool: string;
  sessionType: number;
  sessionDuration: 30;
  participantCategory: number;
  comment: string;
};

const RequestCreditsContainer: FC<RequestCreditsProps> = ({ connectedTeamId, isOpen, onClose }) => {
  const [{ fetching: loading }, createCreditRequest] = useMutation(RequestCreditsMutation);
  const { register, handleSubmit, reset, control, formState, watch, setValue } = useForm<FormDetails>({
    defaultValues: {
      bookingValue: 2,
      totalParticipants: DEFAULT_QUOTA.REMOTE,
      bookingType: BOOKING_TYPE.REMOTE,
      bookingSubType: 1,
      remoteVideoCallTool: 'askableLive',
      sessionType: 1,
      sessionDuration: 30,
      participantCategory: 1,
      comment: '',
    },
  });

  const bookingTypeWatch = watch('bookingType');
  const bookingSubTypeWatch = watch('bookingSubType');

  const handleClose = () => {
    reset();

    onClose();
  };

  const onSubmit = async ({
    bookingType,
    sessionDuration,
    bookingSubType,
    totalParticipants,
    sessionType,
    comment,
    participantCategory,
    remoteVideoCallTool,
  }: FormDetails) => {
    try {
      const { error } = await createCreditRequest({
        _team_id: connectedTeamId!,
        credit_request: {
          booking_type: bookingType,
          booking_subtype: bookingSubType,
          total_participants: totalParticipants,
          session_type: sessionType,
          duration: sessionDuration,
          participant_category: participantCategory,
          comments: comment,
          remote_video_call_tool: remoteVideoCallTool,
        },
      });
      if (error) {
        throw error;
      }

      toast.success('Your credit request has been sent');

      handleClose();
    } catch (e) {
      toast.error('There was an error sending your credit request');
    }
  };

  // Get all the booking types available and exclude Longitudinal studies because they are quite complex to deal with on the request credits for now
  const bookingTypes = bookingUtils.bookingTypes().filter((type) => type.value !== 4);

  const handleBookingTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newType = parseInt(event.target.value, 10);

    const bookingTypeSetup = bookingTypes.find((item) => {
      if (newType === 32) {
        return item.bookingType === 3 && item.bookingSubType === 2;
      }

      return item.bookingType === newType;
    });

    setValue('bookingValue', newType);
    setValue('bookingType', bookingTypeSetup?.bookingType!);
    setValue('bookingSubType', bookingTypeSetup?.bookingSubType!);
    setValue('remoteVideoCallTool', bookingTypeSetup?.bookingType === 2 ? 'askableLive' : '');

    if (bookingTypeSetup?.bookingSubType === 2) {
      setValue('participantCategory', 1);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Request credits</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <FormControl>
                <FormLabel>Number of participants</FormLabel>
                <Controller
                  name="totalParticipants"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <NumberInput {...rest} onChange={(e) => onChange(+e)}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Research method</FormLabel>

                <Select {...register('bookingValue', { onChange: handleBookingTypeChange, valueAsNumber: true })}>
                  {bookingTypes.map((type) => {
                    return (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              {bookingTypeWatch === 2 && (
                <FormControl>
                  <FormLabel>Remote tool</FormLabel>
                  <Controller
                    name="remoteVideoCallTool"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        onChange={(v) => {
                          if (v === 'askableLive') {
                            setValue('sessionType', 1);
                          }
                          field.onChange(v);
                        }}
                      >
                        <Stack direction="row">
                          <Radio value="askableLive">Askable Sessions (recommended)</Radio>
                          <Radio value="other">Other</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              )}

              {watch('remoteVideoCallTool') !== 'askableLive' ? (
                <FormControl>
                  <FormLabel>Session type</FormLabel>
                  <Controller
                    name="sessionType"
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <RadioGroup {...rest} onChange={(v) => onChange(Number(v))}>
                        <Stack direction="row">
                          {bookingUtils.sessionTypes().map((session) => {
                            return <Radio value={session.value}>{session.label}</Radio>;
                          })}
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              ) : null}
              <FormControl>
                <FormLabel>Session duration</FormLabel>

                <Select {...register('sessionDuration', { valueAsNumber: true })}>
                  {bookingUtils.sessionDurations(bookingTypeWatch, bookingSubTypeWatch)?.map((v) => {
                    return (
                      <option key={v.value} value={v.value}>
                        {v.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Participants demographic</FormLabel>

                <Controller
                  name="participantCategory"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <RadioGroup {...rest} onChange={(v) => onChange(Number(v))}>
                      <Stack direction="row">
                        {bookingUtils.bookingParticipantCategories().map((v) => {
                          return <Radio value={v.value}>{v.label}</Radio>;
                        })}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.comment}>
                <FormLabel>Request details</FormLabel>
                <Textarea {...register('comment', { required: 'Comment is required' })} />
                <FormErrorMessage>{formState.errors.comment?.message}</FormErrorMessage>
                <FormHelperText>
                  Please provide a brief outline of your business and research objectives for this study, scope, output
                  audience, and any other relevant details your administer would need for approving your request (job
                  details, approver, cost centre details etc)
                </FormHelperText>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={loading} variant="ghost" onClick={handleClose} colorScheme="gray">
                Cancel
              </Button>
              <Button isLoading={loading} type="submit" colorScheme="gray">
                Confirm
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default RequestCreditsContainer;
