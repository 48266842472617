import { HTMLChakraProps, ThemingProps, FormControlOptions, Textarea } from '@chakra-ui/react';
import autosize from 'autosize';
import { forwardRef, useRef, ForwardedRef, useEffect } from 'react';

interface AutosizeTextareaProps extends HTMLChakraProps<'textarea'>, ThemingProps<'Textarea'>, FormControlOptions {}
export const AutosizeTextarea = forwardRef<HTMLTextAreaElement, AutosizeTextareaProps>((props, parentRef) => {
  const ref = useRef<{ parentRef: ForwardedRef<HTMLTextAreaElement>; internalRef: HTMLTextAreaElement | null }>({
    parentRef,
    internalRef: null,
  });
  useEffect(() => {
    // as per react-hooks/exhaustive-deps message, I assign ref here
    const {
      current: { internalRef },
    } = ref;
    if (internalRef) {
      autosize(internalRef);
    }
    return () => {
      if (internalRef) {
        autosize.destroy(internalRef);
      }
    };
  }, []);

  return (
    <Textarea
      minH="unset"
      w="full"
      ref={(e) => {
        if (!e) {
          return;
        }
        if (typeof ref.current.parentRef === 'function') {
          ref.current.parentRef(e);
        } else if (ref.current.parentRef) {
          ref.current.parentRef.current = e;
        }
        ref.current.internalRef = e;
      }}
      {...props}
      errorBorderColor="red.300"
      focusBorderColor={props.isInvalid ? 'red.300' : 'blue.300'}
    />
  );
});

// In order to properly apply id to component, we need to use forwardRef exported from @chakra-ui/system but
// for now turning off compiler error
// @ts-expect-error
AutosizeTextarea.id = 'Textarea';
AutosizeTextarea.displayName = 'Textarea';
