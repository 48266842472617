import { graphql } from 'src/graphql';

export default graphql(`
  query FetchCountryList {
    countryList {
      _id
      name
      states
      currency_code
      currency_symbol
      country_code
    }
  }
`);
