// module.exports = {
//     ...require('./booking'),
//     ...require('./credit_activity'),
//     ...require('./credits')
// };

import { LocationCriteria, ProjectStatus } from 'generated/graphql';
import { invert } from 'radash';

const BOOKING_STATUS = {
  DRAFT: 0,
  ACTIVE: 1,
  PENDING_REVIEW: 2,
  IN_REVIEW: 3,
  REJECTED: 4,
  COMPLETED: 5,
  PENDING_PAYMENT: 6,
  ARCHIVED: 7,
} as const;

const BOOKING_STATUS_VALUE_TO_KEY = invert(BOOKING_STATUS);

const BOOKING_TYPE = {
  IN_PERSON: 1,
  REMOTE: 2,
  ONLINE: 3,
  LONGITUDINAL_STUDY: 4,
} as const;

const BOOKING_SUBTYPE = {
  ONLINE_TASK: 1,
  SURVEY: 2,
  AI_MODERATED: 3,
};

const CREDIT_ACTIVITY_TYPE = {
  ADMIN_MANUAL: 0,
  CREDIT_USAGE: 1,
  CREDIT_PURCHASE: 2,
  REFUND_NOSHOW: 3,
  REFUND_UNFULIILLED: 4,
  REFUND_CANCELLED_REJECTED: 5,
  REFUND_BAD_PARTICIPANT: 6,
  REFUND_OTHER: 7,
  BOOKING_REQUIREMENT_ADJUSTMENT: 8,
  FREE_PROMOTIONAL: 9,
};

const CREDITS_TYPE = {
  CREDIT_PACK: 1,
  ADHOC: 2,
};

const REQUIRED_DEVICE_TYPE = {
  DESKTOP: 1,
  MOBILE: 2,
  ANY: '',
};

const DEFAULT_QUOTA = {
  IN_PERSON: 10,
  REMOTE: 12,
  ONLINE_TASK: 50,
  SURVEY: 100,
  LONGITUDINAL_STUDY: 10,
  AI_MODERATED: 30,
};

const BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE = {
  ASKABLE_TERMS: 0,
  CUSTOM_AGREEMENT: 1,
};

const BOOKING_ESIGNATURE_TEMPLATE_TYPE = {
  PARTICIPANT_AGREEMENT: 1,
};

const SUBMISSION_AGREEMENT_STATUS = {
  CONSENT_GIVEN: 1,
  CONSENT_DENIED: 2,
  SENT: 3,
  VIEWED: 4,
  DECLINED: 5,
  COMPLETED: 6,
};

export const SUBMISSION_SESSION_STATUS = {
  CONFIRMED: 1,
  WAITLISTED: 2,
  AVAILABLE: 3,
  INVITED: 4,
  IN_PROGRESS: 5,
};

export const MESSAGE_DIRECTION = {
  OTHER: 0, // other or unknown - legacy only
  ASKABLE_TO_PARTICIPANT: 1,
  ASKABLE_TO_CLIENT: 2,
  CLIENT_TO_PARTICIPANT: 3,
  PARTICIPANT_TO_CLIENT: 4,
  ASKABLE_TO_OTHER: 5,
  PARTICIPANT_TO_ASKABLE: 6,
} as const;

export const enum BOOKING_SESSION_STATUS {
  LIVE = 1,
  CANCELLED_BY_ADMIN = 2,
  CANCELLED_BY_CLIENT = 3,
}

const PROJECT_STATUS_KEY_VALUE: Record<ProjectStatus, number> = {
  [ProjectStatus.Draft]: 0,
  [ProjectStatus.Active]: 1,
  [ProjectStatus.Completed]: 5,
  [ProjectStatus.Archived]: 7,
} as const;

const PROJECT_STATUS_VALUE_KEY = invert(PROJECT_STATUS_KEY_VALUE);

enum PROJECT_TYPE {
  // As every study has a _project_id, type is the way to differentiate between standalone study and askable plus study
  // If type is 0, it's a standalone study
  NONE = 0,
  // If type is 1, it's an askable plus study
  ASKABLE_PLUS = 1,
}

export {
  BOOKING_STATUS,
  BOOKING_STATUS_VALUE_TO_KEY,
  BOOKING_TYPE,
  CREDIT_ACTIVITY_TYPE,
  CREDITS_TYPE,
  REQUIRED_DEVICE_TYPE,
  DEFAULT_QUOTA,
  BOOKING_SUBTYPE,
  BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE,
  BOOKING_ESIGNATURE_TEMPLATE_TYPE,
  SUBMISSION_AGREEMENT_STATUS,
  PROJECT_STATUS_KEY_VALUE,
  PROJECT_STATUS_VALUE_KEY,
  PROJECT_TYPE,
};
export const LOCATIONS_KEYS: (keyof Pick<LocationCriteria, 'countries' | 'states' | 'bounds'>)[] = [
  'bounds',
  'states',
  'countries',
];
