import { gql } from '@apollo/client';
import { participantSessionsPageCalendarFragment } from 'data/fragments/bookingSubmission/participantSessionsPageCalendar';

export const manageBookingPageCalendarFragment = gql`
  fragment ManageBookingPageCalendar on Booking {
    _id
    session {
      _id
      start
      end
      status
      created
      _researcher_user_id
      ResearcherUser {
        _id
        name
      }
      askable_live_rooms {
        sid
        composition_sid
      }
      CurrentStatus(_booking_id: $booking_id) {
        ...participantSessionsPageCalendar
      }
      participants(_booking_id: $booking_id, participant_status: [1, 2, 4]) {
        _id
        status
        cancel
        no_show_request
        issue_request
        rating {
          # Remove punctuality and engagement here when you can confirm its not being used anymore as it seems to be used used in calendar, but its legacy field so feel free to remove when its safe to do.
          engagement
          punctuality
          overall
        }
      }
    }
  }
  ${participantSessionsPageCalendarFragment}
`;
