import { gql } from '@apollo/client';
import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export const userFieldsFragment = gql`
  fragment userFields on User {
    _id
    email
    picture
    created
    researcher {
      ux_length
      certification {
        status
      }
      onboarding {
        linkedIn
        ux_length
        methods
      }
    }
    _default_team
    contact {
      phone {
        country_code
        mobile
      }
    }
    location {
      name
      postal_code
      city
      state
      country
      latitude
      longitude
      region
    }
    meta {
      identity {
        firstname
        lastname
        middlename
        gender
      }
      social {
        linkedin {
          profile_url
        }
        google {
          id
        }
      }
      work {
        title
        company_name
      }
    }
    settings {
      notification {
        messages {
          email
          sms
          push
        }
        reminders {
          email
          sms
          push
        }
        promotional {
          email
          sms
          push
        }
      }
      tutorial {
        app
      }
      billing {
        registration
      }
    }
    email_verified
    roles
    type {
      client
      researcher
    }
    Teams {
      ...teamFields
    }
    ConnectedTeam {
      ...teamFields
    }
  }
  ${teamFieldsFragment}
`;
