import { graphql } from 'src/graphql';

export default graphql(`
  query getLocationFromGooglePlaceId($placeId: String, $sessionToken: String) {
    getLocationFromGooglePlaceId(placeId: $placeId, sessionToken: $sessionToken) {
      name
      street1
      street2
      city
      postcode
      state
      country
      latitude
      longitude
      level
      timezone
      postal_code
      formatted_address
      google_location_types
      region
      google_location {
        place_id
        name
        address_components {
          long_name
          short_name
          types
        }
        geometry {
          latitude_ne
          longitude_ne
          latitude_sw
          longitude_sw
        }
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
    }
  }
`);
