import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { CircleUserRound, Sparkles } from 'lucide-react';
import { FacilitatorAvatar } from './FacilitatorAvatar';
import { AnalysesMethod } from 'generated/graphql';

export type FacilitatorItemProps = {
  id: string;
  counts?:
    | {
        method: AnalysesMethod;
        count: number;
      }[]
    | null;
  firstName: string;
  lastName: string;
  pictureLink: string | null;
};

export const FacilitatorItem = ({ id, counts, firstName, lastName, pictureLink }: FacilitatorItemProps) => {
  const { t } = useTranslation();
  const aiCount = counts?.find(({ method }) => method === AnalysesMethod.AiModerated)?.count ?? 0;
  const humanCount = counts?.find(({ method }) => method === AnalysesMethod.Moderated)?.count ?? 0;

  // Fallback to 'Unknown' if facilitator name is empty
  const fullName = firstName === '' && lastName === '' ? t('global.unknown') : `${firstName} ${lastName}`;

  return (
    <>
      <div className="col-span-2 flex items-center gap-2 font-medium" data-testid={`facilitator_${id}`}>
        <FacilitatorAvatar
          firstName={firstName || t('global.unknown')}
          lastName={lastName}
          pictureLink={pictureLink ?? ''}
        />
        {fullName}
      </div>

      {counts?.length ? (
        <div className="grid grid-cols-2 gap-2 text-xs text-muted-foreground">
          <Tooltip>
            <TooltipTrigger className="flex items-center gap-1">
              <CircleUserRound className="h-3 w-3" /> {humanCount}
            </TooltipTrigger>
            <TooltipContent>{t('sections.discover.interviewsHumanModerated')}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger className="flex items-center gap-1">
              <Sparkles className="h-3 w-3" /> {aiCount}
            </TooltipTrigger>
            <TooltipContent>{t('sections.discover.interviewsAiModerated')}</TooltipContent>
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};
