import { gql } from '@apollo/client';

export default gql`
  mutation resendInvitation($_id: ID!) {
    resendInvitation(_id: $_id) {
      _id
      email
      last_sent
    }
  }
`;
