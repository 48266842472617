import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const GridIcon: CreateIconType = createIcon({
  displayName: 'GridIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <rect y="3.5" width="4" height="4" rx="1" fill="currentColor" />,
    <rect y="9.5" width="4" height="4" rx="1" fill="currentColor" />,
    <rect x="6" y="3.5" width="4" height="4" rx="1" fill="currentColor" />,
    <rect x="6" y="9.5" width="4" height="4" rx="1" fill="currentColor" />,
    <rect x="12" y="3.5" width="4" height="4" rx="1" fill="currentColor" />,
    <rect x="12" y="9.5" width="4" height="4" rx="1" fill="currentColor" />,
  ],
});
