import { graphql } from 'src/graphql';

export const ArchiveBookingMutation = graphql(`
  mutation archiveBooking($booking_id: ID!) {
    archiveBooking(booking_id: $booking_id) {
      _id
      status
    }
  }
`);
