import { useState } from 'react';
import { useMutation } from 'urql';
import {
  MaybeCreateKindeOrganizationDocument,
  MaybeCreateKindeOrganizationMutation,
  MaybeCreateKindeOrganizationMutationVariables,
  useUpdateMeMutation,
} from 'generated/graphql';
import { useHandleCombinedError } from './useHandleCombinedError';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { ClientDetails } from 'context/ConnectedClientContext';

import fetchUserById from 'data/queries/user/fetchUserById';
import { useIsKinde } from 'containers/Auth/AuthWrapper';

type Args = {
  updateClientDetails: (details: Partial<ClientDetails>) => void;
};

export function useSwitchTeam({ updateClientDetails }: Args) {
  const isKinde = useIsKinde();
  const { login } = useKindeAuth();
  const [updateUser] = useUpdateMeMutation();
  const [switchingTeams, setSwitchingTeams] = useState(false);
  const [{ fetching, error }, triggerSwitchTeam] = useMutation<
    MaybeCreateKindeOrganizationMutation,
    MaybeCreateKindeOrganizationMutationVariables
  >(MaybeCreateKindeOrganizationDocument);

  const { reuse } = useHandleCombinedError(error);

  const switchTeam = async (teamId: string) => {
    setSwitchingTeams(true);
    try {
      const { data, error: triggerSwitchTeamError } = await triggerSwitchTeam({
        id: teamId,
      });

      if (triggerSwitchTeamError) {
        throw triggerSwitchTeamError;
      }

      if (isKinde) {
        login({ org_code: data?.maybeCreateKindeOrganization?.kinde_org_id! });
        return 'kinde_switch';
      }

      updateClientDetails({
        team: {
          id: data?.maybeCreateKindeOrganization?._id,
          name: data?.maybeCreateKindeOrganization?.name,
        },
      });
      // Update user in the db
      return await updateUser({
        variables: {
          user: {
            _default_team: data?.maybeCreateKindeOrganization?._id,
          },
        },
        refetchQueries: [
          {
            query: fetchUserById,
          },
        ],
      });
    } catch (e) {
      setSwitchingTeams(false);
      reuse({ message: (e as { message?: string })?.message ?? 'Error switching team' });
    }
  };

  return {
    switchingTeams: switchingTeams || fetching,
    switchTeam,
  };
}
