import { graphql } from 'src/graphql';

export const ChangeUserStatusMutation = graphql(`
  mutation UpdateUserStatus($_id: ID!, $userId: ID!, $status: Int!) {
    changeUserStatusOnTeam(_id: $_id, userId: $userId, status: $status) {
      _id
      users {
        _id
        role
        status
        added_date
        User {
          displayName
          _id
          email
        }
      }
    }
  }
`);
