import { graphql } from 'src/graphql';

export const SSOLoginMutation = graphql(`
  mutation login(
    $email: String
    $password: String
    $googleToken: String
    $enterpriseLogin: String
    $ssoLogin: String
    $loginToJoinTeam: Boolean
  ) {
    login(
      email: $email
      password: $password
      googleToken: $googleToken
      enterpriseLogin: $enterpriseLogin
      ssoLogin: $ssoLogin
      loginToJoinTeam: $loginToJoinTeam
    ) {
      _id
      access_token
      email
      _default_team
      sso_url
      sso_error
      researcher {
        ux_length
        certification {
          status
        }
        onboarding {
          linkedIn
          ux_length
        }
      }
      type {
        client
        researcher
      }
      meta {
        identity {
          firstname
          lastname
        }
        social {
          google {
            id
          }
        }
      }
      contact {
        phone {
          country_code
          mobile
        }
      }
      roles
      location {
        name
        postal_code
        city
        state
        country
        latitude
        longitude
        region
      }
      TeamRequests {
        _id
        status
      }
    }
  }
`);
