import * as React from 'react';

import { cn } from '@askable/ui/lib/utils';

import { cva, type VariantProps } from 'class-variance-authority';

const inputVariants = cva(
  'border-1 flex w-full items-center resize-none rounded-md border-input bg-background p-2 text-sm leading-none ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground hover:border-input focus-visible:border-input focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:border-ring disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        error: 'border-destructive focus-visible:border-destructive focus-visible:ring-destructive',
        editable:
          'border-0 bg-transparent focus:ring-0 focus-visible:ring-0 focus-visible:border-transparent focus-visible:ring-transparent',
        borderless: 'border-none focus-visible:ring-0',
      },
      inputSize: {
        default: '',
        lg: 'h-10 px-4 text-md file:text-md',
      },
    },
    defaultVariants: {
      inputSize: 'default',
    },
  },
);

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, inputSize, ...props }, ref) => {
    return <input type={type} className={cn(inputVariants({ variant, inputSize, className }))} ref={ref} {...props} />;
  },
);
Input.displayName = 'Input';

export { Input, inputVariants };
