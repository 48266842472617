import React, { useEffect } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { LoadingOverlay } from 'components/common';
import { toast } from '@askable/ui/components/ui/sonner';
import { localStorage } from 'lib/storage';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useMutation } from 'urql';
import { JoinNufpMutation } from './data/JoinNufp.mutation';

function JoinNUFP(props: any) {
  const { details: clientDetails } = useConnectedClient();
  const [{ fetching: loading }, joinNUFP] = useMutation(JoinNufpMutation);

  useEffect(() => {
    const executeAction = async () => {
      const teamId = clientDetails?.team?.id!;
      if (teamId && _.size(localStorage.get('connectedAsClient')) === 0) {
        try {
          const { error } = await joinNUFP({
            _id: teamId,
          });
          if (error) {
            throw error;
          }
          props.history.replace({ pathname: '/' });
        } catch (e: any) {
          if (_.get(e, 'graphQLErrors[0].message')) {
            toast.error(e.graphQLErrors[0].message);
          }
          props.history.replace({ pathname: '/' });
        }
      }
      props.history.replace({ pathname: '/' });
    };
    executeAction();
  }, []);

  if (loading) return <LoadingOverlay />;
  return null;
}

export default deprecatedWithRouter(JoinNUFP);
