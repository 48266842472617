import { useState } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { CreateTeamCard } from './CreateTeamCard';
import { SectionContainer } from './SectionContainer';
import { TeamCard } from './TeamCard';
import { useNavigate } from 'react-router-dom';
import { useSwitchTeam } from 'src/hooks/useSwitchTeam';
import { cn } from '@askable/ui/lib/utils';
import { useIsKinde } from 'containers/Auth/AuthWrapper';

export const YourTeams = () => {
  const { details, actions } = useConnectedClient();
  const kinde = useKindeAuth();
  const isKinde = useIsKinde();
  const navigate = useNavigate();
  const [switchingTo, setSwitchingTo] = useState<string | null>(null);
  const { switchTeam, switchingTeams } = useSwitchTeam({ updateClientDetails: actions.updateClientDetails });

  const handleTeamClick = (id: string, currentOrgCode?: string | null) => async () => {
    setSwitchingTo(id);
    if (isKinde && currentOrgCode === kinde.getOrganization().orgCode) {
      navigate('/', { replace: true });
      return;
    }

    try {
      const result = await switchTeam(id);

      if (result === 'kinde_switch') {
        return;
      }

      navigate('/', { replace: true });
    } catch (e) {
      setSwitchingTo(null);
      // handleError(e as { message: string });
    }
  };

  return (
    <SectionContainer title="Your teams" className={cn({ 'pointer-events-none': switchingTeams })}>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <CreateTeamCard className={cn({ 'pointer-events-none opacity-50': switchingTeams })} />
        {(details?.Teams ?? []).map((team) => {
          return (
            <TeamCard
              disabled={switchingTeams}
              isLoading={switchingTo === team?._id && switchingTeams}
              onClick={handleTeamClick(team?._id!, team?.kinde_org_id)}
              key={team?._id}
              teamId={team?._id!}
              teamName={team?.name!}
              orgCode={team?.kinde_org_id!}
            />
          );
        })}
      </div>
    </SectionContainer>
  );
};
