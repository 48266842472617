import { gql } from '@apollo/client';
import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

export default gql`
  query FetchMessages($search: MessageSearchInput!) {
    messages(search: $search) {
      ...messagesFields
      UserFrom {
        meta {
          identity {
            firstname
          }
        }
      }
    }
  }
  ${messagesFieldsFragment}
`;
