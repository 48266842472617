import React, { useEffect, Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as _ from 'lodash';
import { utils } from 'lib/utils';
import { bookingUtils } from 'lib/booking';
import momentTimezone from 'moment-timezone';
import { Modal, Button, LoadingOverlay } from 'components/common';
import { toast } from '@askable/ui/components/ui/sonner';

// Queries
import fetchBookingSessionsWithParticipants from 'data/queries/booking/fetchBookingSessionsWithParticipants';
// import findParticipantSessionById from 'data/queries/bookingSubmission/findParticipantSessionById';

// Mutations
import revokeParticipantInviteMutation from 'data/mutations/bookingSubmission/revokeParticipantInvitation';

// External components
import RevokeInvitationContentContainer from './revokeInvitationContentContainer';
import RevokeInvitationConfirmationMessage from './revokeInvitationConfirmationMessage';
import { useConnectedClient } from 'context/ConnectedClientContext';

function RevokeInvitationDialog(props: any) {
  const [state, setState] = useState({
    loading: false,
    sms_message: false,
    showConfirmationMessage: false,
  });
  const { details } = useConnectedClient();

  const [revokeParticipantInvitation] = useMutation(revokeParticipantInviteMutation, {
    client: props.client,
  });

  useEffect(() => {
    // Only initialise state on modal [open], not when this hook is also called on modal [close].
    if (props.open) {
      const { booking, singleSession } = props;
      const timezone = _.get(booking, 'config.timezone') || utils.getCurrentTimezone();
      const sessionStart = momentTimezone(singleSession.session.start).tz(timezone);
      const firstName = singleSession.participant.user.meta.identity.firstname;
      let clientName = '';

      if (details?.meta?.identity?.firstname) {
        clientName = details?.meta?.identity?.firstname;
      }
      // Initialise SMS message/Textarea value
      let smsMessage = `Hi ${firstName}, unfortunately we've had to cancel your invitation for the ${sessionStart.format('h:mmA')} session on the ${sessionStart.format(
        'Do MMMM',
      )} in ${booking.config.location.city} that you applied for. Apologies for any inconvenience this may have caused.\n - ${clientName}`;
      if (bookingUtils.isRemote(booking)) {
        smsMessage = `Hi ${firstName}, unfortunately we've had to cancel your invitation for the ${sessionStart.format('h:mmA')} session on the ${sessionStart.format(
          'Do MMMM',
        )} that you applied for. Apologies for any inconvenience this may have caused.\n - ${clientName}`;
      } else if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
        smsMessage = `Hi ${firstName}, unfortunately we've had to cancel your invitation for the ${
          bookingUtils.isOnlineTask(booking) ? 'online task' : 'research'
        } you applied for. Apologies for any inconvenience this may have caused.\n - ${clientName}`;
      }
      setState({
        ...state,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'boolean'.
        sms_message: smsMessage,
        showConfirmationMessage: false,
      });
    }
  }, [props.open]);

  // const getLastInvitedTime = async (invitedSession, booking) => {
  //     const { data } = await apolloFetch.fetch(findParticipantSessionById, { _id: invitedSession._id });
  //     const timezone = _.get(booking, 'config.timezone') || utils.getCurrentTimezone();
  //     const history = _.get(data, 'findParticipantSessionById.history');
  //     return momentTimezone(_.get(history, 'session_invitation_notification_followup') || _.get(history, 'session_invitation_notification')).tz(timezone);
  // };

  // Handler for revoke button click
  const revokeInvitation = async () => {
    setState({ ...state, loading: true });

    const { sms_message } = state;
    const { singleSession } = props;
    const booking_participant_id = singleSession.participant.invitedSession._id;

    // Call Revoke Mutation
    revokeParticipantInvitation({
      variables: {
        participant_session_id: booking_participant_id,
        sms_message,
      },
      refetchQueries: [
        {
          query: fetchBookingSessionsWithParticipants,
          variables: {
            booking_id: props.booking._id,
          },
        },
      ],
    }).catch((e) => {
      toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
    });

    // Revoke is complete; show confirmation message, reset loading state, refresh table and close the modal
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ showConfirmationMessage: true;... Remove this comment to see the full error message
    setTimeout(() => setState({ showConfirmationMessage: true, loading: false }), 600);
  };

  // Used for any cleanup prior to modal close
  const handleClose = () => {
    props.onClose();
  };

  // Keeps SMS message content in sync with TextArea value
  const updateSmsMessage = (msg: any) => {
    setState({
      ...state,
      sms_message: msg,
    });
  };

  const { loading, sms_message, showConfirmationMessage } = state;
  const { open, singleSession } = props;
  const { participant, session } = singleSession;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      styles={{ paddingTop: 'auto', ...props.styles }}
      className="revokeInvitationContentContainer dialogMaxWidth"
      repositionOnUpdate={false}
    >
      {showConfirmationMessage ? (
        <RevokeInvitationConfirmationMessage {...props} showConfirmationMessage onFinishCustom={handleClose} />
      ) : (
        <Fragment>
          {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
          <p>Revoke {_.get(participant, 'user.meta.identity.firstname')}&apos;s invite for this session?</p>
          <RevokeInvitationContentContainer
            participant={participant}
            session={session}
            onSmsMessageChange={(msg: any) => {
              updateSmsMessage(msg);
            }}
            smsMessage={sms_message}
            {...props}
          />
          <div className="buttonsContainer">
            <Button
              label="Revoke Invite"
              variant="default"
              size="default"
              className="revokeInviteBtn"
              onClick={revokeInvitation}
            />
          </div>
        </Fragment>
      )}
    </Modal>
  );
}

export default RevokeInvitationDialog;
