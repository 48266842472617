import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const SupportIcon: CreateIconType = createIcon({
  displayName: 'SupportIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <g clipPath="url(#clip0_659_5547)">
      <path
        d="M3.81524 1.39421C5.2526 1.23133 6.82946 1.10345 8.27593 1.10345C9.72255 1.10345 11.3007 1.23135 12.7387 1.39427C13.7284 1.50637 14.5052 2.29001 14.6136 3.27995L14.6137 3.28097C14.7694 4.71083 14.8966 6.28608 14.8966 7.72414C14.8966 9.16258 14.7694 10.7408 14.6136 12.1725C14.5058 13.1629 13.7287 13.947 12.7386 14.0582C11.3006 14.2196 9.72251 14.3448 8.27593 14.3448C7.01439 14.3448 5.65368 14.2496 4.37282 14.1182L1.10352 14.8966L1.86123 11.4111C1.74272 10.1942 1.65528 8.91153 1.65528 7.72414C1.65528 6.28605 1.78353 4.71079 1.9399 3.28092C2.04819 2.29054 2.82529 1.5064 3.81524 1.39421Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.27238 8.17499C8.27238 7.77657 8.59641 7.53219 9.1681 7.15021C9.69835 6.79591 9.97805 6.3363 9.85364 5.71081C9.72922 5.08533 9.2124 4.56851 8.58691 4.44409C7.60507 4.24878 6.66016 5.02427 6.66016 6.02534"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.27148 10.5902V10.9351" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </g>,

    <defs>
      <clipPath id="clip0_659_5547">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>,
  ],
});
