import { RouteObject, Navigate } from 'react-router-dom';
import { RequireAuth } from 'containers/Auth/RequireAuth';
import { SettingsAccount } from './components/account';
import { SettingsBilling } from './components/billing';
import { SettingsInvoices } from './components/invoices';
import { SettingsMembers } from './components/members';
import { SettingsNotifications } from './components/notifications';
import { SettingsResources } from './components/resources';
import { SettingsTeam } from './components/team';
import { SettingsUsage } from './components/usage';
import { SettingsLayout } from './SettingsLayout';
import { EnterpriseSSO } from './components/team/containers/EnterpriseSSO/EnterpriseSSO';
import { TeamResearchContainer } from './components/team/containers/Research/TeamResearchContainer';

interface SettingsRoutesPermissions {
  ssoManage: boolean;
  researchManage: boolean;
}

export const settingsRoutes = ({ ssoManage, researchManage }: SettingsRoutesPermissions): RouteObject[] => [
  {
    path: 'settings',
    element: (
      <RequireAuth>
        <SettingsLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: 'account',
        element: <SettingsAccount />,
      },
      {
        path: 'notifications',
        element: <SettingsNotifications />,
      },
      {
        path: 'team',
        element: <SettingsTeam />,
      },
      {
        path: 'members',
        element: <SettingsMembers />,
      },
      {
        path: 'billing',
        element: <SettingsBilling />,
      },
      {
        path: 'usage',
        element: <SettingsUsage />,
      },
      {
        path: 'invoices',
        element: <SettingsInvoices />,
      },
      {
        path: 'billing/usage',
        element: <Navigate to="/settings/usage" replace />,
      },
      {
        path: 'billing/invoice',
        element: <Navigate to="/settings/invoices" replace />,
      },
      {
        path: 'resources',
        element: <SettingsResources />,
      },
      ...(ssoManage
        ? [
            {
              path: 'sso',
              element: <EnterpriseSSO />,
            },
          ]
        : []),
      ...(researchManage
        ? [
            {
              path: 'research',
              element: <TeamResearchContainer />,
            },
          ]
        : []),
      {
        index: true,
        element: <Navigate to="/settings/account" replace />,
      },
    ],
  },
];
