import { FC, LegacyRef, ReactNode, memo } from 'react';
import ReactPlayer, { Config, ReactPlayerProps } from 'react-player';
import { Center } from '@chakra-ui/react';

type Props = Pick<ReactPlayerProps, 'onDuration' | 'volume' | 'onProgress' | 'onEnded'> & {
  alert?: ReactNode;
  onBufferingChange: (b: boolean) => void;
  onPlayerError: (error: any) => void;
  playbackRate: number;
  playerRef: LegacyRef<ReactPlayer>;
  playing: boolean;
  url: string | undefined;
  onReady?: (player: ReactPlayer) => void;
  config?: Config;
};

export const VideoPlayerMedia: FC<Props> = memo(
  ({
    onReady,
    alert,
    onBufferingChange,
    onDuration,
    onPlayerError,
    onProgress,
    onEnded,
    playbackRate,
    playerRef,
    playing,
    url,
    volume,
    config,
  }) => {
    if (alert) {
      return (
        <Center width="full" height="full">
          {alert}
        </Center>
      );
    }

    if (!url) {
      return null;
    }

    return (
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        playbackRate={playbackRate || undefined}
        width="100%"
        height="100%"
        volume={volume}
        config={config}
        onDuration={onDuration}
        playing={playing}
        onEnded={onEnded}
        progressInterval={50}
        onReady={onReady}
        onProgress={onProgress}
        onBuffer={() => {
          if (onBufferingChange) {
            onBufferingChange(true);
          }
        }}
        onBufferEnd={() => {
          if (onBufferingChange) {
            onBufferingChange(false);
          }
        }}
        onError={(error, ...rest) => {
          onPlayerError(error);
        }}
      />
    );
  },
);
