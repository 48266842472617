import { gql } from '@apollo/client';

export default gql`
  mutation validateCoupon($coupon: String!, $_team_id: ID!, $total_price: Float) {
    validateCoupon(coupon: $coupon, _team_id: $_team_id, total_price: $total_price) {
      _id
      code
      discount_value
      discount_type
      validity {
        checkout_amount
      }
      campaign_image
    }
  }
`;
