import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';
import { InsightsTable } from './components/InsightsDataTable';
import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { ScanSearch } from 'lucide-react';
import { DiscoverAnalysesInsightsQuery } from './data/DiscoverAnalysesInsights.query';
import { FilterDuration, type FilterDateProps } from 'containers/Discover/DiscoverContainer';
import { Card, CardHeader, CardTitle } from '@askable/ui/components/ui/card';

type InsightsProps = {
  filterDate: FilterDateProps;
  onInsightClick: (id: string) => void;
  selectedInsightId: string | null;
};

export const PER_PAGE = 50;

export const AnalysesInsights = ({ filterDate, onInsightClick, selectedInsightId }: InsightsProps) => {
  const { t } = useTranslation();
  const [{ data, fetching }] = useQuery({
    query: DiscoverAnalysesInsightsQuery,
    variables: {
      filter: {
        updated: { gte: filterDate.date },
      },
      first: PER_PAGE,
    },
  });

  return (
    <Card>
      <CardHeader className="pb-2 2xl:pb-4">
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <CardTitle className="text-lg">{t('sections.discover.insights')}</CardTitle>
            <div className="text-sm text-muted-foreground">
              {filterDate.days === FilterDuration.ALL_TIME
                ? t('sections.discover.insightsDescriptionAllTime')
                : t('sections.discover.insightsDescriptionInTheLastXDays', { count: filterDate.days })}
            </div>
          </div>
          <ScanSearch className="h-6 w-6 text-muted-foreground" />
        </div>
      </CardHeader>
      {fetching ? (
        <div className="flex w-full flex-col gap-6 p-4">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-6 w-2/5" />
            <Skeleton className="h-4 w-3/5" />
          </div>
          <div className="flex flex-col gap-2">
            <Skeleton className="h-6 w-2/5" />
            <Skeleton className="h-4 w-3/5" />
          </div>
        </div>
      ) : (
        <InsightsTable
          insights={data?.analysesInsights.nodes ?? []}
          onInsightClick={onInsightClick}
          selectedInsightId={selectedInsightId}
        />
      )}
    </Card>
  );
};
