import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  th: {
    fontWeight: 'semibold',
    wordBreak: 'break-word',
    textTransform: 'unset',
    whiteSpace: 'normal',
    letterSpacing: 'normal',
    fontFamily: 'body',
    fontSize: 'sm',
    borderBottom: 'none!',
    boxShadow: 'inset 0 -1px 0 #EDF2F7',
  },
  tr: {
    _hover: {
      bg: 'gray.50',
    },
  },
  td: {
    fontSize: 'sm',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    color: 'gray.800',
  },
  table: {
    tableLayout: 'fixed',
    isolation: 'isolate',
  },
});

const sizes = {
  sm: definePartsStyle({
    th: {
      paddingInlineStart: 6,
      paddingInlineEnd: 6,
      py: 2.5,
      verticalAlign: 'top',
    },
    td: {
      paddingInlineStart: 6,
      paddingInlineEnd: 6,
    },
  }),
};

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
}) as any;
