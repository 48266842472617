import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const CalendarAddIcon: CreateIconType = createIcon({
  displayName: 'CalendarAddIcon',
  defaultProps: defaultOptions,
  path: [
    <path stroke="currentColor" strokeLinecap="round" d="M4.75342 0.75L4.75342 3.28918" />,
    <path stroke="currentColor" strokeLinecap="round" d="M9.23975 0.75L9.23975 3.28918" />,
    <path
      stroke="currentColor"
      d="M1.09183 10.5249C1.25317 11.9642 2.42311 13.1071 3.86983 13.1768C4.86978 13.2249 5.89127 13.2499 6.99991 13.2499C8.10855 13.2499 9.13003 13.2249 10.13 13.1768C11.5767 13.1071 12.7466 11.9642 12.908 10.5249C13.0166 9.55554 13.1058 8.56176 13.1058 7.54941C13.1058 6.53707 13.0166 5.54329 12.908 4.57397C12.7466 3.13459 11.5767 1.99174 10.13 1.92207C9.13003 1.87391 8.10855 1.84888 6.99991 1.84888C5.89127 1.84888 4.86978 1.87391 3.86983 1.92207C2.42311 1.99174 1.25317 3.13459 1.09183 4.57397C0.983182 5.54329 0.894043 6.53707 0.894043 7.54941C0.894043 8.56176 0.983182 9.55554 1.09183 10.5249Z"
    />,
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.5 7.54944H9.5" />,
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M7 5.04944V10.0494" />,
  ],
});
