import React from 'react';
import Spinner from 'react-spinkit';

// Styles
import './styles/LoadingOverlay.scss';

type Props = {
  style?: React.CSSProperties;
};
const LoadingOverlay = (props: Props) => {
  return (
    <div className="overlay-container" style={props.style ? props.style : { zIndex: 999 }}>
      <div id="loading-overlay" />
      <Spinner fadeIn="none" name="three-bounce" color="#FF5266" />
    </div>
  );
};

export default LoadingOverlay;
