import { graphql } from 'src/graphql';

export const SettingsTeamMembersQuery = graphql(`
  query SettingsTeamMembers($_id: ID, $researcherProjectFilter: ProjectConnectionFilter) {
    teamById(_id: $_id) {
      _id
      users {
        _id
        role
        status
        added_date
        User {
          displayName
          _id
          email
          researcher {
            projects(filter: $researcherProjectFilter) {
              nodes {
                _id
                name
              }
            }
          }
        }
      }
    }
  }
`);
