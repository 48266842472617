import { FC, useEffect, useState, useRef, ChangeEvent, TextareaHTMLAttributes } from 'react';

import { cn } from '@askable/ui/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaVariants> {
  disableAutoResizing?: boolean;
  focus?: boolean;
  maxRows?: number;
  readOnly?: boolean;
  value?: string;
  onCursorChange?: (position: number) => void;
}

const textareaVariants = cva(
  'flex w-full resize-none p-2 min-h-[2.36rem] rounded-md border-1 border-input bg-background ring-offset-background text-sm placeholder:text-muted-foreground focus-visible:border-ring focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        error: 'border-destructive focus-visible:ring-destructive',
        borderless: 'border-none bg-transparent focus-visible:ring-0',
      },
      inputSize: {
        default: 'text-sm',
        md: 'text-md min-h-[2.65rem]',
        lg: 'text-xl min-h-[3rem]',
        xl: 'text-4xl md:text-5xl min-h-[4rem]',
      },
    },
    defaultVariants: {
      inputSize: 'default',
    },
  },
);

const rowHeight = { default: 24, md: 24, lg: 30, xl: 52 };

const Textarea: FC<TextareaProps> = ({
  className,
  disableAutoResizing,
  focus,
  inputSize,
  maxRows,
  onChange,
  onCursorChange,
  readOnly,
  rows = 2,
  value,
  variant,
  ...props
}) => {
  const [_value, setValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const heightMinMax = [
    rows * rowHeight[inputSize ?? 'default'],
    maxRows && maxRows >= rows ? maxRows * rowHeight[inputSize ?? 'default'] + 16 : 1024,
  ];

  useEffect(() => {
    if (!textAreaRef.current || disableAutoResizing) {
      return;
    }

    // Resize textarea based on content
    textAreaRef.current.style.height = 'auto';
    const textareaHeight =
      _value === ''
        ? Math.max(heightMinMax[0], textAreaRef.current.scrollHeight)
        : Math.min(heightMinMax[1], Math.max(heightMinMax[0], textAreaRef.current.scrollHeight));
    textAreaRef.current.style.height = `${textareaHeight + 2}px`;
  }, [textAreaRef, _value, disableAutoResizing]);

  // Adjust height on initial value
  useEffect(() => {
    setValue(value ?? '');
  }, [value]);

  useEffect(() => {
    if (focus) {
      textAreaRef.current?.focus();
    }
  }, [focus]);

  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value);
    onChange?.(evt);
  };

  const handleSelect = () => {
    if (onCursorChange) {
      onCursorChange(textAreaRef.current?.selectionStart ?? 0);
    }
  };

  return (
    <textarea
      className={cn(textareaVariants({ variant, className, inputSize }))}
      onChange={handleChange}
      onSelect={handleSelect}
      readOnly={readOnly}
      ref={textAreaRef}
      rows={rows}
      value={_value}
      {...props}
    />
  );
};

Textarea.displayName = 'Textarea';

export { Textarea };
