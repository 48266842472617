import { gql } from '@apollo/client';

export const bookingFieldsFragment = gql`
  fragment bookingFields on Booking {
    _id
    _project_id
    _team_id
    _owner_id
    created
    updated
    type
    name
    status
    total_participants
    config {
      demo
      coupon {
        code
        discount_value
        validity {
          checkout_amount
        }
      }
      timezone
      session {
        slot_min
        slot_max
        duration
        type
        time_limit
      }
      incentives {
        currency_code
        currency_symbol
        country_code
        value
      }
      location {
        name
        street1
        postal_code
        state
        city
        latitude
        longitude
        country
        level
        region
        google_location {
          viewport {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
      }
      criteria {
        meta_identity_birthday_year {
          field
          value
          operator
        }
        meta_identity_gender {
          field
          value
          operator
        }
        meta_family_status {
          field
          value
          operator
        }
        meta_education {
          field
          value
          operator
        }
        meta_work_status {
          field
          value
          operator
        }
        meta_work_employment_type {
          field
          value
          operator
        }
        meta_work_industry {
          _industry_id
          industry_name
          subcategories {
            _subcategory_id
            subcategory_name
          }
        }
        meta_identity_languages_english_speak {
          field
          value
          operator
        }
        meta_identity_languages {
          field
          value
          operator
        }
        meta_education_field {
          field
          value
          operator
        }
        meta_individual_income {
          field
          value
          operator
        }
        meta_family_income {
          field
          value
          operator
        }
        meta_tech_savviness {
          field
          value
          operator
        }
        meta_home_ownership {
          field
          value
          operator
        }
        meta_purchasing_behaviour {
          field
          value
          operator
        }
        meta_children {
          field
          value
          operator
        }
        custom {
          _question_id
          value
          operator
        }
        filters_order
        exclude_participants_from_time
        locations {
          states {
            name
            state
            country
            formatted_address
            latitude
            longitude
            google_location {
              viewport {
                northeast {
                  lat
                  lng
                }
                southwest {
                  lat
                  lng
                }
              }
            }
          }
          countries {
            name
            state
            country
            formatted_address
            latitude
            longitude
            google_location {
              viewport {
                northeast {
                  lat
                  lng
                }
                southwest {
                  lat
                  lng
                }
              }
            }
          }
          bounds {
            formatted_address
            state
            country
            latitude
            longitude
            google_location {
              geometry {
                latitude_ne
                longitude_ne
                latitude_sw
                longitude_sw
              }
              viewport {
                northeast {
                  lat
                  lng
                }
                southwest {
                  lat
                  lng
                }
              }
            }
          }
        }
      }
      options {
        show_company
        review_submission
      }
      question {
        _id
        title
        description
        config {
          display_participant
          multiple_selection
          none_of_the_above
          type
        }
        options {
          _id
          label
          screen_in
        }
      }
      steps {
        step
        status
      }
      information {
        brief_summary
        special_instructions
        short_description
        brief_summary_changed
      }
      participant_category
      in_context {
        location_type
        travel_time_limit
      }
      online_task {
        links {
          _id
          name
          url
          checked
        }
        required_device
        required_features
        tool
        type
      }
      recruitment {
        byo
      }
      contact {
        _organiser_user_id
        _facilitator_user_id
        facilitator_temp_phone_number
      }
      remote {
        tool
        askable_live
      }
      longitudinal_study {
        participant_workload {
          time
          measure
          frequency
        }
        period {
          time
          frequency
        }
        extra {
          time
          measure
          description
        }
      }
      credits_per_participant
      project {
        project_type
        allowed_credits
      }
      participant_agreement {
        type
        _template_id
        recipients
      }
      ai_moderated {
        information {
          welcome_body
          welcome_title
        }
        prompt {
          context
          overview
        }
      }
    }
    session {
      _id
      start
      end
      slot_min
      slot_max
      status
      _researcher_user_id
      ResearcherUser {
        _id
        name
      }
    }
    information {
      title
      body
    }
    history {
      askable_live_recordings_expired
      askable_live_expiring_notification
      completed_date
    }
    steps {
      project_setup
      project_setup_project_title
      project_setup_research_type
      project_setup_devices
      project_setup_tool
      project_setup_quota
      project_setup_session_format
      project_setup_session_duration
      project_setup_study_duration
      project_setup_task_duration
      project_setup_participant_selection
      audience
      audience_panel
      audience_incentives
      audience_participant_locations
      audience_demographic_filters
      audience_custom_screener
      additional_info
      additional_info_your_timezone
      additional_info_session_location
      additional_info_closing_date
      additional_info_devices
      additional_info_short_description
      additional_info_description
      additional_info_additional_instructions
      additional_info_contacts
      additional_info_legal_privacy
      link_to_task
      link_to_task_survey
      link_to_task_online_task
      session_times
      session_times_calendar
      session_times_backup
      review_submit
      review_submit_confirm_booking
      ai_moderated
      ai_moderated_research_objective
      ai_moderated_welcome_end_screen
    }
    cloned
    feature_flags
  }
`;
