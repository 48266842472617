import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Tag: ComponentStyleConfig = {
  variants: {
    success: {
      bg: 'green.500',
      color: 'green.100',
    },
  },
};
