import { useMutation } from '@apollo/client';
import { LoadingOverlay } from 'components/common';
import { useAppContext } from 'components/common/Askable/Providers/appProvider';
import createBookingMutation from 'data/mutations/booking/createBooking';
import createSessionMutation from 'data/mutations/booking/createSession';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import createBookingStepsMutation from 'data/mutations/booking/createBookingSteps';
// import updateBookingStepsMutation from 'data/mutations/booking/updateBookingSteps';
import deleteOnlineTaskLinkMutation from 'data/mutations/booking/deleteOnlineTaskLink';
import deleteSessionMutation from 'data/mutations/booking/deleteSession';
// Mutations
import updateBookingMutation from 'data/mutations/booking/updateBooking';
import updateSessionMutation from 'data/mutations/booking/updateSession';
// Queries
import fetchBookingsByTeam from 'data/queries/booking/fetchBookingsByTeam';
import { analytics } from 'lib/analytics';
import { bookingUtils } from 'lib/booking';
import { localStorage } from 'lib/storage';
import { teamUtils } from 'lib/teams';
import { update, utils } from 'lib/utils';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useIntercom } from 'react-use-intercom';
import AdditionalInstructionsScreen from './additionalInfo/additionalInstructions';
import ClosingDateScreen from './additionalInfo/closingDate';
import ContactsScreen from './additionalInfo/contacts';
import DescriptionScreen from './additionalInfo/description';
import DevicesScreen from './additionalInfo/devices';
// Additional Info
import SessionLocationScreen from './additionalInfo/sessionLocation';
import YourTimezoneScreen from './additionalInfo/yourTimezone';
import CustomScreenerScreen from './audience/customScreener';
import DemographicFiltersScreen from './audience/demographicFilters';
import IncentivesScreen from './audience/incentives';
import LegalPrivacyScreen from './additionalInfo/legalPrivacy';
// Audience
import PanelScreen from './audience/panel';
// Link to Task
import LinkToTaskScreen from './linkToTask/linkToTask';
import ParticipantSelectionScreen from './projectSetup/participantSelection';
// Booking Setup
import ProjectTitleScreen from './projectSetup/projectTitle';
import QuotaScreen from './projectSetup/quota';
import ResearchTypeScreen from './projectSetup/researchType';
import SessionDurationScreen from './projectSetup/sessionDuration';
import SessionFormatScreen from './projectSetup/sessionFormat';
import StudyDurationScreen from './projectSetup/studyDuration';
import TaskDurationScreen from './projectSetup/taskDuration';
import ToolScreen from './projectSetup/tool';
// Review & Submit
import ConfirmBookingScreen from './reviewSubmit/confirmBooking';
// Session Times
import SessionTimesCalendarScreen from './sessionTimes/sessionTimesCalendar';
import CreateBookingStepperMenu from './stepperMenu/stepperMenu';
// External Components
import { stepperMenuUtils } from './stepperMenu/stepperMenuUtils';
// Styles
import './styles/createBookingStyles.scss';
import ResearchObjective from './aiModerated/researchObjective';
import WelcomeEndScreen from './aiModerated/welcomeEndScreen';
import { toast } from '@askable/ui/components/ui/sonner';
import { useConnectedClient } from 'context/ConnectedClientContext';
import ParticipantLocationsMultiRegion from './audience/participantLocationsMultiRegion';
import ParticipantLocations from './audience/participantLocations';
import { useFeatureFlags } from 'feature-flags';

function CreateBookingWithData(props: any) {
  const intercom = useIntercom();
  const params = useParams();
  const { MULTIREGION_COUNTRIES } = useFeatureFlags(['MULTIREGION_COUNTRIES']);
  const { screen, subScreen } = params;

  const context = useAppContext();
  const l = useLocation();
  const { details } = useConnectedClient();

  const [booking, setBooking] = useState(_.get(props, 'booking'));
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'booking.steps'));
  const [loading, setLoading] = useState(false);
  const [rightContent, setRightContent] = useState(null);
  const [rightAppPreview, setRightAppPreview] = useState(null);
  const [lastStep, setLastStep] = useState({
    lastStepOpened: '',
    lastStepGroup: [],
  });

  const screenPages = {
    'project-title': ProjectTitleScreen,
    'research-type': ResearchTypeScreen,
    devices: DevicesScreen,
    tool: ToolScreen,
    quota: QuotaScreen,
    'session-format': SessionFormatScreen,
    'session-duration': SessionDurationScreen,
    'study-duration': StudyDurationScreen,
    'task-duration': TaskDurationScreen,
    'participant-selection': ParticipantSelectionScreen,
    panel: PanelScreen,
    incentives: IncentivesScreen,
    'participant-locations': MULTIREGION_COUNTRIES ? ParticipantLocationsMultiRegion : ParticipantLocations,
    'demographic-filters': DemographicFiltersScreen,
    'custom-screener': CustomScreenerScreen,
    'session-location': SessionLocationScreen,
    'your-timezone': YourTimezoneScreen,
    'closing-date': ClosingDateScreen,
    description: DescriptionScreen,
    'additional-instructions': AdditionalInstructionsScreen,
    contacts: ContactsScreen,
    'legal-privacy': LegalPrivacyScreen,
    calendar: SessionTimesCalendarScreen,
    'online-task': LinkToTaskScreen,
    survey: LinkToTaskScreen,
    'confirm-booking': ConfirmBookingScreen,
    'research-objective': ResearchObjective,
    'welcome-end-screen': WelcomeEndScreen,
  };
  const isDisplayingHeaderAds = localStorage.get('showingHeaderAds') === 'true';
  const currency = teamUtils.getCurrency();

  const [createBooking] = useMutation(createBookingMutation);
  const [updateBooking] = useMutation(updateBookingMutation);
  const [createSession] = useMutation(createSessionMutation);
  const [updateSession] = useMutation(updateSessionMutation);
  const [deleteSession] = useMutation(deleteSessionMutation);
  const [deleteOnlineTaskLink] = useMutation(deleteOnlineTaskLinkMutation);

  useEffect(() => {
    if (props.subscribeToUpdateBooking) props.subscribeToUpdateBooking();
    if (_.get(booking, 'status') !== 0) {
      props.history.push({ path: '/' });
    }
  }, []);

  useEffect(() => {
    if (_.get(props, 'booking') && !bookingUtils.isInDraft(_.get(props, 'booking'))) {
      props.history.push({ pathname: `/bookings/${_.get(props, 'booking._id')}/participants/all` });
    }
  }, [props.booking]);

  useEffect(() => {
    if (rightContent) {
      intercom.update({ horizontalPadding: 295 });
    } else if (rightAppPreview) {
      intercom.update({ horizontalPadding: 395 });
    } else {
      intercom.update({ horizontalPadding: 20 });
    }
  }, [screen, rightContent, rightAppPreview]);

  useEffect(() => {
    // Set intercom bubble position
    if (
      (screen === 'project-setup' && subScreen === 'research-type') ||
      (screen === 'additional-info' && subScreen === 'additional-instructions') ||
      (screen === 'session-times' && subScreen === 'calendar') ||
      (screen === 'review-submit' && subScreen === 'confirm-booking')
    ) {
      intercom.update({ horizontalPadding: 20 });
    } else {
      intercom.update({ horizontalPadding: 295 });
    }
    return () => {
      intercom.update({ horizontalPadding: 20 });
    };
  }, [screen, subScreen]);

  const createDraftBooking = (extraInfo = {}, redirectTo = null) => {
    if (booking) return null;
    setLoading(true);
    const extraSetupFields = {
      ...extraInfo,
      steps: {
        project_setup_project_title: 'seen',
      },
      config: {
        criteria: {
          locations: {
            states: [],
            bounds: [],
            countries: MULTIREGION_COUNTRIES ? [] : undefined,
          },
        },
      },
    };
    updateBookingStepsState({ project_setup_project_title: 'seen' });
    createBooking({
      variables: {
        timezone: bookingUtils.getCurrentTimezone(),
        currency: currency.symbol,
        _team_id: details?.team?.id,
        extra_setup_fields: extraSetupFields,
        country_code: localStorage.get('countryByIp'),
      },
      refetchQueries: [
        {
          query: fetchBookingsByTeam,
          variables: {
            _team_id: details?.team?.id,
            status: [0, 1, 2, 3, 4, 5, 6],
            sortBy: 'created',
            order: -1,
            excludeAskablePlusBookings: true,
          },
        },
      ],
    })
      .then(({ data }) => {
        // Track Event Analytics
        analytics.track('draft_booking_created', {
          page: window.location.href,
          action: 'Draft booking created',
          bookingId: data.createBooking._id,
        });
        updateBookingState(data.createBooking);
        setLoading(false);
        if (redirectTo) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'replace' does not exist on type 'never'.
          const redirectToLink = redirectTo.replace(':bookingID', data.createBooking._id);
          props.history.push({ pathname: redirectToLink });
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error creating study');
      });
  };

  const updateBookingState = (bookingState: any) => {
    setBooking(bookingState);
  };

  const updateBookingStepsState = (bookingStepsState: any) => {
    setBookingSteps(bookingStepsState);
  };

  const updateBookingInDb = async ({ bookingId, objToBeUpdated, updateState }: any) => {
    const result = await updateBooking({
      variables: {
        booking_id: bookingId,
        booking: utils.removeTypenames(objToBeUpdated),
        currency_code: currency.code,
      },
    }).catch((e) => {
      updateBookingState(_.get(props, 'booking'));
      toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
    });

    if (_.get(result, 'data.updateBooking') && updateState) {
      updateBookingState(_.get(result, 'data.updateBooking'));
    }
  };

  const saveBookingValidation = (bookingData: any, link: any, validateCurrentLink = false) => {
    if (!link) return null;
    const validatedSteps = stepperMenuUtils.validateBookingSteps(
      bookingData,
      bookingSteps,
      link,
      validateCurrentLink,
      MULTIREGION_COUNTRIES,
    );
    if (validatedSteps) {
      const bookingToUpdate = {
        steps: validatedSteps,
      };
      const bookingState = {
        ...bookingData,
        steps: validatedSteps,
      };
      updateBookingStepsState(validatedSteps);
      updateBookingState(bookingState);

      if (JSON.stringify(validatedSteps) !== JSON.stringify(bookingSteps)) {
        updateBookingInDb({
          bookingId: _.get(booking, '_id'),
          objToBeUpdated: bookingToUpdate,
        });
        updateBookingStepsState(validatedSteps);
      }
    }
  };

  const saveBooking = async (bookingState: any, objectToUpdate: any) => {
    if (!booking || !objectToUpdate) return null;
    // Update booking state with changes
    updateBookingState(bookingState);

    if (
      _.has(objectToUpdate, 'config.options.review_submission') &&
      !_.get(objectToUpdate, 'config.options.review_submission')
    ) {
      if (bookingUtils.isOnlineTask(booking) && _.size(_.get(booking, 'config.online_task.links')) > 1) {
        const linksToRemove = _.get(booking, 'config.online_task.links').slice(1, 99);
        _.map(linksToRemove, (link: any) => {
          deleteOnlineTaskLink({
            variables: {
              booking_id: booking._id,
              link_id: link._id,
            },
          });
        });
      }
    }

    // Save booking changes
    await updateBookingInDb({
      bookingId: _.get(booking, '_id'),
      objToBeUpdated: objectToUpdate,
      updateState: true,
    });
  };

  const updateSessionDate = (bookingState: any, session: any, sessionTimes: any, redirectTo = null) => {
    updateBookingState(bookingState);
    if (redirectTo) props.history.push({ pathname: redirectTo });

    updateSession({
      variables: {
        booking_id: booking._id,
        session_id: _.get(session, '_id'),
        session: utils.removeTypenames(sessionTimes),
      },
    }).catch(() => {
      toast.error('Error saving study');
    });
  };

  const createSessionDate = async (bookingState: any, defaultSession: any, redirectTo?: string | null) => {
    // updateBookingState({ ...bookingState, steps: booking.steps });
    if (redirectTo) props.history.push({ pathname: redirectTo });

    const result = await createSession({
      variables: {
        booking_id: booking._id,
        session: defaultSession,
      },
    }).catch(() => {
      toast.error('Error saving study');
    });
    updateBookingState({ ...bookingState, session: _.get(result, 'data.createSession.session') });
  };

  const deleteSessionDate = async (bookingState: any, sessionId: any) => {
    const result = await deleteSession({
      variables: {
        booking_id: booking._id,
        session_id: sessionId,
      },
    }).catch(() => {
      toast.error('Error saving study');
    });
    updateBookingState({ ...bookingState, session: _.get(result, 'data.deleteSession.session') });
    return _.get(result, 'data.deleteSession.session');
  };

  const updateLastStep = async ({ step, subStep, stepId, stepIdExtra = null }: any) => {
    const lastStepOpened = _.get(lastStep, 'lastStepOpened');

    // Get saved state to update booking changes
    const locationState = l.state as { booking?: any; bookingState?: any; bookingSession?: any };
    const historyChangesToSave = locationState?.booking ?? null;
    const historyBookingState = locationState?.bookingState ?? null;
    const historySessionData = locationState?.bookingSession ?? null;
    props.history.replace(props.location.pathname, null);

    if (props.booking) {
      let stepsToSave = _.clone(lastStep.lastStepGroup);
      const savedStepIndex = _.findIndex(stepsToSave, (item: any) => item.step === step);
      if (savedStepIndex >= 0) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'subStep' does not exist on type 'never'.
        stepsToSave[savedStepIndex].subStep = subStep;
      } else {
        stepsToSave = update(stepsToSave, {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ step: any; subStep: any; }[]' is not assig... Remove this comment to see the full error message
          $push: [{ step, subStep }],
        });
      }
      setLastStep({
        lastStepOpened: subStep,
        lastStepGroup: stepsToSave,
      });

      let stepsObj = {
        ...bookingSteps,
        [stepId]: _.get(bookingSteps, `[${stepId}]`) === 'error' ? 'error' : 'seen',
      };

      if (stepIdExtra) {
        stepsObj = {
          ...stepsObj,
          [stepIdExtra]: _.get(bookingSteps, `[${stepIdExtra}]`) === 'error' ? 'error' : 'seen',
        };
      }

      let bookingStateObj = historyBookingState;
      // Update booking with saved state
      if (historyChangesToSave) {
        bookingStateObj = {
          ...historyBookingState,
          ...historyChangesToSave,
          config: {
            ..._.get(historyBookingState, 'config'),
            ..._.get(historyChangesToSave, 'config'),
            session: {
              ..._.get(historyBookingState, 'config.session'),
              ..._.get(historyChangesToSave, 'config.session'),
            },
            location: {
              ..._.get(historyBookingState, 'config.location'),
              ..._.get(historyChangesToSave, 'config.location'),
            },
            criteria: {
              ..._.get(historyBookingState, 'config.criteria'),
              ..._.get(historyChangesToSave, 'config.criteria'),
              locations: {
                ..._.get(historyBookingState, 'config.criteria.locations'),
                ..._.get(historyChangesToSave, 'config.criteria.locations'),
              },
            },
            remote: {
              ..._.get(historyBookingState, 'config.remote'),
              ..._.get(historyChangesToSave, 'config.remote'),
            },
            online_task: {
              ..._.get(historyBookingState, 'config.online_task'),
              ..._.get(historyChangesToSave, 'config.online_task'),
            },
            longitudinal_study: {
              ..._.get(historyBookingState, 'config.longitudinal_study'),
              ..._.get(historyChangesToSave, 'config.longitudinal_study'),
            },
            information: {
              ..._.get(historyBookingState, 'config.information'),
              ..._.get(historyChangesToSave, 'config.information'),
            },
          },
          session: {
            ..._.get(historyBookingState, 'session'),
            ..._.get(historyChangesToSave, 'session'),
          },
          steps: stepsObj,
        };

        let validatedSteps = stepsObj;
        if (lastStepOpened && !subStep.includes('/review-submit/confirm-booking')) {
          validatedSteps = stepperMenuUtils.validateBookingSteps(
            bookingStateObj,
            stepsObj,
            subStep,
            true,
            MULTIREGION_COUNTRIES,
          );
        } else {
          validatedSteps = stepperMenuUtils.validateBookingSteps(
            bookingStateObj,
            stepsObj,
            subStep,
            undefined,
            MULTIREGION_COUNTRIES,
          );
        }
        updateBookingState({
          ...bookingStateObj,
          steps: validatedSteps,
        });
        updateBookingStepsState(validatedSteps);
        await updateBookingInDb({
          bookingId: _.get(booking, '_id'),
          objToBeUpdated: {
            ...historyChangesToSave,
            steps: validatedSteps,
          },
          updateState: true,
        });
      } else if (!_.get(bookingSteps, `[${stepId}]`)) {
        bookingStateObj = {
          ...booking,
          steps: stepsObj,
        };
        let validatedSteps = stepsObj;
        if (lastStepOpened.includes('/additional-info/session-location')) {
          validatedSteps = stepperMenuUtils.validateBookingSteps(
            bookingStateObj,
            stepsObj,
            lastStepOpened,
            true,
            MULTIREGION_COUNTRIES,
          );
        }

        if (lastStepOpened && !subStep.includes('/review-submit/confirm-booking')) {
          if (
            !(
              subStep.includes('/additional-info/description') ||
              subStep.includes('/additional-info/session-location') ||
              subStep.includes('/link-to-task/online-task') ||
              subStep.includes('/link-to-task/survey') ||
              subStep.includes('/session-times/calendar')
            )
          ) {
            validatedSteps = stepperMenuUtils.validateBookingSteps(
              bookingStateObj,
              stepsObj,
              subStep,
              true,
              MULTIREGION_COUNTRIES,
            );
          }
        } else {
          // Delete invalid sessions
          if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
            const invalidSessions = _.filter(bookingStateObj.session, (session: any) => session.start === session.end);
            if (_.size(invalidSessions) > 0) {
              await Promise.all(
                _.map(invalidSessions, async (item: any) => {
                  const sessionUpdated = await deleteSessionDate(bookingStateObj, item._id);
                  if (sessionUpdated) bookingStateObj.session = sessionUpdated;
                }),
              );
            }
          } else if (
            (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) &&
            _.size(bookingStateObj.session) > 1
          ) {
            const invalidSessions = _.get(bookingStateObj, 'session').slice(1, 99);
            if (_.size(invalidSessions) > 0) {
              await Promise.all(
                _.map(invalidSessions, async (item: any) => {
                  const sessionUpdated = await deleteSessionDate(bookingStateObj, item._id);
                  if (sessionUpdated) bookingStateObj.session = sessionUpdated;
                }),
              );
            }
          }
          validatedSteps = stepperMenuUtils.validateBookingSteps(
            bookingStateObj,
            stepsObj,
            subStep,
            undefined,
            MULTIREGION_COUNTRIES,
          );
        }
        updateBookingStepsState(validatedSteps);
        updateBookingState({
          ...bookingStateObj,
          steps: validatedSteps,
        });
        updateBookingInDb({
          bookingId: _.get(booking, '_id'),
          objToBeUpdated: {
            steps: utils.removeTypenames(validatedSteps),
          },
        });
      } else {
        bookingStateObj = {
          ...booking,
          steps: stepsObj,
        };
        updateBookingState(bookingStateObj);
        // if (lastStepOpened && !subStep.includes('/review-submit/confirm-booking')) {
        //     saveBookingValidation(bookingStateObj, lastStepOpened, true);
        // } else {
        //     saveBookingValidation(bookingStateObj, subStep);
        // }
        if (subStep.includes('/link-to-task/online-task') || subStep.includes('/link-to-task/survey')) {
          const validatedSteps = stepperMenuUtils.validateBookingSteps(
            bookingStateObj,
            stepsObj,
            subStep,
            true,
            MULTIREGION_COUNTRIES,
          );
          updateBookingStepsState(validatedSteps);
          updateBookingState({
            ...bookingStateObj,
            steps: validatedSteps,
          });
          updateBookingInDb({
            bookingId: _.get(booking, '_id'),
            objToBeUpdated: {
              steps: utils.removeTypenames(validatedSteps),
            },
          });
        }

        if (subStep.includes('/review-submit/confirm-booking')) {
          // Delete invalid sessions
          if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
            const invalidSessions = _.filter(bookingStateObj.session, (session: any) => session.start === session.end);
            if (_.size(invalidSessions) > 0) {
              await Promise.all(
                _.map(invalidSessions, async (item: any) => {
                  const sessionUpdated = await deleteSessionDate(bookingStateObj, item._id);
                  if (sessionUpdated) bookingStateObj.session = sessionUpdated;
                }),
              );
            }
          }
          saveBookingValidation(bookingStateObj, subStep);
        }
      }

      // Update booking with saved state
      if (historySessionData) {
        if (_.has(historySessionData, 'session._id')) {
          updateSessionDate(
            bookingStateObj,
            _.get(historySessionData, 'session'),
            _.pick(_.get(historySessionData, 'sessionTimes'), ['_id', 'start', 'end']),
          );
        } else {
          createSessionDate(bookingStateObj, _.pick(_.get(historySessionData, 'session'), ['start', 'end']));
        }
      }
    }
  };

  const renderLeftSidebar = () => {
    return (
      <div className="createBookingLeftSidebar">
        <CreateBookingStepperMenu
          booking={booking}
          bookingSteps={bookingSteps}
          createBooking={createDraftBooking}
          updateBooking={saveBooking}
          validateBooking={saveBookingValidation}
          updateSession={updateSessionDate}
          createSession={createSessionDate}
          deleteSession={deleteSessionDate}
          team={_.get(props, 'booking.team')}
          client={_.get(props, 'client')}
          context={context}
          updateLastStep={updateLastStep}
          lastStep={_.get(lastStep, 'lastStepGroup') || []}
          updateBookingState={updateBookingState}
          updateBookingStepsState={updateBookingStepsState}
        />
      </div>
    );
  };

  const renderContentPage = (page: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return React.createElement(screenPages[page], {
      renderRightContent: (content: any) => setRightContent(content),
      renderRightAppPreview: (content: any) => setRightAppPreview(content),
      booking,
      bookingSteps,
      createBooking: createDraftBooking,
      updateBooking: saveBooking,
      validateBooking: saveBookingValidation,
      updateSession: updateSessionDate,
      createSession: createSessionDate,
      deleteSession: deleteSessionDate,
      team: _.get(props, 'booking.team'),
      client: _.get(props, 'client'),
      context,
      updateLastStep,
      lastStep: _.get(lastStep, 'lastStepGroup') || [],
      updateBookingState,
      updateBookingStepsState,
    });
  };

  const renderContent = () => {
    switch (screen) {
      case 'create':
        return (
          <ProjectTitleScreen
            renderRightContent={(content: any) => setRightContent(content)}
            renderRightAppPreview={(content: any) => setRightAppPreview(content)}
            booking={booking}
            bookingSteps={bookingSteps}
            createBooking={createDraftBooking}
            updateBooking={saveBooking}
          />
        );
      default:
        return renderContentPage(subScreen);
    }
  };

  const renderRightSidebar = () => {
    return <div className="createBookingRightSidebar">{rightContent}</div>;
  };

  const renderRightAppPreview = () => {
    return <div className="createBookingRightAppPreview">{rightAppPreview}</div>;
  };

  return (
    <>
      <Helmet>
        <title>Draft - {booking?.name || 'Untitled'}</title>
      </Helmet>
      <div className={`createBookingContainer ${isDisplayingHeaderAds ? 'headerAds' : ''}`}>
        {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
        {renderLeftSidebar()}
        <div className="createBookingContentContainer">{renderContent()}</div>
        {rightContent && renderRightSidebar()}
        {rightAppPreview && renderRightAppPreview()}
      </div>
    </>
  );
}

export default deprecatedWithRouter(CreateBookingWithData);
