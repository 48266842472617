import type { InvoiceTransactionsType, Maybe } from 'generated/graphql';

const transactionUtils = {
  getNumberOfCredits(invoice?: Maybe<InvoiceTransactionsType>) {
    return invoice?.credit_quantity;
  },
  getTransactionStatusTitle(invoice?: Maybe<InvoiceTransactionsType>) {
    if (invoice?.amount_due === 0) {
      return 'Paid';
    }

    if (!invoice?.amount_due) {
      return '';
    }

    return 'Unpaid';
  },
  isLegacyTransaction(transactionCreatedDate?: Maybe<number>) {
    // Function to check whether the transaction is in a legacy format
    //  We consider a transaction to be legacy before the rollout of credits only (20th Nov 2018)
    if (!transactionCreatedDate) {
      return 'Not enough information';
    }

    return transactionCreatedDate <= 1542636000000;
  },
};

export { transactionUtils };
