import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const QuoteIcon: CreateIconType = createIcon({
  displayName: 'QuoteIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path
      id="Union"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.60594 0.844964C4.0216 0.684533 5.57463 0.558594 6.99926 0.558594C8.42402 0.558594 9.97829 0.684558 11.3947 0.845013C12.3697 0.955464 13.135 1.72776 13.2413 2.70322C13.2413 2.70322 13.4811 3.81893 13.4811 5.9925C13.4811 8.16606 13.2413 9.28177 13.2413 9.28177C13.1351 10.2572 12.3697 11.0295 11.3947 11.14C10.1046 11.2861 8.70014 11.4037 7.3833 11.4235L5.66035 13.1464C5.03039 13.7764 3.95325 13.3302 3.95325 12.4393V11.2765C3.49625 11.2361 3.04453 11.1897 2.606 11.14C1.63101 11.0295 0.865651 10.2572 0.758984 9.28182C0.758984 9.28182 0.518921 8.16662 0.518921 5.99278C0.518921 3.81893 0.758926 2.70317 0.758926 2.70317C0.865593 1.72775 1.63095 0.955457 2.60594 0.844964Z"
    />,
    <path
      id="Intersect"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.95442 8.11621C5.5084 7.68393 5.95862 6.41516 5.95862 5.80293C5.95862 5.5282 5.95862 5.30242 5.94545 5.08326C5.91336 4.54893 5.48973 4.11482 4.95444 4.11482V4.11482C3.73886 4.11482 3.79822 5.26926 4.01852 5.62865"
    />,
    <path
      id="Intersect_2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.00935 8.11621C9.56333 7.68393 10.0135 6.41516 10.0135 5.80293C10.0135 5.5282 10.0135 5.30242 10.0004 5.08326C9.96829 4.54893 9.54466 4.11482 9.00937 4.11482V4.11482C7.7938 4.11482 7.85315 5.26926 8.07345 5.62865"
    />,
  ],
});
