import CreateAskablePlus from 'components/askablePlus/createAskablePlus';
import { Outlet, RouteObject } from 'react-router-dom';

// External components
import InsightsReports from 'components/askablePlus/onboarding/insightsReports';
import MatchResearcher from 'components/askablePlus/onboarding/matchResearcher';
import MeetBrief from 'components/askablePlus/onboarding/meetBrief';
import Relax from 'components/askablePlus/onboarding/relax';
import ResearchPlan from 'components/askablePlus/onboarding/researchPlan';
import SubmitOnline from 'components/askablePlus/onboarding/submitOnline';
import Welcome from 'components/askablePlus/onboarding/welcome';
import AskablePlusOnboarding from 'components/askablePlus/askablePlusOnboarding';
import AskablePlusConfirmation from 'components/askablePlus/confirmationScreen';
import ResearcherProjectOffer from 'components/researcher/projectOffer/projectOffer';

export const askablePlusRoutes: RouteObject[] = [
  {
    path: 'askable-plus',
    element: <Outlet />,
    children: [
      {
        path: ':screen',
        element: <CreateAskablePlus />,
      },
      {
        path: ':projectID',
        children: [
          {
            path: 'researcher-offer',
            element: <ResearcherProjectOffer />,
          },
          {
            path: 'confirmation',
            element: <AskablePlusConfirmation />,
          },
          {
            path: ':screen/:subScreen',
            element: <CreateAskablePlus />,
          },
        ],
      },
      {
        path: 'onboarding',
        element: <AskablePlusOnboarding />,
        children: [
          {
            path: 'welcome',
            element: <Welcome />,
          },
          {
            path: 'submit-online',
            element: <SubmitOnline />,
          },
          {
            path: 'research-plan',
            element: <ResearchPlan />,
          },
          {
            path: 'match-to-a-researcher',
            element: <MatchResearcher />,
          },
          {
            path: 'meet-brief',
            element: <MeetBrief />,
          },
          {
            path: 'relax',
            element: <Relax />,
          },
          {
            path: 'insights-reports',
            element: <InsightsReports />,
          },
        ],
      },
    ],
  },
];
