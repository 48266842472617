import { gql } from '@apollo/client';
import { participantSessionsHistoryFieldsFragment } from 'data/fragments/bookingSubmission/participantSessionsHistoryFields';

export const manageBookingInviteApplicantsFragment = gql`
  fragment ManageBookingInviteApplicants on Submission {
    _id
    _user_id
    _booking_id
    created
    eligibility
    data {
      _question_id
      _answer_id
      value
    }
    agreement {
      status
    }
    in_context {
      location {
        name
        street1
        street2
        city
        state
        latitude
        longitude
      }
      travel_time
    }
    user {
      _id
      email
      blacklist
      meta {
        identity {
          firstname
          lastname
          gender
          birthday {
            timestamp
          }
          languages {
            english {
              speak {
                native
                fluent
                conversational
                beginner
              }
            }
          }
        }
        work {
          status {
            fulltime
            parttime
            fulltime_student
            unemployed
            homeduties
            retired
          }
          employment_type
          job_title
          _industry_id
          _subindustry_id
        }
        family {
          status {
            single
            relationship
            married
            divorced
            widowed
          }
        }
        education {
          highschool
          some_university
          undergraduate
          postgraduate
          apprenticeship
        }
        social {
          linkedin {
            profile_url
          }
        }
        feedback_sample {
          recording_url
        }
      }
      location {
        city
        state
        postal_code
        timezone
      }
      contact {
        phone {
          country_code
          mobile
        }
      }
      settings {
        remote {
          setup_check
        }
      }
    }
    completed
    user_confirm
    status_updated
    help_requested
    transaction {
      payment_due
      suspended
    }
    rating {
      # Remove punctuality and engagement here when you can confirm its not being used anymore as it seems to be used used in calendar, but its legacy field so feel free to remove when its safe to do.
      engagement
      punctuality
      overall
    }
    feedback {
      participant
    }
    CurrentStatus {
      _id
      _session_id
      status
      cancel
      _online_task_link_id
      history {
        ...participantSessionsHistoryFields
      }
    }
    sessions {
      _id
      _online_task_link_id
      _session_id
      cancel
      cancel_reason
      status
      no_show_request
      issue_request
      session {
        _id
        start
        end
        status
        _researcher_user_id
        askable_live_rooms {
          sid
          composition_sid
        }
      }
      history {
        ...participantSessionsHistoryFields
      }
    }
  }
  ${participantSessionsHistoryFieldsFragment}
`;
