import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const EllipsesMenuIcon: CreateIconType = createIcon({
  displayName: 'EllipsesMenuIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      stroke="none"
      fill="currentColor"
      strokeWidth="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.52368 2.28125C5.52368 3.24125 6.06368 3.78125 7.02368 3.78125C7.98368 3.78125 8.52368 3.24125 8.52368 2.28125C8.52368 1.32125 7.98368 0.78125 7.02368 0.78125C6.06368 0.78125 5.52368 1.32125 5.52368 2.28125Z"
    />,
    <path
      stroke="none"
      strokeWidth="none"
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.52368 7C5.52368 7.96 6.06368 8.5 7.02368 8.5C7.98368 8.5 8.52368 7.96 8.52368 7C8.52368 6.04 7.98368 5.5 7.02368 5.5C6.06368 5.5 5.52368 6.04 5.52368 7Z"
    />,
    <path
      stroke="none"
      fill="currentColor"
      strokeWidth="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.52368 11.7187C5.52368 12.6787 6.06368 13.2187 7.02368 13.2187C7.98368 13.2187 8.52368 12.6788 8.52368 11.7188C8.52368 10.7587 7.98368 10.2187 7.02368 10.2187C6.06368 10.2187 5.52368 10.7587 5.52368 11.7187Z"
    />,
  ],
});
