import { gql } from '@apollo/client';

export default gql`
  mutation bulkInviteParticipants(
    $booking_id: ID!
    $participant_sessions: [SessionsTypeInput!]
    $online_task_link_id: ID
  ) {
    bulkInviteParticipants(
      booking_id: $booking_id
      participant_sessions: $participant_sessions
      online_task_link_id: $online_task_link_id
    ) {
      _id
    }
  }
`;
