import { gql } from '@apollo/client';

export const participantSessionsHistoryFieldsFragment = gql`
  fragment participantSessionsHistoryFields on BookingSessionHistory {
    session_opportunity_application
    session_invitation_notification
    session_invitation_accepted
    session_completed_feedback_submitted
    session_invitation_declined
    session_paid
    session_confirmed_cancellation
    session_participant_cancelled_by_client
    last_quantitative_application_time
    session_quant_invitation_notification
    session_quant_completed
    early_confirmation_request
    session_invitation_notification_followup
    session_start_reminder_no_action
    session_start_reminder_action_needed
    session_start_reminder_action_needed_followup
    session_start_reminder_2_askable_incentive_message
    session_start_reminder_last_askable_warning
    session_start_reminder_confirmation_pending_askable_notification
    session_completed_feedback_request
    session_completed_client_feedback_request
    session_click_confirm_button
  }
`;
