import React, { useEffect, useState, Fragment, useRef } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { askablePlusUtils } from 'lib/askablePlus';

// External components
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { QuotaInput, QuotaInputRef } from '../components/QuotaInput';

function UsabilityTestingQuota(props: any) {
  const quotaInputRef = useRef<QuotaInputRef>(null);
  const navigate = useNavigate();
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      navigate({ pathname: '/askable-plus/create' });
    } else if (totalResearchTypesSelected <= 1) {
      navigate({ pathname: `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria` });
    } else if (!hasUsabilityTestingType) {
      navigate({ pathname: `/askable-plus/${askablePlusBrief._id}/competitive-analysis/quota` });
    } else {
      props.updateLastStep({
        step: 'Usability Testing',
        subStep: `/askable-plus/${askablePlusBrief._id}/usability-testing/quota`,
        stepId: 'usability_testing_quota',
      });
    }
  }, []);

  useEffect(() => {
    if (!quotaInputRef.current?.form.getValues('quota')) {
      quotaInputRef.current?.form.setError('quota', { message: 'This field is required' });
    }
    props.renderRightContent(rightContent());
  }, [askablePlusBrief, askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };
  const onChangeValue = (value: number) => {
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          usability: {
            ...askablePlusBriefState.askable_plus.research_type.usability,
            quota: value,
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/usability-testing/session-duration`;
    navigate({ pathname: redirectTo });
  };

  return (
    <QuotaInput
      className="createAskablePlusBriefContent"
      title="Usability Testing - Quota"
      description="How many participants do you need?"
      onClickNext={onClickNext}
      onQuotaValueChange={onChangeValue}
      defaultValues={{ quota: _.get(askablePlusBrief, 'askable_plus.research_type.usability.quota') }}
      ref={quotaInputRef}
    />
  );
}

export default UsabilityTestingQuota;
