import { gql } from '@apollo/client';

export default gql`
  mutation markAllNotificationsAsReadByTeam($_team_id: ID!) {
    markAllNotificationsAsReadByTeam(_team_id: $_team_id) {
      _id
      read
    }
  }
`;
