import { Outlet } from 'react-router-dom';

// Styles
import './styles/askablePlusOnboardingStyles.scss';

const isDisplayingHeaderAds = localStorage.getItem('showingHeaderAds') === 'true';

function AskablePlusOnboarding() {
  return (
    <div className={`askablePlusOnboardingContainer ${isDisplayingHeaderAds ? 'headerAds' : ''}`}>
      <div className="askablePlusOnboardingContentContainer">
        <Outlet />
      </div>
    </div>
  );
}

export default AskablePlusOnboarding;
