import { Button } from '@askable/ui/components/ui/button';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { MessageCircleWarningIcon } from 'lucide-react';
import { useIntercom } from 'react-use-intercom';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { KNOWN_ERROR_TYPES_ARRAY } from 'lib/graphqlErrorHandler';
import type { KNOWN_ERROR_TYPES } from 'lib/graphqlErrorHandler';

export const ErrorBoundaryGeneric = () => {
  const [params] = useSearchParams();
  const intercom = useIntercom();
  const { t } = useTranslation();
  const onContactSupport = () => {
    intercom.show();
  };

  const title = (() => {
    const error = params.get('error');

    if (KNOWN_ERROR_TYPES_ARRAY.some((e) => e === error)) {
      return t(`sections.errorBoundary.${error as KNOWN_ERROR_TYPES}`);
    }

    return t('sections.errorBoundary.default');
  })();

  return (
    <ErrorPage className="text md:max-w-[540px]">
      <MessageCircleWarningIcon className="h-8 w-8" />
      <h2 className="text-lg font-medium text-foreground">{title}</h2>
      <p>
        {t('sections.errorBoundary.teamNotified')}{' '}
        <Button variant="link" className="p-0" onClick={onContactSupport}>
          {t('sections.errorBoundary.contactSupport')}
        </Button>
      </p>
    </ErrorPage>
  );
};
