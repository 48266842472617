import { graphql } from 'src/graphql';

export const GetCountsQueries = graphql(`
  query getCounts($filter: AnalysesCountFilter!) {
    analysesCount(filter: $filter) {
      method
      count
    }
    analysesCountByFacilitator(filter: $filter) {
      _facilitator_id
      facilitator {
        _id
        picture_link
        first_name
        last_name
      }
      counts {
        method
        count
      }
    }
  }
`);
