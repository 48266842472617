import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Progress: ComponentStyleConfig = {
  baseStyle: ({ colorMode, colorScheme, isIndeterminate, isAnimated }) => {
    const barColor = (() => {
      if (isIndeterminate) {
        return undefined;
      }
      switch (`${colorMode}.${colorScheme}`) {
        case 'dark.yellow':
          return 'yellow.500';
        default:
          return undefined;
      }
    })();
    return {
      filledTrack: {
        backgroundColor: !isIndeterminate ? barColor : undefined,
        transition: isAnimated && !isIndeterminate ? 'width 0.2s' : undefined,
      },
    };
  },
};
