import { gql } from '@apollo/client';
import { userFieldsFragment } from 'data/fragments/user/userFields';

export default gql`
  query FetchUserByID {
    userByID: viewer {
      ...userFields
    }
  }
  ${userFieldsFragment}
`;
