import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ImageIcon: CreateIconType = createIcon({
  displayName: 'ImageIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path
      id="Intersect"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284C4.78221 13.1476 5.88037 13.25 7 13.25C8.11963 13.25 9.21779 13.1476 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.1539 9.20502 13.25 8.11315 13.25 7C13.25 5.88684 13.1539 4.79498 13.0401 3.73147C12.886 2.29082 11.7273 1.13211 10.2873 0.971609C9.21779 0.852392 8.11963 0.75 7 0.75C5.88037 0.75 4.78221 0.852392 3.71266 0.971609C2.2727 1.13211 1.114 2.29082 0.959867 3.73147C0.846083 4.79498 0.75 5.88684 0.75 7C0.75 8.11315 0.846084 9.20502 0.959867 10.2685Z"
    />,
    <path
      id="Vector"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 6.75C9.9665 6.75 10.75 5.9665 10.75 5C10.75 4.0335 9.9665 3.25 9 3.25C8.0335 3.25 7.25 4.0335 7.25 5C7.25 5.9665 8.0335 6.75 9 6.75Z"
    />,
    <path
      id="Intersect_2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.941162 10.2062C1.7583 9.18407 2.42264 8.55806 3.47809 7.82359C4.06533 7.41494 4.84176 7.37968 5.43317 7.78226C7.36002 9.09391 8.98317 11.0975 10.0361 13.0448"
    />,
  ],
});
