import { cn } from '@askable/ui/lib/utils';
import { LucideProps, createLucideIcon } from 'lucide-react';

export const RejectStatusComp = createLucideIcon('RejectStatus', [
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '11',
      fill: 'currentColor',
      stroke: 'currentColor',
      strokeWidth: '2px',
    },
  ],
  [
    'path',
    {
      stroke: '#fff',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '2',
      d: 'm16 8-8 8m0-8 8 8',
    },
  ],
]);

export const RejectStatus = ({ className, ...props }: LucideProps) => {
  return <RejectStatusComp className={cn('text-destructive', className)} {...props} />;
};
