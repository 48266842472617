import { useMemo, useState } from 'react';

import { columns as columnsData } from './bookings-columns';

import {
  BookingsConnectionDocument,
  BookingsConnectionQuery,
  BookingsConnectionQueryVariables,
} from 'generated/graphql';
import { useQuery } from 'urql';
import { BOOKING_STATUS } from 'lib/constants';

import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Helmet } from 'react-helmet-async';
import { Table } from '../components/Table';
import { sortByStatusThenCreated } from '../utils/utils';
import { sift } from 'radash';
import { BookingTableSchemaType } from './bookings-table-schema';
import { BookingsToolbar } from './BookingsToolbar';
import { BookingsTableSkeletons } from './BookingsTableSkeletons';
import { useConnectedClient } from 'context/ConnectedClientContext';

type Props<TData, TValue> = {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  fetching: boolean;
};

function BookingsTable<TData extends BookingTableSchemaType, TValue>({
  columns,
  data,
  fetching,
}: Props<TData, TValue>) {
  const { details } = useConnectedClient();
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    // These defaultColumn value is required for fixed columns
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },

    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <Table
      fetching={fetching}
      toolbar={<BookingsToolbar table={table} />}
      table={table}
      searchId="name"
      skeletons={<BookingsTableSkeletons />}
      tableNoDataToDisplayUi={{
        title: 'Studies',
        description1: !details?.type?.researcher
          ? "Here you'll find all the studies created by you or anyone on your team."
          : "Here you'll find all of your Askable Plus project studies.",
        description2: !details?.type?.researcher
          ? 'Create a new study to choose your study type and start the recruitment process.'
          : 'You currently have no studies assigned to you.',
        createButton: !details?.type?.researcher
          ? {
              link: '/booking-setup/create',
              text: 'Create a study',
            }
          : undefined,
      }}
    />
  );
}

const DEFAULT_SORT_ORDER: Record<(typeof BOOKING_STATUS)[keyof typeof BOOKING_STATUS], number> = {
  [BOOKING_STATUS.ACTIVE]: 1,
  [BOOKING_STATUS.IN_REVIEW]: 2,
  [BOOKING_STATUS.DRAFT]: 3,
  [BOOKING_STATUS.COMPLETED]: 4,
  [BOOKING_STATUS.REJECTED]: 5,
  [BOOKING_STATUS.PENDING_REVIEW]: 6,
  [BOOKING_STATUS.PENDING_PAYMENT]: 7,
  [BOOKING_STATUS.ARCHIVED]: 8,
};

export const BookingsContainer = () => {
  const { details } = useConnectedClient();
  const [{ data, fetching }] = useQuery<BookingsConnectionQuery, BookingsConnectionQueryVariables>({
    query: BookingsConnectionDocument,
    requestPolicy: 'cache-and-network',
    variables: {
      filter: {
        _team_id: details?.ConnectedTeam?._id,
      },
    },
  });

  const sortByStatus = useMemo(() => {
    const copiedBookings = [...(data?.bookingsConnection.nodes || [])];
    return sift(sortByStatusThenCreated(copiedBookings || [], DEFAULT_SORT_ORDER));
  }, [data?.bookingsConnection.nodes]);

  return (
    <>
      <Helmet>
        <title>Studies</title>
      </Helmet>
      <div className="w-full flex-auto">
        <BookingsTable data={sortByStatus} columns={columnsData} fetching={fetching} />
      </div>
    </>
  );
};
