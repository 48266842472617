import { graphql } from 'src/graphql';

export default graphql(`
  mutation NewTeam($name: String!, $allowedDomains: [String], $billingCountry: String!) {
    newTeam(name: $name, allowed_domains: $allowedDomains, billing_country: $billingCountry) {
      _id
      name
    }
  }
`);
