import { gql } from '@apollo/client';

export default gql`
  mutation markParticipantNoShow($booking_id: ID!, $participant_session_id: ID!, $no_show_request: Int) {
    markParticipantNoShow(
      booking_id: $booking_id
      participant_session_id: $participant_session_id
      no_show_request: $no_show_request
    ) {
      _id
      booking {
        team {
          _id
          settings {
            billing {
              subscription {
                credit {
                  remaining
                }
              }
            }
          }
        }
      }
    }
  }
`;
