import _ from 'lodash';
import { CREDITS_TYPE } from 'lib/constants';
import { CreditsPriceType, Credits as CreditsType } from 'generated/graphql';
import { teamUtils } from 'lib/teams';

const creditUtils = {
  getCreditPackPrice(creditPacks: CreditsType[] = [], numCredits = 0) {
    const currency = teamUtils.getCurrency();
    const adHocCreditPack = creditPacks.find((creditPack: CreditsType) => creditPack.type === CREDITS_TYPE.ADHOC);
    const predefinedCreditPacks = creditPacks.filter(
      (creditPack: CreditsType) => creditPack.type === CREDITS_TYPE.CREDIT_PACK,
    );
    const lowestCreditPack = _.minBy(predefinedCreditPacks, 'quantity');
    const biggestCreditPack = _.maxBy(predefinedCreditPacks, 'quantity');
    // If no credit pack price is found, it should default to USD
    const fallbackNoCurrencyFoundCreditPack = adHocCreditPack?.price?.find((price) => price?.currency_code === 'USD');

    // If the number of credits selected is smaller than lowest credit pack available, it should just return the price of the custom credit pack
    if (numCredits < (lowestCreditPack?.quantity || 0)) {
      return (
        adHocCreditPack?.price?.find((price) => price?.currency_code === currency.code) ||
        fallbackNoCurrencyFoundCreditPack
      );
    }
    // If the number of credits selected is bigger than the biggest credit pack available, it should return the biggest credit pack amount
    if (numCredits > (biggestCreditPack?.quantity || 0)) {
      return (
        biggestCreditPack?.price?.find((price) => price?.currency_code === currency.code) ||
        fallbackNoCurrencyFoundCreditPack
      );
    }
    const creditPackEquivalent = _.maxBy(
      predefinedCreditPacks.filter((creditPack: CreditsType) => (creditPack?.quantity || 0) <= numCredits),
      'quantity',
    );
    return (
      creditPackEquivalent?.price?.find((price) => price?.currency_code === currency.code) ||
      fallbackNoCurrencyFoundCreditPack
    );
  },
  hasSavings(creditPacks: any, numCredits: any) {
    // Method to check whether or not the numCredits should be classified as in a savings tier
    const predefinedCreditPacks = _.filter(
      creditPacks,
      (creditPack: any) => creditPack.type === CREDITS_TYPE.CREDIT_PACK,
    );
    const { quantity } = _.minBy(predefinedCreditPacks, 'quantity');
    return numCredits >= quantity;
  },
  generateArrayOfStepsForSlider(creditPacks: any) {
    const newArray = [];
    newArray.push({ index: 0, value: 0, savings: 0 });
    let finalNumber = 0;
    let increment = 100;
    for (let r = 1; r <= 1000; r += 1) {
      finalNumber += increment;
      // Get the savings
      const savingsToSave = creditUtils.getSavings(creditPacks, finalNumber);
      newArray.push({ index: r, value: finalNumber, savings: savingsToSave });
      if (r === 200) {
        increment = 300;
      } else if (r === 300) {
        increment = 500;
      } else if (r === 400) {
        increment = 1000;
      } else if (r === 500 || r === 600) {
        increment = 1500;
      }
    }
    return newArray;
  },
  getSavings(creditPacks: any, credits: any) {
    const taxesForCountry = teamUtils.getTax();
    const creditPackPrice = creditUtils.getCreditPackPrice(creditPacks, credits);
    const adHocCreditPack = _.find(creditPacks, (creditPack: any) => creditPack.type === CREDITS_TYPE.ADHOC);

    const adHocPrice = (adHocCreditPack?.price || [])
      .filter(Boolean)
      .find((price: CreditsPriceType) => price.currency_code === taxesForCountry?.currency_code)?.value;

    return Math.round(((adHocPrice - (creditPackPrice?.value || 0)) / adHocPrice) * 100);
  },
  findClosest(creditPacks: any, arraySource: any, valueToSearchFor: any, field: any) {
    const savings = creditUtils.getSavings(creditPacks, valueToSearchFor);

    // Gets the higher nearest value in the array
    const nextHigherValue = _.find(
      arraySource,
      (item: any) => item[field] >= valueToSearchFor && item.savings === savings,
    );
    // Gets the lower nearest value in the array
    const nextLowerValue = _.filter(
      arraySource,
      (item: any) => item[field] < valueToSearchFor && item.savings === savings,
    ).slice(-1)[0];

    // returns the best match for value
    if (nextHigherValue && nextHigherValue.value) {
      return nextHigherValue;
    }
    if (nextLowerValue && nextLowerValue.value) {
      return nextLowerValue;
    }

    return arraySource.reduce((best: any, current: any) => {
      return current[field] >= valueToSearchFor && (!best || current[field] < best[field]) ? current : best;
    }, undefined);
  },
};

export { creditUtils };
