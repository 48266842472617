import React, { useEffect, useState, Fragment } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Button } from '@askable/ui/components/ui/button';
import { bookingUtils } from 'lib/booking';

// External components
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import CardContainer from 'components/createBooking/components/cardContainer';
import { Label } from '@askable/ui/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';

function SessionDuration(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingData, setBookingData] = useState(null);
  const [sessionDurationValue, setSessionDurationValue] = useState(`${_.get(booking, 'config.session.duration')}`);

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/session-duration`,
      stepId: 'project_setup_session_duration',
    });
    if (bookingUtils.isOnlineTask(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/project-setup/task-duration` });
    }
  }, []);

  useEffect(() => {
    setBookingState(props.booking);
  }, [props.booking]);

  useEffect(() => {
    parseSessionDuration(sessionDurationValue);
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [booking, bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText:
          'Yes they do. Since the price above includes participant incentives, running longer sessions will require higher incentives and therefore cost a bit more.',
      },
    ];
    let typeLabel = 'sessions';
    if (bookingUtils.isLongitudinal(booking)) typeLabel = 'studies';
    else if (bookingUtils.isSurvey(booking)) typeLabel = 'surveys';
    else if (bookingUtils.isOnlineTask(booking)) typeLabel = 'tasks';

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__sessionDurationCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle={`Do longer ${typeLabel} cost more?`}
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open(
              'http://help.askable.com/en/articles/4254373-learning-more-about-credits#h_8ee2bdaff0',
              '_blank',
            );
          }}
        />
      </Fragment>
    );
  };

  const parseSessionDuration = (duration: string) => {
    setSessionDurationValue(duration);
  };

  const onChangeValue = (value: string) => {
    parseSessionDuration(value);

    const parsedValue = Number(value);

    if (parsedValue && parsedValue > 0) {
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          session: {
            ...bookingState.config.session,
            duration: parsedValue,
            time_limit: parsedValue,
          },
        },
      };
      setBookingState(bookingStateObj);

      if (parsedValue !== _.get(booking, 'config.session.duration')) {
        const bookingObj = {
          config: {
            session: {
              duration: parsedValue,
              time_limit: parsedValue,
            },
          },
        };
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { duration:... Remove this comment to see the full error message
        setBookingData(bookingObj);
        props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
      } else {
        setBookingData(null);
        props.history.replace({ booking: null, bookingState: null });
      }
    }
  };

  const onClickNext = () => {
    let redirectTo = `/booking-setup/${booking._id}/project-setup/participant-selection`;
    if (bookingUtils.isFocusGroup(booking)) {
      redirectTo = `/booking-setup/${booking._id}/audience/panel`;
    }
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Session duration
      </h1>
      <div className="flex max-w-sm flex-col gap-2">
        <Label htmlFor="__sessionduration">How long will your sessions go for?</Label>

        <Select onValueChange={onChangeValue} value={`${sessionDurationValue}`}>
          <SelectTrigger id="__sessionduration">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {bookingUtils
              .sessionDurations(_.get(booking, 'type'), _.get(booking, 'config.online_task.type'))
              ?.map((value) => {
                return (
                  <SelectItem key={value.value} value={`${value.value}`}>
                    {value.label}
                  </SelectItem>
                );
              })}
          </SelectContent>
        </Select>
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(SessionDuration);
