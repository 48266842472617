import { Modal, ModalContent, ModalHeader, ModalOverlay, Spinner, VStack } from 'ui';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const UpdateBillingCountryComponent = (props: Props) => {
  return (
    <Modal size="xs" isCentered isOpen={props.isOpen} onClose={props.onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <VStack my="10">
          <Spinner />
          <ModalHeader>Updating billing country...</ModalHeader>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
