import { gql } from '@apollo/client';

export default gql`
  mutation updateQuestion($booking_id: ID!, $question_id: ID!, $question: BookingInputConfigQuestion!) {
    updateQuestion(booking_id: $booking_id, question_id: $question_id, question: $question) {
      _id
      config {
        question {
          _id
          title
          description
          config {
            display_participant
            multiple_selection
            none_of_the_above
            type
          }
          options {
            _id
            label
            screen_in
          }
        }
      }
    }
  }
`;
