import { gql } from '@apollo/client';

export default gql`
  mutation deleteProjectQuestion($project_id: ID!, $question_id: ID!) {
    deleteProjectQuestion(project_id: $project_id, question_id: $question_id) {
      _id

      askable_plus {
        audience {
          booking_config {
            question {
              _id
              title
              description
              config {
                display_participant
                multiple_selection
                none_of_the_above
                type
              }
              options {
                _id
                label
                screen_in
              }
            }
          }
        }
      }
    }
  }
`;
