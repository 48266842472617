import { gql } from '@apollo/client';

export default gql`
  mutation createProjectQuestion($project_id: ID!, $question: BookingInputConfigQuestion!) {
    createProjectQuestion(project_id: $project_id, question: $question) {
      _id
      askable_plus {
        audience {
          booking_config {
            question {
              _id
              title
              description
              config {
                display_participant
                multiple_selection
                none_of_the_above
                type
              }
              options {
                _id
                label
                screen_in
              }
            }
          }
        }
      }
    }
  }
`;
