import gql from 'graphql-tag';

export default gql`
  mutation inviteParticipantSession($booking_id: ID!, $participant_session_id: ID!, $online_task_link_id: ID) {
    inviteParticipantSession(
      booking_id: $booking_id
      participant_session_id: $participant_session_id
      online_task_link_id: $online_task_link_id
    ) {
      _id
    }
  }
`;
