import { graphql } from 'src/graphql';

export const TeamTransactionHistoryQuery = graphql(`
  query TeamTransactionHistoryQuery($_team_id: ID!) {
    transactionsByTeam(_team_id: $_team_id, types: [1]) {
      _id
      type
      created
      method
      total_amount
      currency
      invoice {
        amount_due
        credit_quantity
        xero_id
      }
      Team {
        _id
        name
        settings {
          billing {
            stripe {
              customer_id
              payment(_id: $_team_id) {
                last4
              }
            }
          }
        }
      }
    }
  }
`);
