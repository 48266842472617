import { useCallback, useState } from 'react';

/* eslint-disable no-shadow, no-unused-vars */
export enum HubbleSurveys {
  AlwaysOn = '230774df-a73d-431e-8fce-bdc889ddade3',
  SessionsAlwaysOn = 'ac167d8b-2c6f-4bbe-85df-f36d4d93807b',
  SessionsDownloadTranscript = '5afa7efe-e6e1-469d-b828-085337892dcd',
  HighlightsAndInsightsEarlyAccess = '06ebdb40-fbae-4036-8d7b-583398d05f4d',
  TranscriptEdits = 'ff83eefa-f1e4-4cbd-b70b-02a5ffc9dda8',
  ReviewCall = '658853fb-8c33-4be4-969b-7332ad82e8ed',
  ParticipantsExport = '25a01390-c8fc-4dcc-a0ba-36927f7a9b49',
  AskAI = '9ed0e388-6821-4ec9-9960-688d308cd29c',
  DiscoverTimeDropdown = '748f13a6-08c9-4523-93d0-224b88f38d2a',
}

declare module Hubble {
  const isReady: boolean;
  function show(s: string): void;
}

const HUBBLE_SCRIPT_NAME = 'Hubble';

export function useHubble() {
  const hubble = () => {
    // @ts-ignore
    if (window && window[HUBBLE_SCRIPT_NAME]) {
      // @ts-ignore
      return window[HUBBLE_SCRIPT_NAME];
    }
    return null;
  };
  const [isReady, setIsReady] = useState(!!hubble());
  let isReadyInterval: number | undefined;
  if (!isReady) {
    isReadyInterval = window.setInterval(() => {
      if (hubble()) {
        setIsReady(true);
        clearInterval(isReadyInterval);
      }
    }, 200);
  }

  const show = useCallback(
    (survey: HubbleSurveys) => {
      if (isReady) {
        hubble().show(survey);
      } else {
        console.warn('Hubble is not set up in this environment');
        throw new Error('Hubble is not set up in this environment');
      }
    },
    [isReady],
  );

  const hide = () => {
    const shadowRoot = document.getElementsByTagName('hubble-container')[0].shadowRoot; // eslint-disable-line
    if (!shadowRoot) {
      return;
    }

    const closeButton: HTMLElement | null = shadowRoot.querySelector('.modal__close');
    if (closeButton) {
      closeButton.click();
    }
  };

  return {
    isReady,
    show,
    hide,
  };
}
