import { gql } from '@apollo/client';
import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export default gql`
  query FetchTeamById($_id: ID) {
    teamById(_id: $_id) {
      ...teamFields
      TeamInvitations {
        _id
        email
        last_sent
        InviterUser {
          _id
          meta {
            identity {
              firstname
              lastname
              middlename
              gender
            }
            social {
              google {
                id
              }
            }
            work {
              title
              company_name
            }
          }
        }
      }
    }
  }
  ${teamFieldsFragment}
`;
