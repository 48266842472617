import { useMemo } from 'react';
import { sortByStatusThenCreated } from 'containers/Root/utils/utils';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { ProjectsDocument, ProjectsQuery, ProjectsQueryVariables } from 'generated/graphql';
import { sift } from 'radash';
import { useQuery } from 'urql';
import { PROJECT_STATUS_KEY_VALUE } from 'lib/constants';

const DEFAULT_SORT_ORDER: Record<(typeof PROJECT_STATUS_KEY_VALUE)[keyof typeof PROJECT_STATUS_KEY_VALUE], number> = {
  [PROJECT_STATUS_KEY_VALUE.active]: 1,
  [PROJECT_STATUS_KEY_VALUE.draft]: 2,
  [PROJECT_STATUS_KEY_VALUE.completed]: 3,
  [PROJECT_STATUS_KEY_VALUE.archived]: 4,
};

export const useProjectsData = () => {
  const { details } = useConnectedClient();
  const [{ fetching, data: _data }] = useQuery<ProjectsQuery, ProjectsQueryVariables>({
    query: ProjectsDocument,
    requestPolicy: 'cache-and-network',
    variables: {
      filter: {
        type: {
          eq: 1,
        },
        _team_id: details?.ConnectedTeam?._id,
      },
    },
  });

  const data = useMemo(() => {
    const copiedProjects = [...(_data?.projects?.nodes || [])];
    return sift(sortByStatusThenCreated(copiedProjects || [], DEFAULT_SORT_ORDER));
  }, [_data]);

  return {
    fetching,
    data,
  };
};
