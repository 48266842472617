import { gql } from '@apollo/client';
import { manageBookingInviteApplicantsFragment } from 'data/fragments/bookingSubmission/manageBookingInviteApplicants';

export default gql`
  query findParticipantsSubmission($bookingId: ID!, $filters: String, $sorting: String, $offset: Int, $limit: Int) {
    findParticipantsSubmission(
      bookingId: $bookingId
      filters: $filters
      sorting: $sorting
      offset: $offset
      limit: $limit
    ) {
      ...ManageBookingInviteApplicants
    }
  }
  ${manageBookingInviteApplicantsFragment}
`;
