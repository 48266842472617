import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const CalendarIcon: CreateIconType = createIcon({
  displayName: 'CalendarIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path d="M5.521 1.10339V3.90525" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path d="M10.4714 1.10339V3.90525" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />,
    <path
      d="M1.4807 11.8897C1.65873 13.478 2.9497 14.739 4.54608 14.8159C5.64947 14.869 6.77663 14.8967 7.99996 14.8967C9.22329 14.8967 10.3504 14.869 11.4539 14.8159C13.0502 14.739 14.3411 13.478 14.5192 11.8897C14.6391 10.8201 14.7375 9.72348 14.7375 8.6064C14.7375 7.48934 14.6391 6.39275 14.5192 5.32317C14.3411 3.73488 13.0502 2.47381 11.4539 2.39692C10.3504 2.34378 9.22329 2.31616 7.99996 2.31616C6.77663 2.31616 5.64947 2.34378 4.54608 2.39692C2.9497 2.47381 1.65873 3.73488 1.4807 5.32317C1.36081 6.39275 1.26245 7.48934 1.26245 8.6064C1.26245 9.72348 1.36081 10.8201 1.4807 11.8897Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />,
    <path d="M1.58484 6.34485H14.4814" stroke="currentColor" strokeWidth="1.5" />,
  ],
});
