import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { ChevronLeft } from 'lucide-react';
import { Progress } from '@askable/ui/components/ui/progress';
import { Button } from '@askable/ui/components/ui/button';

function MeetBrief(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/relax' });
  };

  const onClickBack = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/match-to-a-researcher' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerBack">
          <ChevronLeft className="backIcon h-7 w-7" onClick={onClickBack} />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="contentTextData">
            <span className="textTitle font--title1">Meet & brief your researcher</span>
            <span className="descriptionText">
              Take the time to connect with your researcher over video call, run through your research plan and lock
              down a discussion guide.
            </span>
          </div>
          <div className="contentImageData">
            <div className="illustrationContainer">
              <img className="illustration" src="/askablePlus/illustrations/personsMeeting.png" alt="personsMeeting" />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={5} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(MeetBrief);
