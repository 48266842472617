import { TooltipProvider, TooltipTrigger, TooltipContent, Tooltip } from '@askable/ui/components/ui/tooltip';
import { ReactNode, useRef } from 'react';
import { useElementOverflow } from 'src/hooks/useElementOverflow';

type Props = {
  children: ReactNode;
};

export const ProjectsCellResearcher = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflow = useElementOverflow(ref);

  const comp = (
    <div ref={ref} className="max-w-[136px] overflow-hidden text-ellipsis whitespace-nowrap text-muted-foreground">
      {children}
    </div>
  );

  if (!isOverflow) {
    return comp;
  }
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="cursor-text">{comp}</TooltipTrigger>
        <TooltipContent>
          <p>{children}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
