import { AccessorColumnDef } from '@tanstack/react-table';

import { ColumnHeader } from '../components/ColumnHeader';
import { bookingTableSchema } from './bookings-table-schema';
import { z } from 'zod';
import { format } from 'date-fns';
import { BookingsCellType } from './BookingsCellType';
import { BookingCellStatus } from './BookingsCellStatus';
import { BookingsCellActions } from './BookingsCellActions';
import { BookingsConnection } from 'generated/graphql';
import { ArrayElement } from 'shared-utils';
import { BOOKING_TYPE, BOOKING_STATUS, BOOKING_STATUS_VALUE_TO_KEY } from 'lib/constants';
import { RESOLVED_BOOKING_TYPES, getResolvedBookingType } from 'utils/booking-utils';
import { CellCreatedBy } from '../components/CellCreatedBy';

import { bookingUtils } from 'lib/booking';
import { CellName } from '../components/CellName';
import { BookingCellRateExperience } from './BookingCellRateExperience';

const getDueDate = (startEndSessions: NonNullable<ArrayElement<BookingsConnection['nodes']>>['StartEndSessions']) => {
  const startDate = startEndSessions?.start?.start ? format(startEndSessions?.start?.start, 'dd MMM yy') : '';
  const endDate = startEndSessions?.end?.start ? format(startEndSessions?.end.start, 'dd MMM yy') : '';

  if (startDate === endDate) return startDate;

  const shortStartDate = startEndSessions?.start?.start ? format(startEndSessions?.start?.start, 'dd MMM') : '';
  return `${shortStartDate} - ${endDate}`;
};

type BookingTableSchemaType = z.infer<typeof bookingTableSchema>;

export const columns: AccessorColumnDef<BookingTableSchemaType>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => <ColumnHeader column={column} title="Title" />,
    cell: ({ row }) => {
      const { url } = bookingUtils.getBookingLinkURL(row.original);
      return <CellName url={url}>{row.original.name}</CellName>;
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'type',
    enableResizing: false,
    maxSize: 176,
    minSize: 176,
    size: 176,
    accessorFn: (row) => {
      return getResolvedBookingType(
        row.type as (typeof BOOKING_TYPE)[keyof typeof BOOKING_TYPE],
        row.config?.online_task?.type,
      );
    },
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Type" />
    ),
    cell: ({ getValue }) => {
      return <BookingsCellType type={getValue() as RESOLVED_BOOKING_TYPES} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: ({ status }) => {
      return BOOKING_STATUS_VALUE_TO_KEY[status as (typeof BOOKING_STATUS)[keyof typeof BOOKING_STATUS]];
    },
    enableResizing: false,
    maxSize: 144,
    minSize: 144,
    size: 144,
    id: 'status',
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Status" />
    ),
    cell: ({ getValue }) => {
      return <BookingCellStatus status={getValue() as keyof typeof BOOKING_STATUS} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'total_participants',
    enableResizing: false,
    maxSize: 96,
    minSize: 96,
    size: 96,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Quota" />
    ),
    cell: ({ getValue }) => {
      return <div className="text-muted-foreground">{getValue() as string}</div>;
    },
  },
  {
    accessorFn: ({ StartEndSessions }) => {
      return StartEndSessions?.end?.start || undefined;
    },
    id: 'closing date',
    enableResizing: false,
    maxSize: 176,
    minSize: 176,
    size: 176,
    sortUndefined: 1,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Closing date" />
    ),
    cell: ({ row }) => {
      const startEndSessions = row.original.StartEndSessions;
      if (!startEndSessions) {
        return null;
      }
      return <div className="text-muted-foreground">{getDueDate(startEndSessions)}</div>;
    },
  },
  {
    accessorKey: 'created',
    enableResizing: false,
    maxSize: 160,
    minSize: 160,
    size: 160,
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Created date" />
    ),
    cell: ({ getValue }) => {
      const created = getValue();
      if (!created) {
        return null;
      }
      return <div className="text-muted-foreground">{format(created as number, 'd MMM yy')}</div>;
    },
  },
  {
    id: 'created by',
    enableResizing: false,
    maxSize: 160,
    minSize: 160,
    size: 160,
    accessorFn: ({ user }) => {
      if (!user?.meta?.identity?.firstname && !user?.meta?.identity?.lastname) {
        return null;
      }
      return `${user?.meta?.identity?.firstname || ''} ${user?.meta?.identity?.lastname || ''}`;
    },
    header: ({ column, table }) => (
      <ColumnHeader disableSortByPageCount={table.getPageCount()} column={column} title="Created by" />
    ),
    cell: ({ getValue }) => {
      const fullname = getValue();

      return <CellCreatedBy displayName={fullname as string} />;
    },
  },
  {
    accessorKey: 'rate booking',
    header: '',
    enableResizing: false,
    maxSize: 56,
    minSize: 56,
    size: 56,
    cell: ({ row }) => {
      return <BookingCellRateExperience row={row} />;
    },
    enableHiding: false,
  },
  {
    accessorKey: 'actions',
    enableResizing: false,
    maxSize: 56,
    minSize: 56,
    size: 56,
    cell: ({ row }) => (
      <BookingsCellActions
        demo={row.original.config?.demo}
        status={row.original.status}
        name={row.original.name}
        _id={row.original._id}
      />
    ),
    header: '',
    enableHiding: false,
  },
];
