import type { ComponentStyleConfig, BoxProps } from '@chakra-ui/react';

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
export const PageHeader: ComponentStyleConfig = {
  baseStyle: ({ colorMode }) => {
    const darkMode = colorMode === 'dark';

    return {
      title: {
        color: darkMode ? 'white' : 'gray.800',
      },
      body: {
        color: darkMode ? 'gray.400' : 'gray.600',
      },
    };
  },
};
