import { gql } from '@apollo/client';

export default gql`
  query FetchIndustryList {
    industryList {
      _id
      name
      subcategories {
        _id
        name
      }
    }
  }
`;
