import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const MicrophoneIcon: CreateIconType = createIcon({
  displayName: 'MicrophoneIcon',
  defaultProps: defaultOptions,
  path: [
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6.93829 11.3688V13.2507" />,
    <path
      stroke="currentColor"
      d="M6.98167 0.765015C8.52256 0.765015 9.91745 1.68301 9.95056 3.00055C10.0067 5.23649 10.5304 8.78194 7.01829 8.78194C3.5062 8.78194 3.99321 5.23649 4.0494 3.00055C4.08251 1.68301 5.44078 0.765015 6.98167 0.765015Z"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.41931 7.05945C1.60266 9.46795 3.60686 11.3691 6.96331 11.3691C10.1127 11.3691 12.2009 9.46263 12.5808 7.05945"
    />,
  ],
});
