import { ApolloError } from '@apollo/client';

export function handleError(e: any): string {
  if (e instanceof ApolloError) {
    return e.message;
  }

  return e?.message ?? 'An unknown error has occurred. We are looking into it.';
}

const prefix = 'Invariant failed';

export function invariant(condition: any, message?: string | (() => string)): asserts condition {
  if (condition) {
    return;
  }
  // Condition not passed

  const provided: string | undefined = typeof message === 'function' ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const value: string = provided ? `${prefix}: ${provided};` : prefix;
  throw new Error(value);
}
