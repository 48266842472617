import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  variants: {
    outline: ({ colorMode }) => {
      const isLight = colorMode === 'light';
      return {
        field: {
          _placeholder: {
            color: isLight ? 'gray.500' : 'gray.400',
          },
          borderColor: isLight ? 'gray.500' : 'gray.400',
          _hover: {
            borderColor: isLight ? 'gray.600' : 'gray.400',
          },
        },
      };
    },
  },
};
