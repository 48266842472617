import { gql } from '@apollo/client';
import { projectFieldsFragment } from 'data/fragments/project/projectFields';

export default gql`
  mutation submitAskablePlusProject(
    $_project_id: ID!
    $_transaction_id: ID
    $currency_code: String
    $study_flags: SubmitAskablePlusProjectStudyFlagsInput
  ) {
    submitAskablePlusProject(
      _project_id: $_project_id
      _transaction_id: $_transaction_id
      currency_code: $currency_code
      study_flags: $study_flags
    ) {
      ...projectFields
    }
  }
  ${projectFieldsFragment}
`;
