import { graphql } from 'src/graphql';

export default graphql(`
  query projectAskablePlusCreditPrice($project: ProjectInput!) {
    projectAskablePlusCreditPrice(project: $project) {
      booking_credits
      researcher_hours
      total_credits
      total_participants
    }
  }
`);
