import { useEffect } from 'react';

const MessageDrawerQuery = (props: any) => {
  useEffect(() => {
    if (props.subscribeToRealTimeData) props.subscribeToRealTimeData();
    if (props.scrollToBottom) props.scrollToBottom();
  }, []);

  return props.children;
};

export default MessageDrawerQuery;
