import { bookingUtils } from 'lib/booking';
import { useState } from 'react';

const AVAILABLE_POLYGONS = [
  'ACT',
  'AU',
  'CA',
  'GB',
  'IE',
  'IN',
  'NSW',
  'NT',
  'NZ',
  'PH',
  'QLD',
  'SA',
  'TAS',
  'US',
  'VIC',
  'WA',
] as const;

const MARKER_ICON = '/booking/icons/mapMarkerIcon.svg';

type AvailablePolygons = (typeof AVAILABLE_POLYGONS)[number];
type LatLng = { lat: number; lng: number };
type Visualisation = google.maps.Polygon[] | google.maps.Circle;

/**
 * Given a google map instance, this hook is used to add markers and visualisations to the map.
 * The visualisation consists of either a circle or a polygon, depending on whether the polygonName is provided.
 */
export const useGoogleMapMarkers = (googleMap: google.maps.Map | null) => {
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [visualisations, setVisualisations] = useState<{ name: string; visualisations: Visualisation }[]>([]);

  const resetMarkers = () => {
    markers.forEach((marker) => marker.setMap(null));
    visualisations.forEach((visualisation) => {
      if (Array.isArray(visualisation.visualisations)) {
        visualisation.visualisations.forEach((v) => v.setMap(null));
        return;
      }
      visualisation.visualisations.setMap(null);
    });
    setMarkers([]);
    setVisualisations([]);
  };

  const addMarker = async ({ lat, lng }: LatLng, title: string, polygonName?: string | null) => {
    // set marker
    const marker = new window.google.maps.Marker({
      map: googleMap,
      position: {
        lat,
        lng,
      },
      title,
      animation: window.google.maps.Animation.DROP,
      icon: { url: MARKER_ICON },
    });
    setMarkers((_markers) => [..._markers, marker]);

    // if polygonName is provided, draw polygon
    if (polygonName && AVAILABLE_POLYGONS.includes(polygonName as AvailablePolygons)) {
      const paths = await bookingUtils.getLocationPolygons(polygonName);
      if (paths) {
        const polygonPaths = paths.map((item) => {
          const polygon = new window.google.maps.Polygon({
            paths: item,
            map: googleMap,
            fillColor: '#56A1EB',
            strokeColor: '#066CD2',
            strokeWeight: 1,
          });
          return polygon;
        });
        setVisualisations((_visualisations) => [..._visualisations, { name: title, visualisations: polygonPaths }]);
      }
      return;
    }

    // if polygonName is not provided, draw circle
    const circle = new window.google.maps.Circle({
      strokeColor: '#56A1EB',
      strokeWeight: 1,
      fillColor: '#066CD2',
      map: googleMap,
      center: { lat, lng },
      radius: 20000,
    });
    setVisualisations((_visualisations) => [..._visualisations, { name: title, visualisations: circle }]);
  };

  return { addMarker, resetMarkers };
};
