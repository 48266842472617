import { useEffect, useState, useMemo, Fragment } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useIntercom } from 'react-use-intercom';
import _ from 'lodash';
import { Button } from '@askable/ui/components/ui/button';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { askablePlusUtils } from 'lib/askablePlus';
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Label } from '@askable/ui/components/ui/label';

// External components
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';

function Deliverables(props: any) {
  const intercom = useIntercom();

  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [deliverables, setDeliverables] = useState(_.get(askablePlusBrief, 'askable_plus.deliverables'));
  const [deliverablesOther, setDeliverablesOther] = useState(
    _.get(askablePlusBrief, 'askable_plus.deliverables.other_description'),
  );
  const [deliverablesOtherError, setDeliverablesOtherError] = useState(
    _.get(askablePlusBrief, 'askable_plus.deliverables.other') &&
      !_.get(askablePlusBrief, 'askable_plus.deliverables.other_description'),
  );

  let filteredDeliverables = askablePlusUtils.deliverables();
  if (
    !(
      askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief) ||
      askablePlusUtils.hasUsabilityTestingType(askablePlusBrief) ||
      askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief) ||
      askablePlusUtils.isContinuousDiscoverType(askablePlusBrief)
    )
  ) {
    filteredDeliverables = _.filter(
      askablePlusUtils.deliverables(),
      (deliverable: any) => deliverable.basicResearchDeliverable === true,
    );
  }

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/deliverables`,
        stepId: 'project_setup_deliverables',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    const cardTexts = [
      { cardText: 'The findings will be compiled into one report at the end of the project.' },
      {
        cardText:
          'Additionally, the researcher will provide you with an interim report at the end of each research phase.',
        additionalText: true,
      },
    ];
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__deliverablesCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="I selected multiple research types. What will the deliverables look like?"
          cardTexts={cardTexts}
          onLinkClick={() => {
            intercom.showNewMessages();
          }}
          linkText="Chat to us"
          hideArrow
        />
      </Fragment>
    );
  };

  const onCheckOption = (key: any) => {
    setDeliverablesOtherError(false);
    const newDeliverables = _.clone(deliverables);
    if (_.get(newDeliverables, `[${key}]`)) {
      newDeliverables[key] = false;
    } else {
      newDeliverables[key] = true;
    }

    setDeliverables(newDeliverables);

    let newDeliverablesOther = deliverablesOther;
    if (key === 'other') {
      newDeliverablesOther = '';
      setDeliverablesOther('');
    }

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        deliverables: {
          ...askablePlusBriefState.askable_plus.deliverables,
          ...newDeliverables,
          other_description: newDeliverablesOther,
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onChangeDeliverablesOther = (value: any) => {
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        deliverables: {
          ...askablePlusBriefState.askable_plus.deliverables,
          other_description: value,
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const debouncedInputChangeHandler = useMemo(
    () => _.debounce(onChangeDeliverablesOther, 300),
    [props, askablePlusBrief],
  );

  const onClickNext = () => {
    if (_.get(deliverables, 'other') && !deliverablesOther) {
      setDeliverablesOtherError(true);
    } else {
      let redirectTo;
      if (askablePlusUtils.isContinuousDiscoverType(askablePlusBrief)) {
        redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/frequency-duration`;
      } else {
        redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/due-date`;
      }
      props.history.push({ pathname: redirectTo });
    }
  };

  const renderCheckBox = ({ key, label, description }: any) => {
    return (
      <div className="flex gap-2 px-6 py-2">
        <Checkbox
          id={key}
          key={key}
          checked={_.get(deliverables, `[${key}]`)}
          onCheckedChange={() => {
            onCheckOption(key);
          }}
        />
        <Label htmlFor={key} className="flex flex-col gap-1">
          <div>{label}</div> <div className="text-sm">{description}</div>
        </Label>
      </div>
    );
  };

  if (askablePlusUtils.isContinuousDiscoverType(askablePlusBrief)) {
    const subHeaderClass = 'text-base font-semibold mt-5';
    const listClass = 'list-inside list-disc text-base pl-4 label';
    return (
      <div className="createAskablePlusBriefContent">
        <h1 className="title">Deliverables</h1>
        <p className="label">What you’ll receive throughout your project:</p>
        <p className={subHeaderClass}>Weekly insight report</p>
        <ul className={listClass}>
          <li>Participant overview</li>
          <li>Summary of findings</li>
          <li>Trends and insights extracted from the interviews</li>
          <li>Quotes</li>
          <li>Highlight reels</li>
        </ul>
        <p className={subHeaderClass}>Interview recordings</p>
        <ul className={listClass}>
          <li>Full recording of every interview (Moderated & AI moderated)</li>
          <li>Transcript</li>
        </ul>
        <p className={subHeaderClass}>Insight dashboard access</p>
        <ul className={listClass}>
          <li>Auto-generated dashboard showing trending insights & themes</li>
          <li>Supporting evidence per insight</li>
          <li>All dynamic - updating as your study evolves</li>
        </ul>
        <img src="/booking/images/insightReportExample.png" alt="Insight report example" className="mt-10" />
        <p className="label pt-3">Insight report example</p>
        <div className="buttonNextContainer">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Deliverables</h1>
      <p className="label">What deliverables do you require the researcher to provide?</p>
      {_.map(filteredDeliverables, (item: any) => {
        return renderCheckBox(item);
      })}
      {_.get(deliverables, 'other') ? (
        <div className="flex flex-col gap-2">
          <Textarea
            id="__deliverablesOther"
            placeholder="Please specify"
            required
            value={deliverablesOther}
            rows={1}
            maxRows={2}
            onChange={(e) => {
              setDeliverablesOther(e.target.value);
              setDeliverablesOtherError(false);
              debouncedInputChangeHandler(e.target.value);
            }}
            variant={deliverablesOtherError ? 'error' : undefined}
          />
          {deliverablesOtherError ? <div className="text-xs text-destructive">Required</div> : null}
        </div>
      ) : null}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(Deliverables);
