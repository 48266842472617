import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter } from '@askable/ui/components/ui/dialog';
import { Button } from '@askable/ui/components/ui/button';

export const DialogArchive = ({
  resourceTypeName,
  resourceName,
  onArchive,
  onCancel,
  onOpenChange,
  open,
}: {
  resourceTypeName: string;
  resourceName: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  onCancel: () => void;
  onArchive: () => void;
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle>Archive {resourceTypeName}</DialogTitle>
        <DialogDescription>
          <p className="text-foreground">
            Do you want to archive <span className="font-medium">{resourceName}</span>
          </p>
        </DialogDescription>
        <DialogFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant="primary" onClick={onArchive}>
            Archive
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
