import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const SendIcon: CreateIconType = createIcon({
  displayName: 'SendIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M12.4792 1.28683C11.8226 0.630236 10.5582 0.494267 5.77247 2.45926C2.43711 3.82876 0.510501 4.63974 0.847961 6.72717C0.996152 7.64383 1.9393 9.05113 2.99994 10.1828V12.2394C2.99994 13.1076 4.03024 13.5634 4.67261 12.9794L5.48367 12.2421C6.05799 12.5982 6.60371 12.8477 7.03886 12.9181C9.1263 13.2555 9.93728 11.3289 11.3068 7.99356C13.2718 3.20787 13.1358 1.94343 12.4792 1.28683Z"
    />,
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M12.5701 1.38593L2.73185 9.88798C1.78441 8.81487 0.983586 7.56573 0.848022 6.72717C0.510562 4.63974 2.43717 3.82876 5.77253 2.45926C10.5582 0.494267 11.8227 0.630236 12.4793 1.28683C12.5108 1.31836 12.5411 1.35128 12.5701 1.38593Z"
    />,
  ],
});
