import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ClockFilledIcon: CreateIconType = createIcon({
  displayName: 'ClockFilledIcon',
  defaultProps: {
    ...defaultOptions,
    color: 'green.500',
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9 4C9 3.44772 8.55229 3 8 3C7.44772 3 7 3.44772 7 4V8.8C7 9.2089 7.24895 9.57661 7.62861 9.72848L10.6286 10.9285C11.1414 11.1336 11.7234 10.8842 11.9285 10.3714C12.1336 9.85861 11.8842 9.27664 11.3714 9.07152L9 8.12297V4Z"
      fill="currentColor"
    />
  ),
});
