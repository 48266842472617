import { Global } from '@emotion/react';

export const Fonts = () => {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Wigrum';
        font-style: normal;
        font-weight: regular;
        font-display: swap;
        src:  url(/fonts/Wigrum-Regular.otf) format('opentype');
      }

      @font-face {
        font-family: Wigrum;
        font-style: normal;
        font-weight: medium;
        font-display: swap;
        src: url(/fonts/Wigrum-Medium.otf) format('opentype');
      }
      `}
    />
  );
};
