import { ClientDetails } from 'context/ConnectedClientContext';
import { Project } from 'generated/graphql';
import { PROJECT_STATUS_KEY_VALUE } from 'lib/constants';

export const canProjectBeDuplicated = (details: ClientDetails) => {
  return !details?.type?.researcher;
};

export const canProjectBeArchived = (project: Pick<Project, 'status'>) => {
  return (
    typeof project.status === 'number' &&
    ![PROJECT_STATUS_KEY_VALUE.active, PROJECT_STATUS_KEY_VALUE.archived].includes(project.status)
  );
};
