import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { ChevronLeft } from 'lucide-react';
import { Progress } from '@askable/ui/components/ui/progress';
import { Button } from '@askable/ui/components/ui/button';

function SubmitOnline(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/research-plan' });
  };

  const onClickBack = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/welcome' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerBack">
          <ChevronLeft className="backIcon h-7 w-7" onClick={onClickBack} />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="contentTextData">
            <span className="textTitle font--title1">Submit your Askable+ project online</span>
            <span className="descriptionText">
              Select how many remote interviews need to happen and who you are targeting, and your project is set up.
            </span>
          </div>
          <div className="contentImageData">
            <div className="illustrationContainer">
              <img className="illustration" src="/askablePlus/illustrations/personOnLaptop.png" alt="personOnLaptop" />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={2} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(SubmitOnline);
