import { FC, ReactNode, memo } from 'react';
import { Box, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';

type Props = FlexProps & {
  title: string;
  icon?: ReactNode;
};

const SVGImage = memo(() => {
  return (
    <svg width="175" height="118" viewBox="0 0 175 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_4)">
        <path
          d="M112.01 51.44C152.49 47.88 163.99 53.52 167.95 59.27C167.99 56.92 167.54 55.58 167.04 53.24C163.39 36.39 140.09 26.02 87.19 36.63C34.29 47.24 0 98.65 0 98.65C0 98.65 0.12 98.73 0.35 98.88C11.39 91.95 64.09 55.66 112.01 51.44Z"
          fill="#F7FAFC"
        />
        <path
          d="M172.67 82.56C175.6 76.36 173.71 68.84 168.03 64.99C159.29 59.07 141.91 55.93 113.49 58.44C82.21 61.19 51.65 72.84 23.59 86.43C16.26 89.98 6.62001 93.64 0.350006 98.89C1.93001 99.71 4.67001 101.59 7.84001 103.58C19.27 110.75 34.56 112.88 49.46 115.12C65.95 117.6 82.69 118.15 99.29 116.53C132.01 113.34 153.4 103.8 164.81 93.51C168.31 89.44 170.99 86.13 172.67 82.57V82.56Z"
          fill="#F7FAFC"
        />
        <path
          d="M91.24 47.21L80.2 54.04L75.43 38.41L57.66 64.02L52.33 64.36"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.34 39.21C121.56 33.45 119.61 16.08 115.77 14.26C115.03 13.91 113.43 13.76 112.07 14.57C110.71 15.39 110.1 16.88 109.89 17.39C106.68 24.98 97.64 33.25 99.76 38.03C101.33 41.57 108.67 42.48 113.35 39.21H113.34Z"
          fill="url(#paint0_radial_2_4)"
        />
        <path
          d="M103.82 52.19C103.82 52.19 114.07 43.38 117.05 32.5C118.34 27.82 117.31 15.24 117.31 15.24C117.31 15.24 121.31 23.66 122.36 31.59C123.41 39.51 118.66 44.13 122.13 48.98C125.6 53.83 128.18 58.8 128.18 58.8L135.98 58.77"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.3 45.18C144.57 38.77 135.28 36.47 127.34 34.51C112.19 30.77 110.41 36.21 90 33.63C85.04 33 71.32 32.24 67.74 34.48C62.41 37.82 65.54 43.69 68.49 48.63C70.15 51.4 77.19 54.77 82.67 56.42C99.77 61.56 96.8 62.57 105.2 64.26C115.5 66.34 126.54 68.57 135.88 62.61C141.49 59.03 148.01 51.54 146.3 45.18Z"
          fill="url(#paint1_linear_2_4)"
        />
        <path
          d="M101.44 45.45C101.44 45.45 109.56 44.56 112.62 43.52C115.68 42.49 128.81 41.41 128.81 41.41L139.2 38.98C139.2 38.98 127.41 31.52 114.62 31.23C101.83 30.94 91.19 31.91 91.19 31.91L92.1 39.84C92.1 39.84 91.95 46 101.44 45.44V45.45Z"
          fill="url(#paint2_linear_2_4)"
        />
        <path
          d="M75.95 40.76C75.95 40.76 76.13 44.17 83.13 45.17C90.12 46.17 91.99 44.8 93.52 42.91C95.05 41.02 93.49 34.9 93.49 34.9L92.68 32.85C92.32 31.94 91.41 31.37 90.43 31.43L72.17 32.59L75.95 40.76Z"
          fill="url(#paint3_linear_2_4)"
        />
        <path
          d="M115.29 57.87C117.48 60.41 126.6 57.71 131.88 51.95C134.28 49.34 135.21 46.84 136.76 42.42C142.4 26.4 145.22 18.38 144.11 17.7C141.45 16.06 130.27 29.83 124.03 38.91C118.86 46.44 112.91 55.12 115.28 57.87H115.29Z"
          fill="url(#paint4_radial_2_4)"
        />
        <path
          d="M58.74 41.05C52.82 35.07 47.14 28.1 40.49 22.95C35.28 18.92 29.02 16.67 23.17 13.78C16.66 10.55 10.22 7.11 3.72 3.84"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.35 28.77C63.57 27.51 58.1 31.55 56.02 35.77C55.27 37.28 55.16 39.48 54.93 43.88C54.56 50.83 54.42 54.42 56.32 55.56C57.11 56.03 58.57 56.27 62.58 54.04C65.01 52.69 79.71 44.51 77.4 35.79C76.43 32.13 72.76 29.51 69.35 28.77Z"
          fill="url(#paint5_linear_2_4)"
        />
        <path
          d="M68.67 34.5C66.83 35.39 65.62 37.74 66.3 38.99C67.11 40.49 70.8 40.62 72.52 38.77C73.32 37.91 73.98 36.34 73.33 35.21C72.54 33.81 70.17 33.78 68.67 34.51V34.5Z"
          fill="url(#paint6_linear_2_4)"
        />
        <path
          d="M91.27 54.72L82.1 63.92L73.85 49.81L62.46 78.82L57.36 80.38"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.13 36.15C62.13 36.15 58.48 24.9 52.94 21C49.29 18.44 35.6 8.14 26.67 1.41"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M126.43 55.62C126.43 55.62 136.68 46.81 139.66 35.93C140.95 31.25 144.59 20.44 144.59 20.44C144.59 20.44 148.59 28.86 149.64 36.79C150.69 44.71 150.49 58.4 153.96 63.24C157.43 68.09 160.01 73.06 160.01 73.06L167.81 73.03"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.43 55.93L120.53 45.62L120.6 65.33C120.6 65.33 123.32 71.52 124.35 71.86C125.38 72.2 127.08 71.85 127.08 71.85"
          stroke="#A0AEC0"
          strokeWidth="2.83"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2_4"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(114.975 -0.355315) rotate(7.11) scale(30.6783 34.47)"
        >
          <stop stopColor="white" />
          <stop offset="0.41" stopColor="#FCFCFD" />
          <stop offset="0.67" stopColor="#F3F6F9" />
          <stop offset="0.89" stopColor="#E5EAF1" />
          <stop offset="0.93" stopColor="#E2E8F0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2_4"
          x1="88.4797"
          y1="6.26157"
          x2="118.971"
          y2="72.1803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7FAFC" />
          <stop offset="0.28" stopColor="#F1F5F8" />
          <stop offset="0.66" stopColor="#E0E6ED" />
          <stop offset="1" stopColor="#CBD5E0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_4"
          x1="116.213"
          y1="20.9"
          x2="114.588"
          y2="44.0961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7FAFC" />
          <stop offset="0.28" stopColor="#F1F5F8" />
          <stop offset="0.66" stopColor="#E0E6ED" />
          <stop offset="1" stopColor="#CBD5E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_4"
          x1="93.1293"
          y1="19.7946"
          x2="79.7052"
          y2="43.274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7FAFC" />
          <stop offset="0.28" stopColor="#F1F5F8" />
          <stop offset="0.66" stopColor="#E0E6ED" />
          <stop offset="1" stopColor="#CBD5E0" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_2_4"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(154.323 49.2702) rotate(-6.19) scale(32.79 32.79)"
        >
          <stop stopColor="white" />
          <stop offset="0.41" stopColor="#FCFCFD" />
          <stop offset="0.67" stopColor="#F3F6F9" />
          <stop offset="0.89" stopColor="#E5EAF1" />
          <stop offset="0.93" stopColor="#E2E8F0" />
        </radialGradient>
        <linearGradient
          id="paint5_linear_2_4"
          x1="55.7198"
          y1="13.0984"
          x2="70.1943"
          y2="56.7013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7FAFC" />
          <stop offset="0.28" stopColor="#F1F5F8" />
          <stop offset="0.66" stopColor="#E0E6ED" />
          <stop offset="1" stopColor="#CBD5E0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2_4"
          x1="71.55"
          y1="30.56"
          x2="69.22"
          y2="39.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.02" stopColor="#F7FAFC" />
          <stop offset="0.29" stopColor="#F4F7F9" />
          <stop offset="0.48" stopColor="#EAEFF3" />
          <stop offset="0.66" stopColor="#DAE1E8" />
          <stop offset="0.82" stopColor="#C4CDD8" />
          <stop offset="0.97" stopColor="#A7B4C5" />
          <stop offset="1" stopColor="#A0AEC0" />
        </linearGradient>
        <clipPath id="clip0_2_4">
          <rect width="174.04" height="117.44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export const ExhaustedSearch: FC<Props> = ({ title, icon, children }) => {
  return (
    <Flex
      tabIndex={0}
      direction="column"
      alignItems="center"
      textAlign="center"
      position="absolute"
      inset={0}
      top="72"
      aria-label={title}
    >
      {icon || <Box as={SVGImage} w="44" h="44" />}
      <Heading size="md" mb={2} mt="6" maxWidth="80">
        {title}
      </Heading>
      <Text>{children}</Text>
    </Flex>
  );
};
