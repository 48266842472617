import getHours from 'date-fns/getHours';
import format from 'date-fns/format';
import { formatInTimeZone } from 'date-fns-tz';

export type Formats = 'LLL do' | 'LLL d yyyy' | 'EEE, d MMM h:mm aaa' | 'h:mm aaa' | 'd LLL yy' | 'EEE, d MMM h:mm';

export function formatDate(d: Date | number, f: Formats): string {
  return format(d, f);
}

export function shouldShowAmPm(start: number, end: number) {
  const startHours = getHours(start);
  const endHours = getHours(end);

  if (startHours >= 12) {
    return false;
  }

  if (endHours < 12) {
    return false;
  }

  return true;
}

type FormatSessionTime = {
  end: number | null;
  start: number | null;
  timezone?: string;
};

const getStartTimeFormat = (showAmPm: boolean) => `E d MMM, h:mm${showAmPm ? 'aaa' : ''}`;

const endTimeFormat = 'h:mmaaa';

export function formatSessionTime({ start, end, timezone }: FormatSessionTime): string {
  if (!start) {
    return '';
  }

  if (timezone) {
    return `${formatInTimeZone(start, timezone, getStartTimeFormat(shouldShowAmPm(start, end!)))} - ${formatInTimeZone(end!, timezone, endTimeFormat)}`;
  }

  return `${format(start, getStartTimeFormat(shouldShowAmPm(start, end!)))} - ${format(end!, endTimeFormat)}`;
}
