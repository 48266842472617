import { Fragment, ReactNode, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import _ from 'lodash';
import { User } from 'lucide-react';

import { Input } from '@askable/ui/components/ui/input';
import { Button, Modal, Slider } from 'components/common';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { bookingUtils } from 'lib/booking';
import { CREDITS_TYPE } from 'lib/constants';
import { creditUtils } from 'lib/credits';
import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';
import { Credits } from 'generated/graphql';

// Mutations

// Styles
import { useConnectedClient } from 'context/ConnectedClientContext';
import { FetchCreditPacksQuery } from 'data/queries/credits/fetchCreditPacks';
import { HStack, Spinner, VStack } from 'ui';
import { useAppContext } from '../Providers/appProvider';
import './creditsModal.scss';
import { NotifyLeadsMutation } from './data/NotifyLeads.mutation';

const MAX_STEP = 700;
const DEFAULT_STEP = 100;
const DEFAULT_CREDITS_AMOUNT = 10000;

const IncentiveTypeButton: React.FC<{ onClick: () => void; isSelected: boolean; label: string; icon?: ReactNode }> = ({
  onClick,
  isSelected,
  label,
  icon,
}) => {
  if (isSelected) {
    return (
      <Button onClick={onClick} label={label} icon={icon} variant="outline" className="pointer-events-none h-8 px-3" />
    );
  }
  return (
    <Button
      onClick={onClick}
      label={label}
      icon={icon}
      variant="outline"
      // size="sm"
      className="h-8 bg-transparent px-3"
    />
  );
};

function CreditsModal(props: any) {
  const taxesForCountry = teamUtils.getTax();
  const userContext = useConnectedClient();
  const appContext = useAppContext();

  // State
  const [credits, setCredits] = useState(DEFAULT_CREDITS_AMOUNT);
  const [savings, setSavings] = useState();
  const [participantCategory, setParticipantCategory] = useState(1);
  const [valueStep, setValueStep] = useState(DEFAULT_STEP);
  const [stepArray, setStepArray] = useState([]);

  // Vars
  const maxCreditPackSize = 500000;
  const participantCategoryMultiplier = bookingUtils.getCreditsForParticipantCategory(null, participantCategory);
  let leadTimerCount = 0;
  let leadTimerId: any = null;

  // Queries
  const [creditPacksResult] = useDeferredValue(useQuery({ query: FetchCreditPacksQuery, pause: !props.open }));
  const [, notifyLead] = useMutation(NotifyLeadsMutation);

  useEffect(() => {
    const creditsPack = creditPacksResult?.data?.creditPacks;
    if (creditsPack) {
      const savingsData = creditUtils.getSavings(creditsPack, DEFAULT_CREDITS_AMOUNT);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setSavings(savingsData);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ index: number; value: number; ... Remove this comment to see the full error message
      setStepArray(creditUtils.generateArrayOfStepsForSlider(creditsPack));
    }
  }, [creditPacksResult.data]);

  // Triggered when the modal is opened / closed
  useEffect(() => {
    if (props.open && !leadTimerId) {
      // Trigger a custom fullStory event
      // fullStory.event('heatingUp');

      leadTimerId = setInterval(() => {
        leadTimerCount += 1;
      }, 1000);
    }
    // Sets the number of credits to the default amount
    setCredits(DEFAULT_CREDITS_AMOUNT);
  }, [props.open]);

  const handleModalClose = (bypassLeadSubmission: boolean) => {
    // Only submit a lead if user has been on modal for >1 sec
    if (leadTimerCount >= 1 && !bypassLeadSubmission) {
      notifyLead({
        teamId: userContext.details?.team?.id!,
        userId: userContext.details?.id!,
        leadCounter: leadTimerCount,
      });
    }
    // Clear timer
    clearInterval(leadTimerId);
    leadTimerCount = 0;
    leadTimerId = null;

    props.onClose(); // Close the modal
  };

  const modalContentsReady = useMemo(() => {
    return !!(creditPacksResult.data?.creditPacks?.length && taxesForCountry?.currency_code);
  }, [creditPacksResult.data]);

  const renderBookingType = ({ title, image, participants }: any) => {
    return (
      <div className="bookingType">
        <h2 className="!mt-12 py-2">{participants}</h2>
        <p className="typeTitle">{title}</p>
        <p className="incentives">Incentives included</p>
        <img className="illustration" src={image} alt="Type illustration" />
      </div>
    );
  };

  const renderCreditPackStep = (value: any, className: any, savingsToRender: any, index: any) => {
    const isSavingActive = savingsToRender === savings;
    return (
      <Fragment key={index}>
        <div className={`step t${className}`} />
        <p className={`stepValue t${className}`}>{value}</p>
        <div className={`savingContainer t${className} s${className} ${isSavingActive ? 'active' : ''}`}>
          <p>Save {savingsToRender}%</p>
        </div>
      </Fragment>
    );
  };

  const creditPacks = creditPacksResult?.data?.creditPacks as Credits[];

  // Price per credit should be based on the country code and the number of credits the client is willing to purchase
  const creditPackPrice = creditUtils.getCreditPackPrice(creditPacks, credits);

  const bulkCreditPacks = (creditPacks ?? []).filter((creditPack) => creditPack.type === CREDITS_TYPE.CREDIT_PACK);
  const bulkCreditPacksOrdered: typeof bulkCreditPacks = utils.sortArray(bulkCreditPacks, 'quantity');

  const adHocCreditPack = (creditPacks ?? []).find((creditPack) => creditPack.type === CREDITS_TYPE.ADHOC);
  const adHocCreditPackPrice = (adHocCreditPack?.price ?? []).find(
    (price) => price?.currency_code === taxesForCountry?.currency_code,
  );
  const adHocPrice = adHocCreditPackPrice?.value;
  let discountMark = 0;
  return (
    <Modal
      className="creditsModal overflow-x-hidden p-0"
      bodyClassName={`creditsModalInternal ${modalContentsReady ? 'ready' : 'notReady'}`}
      open={props.open}
      onClose={() => handleModalClose(false)}
    >
      <>
        <div className="leftContainer">
          <div className="sliderContainer">
            <div className="customCreditsComponent w-48">
              <Input
                id="numberOfCredits"
                className="px-2 py-2 text-right text-xl font-bold text-foreground"
                type="number"
                max={maxCreditPackSize}
                value={credits}
                autoFocus
                onChange={(e) => {
                  const value = Number(e.target.value);

                  if (value <= maxCreditPackSize) {
                    setCredits(value);
                    // Check whether the savings are different to what we had. If so, it should set to the new one
                    const closesValueInArray = creditUtils.findClosest(creditPacks, stepArray, e.target.value, 'value');
                    if (_.get(closesValueInArray, 'savings') !== savings) {
                      setSavings(_.get(closesValueInArray, 'savings'));
                    }
                    // Find the index of the value step and set it to update the slider
                    if (_.has(closesValueInArray, 'index')) setValueStep(closesValueInArray.index);
                  }
                }}
              />
              <h2>credits</h2>
            </div>
            <div className="sliderComponent">
              <Slider
                min={0}
                max={MAX_STEP}
                startValue={valueStep}
                railCustomStyles={{ backgroundColor: '#2F3B52' }}
                // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'index' implicitly has an 'any' ty... Remove this comment to see the full error message
                onUpdateValue={([index]) => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'never'.
                  setCredits(stepArray[index].value);

                  const closesValueInArray = creditUtils.findClosest(
                    creditPacks,
                    stepArray,
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'never'.
                    stepArray[index].value,
                    'value',
                  );
                  if (_.get(closesValueInArray, 'savings') !== savings) {
                    setSavings(_.get(closesValueInArray, 'savings'));
                  }
                }}
              />
              <div className="ticks">
                {(bulkCreditPacksOrdered ?? []).map((bulkCredits, index) => {
                  discountMark += 1;
                  const bulkCreditPackPrice = bulkCredits?.price?.find(
                    (price) => price?.currency_code === taxesForCountry?.currency_code,
                  );

                  return bulkCredits.quantity
                    ? renderCreditPackStep(
                        utils.formatMoney(bulkCredits.quantity),
                        discountMark,
                        typeof adHocPrice === 'number' && typeof bulkCreditPackPrice?.value === 'number'
                          ? Math.round(((adHocPrice - bulkCreditPackPrice.value) / adHocPrice) * 100)
                          : undefined,
                        index,
                      )
                    : null;
                })}
              </div>
            </div>
          </div>
          <div className="explanationContainer">
            <div className="expHeader">
              <p className="text-balance">
                What does <strong className="whitespace-no-wrap">{credits.toLocaleString()}</strong> credits get you?
              </p>
              <div className="flex space-x-2">
                <IncentiveTypeButton
                  onClick={() => {
                    setParticipantCategory(1);
                  }}
                  isSelected={participantCategory === 1}
                  label="Standard incentive"
                  icon={<User style={{ height: 18 }} className="imgCategory" />}
                />
                <IncentiveTypeButton
                  onClick={() => {
                    setParticipantCategory(2);
                  }}
                  isSelected={participantCategory === 2}
                  label="Premium incentive"
                  icon={<img alt="Professionals" src="/icons/account-tie.svg" className="professionals imgCategory" />}
                />
              </div>
            </div>
            <div className="bookingTypesContainer">
              {renderBookingType({
                title: 'Video call participants',
                image: '/illustrations/type-videocall.svg',
                participants: Math.floor(credits / 80 / participantCategoryMultiplier).toLocaleString(),
              })}
              <p className="or">or</p>
              {renderBookingType({
                title: 'Online task respondents',
                image: '/illustrations/type-onlinetask.svg',
                participants: Math.floor(credits / 20 / participantCategoryMultiplier).toLocaleString(),
              })}
              {participantCategory === 1 && (
                <>
                  <p className="or">or</p>
                  {renderBookingType({
                    title: 'Survey respondents',
                    image: '/illustrations/type-survey.svg',
                    participants: Math.floor(credits / 10 / participantCategoryMultiplier).toLocaleString(),
                  })}
                </>
              )}
              <p className="or">or</p>
              {renderBookingType({
                title: 'In-person participants',
                image: '/illustrations/type-inperson.svg',
                participants: Math.floor(credits / 100 / participantCategoryMultiplier).toLocaleString(),
              })}
            </div>
          </div>
        </div>
        <VStack alignItems="flex-start" className="rightContainer" spacing={6}>
          <VStack alignItems="flex-start">
            <h3>Total</h3>
            <h2 id="__creditsToBuy">{utils.formatMoney(credits)} credits</h2>
          </VStack>
          <div className="costContainer">
            {savings !== 0 && (
              <div className="savingsContainer">
                <div className="originalPriceContainer">
                  <span className="originalPrice">
                    {
                      adHocCreditPackPrice?.value
                        ? `${creditPackPrice?.currency_symbol ?? ''} ${utils.formatMoney(credits * adHocCreditPackPrice.value, 2)}`
                        : '\u00A0' /* Non-breaking space */
                    }
                  </span>
                </div>
                <div className="savingsLabel">
                  <p id="__savingTag">Save {savings}%</p>
                </div>
              </div>
            )}
            <HStack className="leading-normal">
              <h2 className="currency">{creditPackPrice?.currency_symbol ?? ''}</h2>
              <h1 id="__totalAmount" className="total">
                {utils.formatMoney(credits * (creditPackPrice?.value || 0), 2)}
              </h1>
            </HStack>
            <p>
              <strong>{creditPackPrice?.currency_code ?? ''}</strong>
              {taxesForCountry?.tax_label && <span id="__taxesLabel"> plus {taxesForCountry.tax_label}</span>}
            </p>
          </div>
          <Button
            label="Go to Checkout"
            onClick={() => {
              handleModalClose(true);
              sessionStorage.setItem('creditsToBuy', credits.toString());
              appContext.onOpenBuyCreditsModal({
                team_id: userContext.details?.team?.id,
                creditsToBuy: credits,
                onSuccess: () => {
                  // Close the checkout page
                  appContext.onCloseBuyCreditsModal({ shouldGoBack: false });
                  // Close modal
                  handleModalClose(true);

                  props.onClose();
                  // Redirects to the dashboard
                  props.history.replace({ pathname: '/' });
                },
              });
            }}
            className="checkoutButton"
            size="default"
          />
        </VStack>
        {!modalContentsReady && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <Spinner className="loadingSpinner" />
          </div>
        )}
      </>
    </Modal>
  );
}

export default deprecatedWithRouter(CreditsModal);
