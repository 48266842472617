import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const CancelIcon: CreateIconType = createIcon({
  displayName: 'CancelIcon',
  defaultProps: {
    ...defaultOptions,
    color: 'gray.500',
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58578 7.99999L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.2929 11.7071C4.68342 12.0976 5.31659 12.0976 5.70711 11.7071L8 9.4142L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 7.99999L11.7071 5.70711C12.0976 5.31659 12.0976 4.68342 11.7071 4.2929C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58577L5.70711 4.29289Z"
      fill="currentColor"
    />,
  ],
});
