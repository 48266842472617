import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useFeatureFlags } from 'feature-flags';
import { useQuery } from 'urql';
import { addDays } from 'date-fns';
import { cn } from '@askable/ui/lib/utils';
import { HubbleSurveys, useHubble } from 'shared-utils/hooks';
import { ASKABLE_PRIVACY_URL, ASKABLE_TERMS_URL } from 'shared-utils';
import { GetCountsQueries } from './containers/Interviews/data/GetInterviewCounts.query';

import { AnalysesInsights } from './containers/Insights/AnalysesInsights';
import { AnalysesInterviews } from './containers/Interviews/AnalysesInterviews';
import { AnalysesThemes } from './containers/Themes/AnalysesThemes';

// Components
import { DiscoverHeader } from './components/DiscoverHeader';
import { InsightDetailsPanel } from './components/InsightDetailsPanel';

// UI Components
import { Button } from '@askable/ui/components/ui/button';
import { Link } from '@askable/ui/components/ui/link';
import { NoResults } from './components/NoResults';

import { Calendar } from 'lucide-react';

/**
 * Discover Dashboard
 */
export type FilterDateProps = { days: number; date: number };

const offsetToDate = (days: number) => {
  return Math.floor(addDays(new Date(), -days).getTime());
};

export enum FilterDuration {
  ALL_TIME = 0,
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90,
}

const filterDates: FilterDateProps[] = [
  { days: FilterDuration.ALL_TIME, date: 0 },
  { days: FilterDuration.WEEK, date: offsetToDate(7) },
  { days: FilterDuration.MONTH, date: offsetToDate(30) },
  { days: FilterDuration.QUARTER, date: offsetToDate(90) },
];

export const DiscoverContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { DISCOVER_PAGE } = useFeatureFlags(['DISCOVER_PAGE']);
  const { show, isReady } = useHubble();

  const id = searchParams.get('id');

  const [filterDate] = useState<FilterDateProps>(filterDates[0]);
  const [selectedInsightId, setSelectedInsightId] = useState<string | null>(null);

  const [{ data: interviews, fetching }] = useQuery({
    query: GetCountsQueries,
    variables: {
      filter: {
        created: { gte: filterDate.date },
      },
    },
  });

  // Update the URL query params when the selected Insight changes
  const onSelectedInsightChange = (newId: string | null) => {
    const newParams = new URLSearchParams(searchParams);
    if (newId !== null) {
      newParams.set('id', newId);
    } else {
      newParams.delete('id');
    }

    setSearchParams(newParams.toString(), { replace: true });
  };

  const onDateRangeSelect = () => {
    show(HubbleSurveys.DiscoverTimeDropdown);
  };

  // Set the initial values of selectedInsightId and filterDate from the URL query params
  useEffect(() => {
    if (fetching || !interviews) {
      return;
    }

    setSelectedInsightId(id);
  }, [id, fetching, interviews]);

  if (!DISCOVER_PAGE) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className={cn('h-full overflow-auto dark:bg-background', selectedInsightId ? 'group/panel-open' : '')}>
      <DiscoverHeader />

      <div className="flex flex-1">
        <div className="flex-1">
          <main className="flex flex-col gap-4 p-4 text-foreground @container sm:gap-6 sm:p-6">
            <header className="flex flex-wrap items-center justify-between">
              <h1 className="text-xl font-bold text-foreground sm:text-2xl">{t('sections.discover.title')}</h1>

              {isReady ? (
                <Button
                  className="w-1/2 justify-start gap-2 sm:w-36"
                  onClick={onDateRangeSelect}
                  title={t('sections.discover.dateRange')}
                  variant="outline"
                >
                  <Calendar className="h-4 w-4" /> {t('sections.discover.allTime')}
                </Button>
              ) : null}
            </header>

            {!fetching && interviews?.analysesCount.reduce((acc, { count }) => acc + count, 0) === 0 ? (
              <NoResults />
            ) : (
              <>
                <div className="grid grid-cols-1 gap-4 @2xl:grid-cols-2 2xl:gap-6">
                  <AnalysesInterviews fetching={fetching} filterDate={filterDate} interviews={interviews} />
                  <AnalysesThemes filterDate={filterDate} />
                </div>

                <AnalysesInsights
                  filterDate={filterDate}
                  onInsightClick={onSelectedInsightChange}
                  selectedInsightId={selectedInsightId}
                />
              </>
            )}
          </main>

          <footer className="flex flex-wrap items-center justify-between gap-2 px-4 pb-6 text-xs text-muted-foreground 2xl:px-6">
            <div className="hidden sm:block">&copy; Askable</div>
            <div className="gap-2 leading-normal sm:flex">
              {t('sections.discover.disclaimer')}
              <div className="flex gap-1">
                <Link href={ASKABLE_TERMS_URL} target="_blank" className="text-muted-foreground no-underline">
                  {t('global.termsOfUse')}
                </Link>
                |
                <Link href={ASKABLE_PRIVACY_URL} target="_blank" className="text-muted-foreground no-underline">
                  {t('global.privacyPolicy')}
                </Link>
              </div>
            </div>
          </footer>
        </div>

        <InsightDetailsPanel
          filterDate={filterDate}
          insightId={selectedInsightId}
          onSelectedInsightChange={onSelectedInsightChange}
        />
      </div>
    </div>
  );
};
