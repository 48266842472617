import { AskableIconLoading } from '@askable/ui/components/ui/askable-icon';
import { type ReactNode, Suspense } from 'react';

export const LoadingSuspense = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <AskableIconLoading />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
