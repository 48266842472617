import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers: ReturnType<
  typeof createMultiStyleConfigHelpers<'container' | 'leftElement' | 'rightElement' | 'bottomElement'>
> = createMultiStyleConfigHelpers(['container', 'leftElement', 'rightElement', 'bottomElement']);

export const TextareaGroup: ReturnType<typeof helpers.defineMultiStyleConfig> = helpers.defineMultiStyleConfig({
  baseStyle: () => ({
    container: {
      position: 'relative',
    },
    leftElement: {
      pointerEvents: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    rightElement: {
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
    },
    bottomElement: {
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
      bottom: 0,
    },
  }),

  sizes: {
    xs: {
      leftElement: {
        w: 6,
        h: 6,
        fontSize: 'xs',
      },
      rightElement: {
        w: 6,
        h: 6,
        fontSize: 'xs',
      },
      bottomElement: {
        h: 8,
        fontSize: 'xs',
      },
    },
    sm: {
      leftElement: {
        w: 8,
        h: 8,
        fontSize: 'sm',
      },
      rightElement: {
        w: 8,
        h: 8,
        fontSize: 'sm',
      },
      bottomElement: {
        h: 10,
        fontSize: 'sm',
      },
    },
    md: {
      leftElement: {
        w: 10,
        h: 10,
        fontSize: 'md',
      },
      rightElement: {
        w: 10,
        h: 10,
        fontSize: 'md',
      },
      bottomElement: {
        h: 12,
        fontSize: 'md',
      },
    },
    lg: {
      leftElement: {
        w: 12,
        h: 12,
        fontSize: 'lg',
      },
      rightElement: {
        w: 12,
        h: 12,
        fontSize: 'lg',
      },
      bottomElement: {
        h: 12,
        fontSize: 'lg',
      },
    },
  },
});
