import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { ChevronLeft } from 'lucide-react';
import { Progress } from '@askable/ui/components/ui/progress';
import { Button } from '@askable/ui/components/ui/button';

function InsightsReports(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/create' });
  };

  const onClickBack = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/relax' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerBack">
          <ChevronLeft className="backIcon h-7 w-7" onClick={onClickBack} />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="contentTextData">
            <span className="textTitle font--title1">Get the insights & reports</span>
            <span className="descriptionText">
              Designed by Askable, assembled by your researcher, this report comprehensively details the insights,
              recommendations and next steps for your project. You&apos;ll also have access to interview recordings.
            </span>
          </div>
          <div className="contentImageData">
            <div className="illustrationContainer">
              <img
                className="illustration"
                src="/askablePlus/illustrations/insightsReports.png"
                alt="insightsReports"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={7} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            OK, Got it
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(InsightsReports);
