import { ReactNode, forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link as LinkComp } from '@askable/ui/components/ui/link';
import { Link } from 'react-router-dom';
import { useElementOverflow } from 'src/hooks/useElementOverflow';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@askable/ui/components/ui/tooltip';

type Props = {
  url: string;
  children: ReactNode;
};

const Wrapper = forwardRef<HTMLDivElement, { children: ReactNode }>(({ children }: { children: ReactNode }, ref) => {
  return (
    <div ref={ref} className="flex min-w-[320px] items-center overflow-hidden whitespace-nowrap">
      {children}
    </div>
  );
});

export const CellName = ({ url, children }: Props) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState<number | null>(null);
  const isOverflowing = useElementOverflow(ref, typeof wrapperWidth !== 'number');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const updateWrapperWidth = useCallback(() => {
    if (wrapperRef.current) {
      setWrapperWidth(wrapperRef.current?.clientWidth);
    }
  }, [wrapperRef]);

  useEffect(() => {
    window.addEventListener('resize', updateWrapperWidth);
    return () => {
      window.removeEventListener('resize', updateWrapperWidth);
    };
  }, [updateWrapperWidth]);

  useLayoutEffect(() => {
    updateWrapperWidth();
  }, [updateWrapperWidth]);

  const linkComp = (
    <LinkComp
      asChild
      style={{
        ...(wrapperWidth ? { maxWidth: wrapperWidth } : {}),
      }}
      className="inline-block overflow-hidden text-ellipsis font-medium text-foreground no-underline"
    >
      <Link ref={ref} to={url}>
        {wrapperWidth && children}
      </Link>
    </LinkComp>
  );

  if (!isOverflowing) {
    return <Wrapper ref={wrapperRef}>{linkComp}</Wrapper>;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <Wrapper ref={wrapperRef}>
          <TooltipTrigger asChild>{linkComp}</TooltipTrigger>
          <TooltipContent>
            <p>{children}</p>
          </TooltipContent>
        </Wrapper>
      </Tooltip>
    </TooltipProvider>
  );
};
