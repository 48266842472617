import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { LoadingOverlay } from 'components/common';
import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { globalVariables } from 'lib/globalVariables';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

type Props = {
  query?: string;
  isKinde: boolean;
  authToken?: string;
  sessionsURL: string;
  params: Readonly<
    Partial<{
      '*': string;
    }>
  >;
};

export function constructSessionsPath({ params, sessionsURL, isKinde, authToken, query = '' }: Props): URL {
  invariant(params['*'], 'no redirect path ');

  if (isKinde) {
    return new URL(
      `${sessionsURL}/api/auth/login?post_login_redirect_url=${sessionsURL}/${params['*']}${query ? `?${query}` : ''}`,
    );
  }

  invariant(authToken, 'Auth token is required when not using legacy authentication');

  return new URL(`${sessionsURL}/${params['*']}?authToken=${authToken}${query ? `&${query}` : ''}`);
}

export function SessionsRedirect() {
  const params = useParams<{ '*': string }>();
  const isKinde = useIsKinde();
  const [query] = useSearchParams();
  const { getOrganization } = useKindeAuth();
  const [err, setErr] = useState<string | null>(null);

  const handle = () => {
    if (isKinde) {
      const org = getOrganization();
      query.append('org_code', org.orgCode);
    }

    try {
      const redirectURL = constructSessionsPath({
        params,
        isKinde,
        sessionsURL: globalVariables.getEnvironmentVariables().sessionsAppURI,
        authToken: localStorage.getItem('access_token') ?? '',
        query: query.toString(),
      });

      window.location.replace(redirectURL.href);
    } catch (e) {
      console.error(e);
      setErr(e instanceof Error ? e.message : 'An error occurred while constructing the redirect URL');
    }
  };

  useEffect(() => {
    handle();
  }, [params, isKinde, query]);

  if (err) {
    return (
      <div className="my-10 flex h-full w-full justify-center">
        <h1 className="text-2xl font-bold">{err}</h1>
      </div>
    );
  }

  return <LoadingOverlay />;
}
