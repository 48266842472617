import { cn } from '@askable/ui/lib/utils';
import { LucideProps, createLucideIcon } from 'lucide-react';

export const ActiveStatusComp = createLucideIcon('ActiveStatus', [
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '10',
      stroke: 'currentColor',
      strokeWidth: '2px',
    },
  ],
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '5',
      fill: 'currentColor',
      stroke: 'currentColor',
      strokeWidth: '2px',
    },
  ],
]);

export const ActiveStatus = ({ className, ...props }: LucideProps) => {
  return <ActiveStatusComp className={cn('text-success', className)} {...props} />;
};
