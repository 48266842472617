import { gql } from '@apollo/client';

export default gql`
  query FetchBookingSubmissionWithParticipantExclusion($userId: ID, $bookingId: ID) {
    findBookingSubmission(userId: $userId, bookingId: $bookingId) {
      _id
      previousAttendance {
        _id
        _booking_id
        CurrentStatus {
          _id
          _session_id
          session {
            _id
            start
            end
          }
        }
        booking {
          _id
          name
          _team_id
          type
          config {
            timezone
          }
        }
      }
    }
  }
`;
