import React, { useEffect, useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Wysiwyg } from 'components/common';
import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';
import { X } from 'lucide-react';

// External components
import CardContainer from 'components/createBooking/components/cardContainer';
// import ParticipantsAppPreview from 'components/createBooking/components/participantsAppPreview';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';

// Styles
import './styles/descriptionStyles.scss';

const MAX_INPUT_LENGTH = 240;

function Description(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingData, setBookingData] = useState();
  const [bookingState, setBookingState] = useState(booking);
  const [bookingShortDescription, setBookingShortDescription] = useState(
    _.get(booking, 'config.information.short_description'),
  );
  const [shortDescriptionError, setShortDescriptionError] = useState<boolean>(false);
  const [briefSummary, setBriefSummary] = useState(_.get(booking, 'config.information.brief_summary'));
  const [showDescriptionTip, setShowDescriptionTip] = useState(true);

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/description`,
      stepId: 'additional_info_description',
      stepIdExtra: 'additional_info_short_description',
    });

    const linkState = _.get(props.location, 'linkState', null);
    if (linkState && _.get(linkState, 'fieldFocus') === 'brief_summary') {
      setTimeout(() => {
        const target = document.getElementById('rdw-wrapper-fullDescription');
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const elements = target.querySelectorAll('div div div div div div div span [data-text=true]');
        if (elements) {
          if (elements.length === 1) {
            utils.placeCaretAtEnd(elements[0]);
          } else {
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
            utils.placeCaretAtEnd(elements[elements.length - 1], 1);
          }
        }
      }, 20);
    }
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    if (
      !_.get(props.booking, 'config.information.brief_summary') ||
      _.get(props.booking, 'config.information.brief_summary_changed') === false
    ) {
      const bookingObj = {
        config: {
          information: {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ..._.get(bookingData, 'config.information'),
            brief_summary: bookingUtils.getBriefSummaryExample(props.booking),
          },
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { information: any; };... Remove this comment to see the full error message
      setBookingData(bookingObj);

      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          information: {
            ...bookingState.config.information,
            brief_summary: bookingUtils.getBriefSummaryExample(props.booking),
          },
        },
      };
      setBriefSummary(bookingUtils.getBriefSummaryExample(props.booking));
      setBookingState(bookingStateObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    }
  }, [props.booking]);

  useEffect(() => {
    // Render the app preview once the participants app 2 is live
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);

    if (_.get(booking, 'steps.additional_info_short_description') === 'error' && !bookingShortDescription) {
      setShortDescriptionError(true);
    }
  }, [booking, bookingShortDescription, briefSummary]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: <span>&quot;ACME customers who&apos;ve upgraded their phone plans in the last 12 months&quot;</span>,
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span>&quot;Anyone in Victoria who&apos;s made a car or home insurance claim in the last 3 months&quot;</span>
        ),
      },
    ];
    const cardTexts3 = [{ cardText: <span>&quot;University students who speak a second language at home&quot;</span> }];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer booking={bookingState} team={_.get(props, 'team')} context={props.context} condensedCard />
        <p className="cardContainerTitle additionalTitle">Example short descriptions</p>
        <CardContainer cardId="__descriptionCard1" cardClass="slideInAnimationDelay80" cardTexts={cardTexts1} />
        <CardContainer
          additionalCard
          cardId="__descriptionCard2"
          cardClass="slideInAnimationDelay160"
          cardTexts={cardTexts2}
        />
        <CardContainer
          additionalCard
          cardId="__descriptionCard3"
          cardClass="slideInAnimationDelay240"
          cardTexts={cardTexts3}
        />
      </>
    );
  };

  const onChangeValue = (field: any, value: any) => {
    let briefSummaryChanged = _.get(booking, 'config.information.brief_summary_changed');
    if (field === 'short_description') {
      setBookingShortDescription(value);
      // setAppPreviewParams({ short_description: value });
    } else if (field === 'brief_summary') {
      briefSummaryChanged = value !== _.get(booking, 'config.information.brief_summary_changed');
      setBriefSummary(value);
      // setAppPreviewParams({ brief_summary: value });
    }

    const bookingObj = {
      config: {
        information: {
          short_description: field === 'short_description' ? value : bookingShortDescription,
          brief_summary: field === 'brief_summary' ? value : briefSummary,
          brief_summary_changed: briefSummaryChanged,
        },
      },
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { information: { short... Remove this comment to see the full error message
    setBookingData(bookingObj);

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        information: {
          ...bookingState.config.information,
          short_description: field === 'short_description' ? value : bookingShortDescription,
          brief_summary: field === 'brief_summary' ? value : briefSummary,
          brief_summary_changed: briefSummaryChanged,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (
      (_.get(booking, 'steps.additional_info_short_description') === 'error' &&
        _.get(bookingObj, 'config.information.short_description')) ||
      (_.get(booking, 'steps.additional_info_description') === 'error' &&
        _.get(bookingObj, 'config.information.brief_summary')) ||
      (_.get(booking, 'steps.additional_info_short_description') === 'seen' &&
        !_.get(bookingObj, 'config.information.short_description')) ||
      (_.get(booking, 'steps.additional_info_description') === 'seen' &&
        !_.get(bookingObj, 'config.information.brief_summary')) ||
      (_.get(booking, 'steps.additional_info_short_description') === 'seen' &&
        _.size(_.get(bookingObj, 'config.information.short_description')) > 240)
    ) {
      props.validateBooking(bookingStateObj, document.location.pathname, true);
    }

    if (
      _.get(bookingObj, 'config.information.short_description') !==
        _.get(booking, 'config.information.short_description') ||
      _.get(bookingObj, 'config.information.brief_summary') !== _.get(booking, 'config.information.brief_summary')
    ) {
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      props.history.replace({ booking: null });
    }
  };

  const onClickNext = () => {
    if (!bookingShortDescription) {
      setShortDescriptionError(true);
    }

    let redirectTo = `/booking-setup/${booking._id}/additional-info/additional-instructions`;
    if (bookingUtils.isOnlineTask(booking)) {
      redirectTo = `/booking-setup/${booking._id}/additional-info/contacts`;
    }

    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Short description of who you're looking for (Required)
      </h1>

      <div className="fieldContainer flex max-w-lg flex-col gap-2">
        <Label htmlFor="__projectTitle" className="leading-snug">
          Give your audience a short, one sentence summary on what kind of people you&apos;re looking for. Focus on the
          most important criteria for you.
        </Label>
        <Textarea
          id="__projectTitle"
          placeholder="e.g. Working mums with at least 2 young kids"
          value={bookingShortDescription || ''}
          maxRows={6}
          required
          autoFocus
          onChange={(e) => {
            setShortDescriptionError(e.target.value.length === 0 || e.target.value.length > MAX_INPUT_LENGTH);
            onChangeValue('short_description', e.target.value);
          }}
          variant={shortDescriptionError ? 'error' : undefined}
        />

        <div className="flex items-center gap-1 text-xs text-muted-foreground">
          <div className={shortDescriptionError ? 'text-sm font-bold text-destructive' : undefined}>
            {`${_.size(bookingShortDescription)}`}
          </div>
          /<div>{MAX_INPUT_LENGTH}</div>
        </div>
      </div>

      {showDescriptionTip && (
        <div className="additionalInfoContainer spaceTop">
          <img className="additionalInfoImage" src="/booking/illustrations/personNotes.svg" alt="illustration" />
          <div className="additionalInfoContent">
            <p className="additionalInfoTitle">But what about bias? Or liars?</p>
            <p className="additionalInfoText">
              Giving a bit of info helps participants know which opportunities are relevant to them, meaning you&apos;ll
              get better quality applicants. If you&apos;re concerned about dishonesty, we pre-screen participants for
              that. If you&apos;re concerned about bias, just be as descriptive as you can.
            </p>
          </div>
          <X
            id="__closeIcon"
            className="closeIcon"
            onClick={() => {
              setShowDescriptionTip(false);
            }}
            style={{ width: 20, height: 20 }}
          />
        </div>
      )}
      <h1 id="__pageTitle" className="title additionalField">
        Full description of the opportunity
      </h1>
      <Wysiwyg
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ wrapperId: string; className: string; edit... Remove this comment to see the full error message
        wrapperId="fullDescription"
        className="wysiwygContainerField"
        editorClassName="wysiwygEditor"
        value={briefSummary || ''}
        onBlurComponent={(value: any) => onChangeValue('brief_summary', value)}
        required
      />
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(Description);
