import { memo } from 'radash';

export const parseURL = memo((urlString?: string | null): [boolean, string] => {
  if (!urlString) {
    return [false, ''];
  }

  try {
    const url = new URL(urlString.indexOf('://') === -1 ? `https://${urlString}` : urlString);
    return [true, url.href];
  } catch (e) {
    return [false, ''];
  }
});
