import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const VolumeFilledHighIcon: CreateIconType = createIcon({
  displayName: 'VolumeFilledHighIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M7.99996 1.52668L4.07996 5.48001C4.04647 5.50882 4.00412 5.52529 3.95996 5.52668H2.49996C2.14634 5.52668 1.8072 5.66715 1.55715 5.9172C1.3071 6.16725 1.16663 6.50639 1.16663 6.86001V9.14001C1.16663 9.49363 1.3071 9.83277 1.55715 10.0828C1.8072 10.3329 2.14634 10.4733 2.49996 10.4733H3.95996C3.9821 10.4724 4.0042 10.476 4.02486 10.4841C4.04552 10.4921 4.06428 10.5043 4.07996 10.52L7.99996 14.4667C8.09281 14.5603 8.21133 14.6243 8.34054 14.6505C8.46975 14.6768 8.60384 14.6641 8.72586 14.6142C8.84788 14.5642 8.95235 14.4792 9.02606 14.3699C9.09977 14.2606 9.13941 14.1319 9.13996 14V2.00001C9.14073 1.8676 9.10205 1.73795 9.02885 1.6276C8.95565 1.51726 8.85124 1.43121 8.72895 1.38043C8.60665 1.32965 8.472 1.31645 8.34217 1.34249C8.21234 1.36854 8.09321 1.43266 7.99996 1.52668Z"
      fill="currentColor"
    />,
    <path
      d="M11.26 5.00004C11.1983 4.93788 11.1249 4.88848 11.0441 4.85468C10.9633 4.82087 10.8766 4.80331 10.789 4.803C10.7014 4.80269 10.6146 4.81964 10.5336 4.85287C10.4525 4.88611 10.3788 4.93498 10.3167 4.9967C10.2545 5.05842 10.2051 5.13178 10.1713 5.21259C10.1375 5.2934 10.12 5.38008 10.1196 5.46768C10.1193 5.55528 10.1363 5.64208 10.1695 5.72312C10.2028 5.80417 10.2516 5.87788 10.3133 5.94004C10.8581 6.48725 11.1639 7.22793 11.1639 8.00004C11.1639 8.77214 10.8581 9.51282 10.3133 10.06C10.1892 10.1849 10.1195 10.3539 10.1195 10.53C10.1195 10.7062 10.1892 10.8751 10.3133 11C10.3753 11.0625 10.4491 11.1121 10.5303 11.146C10.6115 11.1798 10.6987 11.1972 10.7867 11.1972C10.8747 11.1972 10.9618 11.1798 11.0431 11.146C11.1243 11.1121 11.198 11.0625 11.26 11C12.0547 10.2039 12.501 9.12493 12.501 8.00004C12.501 6.87514 12.0547 5.79621 11.26 5.00004Z"
      fill="currentColor"
    />,
    <path
      d="M12.8933 3.33337C12.8314 3.27089 12.7576 3.22129 12.6764 3.18745C12.5952 3.1536 12.508 3.13617 12.42 3.13617C12.332 3.13617 12.2449 3.1536 12.1636 3.18745C12.0824 3.22129 12.0087 3.27089 11.9467 3.33337C11.8225 3.45828 11.7528 3.62725 11.7528 3.80337C11.7528 3.9795 11.8225 4.14847 11.9467 4.27337C12.4393 4.76504 12.8302 5.34904 13.0968 5.99194C13.3635 6.63484 13.5008 7.32403 13.5008 8.02004C13.5008 8.71606 13.3635 9.40524 13.0968 10.0481C12.8302 10.691 12.4393 11.275 11.9467 11.7667C11.8225 11.8916 11.7528 12.0606 11.7528 12.2367C11.7528 12.4128 11.8225 12.5818 11.9467 12.7067C12.0087 12.7692 12.0824 12.8188 12.1636 12.8526C12.2449 12.8865 12.332 12.9039 12.42 12.9039C12.508 12.9039 12.5952 12.8865 12.6764 12.8526C12.7576 12.8188 12.8314 12.7692 12.8933 12.7067C14.135 11.463 14.8324 9.77744 14.8324 8.02004C14.8324 6.26264 14.135 4.57705 12.8933 3.33337Z"
      fill="currentColor"
    />,
  ],
});

export const VolumeFilledLowIcon: CreateIconType = createIcon({
  displayName: 'VolumeFilledLowIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M7.99996 1.52668L4.07996 5.48001C4.04647 5.50882 4.00412 5.52529 3.95996 5.52668H2.49996C2.14634 5.52668 1.8072 5.66715 1.55715 5.9172C1.3071 6.16725 1.16663 6.50639 1.16663 6.86001V9.14001C1.16663 9.49363 1.3071 9.83277 1.55715 10.0828C1.8072 10.3329 2.14634 10.4733 2.49996 10.4733H3.95996C3.9821 10.4724 4.0042 10.476 4.02486 10.4841C4.04552 10.4921 4.06428 10.5043 4.07996 10.52L7.99996 14.4667C8.09281 14.5603 8.21133 14.6243 8.34054 14.6505C8.46975 14.6768 8.60384 14.6641 8.72586 14.6142C8.84788 14.5642 8.95235 14.4792 9.02606 14.3699C9.09977 14.2606 9.13941 14.1319 9.13996 14V2.00001C9.14073 1.8676 9.10205 1.73795 9.02885 1.6276C8.95565 1.51726 8.85124 1.43121 8.72895 1.38043C8.60665 1.32965 8.472 1.31645 8.34217 1.34249C8.21234 1.36854 8.09321 1.43266 7.99996 1.52668Z"
      fill="currentColor"
    />,
    <path
      d="M11.26 5.00004C11.1983 4.93788 11.1249 4.88848 11.0441 4.85468C10.9633 4.82087 10.8766 4.80331 10.789 4.803C10.7014 4.80269 10.6146 4.81964 10.5336 4.85287C10.4525 4.88611 10.3788 4.93498 10.3167 4.9967C10.2545 5.05842 10.2051 5.13178 10.1713 5.21259C10.1375 5.2934 10.12 5.38008 10.1196 5.46768C10.1193 5.55528 10.1363 5.64208 10.1695 5.72312C10.2028 5.80417 10.2516 5.87788 10.3133 5.94004C10.8581 6.48725 11.1639 7.22793 11.1639 8.00004C11.1639 8.77214 10.8581 9.51282 10.3133 10.06C10.1892 10.1849 10.1195 10.3539 10.1195 10.53C10.1195 10.7062 10.1892 10.8751 10.3133 11C10.3753 11.0625 10.4491 11.1121 10.5303 11.146C10.6115 11.1798 10.6987 11.1972 10.7867 11.1972C10.8747 11.1972 10.9618 11.1798 11.0431 11.146C11.1243 11.1121 11.198 11.0625 11.26 11C12.0547 10.2039 12.501 9.12493 12.501 8.00004C12.501 6.87514 12.0547 5.79621 11.26 5.00004Z"
      fill="currentColor"
    />,
  ],
});

export const VolumeFilledMuteIcon: CreateIconType = createIcon({
  displayName: 'VolumeFilledMuteIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M7.99996 1.52668L4.07996 5.48001C4.04647 5.50882 4.00412 5.52529 3.95996 5.52668H2.49996C2.14634 5.52668 1.8072 5.66715 1.55715 5.9172C1.3071 6.16725 1.16663 6.50639 1.16663 6.86001V9.14001C1.16663 9.49363 1.3071 9.83277 1.55715 10.0828C1.8072 10.3329 2.14634 10.4733 2.49996 10.4733H3.95996C3.9821 10.4724 4.0042 10.476 4.02486 10.4841C4.04552 10.4921 4.06428 10.5043 4.07996 10.52L7.99996 14.4667C8.09281 14.5603 8.21133 14.6243 8.34054 14.6505C8.46975 14.6768 8.60384 14.6641 8.72586 14.6142C8.84788 14.5642 8.95235 14.4792 9.02606 14.3699C9.09977 14.2606 9.13941 14.1319 9.13996 14V2.00001C9.14073 1.8676 9.10205 1.73795 9.02885 1.6276C8.95565 1.51726 8.85124 1.43121 8.72895 1.38043C8.60665 1.32965 8.472 1.31645 8.34217 1.34249C8.21234 1.36854 8.09321 1.43266 7.99996 1.52668Z"
      fill="currentColor"
    />,
  ],
});
