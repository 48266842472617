import { Component } from 'react';
import _ from 'lodash';

// Styles
import './confirmationMessage.scss';
import { CheckIcon } from 'ui';

class ConfirmationMessageInvitationDialog extends Component<{}, { step?: number }> {
  constructor(props: any) {
    super(props);
    this.state = {
      step: 1,
    };
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ step: 2 }, () => {
        setTimeout(() => {
          // Calls a custom finisher
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFinishCustom' does not exist on type '... Remove this comment to see the full error message
          if (this.props.onFinishCustom) this.props.onFinishCustom();
          // Call the default finisher that will ultimately closes the confirmation message
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFinishInvitation' does not exist on ty... Remove this comment to see the full error message
          this.props.onFinishInvitation();
        }, 2000);
      });
    }, 600);
  }

  renderConfirmationMessageDescription(step: any, participant: any, confirmationMessage = null) {
    let recipient = confirmationMessage || null;
    if (!recipient) recipient = _.get(participant, 'user.meta.identity.firstname');
    switch (step) {
      case 1:
        return <h3>Sending SMS to {recipient}...</h3>;
      case 2:
        if (confirmationMessage) {
          return recipient;
        }
        return (
          <h3>
            <strong>{recipient}</strong> has been invited successfully
          </h3>
        );
      default:
        return <h3>Sending SMS to {recipient}...</h3>;
    }
  }

  render() {
    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showConfirmationMessage' does not exist ... Remove this comment to see the full error message
      <div className={`confirmationMessageOnInvitation ${this.props.showConfirmationMessage ? 'visible' : ''}`}>
        <CheckIcon
          style={{
            color: 'green',
            padding: '20%',
          }}
          visibility={this.state.step === 2 ? 'visible' : 'hidden'}
          className="animatedIcon animatedDone"
        />
        {this.renderConfirmationMessageDescription(
          this.state.step,
          _.get(this.props, 'singleSession.participant'),
          _.get(this.props, 'confirmationMessage', null),
        )}
      </div>
    );
  }
}

export default ConfirmationMessageInvitationDialog;
