import { graphql } from 'src/graphql';

export const FetchCreditPacksQuery = graphql(`
  query FetchCreditPacks($type: Int) {
    creditPacks(type: $type) {
      _id
      quantity
      type
      price {
        currency_code
        currency_symbol
        value
      }
    }
  }
`);
