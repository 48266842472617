import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { ChevronLeft } from 'lucide-react';
import { Progress } from '@askable/ui/components/ui/progress';
import { Button } from '@askable/ui/components/ui/button';

function Relax(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/insights-reports' });
  };

  const onClickBack = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/meet-brief' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerBack">
          <ChevronLeft className="backIcon h-7 w-7" onClick={onClickBack} />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="contentTextData mright20">
            <span className="textTitle font--title1">Relax, your researcher is on it</span>
            <span className="descriptionText width380">
              Your researcher will facilitate interviews, complete analysis, execute synthesis and compile a detailed
              report. Your team will be free to get to the rest of their to-do-list or start another project.
            </span>
          </div>
          <div className="contentImageData">
            <div className="illustrationContainer">
              <img className="illustration" src="/askablePlus/illustrations/personRelaxing.png" alt="personRelaxing" />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={6} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(Relax);
