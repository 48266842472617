import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle(({ colorMode }) => {
  const isLight = colorMode === 'light';
  return {
    _placeholder: {
      color: isLight ? 'gray.500' : 'gray.400',
    },
    borderColor: isLight ? 'gray.500' : 'gray.400',
    _hover: {
      borderColor: colorMode === 'light' ? 'gray.600' : 'gray.300',
    },
  };
});

export const textareaTheme = defineStyleConfig({
  variants: { outline },
  sizes: {
    xs: {
      lineHeight: 'base',
      _placeholder: {
        lineHeight: 'base',
      },
      py: '2px',
    },
    sm: {
      lineHeight: 5,
      _placeholder: {
        lineHeight: 5,
      },
      py: '5px',
    },
    md: {
      lineHeight: 7,
      _placeholder: {
        lineHeight: 7,
      },
      py: '5px',
    },
    lg: {
      lineHeight: 7,
      _placeholder: {
        lineHeight: 7,
      },
    },
  },
}) as any;
