import { graphql } from 'src/graphql';

export const EnterpriseSSODetails = graphql(`
  query EnterpriseSSO {
    viewer {
      ConnectedTeam {
        settings {
          sso {
            company_domains
          }
          sso_connections {
            name
            connection_id
            enabled
            strategy
            options {
              home_realm_domains
              saml_custom {
                saml_entity_id
                saml_idp_metadata_url
                saml_email_key_attr
              }
              azure_ad {
                client_id
                client_secret
                entra_id_domain
                is_use_common_endpoint
              }
            }
            connection_id
          }
        }
      }
    }
  }
`);
