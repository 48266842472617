import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const PlayIcon: CreateIconType = createIcon({
  displayName: 'PlayIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M13.568 7.30505L3.12481 2.08044C3.00587 2.02178 2.87395 1.99442 2.7415 2.00094C2.60905 2.00747 2.48045 2.04766 2.36786 2.11773C2.25527 2.18779 2.1624 2.28541 2.09803 2.40136C2.03366 2.51732 1.99992 2.64777 2 2.78039V13.2196C1.99992 13.3522 2.03366 13.4827 2.09803 13.5986C2.1624 13.7146 2.25527 13.8122 2.36786 13.8823C2.48045 13.9523 2.60905 13.9925 2.7415 13.9991C2.87395 14.0056 3.00587 13.9782 3.12481 13.9196L13.568 8.69495C13.6977 8.63096 13.8069 8.53196 13.8832 8.40915C13.9595 8.28634 14 8.14461 14 8C14 7.85539 13.9595 7.71366 13.8832 7.59085C13.8069 7.46804 13.6977 7.36904 13.568 7.30505Z"
      fill="currentColor"
    />
  ),
});
