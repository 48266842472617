import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { Button } from '@askable/ui/components/ui/button';
import { Progress } from '@askable/ui/components/ui/progress';
import { X } from 'lucide-react';

function Welcome(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/submit-online' });
  };

  const onClickClose = () => {
    props.history.push({ pathname: '/askable-plus/create' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerClose">
          <X
            className="closeIcon"
            aria-label="Close project onboarding"
            onClick={onClickClose}
            style={{ width: 30, height: 30, fill: '#444444' }}
          />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="welcomeContentImageData">
            <span className="font--largeTitle title">Get research done.</span>
            <div className="welcomeIllustrationContainer">
              <img
                className="welcomeIllustration"
                src="/askablePlus/illustrations/askablePlusPerson.png"
                alt="askablePlusRecruitment"
              />
            </div>
          </div>
          <div className="welcomeContentTextData">
            <span className="descriptionText">
              Meet Askable+. Instantly scale your research team and get moderated research projects done by tapping into
              our network of the world’s best User Researchers.
            </span>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={1} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Get started
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(Welcome);
