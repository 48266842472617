import { useEffect, useState, Fragment } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Button } from '@askable/ui/components/ui/button';
import { bookingUtils } from 'lib/booking';
import { askablePlusUtils } from 'lib/askablePlus';

// External components
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';

function SurveyDuration(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [sessionDurationValue, setSessionDurationValue] = useState(
    `${_.get(askablePlusBrief, 'askable_plus.research_type.survey.session.duration')}`,
  );

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasSurveyType = askablePlusUtils.hasSurveyType(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected <= 1) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`);
    } else if (!hasSurveyType) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/longitudinal-study/quota`);
    } else {
      props.updateLastStep({
        step: 'Survey',
        subStep: `/askable-plus/${askablePlusBrief._id}/survey/survey-duration`,
        stepId: 'survey_duration',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBrief, askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const parseSessionDuration = (duration: number) => {
    setSessionDurationValue(`${duration}`);
  };

  const onChangeValue = (value: string) => {
    parseSessionDuration(Number(value));
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          survey: {
            ...askablePlusBriefState.askable_plus.research_type.survey,
            session: {
              ...askablePlusBriefState.askable_plus.research_type.survey.session,
              duration: Number(value),
              time_limit: 120,
            },
          },
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/longitudinal-study/quota`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Survey duration</h1>
      <p className="label">How long should the tasks go for?</p>
      <Select onValueChange={(v) => onChangeValue(v)} value={sessionDurationValue}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {bookingUtils.sessionDurations(3, 2)?.map((value) => {
            return (
              <SelectItem key={value.value} value={`${value.value}`}>
                {value.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(SurveyDuration);
