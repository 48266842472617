import { gql } from '@apollo/client';

export default gql`
  mutation updateSession($booking_id: ID!, $session_id: ID!, $session: BookingInputSession!) {
    updateSession(booking_id: $booking_id, session_id: $session_id, session: $session) {
      _id
      session {
        _id
        start
        end
        slot_min
        slot_max
        status
        _researcher_user_id
        ResearcherUser {
          _id
          name
        }
      }
    }
  }
`;
