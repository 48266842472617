import { gql } from '@apollo/client';
import { userFieldsFragment } from 'data/fragments/user/userFields';

export default gql`
  mutation updateMe($user: UserInput!) {
    updateMe(user: $user) {
      ...userFields
    }
  }
  ${userFieldsFragment}
`;
