import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ReelIcon: CreateIconType = createIcon({
  displayName: 'ReelIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M1.17965 2.75239C1.24886 1.90063 1.90062 1.24886 2.75239 1.17966C3.82847 1.09223 5.54533 1 8 1C10.4547 1 12.1715 1.09222 13.2476 1.17965C14.0994 1.24886 14.7511 1.90062 14.8203 2.75239C14.9078 3.82847 15 5.54533 15 8C15 10.4547 14.9078 12.1715 14.8203 13.2476C14.7511 14.0994 14.0994 14.7511 13.2476 14.8203C12.1715 14.9078 10.4547 15 8 15C5.54533 15 3.82847 14.9078 2.75239 14.8203C1.90063 14.7511 1.24886 14.0994 1.17966 13.2476C1.09223 12.1715 1 10.4547 1 8C1 5.54533 1.09222 3.82847 1.17965 2.75239Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.78355 7.95761C5.86685 7.38098 6.37535 7.05291 6.91875 7.26315C7.30005 7.41065 7.81398 7.64075 8.48955 7.99965C9.13552 8.34281 9.63368 8.64671 10.0094 8.89791C10.5955 9.28971 10.5955 10.0429 10.0094 10.4347C9.63368 10.6859 9.13552 10.9898 8.48955 11.333C7.81398 11.6919 7.30005 11.9219 6.91875 12.0695C6.37535 12.2797 5.86685 11.9516 5.78355 11.375C5.72022 10.9365 5.66602 10.359 5.66602 9.66631C5.66602 8.97361 5.72022 8.39608 5.78355 7.95761Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.9348 4.66797H1.06445"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6.76302 1.05078L4.09375 4.60978"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.2383 1.125L9.62109 4.61456"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
