import { RouteObject } from 'react-router-dom';

import { TeamsContainer } from './TeamsContainer';
import { TeamsLayout } from './TeamsLayout';
import { RequireAuth } from 'containers/Auth/RequireAuth';
import { CreateTeam } from './components/CreateTeam';

export const teamRoutes: RouteObject[] = [
  {
    path: 'teams',
    element: (
      <RequireAuth>
        <TeamsLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: 'create',
        element: <CreateTeam />,
      },
      {
        index: true,
        element: <TeamsContainer />,
      },
    ],
  },
];
