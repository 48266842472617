import { ReactNode } from 'react';
import { cn } from '@askable/ui/lib/utils';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface SettingsContainerProps {
  title: string;
  children: ReactNode;
  width?: 'default' | 'wide';
}

export const SettingsContainer = ({ children, title, width = 'default' }: SettingsContainerProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn('mx-auto flex w-full flex-col gap-8 p-4 py-6', {
        'max-w-3xl': width === 'wide',
        'max-w-xl': width === 'default',
      })}
    >
      <Helmet>
        <title>
          {title} - {t('sections.settings.title')}
        </title>
      </Helmet>
      <header className="flex flex-col">
        <h1 className="text-xl font-semibold">{title}</h1>
      </header>

      {children}
    </div>
  );
};
