import { ReactElement } from 'react';
import { Button } from '@askable/ui/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@askable/ui/components/ui/dialog';

// Styles
import './styles/dialogStyles.scss';

type Props = {
  title?: string;
  open: boolean;
  children: ReactElement | ReactElement[];
  onCancel?: () => void;
  onConfirm?: () => void;
  onRequestClose?: () => void;
  customActions?: any[];
  modal?: boolean;
  styles?: any;
  className?: string;
  contentClassName?: string;
  scroll?: boolean;
  bodyStyle?: any;
  buttonsGroupClass?: string;
};

// Component created to display confirmation messages
const DialogMessage = (props: Props) => {
  let actions: ReactElement | ReactElement[] = [];
  if (props.customActions && props.customActions.length > 0) {
    actions = (
      <div className={props.buttonsGroupClass || 'confirmDeleteQuestions'}>
        {props.customActions?.map((action: any) => {
          if (action.buttonType && action.buttonType !== 'flat') {
            return (
              <div key={action.label} className={action.buttonContainerClass} style={action.containerButtonStyle}>
                <DialogClose>
                  <Button variant="primary" onClick={action.customFunction} disabled={action.disabled}>
                    {action.label}
                  </Button>
                </DialogClose>
              </div>
            );
          }
          return (
            <div key={action.label} className={action.buttonContainerClass}>
              <DialogClose>
                <Button variant="outline" onClick={action.customFunction} disabled={action.disabled}>
                  {action.label}
                </Button>
              </DialogClose>
            </div>
          );
        })}
      </div>
    );
  } else if (props.onCancel && props.onConfirm) {
    actions = [
      <DialogClose key="cancel">
        <Button key="cancel" variant="outline" onClick={props.onCancel}>
          Cancel
        </Button>
      </DialogClose>,
      <DialogClose key="confirm">
        <Button key="confirm" variant="primary" onClick={props.onConfirm}>
          Confirm
        </Button>
      </DialogClose>,
    ];
  }

  return (
    <Dialog open={props.open} modal={props.modal} onOpenChange={(val) => !val && props?.onRequestClose?.()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{props.children}</DialogDescription>
        <DialogFooter>{actions}</DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DialogMessage;
