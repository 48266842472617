import { useState } from 'react';
import momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { Dialog, DialogContent } from '@askable/ui/components/ui/dialog';
import { utils } from 'lib/utils';
import { bookingParticipantUtils } from 'lib/bookingParticipant';

// External components
import InvitationDialog from './invitationDialog/view';

// Styles
import './styles/viewInviteToSessionStyles.scss';

// DEPRECATED?

function InviteToSession(props: any) {
  const [openInvitationDialog, setOpenInvitationDialog] = useState(false);
  const [sessionSelected, setSessionSelected] = useState({ start: momentTimezone().valueOf() });
  const [isAvailableForSession, setIsAvailableForSession] = useState(false);
  const [participantSessionId, setParticipantSessionId] = useState();

  const timezone = _.get(props.booking, 'config.timezone') || utils.getCurrentTimezone();
  const activeBookingSessions = _.filter(_.get(props.booking, 'session'), (session: any) => {
    return _.get(session, 'status') !== 3;
  });
  const orderedSessions = utils.orderArrayByDate(activeBookingSessions, 'start');
  const validSessions = _.map(orderedSessions, (item: any) => {
    if (momentTimezone(item.start).tz(timezone).diff(momentTimezone(), 'minutes') > 0) return item;
  }).filter(Boolean);
  const sessionsGroupedByDate = utils.groupByDate(validSessions, 'start', timezone, 'dddd Do MMM');

  const onClickTimeContainer = (
    session: any,
    participant_session_id: any,
    isAvailable: any,
    isInvalid: any,
    participant: any,
  ) => {
    if (!isInvalid) {
      setOpenInvitationDialog(true);
      setSessionSelected(session);
      setIsAvailableForSession(isAvailable);
      setParticipantSessionId(participant_session_id);
    }
    // If there's no onInviteParticipant callback it assumes the user just wants to return the time selected
    if (!props.onInviteParticipant && props.onTimeSelected && !isInvalid) {
      const participantNotAvailableSession = {
        _booking_id: props.booking._id,
        _session_id: session._id,
        _user_id: participant.user._id,
      };

      props.onTimeSelected({
        sessionSelected: session,
        isAvailableForSession: isAvailable,
        participant_session_id,
        submission_id: participant.submission_id,
        bookingParticipantSelected: _.find(
          participant.available_times,
          (item: any) => item._id === participant_session_id,
        ),
        participantNotAvailableSession,
      });
    }
  };

  const renderTimeSlot = (session: any) => {
    let isAvailable = false;
    let isInvalid = false;
    let bookingParticipantRecord: any;

    // Check if the person is available for that specific session
    const available_times = _.get(props.participant, 'available_times');
    if (available_times) {
      bookingParticipantRecord = available_times.filter(
        (item: any) => item.session._id === session._id && item.cancel === 0,
      );
      isAvailable = bookingParticipantRecord.length > 0;
    }

    // Check if the session is not in the past. Clients shouldn't be able to invite participants for sessions in the past
    if (momentTimezone(session.start).tz(timezone).diff(momentTimezone(), 'minutes') < 0) isInvalid = true;

    const sessionStatus = bookingParticipantUtils.getSessionStatus(session._id, props.allParticipantsSessions);
    return (
      <div
        key={session._id}
        className={`timeContainer ${isAvailable ? 'available' : ''} ${isInvalid ? 'invalid' : ''}`}
        onClick={() => {
          onClickTimeContainer(
            session,
            _.get(bookingParticipantRecord, '[0]._id'),
            isAvailable,
            isInvalid,
            props.participant,
          );
        }}
      >
        <span className="session">{momentTimezone(session.start).tz(timezone).format('h:mm A')}</span>
        {sessionStatus && <span className={`status ${sessionStatus.status}`}>{sessionStatus.label}</span>}
      </div>
    );
  };

  const renderDate = (data: any, key: any) => {
    return (
      <div key={`date-${key}`} className="date">
        <h3>{key}</h3>
        {data.map((session: any) => renderTimeSlot(session))}
      </div>
    );
  };

  // If onInviteParticipant callback exists, it assumes the user wants to invite a participant
  // If not, it should just return the time selected on a onTimeSelected callback
  return (
    <>
      <Dialog
        open={props.open}
        onOpenChange={(open: boolean) => {
          if (!open) {
            props.onClose;
          }
        }}
      >
        <DialogContent className="inviteToSessionContentContainer">
          {validSessions.length > 0 && (
            <>
              <div className="header">
                <p>
                  {_.get(props, 'participant.user.meta.identity.firstname')}’s preferred times are in blue, but you can
                  invite them to any session
                </p>
              </div>
              <div className="content">
                {_.map(sessionsGroupedByDate, (data: any, key: any) => renderDate(data, key))}
              </div>
            </>
          )}
          {validSessions.length <= 0 && (
            <div className="pastSessions">
              <p>All sessions are now past</p>
              <a
                className="addSession"
                onClick={() => {
                  props.onClose();
                  if (props.onCloseAll) props.onCloseAll();
                }}
                href={`/bookings/${props.booking._id}/sessions`}
              >
                Add more session times?
              </a>
            </div>
          )}
        </DialogContent>
      </Dialog>
      {openInvitationDialog && props.onInviteParticipant && (
        <InvitationDialog
          client={props.client}
          open={openInvitationDialog}
          onCloseInvitationDialog={() => {
            setOpenInvitationDialog(false);
          }}
          onRefreshTable={props.onInviteParticipant}
          onCloseSessionsContainer={props.onClose}
          singleSession={{
            session: sessionSelected,
            sessionStatus: bookingParticipantUtils.getSessionStatus(
              _.get(sessionSelected, '_id'),
              props.allParticipantsSessions,
            ),
            participant: props.participant,
            participant_session_id: participantSessionId,
            isAvailableForSession,
          }}
          booking={props.booking}
        />
      )}
    </>
  );
}

export default InviteToSession;
