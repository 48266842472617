import React from 'react';

import './defaultButton.scss';

export default function DefaultButton(props: any) {
  let customClass = '';
  // Button can be outlined
  // It will make the background transparent and create borders with the color of the button
  if (props.outlined) {
    customClass = 'outlinedButton';
  }
  return (
    <button
      type="button"
      id={props.id}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; type: "button"; id: any; la... Remove this comment to see the full error message
      label={props.label}
      className={`btn_default ${props.className ? props.className : ''} ${customClass}`}
      onClick={props.onClick}
      href={props.href ? props.href : null}
      target={props.target ? props.target : ''}
      disabled={props.disabled || false}
      style={props.style ? props.style : {}}
    >
      {props.label}
    </button>
  );
}
