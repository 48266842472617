import gql from 'graphql-tag';

export default gql`
  mutation reInviteParticipantSession($booking_id: ID!, $participant_session_id: ID!, $customMessage: String) {
    reInviteParticipantSession(
      booking_id: $booking_id
      participant_session_id: $participant_session_id
      customMessage: $customMessage
    ) {
      _id
    }
  }
`;
