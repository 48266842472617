import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const InProgressNoneIcon: CreateIconType = createIcon({
  displayName: 'InProgressNoneIcon',
  defaultProps: {
    ...defaultOptions,
    color: 'yellow.500',
    viewBox: '0 0 16 16',
  },
  path: [<circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />],
});
