import { graphql } from 'src/graphql';

export const DiscoverThemesQuery = graphql(`
  query DiscoveryThemes($first: Int!, $filter: AnalysesThemeFilter!) {
    analysesThemes(first: $first, filter: $filter) {
      totalCount
      nodes {
        _id
        count
        name
        accent
      }
    }
  }
`);
