import { gql } from '@apollo/client';
import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';

export default gql`
  mutation deleteOnlineTaskLink($booking_id: ID!, $link_id: ID!) {
    deleteOnlineTaskLink(booking_id: $booking_id, link_id: $link_id) {
      ...bookingFields
    }
  }
  ${bookingFieldsFragment}
`;
