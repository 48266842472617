import { RefObject, useEffect, useRef } from 'react';

/**
 * Used to create a single Google Map instance.
 */
export const useGoogleMap = (el: RefObject<HTMLDivElement | null>, options: google.maps.MapOptions = {}) => {
  const googleMap = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (!el.current) {
      console.warn('Google Map element is not available');
      return;
    }

    // If the map is already initialized, do nothing
    if (googleMap.current) {
      console.warn("Google Map is already initialized. Don't initialize it again.");
      return;
    }

    googleMap.current = new window.google.maps.Map(el.current, {
      ...options,
      controlSize: 24,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: 'cooperative',
    });
  }, [el.current]);

  return googleMap.current;
};
