import { gql } from '@apollo/client';

export default gql`
  mutation reorderQuestions($booking_id: ID!, $questions: [BookingInputConfigQuestion]) {
    reorderQuestions(booking_id: $booking_id, questions: $questions) {
      _id
      config {
        question {
          _id
          title
          description
          config {
            display_participant
            multiple_selection
            none_of_the_above
            type
          }
          options {
            _id
            label
            screen_in
          }
        }
      }
    }
  }
`;
