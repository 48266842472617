import { gql } from '@apollo/client';

export default gql`
  mutation markParticipantSessionIssue(
    $booking_id: ID!
    $participant_session_id: ID!
    $issue_request: Int
    $issue_reason: String
  ) {
    markParticipantSessionIssue(
      booking_id: $booking_id
      participant_session_id: $participant_session_id
      issue_request: $issue_request
      issue_reason: $issue_reason
    ) {
      _id
      booking {
        team {
          _id
          settings {
            billing {
              subscription {
                credit {
                  remaining
                }
              }
            }
          }
        }
      }
    }
  }
`;
