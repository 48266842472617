import { ReactNode, type PropsWithChildren } from 'react';

import { MinimalHeader } from 'components/MinimalHeader/MinimalHeader';
import { cn } from '@askable/ui/lib/utils';

type Props = {
  bodyClasses?: string;
  footer?: ReactNode;
};

export const MinimalPage = ({ bodyClasses = '', footer, children }: PropsWithChildren<Props>) => {
  return (
    <div className={cn('mx-2 flex h-screen flex-col overflow-y-auto p-4', { 'pb-16': !footer })}>
      <MinimalHeader />
      <div className={bodyClasses}>{children}</div>
      {footer ? <div className="mb-0 mt-auto flex-grow-0 border-t pb-2 pt-6">{footer}</div> : null}
    </div>
  );
};
