const localStorage = {
  save(id: any, value: any) {
    window.localStorage.setItem(id, value);
  },
  get(id: any) {
    return window.localStorage.getItem(id);
  },
  getAll() {
    return { ...window.localStorage };
  },
  remove(id: any) {
    return window.localStorage.removeItem(id);
  },
  reset() {
    window.localStorage.clear();
  },
};

const sessionStorage = {
  save(id: any, value: any) {
    window.sessionStorage.setItem(id, value);
  },
  get(id: any) {
    return window.sessionStorage.getItem(id);
  },
  remove(id: any) {
    window.sessionStorage.removeItem(id);
  },
};

export { localStorage, sessionStorage };
