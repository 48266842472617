import type { ComponentStyleConfig, SystemStyleObject } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  variants: {
    activatedPurple: ({ colorMode }) => {
      return {
        color: 'gray.800',
        backgroundColor: 'purple.100',
        _hover: {
          backgroundColor: 'purple.200',
        },
        _active: {
          backgroundColor: 'purple.200',
        },
      };
    },
    activated: ({ colorMode }) => {
      const isLight = colorMode === 'light';

      return {
        color: isLight ? 'gray.800' : 'yellow.200',
        backgroundColor: isLight ? 'yellow.100' : 'yellowAlpha.300',
        _hover: {
          backgroundColor: isLight ? 'yellow.200' : 'yellowAlpha.400',
        },
        _active: {
          backgroundColor: isLight ? 'yellow.300' : 'yellowAlpha.500',
        },
      };
    },
    solid: ({ colorScheme, colorMode }) => {
      const isLight = colorMode === 'light';
      if (colorScheme === 'whiteAlpha') {
        return {
          color: 'white',
          backgroundColor: 'whiteAlpha.200',
          _hover: {
            backgroundColor: 'whiteAlpha.300',
          },
          _active: {
            backgroundColor: 'whiteAlpha.400',
          },
        };
      }

      if (colorScheme === 'blue') {
        return {
          color: isLight ? 'white' : 'gray.800',
          backgroundColor: isLight ? 'blue.800' : 'blue.200',
          _hover: {
            backgroundColor: isLight ? 'blue.700' : 'blue.300',
          },
          _active: {
            backgroundColor: isLight ? 'blue.900' : 'blue.400',
          },
        };
      }
      if (colorScheme === 'purple') {
        return {
          color: 'white',
          backgroundColor: 'purple.500',
          _hover: {
            backgroundColor: 'purple.600',
          },
          _active: {
            backgroundColor: 'purple.700',
          },
        };
      }

      return {};
    },
    ghost: ({ colorScheme }) => {
      const whiteAlphaConfig: SystemStyleObject = {
        color: 'white',
        _hover: {
          backgroundColor: 'whiteAlpha.200',
        },
        _active: {
          backgroundColor: 'whiteAlpha.300',
        },
      };
      return {
        ...(colorScheme === 'whiteAlpha' && whiteAlphaConfig),
      };
    },
  },
  baseStyle: ({ variant, colorMode, size }) => {
    const isLight = colorMode === 'light';
    const solidConfig: SystemStyleObject = {
      _disabled: {
        color: isLight ? 'gray.800' : 'white',
        backgroundColor: isLight ? 'gray.100' : 'whiteAlpha.200',
        opacity: '0.4',
      },
      _hover: {
        _disabled: {
          backgroundColor: isLight ? 'gray.100' : 'whiteAlpha.200',
          color: isLight ? 'gray.800' : 'white',
        },
      },
      _active: {
        _disabled: {
          backgroundColor: isLight ? 'gray.100' : 'whiteAlpha.200',
          color: isLight ? 'gray.800' : 'white',
        },
      },
    };
    const ghostConfig: SystemStyleObject = {
      _disabled: {
        color: isLight ? 'gray.800' : 'white',
        opacity: '0.4',
      },
      _hover: {
        _disabled: {
          color: isLight ? 'gray.800' : 'white',
        },
      },
      _active: {
        _disabled: {
          color: isLight ? 'gray.800' : 'white',
        },
      },
    };
    // fixed an alignment issue caused by small font size * line height (14 * 1.2 = 16.8) rounding text alignment to the nearest pixel and looking weird
    const smallConfig = {
      lineHeight: 1,
    };
    return {
      ...(variant === 'solid' && solidConfig),
      ...(variant === 'ghost' && ghostConfig),
      ...(size === 'sm' && smallConfig),
    };
  },
};
