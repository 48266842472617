import { graphql } from 'src/graphql';

export const SearchAskableMeeting = graphql(`
  query searchAskableMeeting($input: AskableVideoInput!) {
    searchAskableMeeting(input: $input) {
      _id
      code
      start
      end
      context {
        _project_id
        _booking_id
        _session_id
        _user_id
      }
      rooms {
        _id
        sid
        composition {
          sid
          status
        }
      }
      users {
        _id
        User {
          _id
          type {
            participant
          }
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`);
