export const DataTestId = {
  // apps/sessions
  InsightCollectionHeaderMiddleTitle: 'insight-collection-header-middle-title',
  cellStatus: 'cell-status',
  cellBookingType: 'cell-booking-type',
  statusPopover: 'Status-popover',
  typePopover: 'Type-popover',
  userName: '__userName',
  // apps/clients
} as const;
