import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const TickIcon: CreateIconType = createIcon({
  displayName: 'TickIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      d="M3 8.375L6.63636 12L13 4"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
