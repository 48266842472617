import { ReactNode } from 'react';

export const SectionHeader = ({ title, description }: { title: string; description?: string | ReactNode }) => {
  return (
    <header className="flex flex-col">
      <h3 className="text-md font-semibold">{title}</h3>
      <div className="flex max-w-lg gap-2 text-pretty text-sm text-muted-foreground">{description}</div>
    </header>
  );
};
