import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { withAppContext } from 'components/HOCS';
import { bookingParticipantUtils } from 'lib/bookingParticipant';
import { bookingUtils } from 'lib/booking';
import { toast } from '@askable/ui/components/ui/sonner';

// Queries
import fetchBookingSessionsWithParticipants from 'data/queries/booking/fetchBookingSessionsWithParticipants';

// Mutations
import reInviteParticipantSessionMutation from 'data/mutations/bookingSubmission/reInviteParticipantSession';
import inviteParticipantSessionMutation from 'data/mutations/bookingSubmission/inviteParticipantSession';
import bulkInviteParticipantsMutation from 'data/mutations/bookingSubmission/bulkInviteParticipants';
import bulkReinviteParticipantsMutation from 'data/mutations/bookingSubmission/bulkReinviteParticipants';
import clientSaveParticipantAvailabilityMutation from 'data/mutations/bookingSubmission/clientSaveParticipantAvailability';
import cancelParticipantSessionMutation from 'data/mutations/bookingSubmission/cancelParticipantSession';
import isParticipantExcludedMutation from 'data/mutations/bookingSubmission/isParticipantExcluded';
import findParticipantsSubmission from 'data/queries/bookingSubmission/findParticipantsSubmission';

// External Components
import ReInviteParticipant from './reInviteParticipant';
import InviteParticipant from './inviteParticipant';
import BulkInviteParticipantsToSessions from './bulkInviteParticipantsToSessions';

// Styles
import './invitationDialogStyles.scss';

function InvitationDialog(props: any) {
  const { booking, singleSession, bulkSession, isBulkInvite, onFinishInvitationProcess } = props;

  const [loading, setLoading] = useState(false);
  const [participantSessionId, setParticipantSessionId] = useState(singleSession.participant_session_id);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [hasNotInvitedParticipants, setHasNotInvitedParticipants] = useState(false);

  const [isParticipantExcluded] = useMutation(isParticipantExcludedMutation, {
    client: props.client,
  });
  const [reInviteParticipantSession] = useMutation(reInviteParticipantSessionMutation, {
    refetchQueries: [
      {
        query: fetchBookingSessionsWithParticipants,
        variables: { booking_id: booking._id },
      },
    ],
    client: props.client,
  });
  const [cancelParticipantSession] = useMutation(cancelParticipantSessionMutation, {
    // refetchQueries: [{
    //     query: fetchBookingSessionsWithParticipants,
    //     variables: { booking_id: booking._id },
    // }],
    client: props.client,
  });
  const [clientSaveParticipantAvailability] = useMutation(clientSaveParticipantAvailabilityMutation, {
    // refetchQueries: [{
    //     query: fetchBookingSessionsWithParticipants,
    //     variables: { booking_id: booking._id },
    // }],
    client: props.client,
  });
  const [bulkReinviteParticipants] = useMutation(bulkReinviteParticipantsMutation, {
    // refetchQueries: [{
    //     query: fetchBookingSessionsWithParticipants,
    //     variables: { booking_id: booking._id },
    // }],
    client: props.client,
  });
  const [bulkInviteParticipants] = useMutation(bulkInviteParticipantsMutation, {
    refetchQueries: [
      {
        query: fetchBookingSessionsWithParticipants,
        variables: { booking_id: booking._id },
      },
    ],
    client: props.client,
  });
  const [inviteParticipantSession] = useMutation(inviteParticipantSessionMutation, {
    refetchQueries: [findParticipantsSubmission],
    client: props.client,
  });

  const onConfirmInvitation = async () => {
    let confirmInvitation = true;

    const participantSessions = _.get(singleSession, 'participant.booking_participant');
    const participantWaitlistSessions = _.filter(participantSessions, (item: any) => item.status === 2);
    await _.map(participantWaitlistSessions, (session: any) => {
      cancelParticipantSession({
        variables: {
          booking_id: booking._id,
          participant_session_id: _.get(session, '_id'),
        },
      });
    });

    setLoading(true);

    if (!participantSessionId) {
      await clientSaveParticipantAvailability({
        variables: {
          booking_id: booking._id,
          user_id: singleSession.participant.user._id,
          session_id: singleSession.session._id,
          created_context: 3,
        },
      })
        .then((res) => {
          // When successful
          setParticipantSessionId(res.data.clientSaveParticipantAvailability._id);
        })
        .catch(() => {
          confirmInvitation = false;
          setLoading(false);
          setShowConfirmationMessage(confirmInvitation);
        });
    }

    if (confirmInvitation) {
      inviteParticipantSession({
        variables: {
          booking_id: booking._id,
          participant_session_id: participantSessionId,
          online_task_link_id: _.get(props, 'link_id'),
        },
      }).catch((e) => {
        toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
      });

      // Stops the loading state and start the confirmation message animation
      setLoading(false);
      setShowConfirmationMessage(confirmInvitation);
    }
  };

  const onConfirmReInvitation = async () => {
    const { participant } = props.singleSession;
    setLoading(true);

    await reInviteParticipantSession({
      variables: {
        booking_id: booking._id,
        participant_session_id: _.get(participant, 'invitedSession._id'),
      },
    }).catch((e) => {
      toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
    });

    // Stops the loading state and start the confirmation message animation
    setLoading(false);
    setShowConfirmationMessage(true);
  };

  const onConfirmBulkInvitation = async (selectedSessions = []) => {
    let totalParticipants = _.size(bulkSession.participants);
    let participantsToInvite = [];
    let participantsToReinvite = [];
    setLoading(true);

    // If the client is trying to bulk invite for a quant or longitudinal job, then it should normalise the participants array to include only the available session
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      // Available participants
      const availableParticipants = _.filter(
        bulkSession.participants,
        (item: any) => item.sessions && !item.invitedParticipant,
      );
      participantsToInvite = _.map(availableParticipants, (item: any) => {
        // Normalise the booking participant object before sending it over to the server
        return bookingParticipantUtils.normaliseBookingParticipantToSave(item.sessions[0]);
      });
      // Invited participants
      const invitedParticipants = _.filter(bulkSession.participants, (item: any) => item.invitedParticipant);
      participantsToReinvite = _.map(invitedParticipants, (item: any) => {
        // Normalise the booking participant object before sending it over to the server
        return bookingParticipantUtils.normaliseBookingParticipantToSave(item.sessions[0]);
      });
    } else {
      totalParticipants = selectedSessions.length;
      for (let i = 0; i < selectedSessions.length; i += 1) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantReinvitation' does not exist ... Remove this comment to see the full error message
        if (selectedSessions[i].participantReinvitation) {
          // prettier-ignore
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingParticipantSelected' does not exi... Remove this comment to see the full error message
          participantsToReinvite.push(bookingParticipantUtils.normaliseBookingParticipantToSave(selectedSessions[i].bookingParticipantSelected));
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'isAvailableForSession' does not exist on... Remove this comment to see the full error message
        } else if (selectedSessions[i].isAvailableForSession === false) {
          // eslint-disable-next-line no-await-in-loop
          const newSession = await clientSaveParticipantAvailability({
            variables: {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantNotAvailableSession' does not... Remove this comment to see the full error message
              booking_id: selectedSessions[i].participantNotAvailableSession._booking_id,
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantNotAvailableSession' does not... Remove this comment to see the full error message
              user_id: selectedSessions[i].participantNotAvailableSession._user_id,
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantNotAvailableSession' does not... Remove this comment to see the full error message
              session_id: selectedSessions[i].participantNotAvailableSession._session_id,
              created_context: 3,
            },
          }).catch(() => {});

          if (_.has(newSession, 'data.clientSaveParticipantAvailability')) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'void | Fet... Remove this comment to see the full error message
            const bookingParticipantSession = newSession.data.clientSaveParticipantAvailability;
            participantsToInvite.push(
              bookingParticipantUtils.normaliseBookingParticipantToSave(bookingParticipantSession),
            );
          }
        } else {
          // eslint-disable-next-line no-await-in-loop
          const { data } = await isParticipantExcluded({
            variables: {
              bookingId: _.get(selectedSessions[i], 'participantNotAvailableSession._booking_id'),
              userId: _.get(selectedSessions[i], 'participantNotAvailableSession._user_id'),
            },
          });
          if (!data || !data.isParticipantExcluded) {
            // prettier-ignore
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingParticipantSelected' does not exi... Remove this comment to see the full error message
            participantsToInvite.push(bookingParticipantUtils.normaliseBookingParticipantToSave(selectedSessions[i].bookingParticipantSelected));
          }
        }
      }
    }

    // Bulk invite all the selected sessions
    if (_.size(participantsToInvite) > 0) {
      await bulkInviteParticipants({
        variables: {
          booking_id: booking._id,
          participant_sessions: participantsToInvite,
          online_task_link_id: _.get(props, 'link_id'),
        },
      }).catch((e) => {
        toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
      });
    }

    // Bulk reinvite all the selected sessions
    if (_.size(participantsToReinvite) > 0) {
      await bulkReinviteParticipants({
        variables: {
          booking_id: booking._id,
          participant_sessions: participantsToReinvite,
          online_task_link_id: _.get(props, 'link_id'),
        },
      }).catch((e) => {
        toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error saving study');
      });
    }

    const notInvitedParticipants = totalParticipants - (_.size(participantsToInvite) + _.size(participantsToReinvite));
    const confirmationMessageComponent = (
      <h5>
        {_.size(participantsToInvite) > 0 && (
          <span>{`${_.size(participantsToInvite)} ${_.size(participantsToInvite) > 1 ? 'participants were' : 'participant was'} invited`}</span>
        )}
        {_.size(participantsToReinvite) > 0 && (
          <span>
            <br />
            {`${_.size(participantsToReinvite)} ${_.size(participantsToReinvite) > 1 ? 'participants were' : 'participant was'} reinvited`}
          </span>
        )}
        {notInvitedParticipants > 0 && (
          <span>
            <br />
            {`${notInvitedParticipants} ${notInvitedParticipants > 1 ? 'participants were' : 'participant was'} not invited for having exclusion rules`}
          </span>
        )}
      </h5>
    );

    setTimeout(() => {
      setLoading(false);
      setShowConfirmationMessage(true);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
      setShowConfirmationMessage(confirmationMessageComponent);
      setHasNotInvitedParticipants(notInvitedParticipants > 0);
    }, 2000);
  };

  // Parent component to deal with all different scenarios to invite participants
  //  It accepts either single invitation and bulk invitation
  //  If it gets a 'single_session' object as a prop then it means the client is trying to invite just one participant
  //  If it gets a 'bulk_session' object as a prop then it means the client is trying to inbite multiple participants for sessions

  // Handles single sessions
  if (!isBulkInvite && _.has(props, 'singleSession.participant')) {
    const hasBeenInvited = _.has(props, 'singleSession.participant.invitedSession');
    if (hasBeenInvited) {
      return (
        <ReInviteParticipant
          {...props}
          loading={loading}
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          onConfirm={(callback: any) => onConfirmReInvitation(callback)}
          onFinishInvitation={() => {
            // Close the confirmation message and after closes the sessions container
            setShowConfirmationMessage(false);
            // Custom callback after finishing everything
            if (onFinishInvitationProcess) onFinishInvitationProcess();

            if (props.onRefreshTable) props.onRefreshTable();
          }}
          showConfirmationMessage={showConfirmationMessage}
        />
      );
    }
    return (
      <InviteParticipant
        {...props}
        loading={loading}
        onConfirm={onConfirmInvitation}
        onFinishInvitation={() => {
          // Close the invitation dialog
          if (props.onCloseInvitationDialog) props.onCloseInvitationDialog();
          // Custom callback after finishing everything
          if (props.onFinishInvitationProcess) props.onFinishInvitationProcess();
          // Close the confirmation message and after closes the sessions container
          // setState({ showConfirmationMessage: false }, () => {
          //     if (props.onCloseSessionsContainer) props.onCloseSessionsContainer();
          // });
          setShowConfirmationMessage(false);

          if (props.onRefreshTable) props.onRefreshTable();
        }}
        showConfirmationMessage={showConfirmationMessage}
      />
    );
  }

  // Handles bulk invitations
  //  Quant or Longitudinal bookings
  if (isBulkInvite && (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking))) {
    return (
      <InviteParticipant
        {...props}
        loading={loading}
        onConfirm={onConfirmBulkInvitation}
        onFinishInvitation={() => {
          // Close the invitation dialog
          if (props.onCloseInvitationDialog) props.onCloseInvitationDialog();
          // Custom callback after finishing everything
          if (props.onFinishInvitationProcess) props.onFinishInvitationProcess();
          // Close the confirmation message and after closes the sessions container
          setShowConfirmationMessage(false);

          if (props.onRefreshTable) props.onRefreshTable();
        }}
        showConfirmationMessage={showConfirmationMessage}
        confirmationMessage="Participants have been invited successfully"
        hasNotInvitedParticipants={hasNotInvitedParticipants}
      />
    );
  }

  if (isBulkInvite && (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking))) {
    return (
      <BulkInviteParticipantsToSessions
        {...props}
        loading={loading}
        onConfirm={onConfirmBulkInvitation}
        onFinishInvitation={() => {
          // Close the invitation dialog
          if (props.onCloseInvitationDialog) props.onCloseInvitationDialog();
          // Custom callback after finishing everything
          if (props.onFinishInvitationProcess) props.onFinishInvitationProcess();
          // Close the confirmation message and after closes the sessions container
          setShowConfirmationMessage(false);

          if (props.onRefreshTable) props.onRefreshTable();
        }}
        showConfirmationMessage={showConfirmationMessage}
        confirmationMessage="Participants have been invited successfully"
        hasNotInvitedParticipants={hasNotInvitedParticipants}
        onClose={() => {
          // Close the invitation dialog
          if (props.onCloseInvitationDialog) props.onCloseInvitationDialog();
        }}
      />
    );
  }
}

export default withAppContext(InvitationDialog);
