import { memo } from 'radash';

export const colorIsLight = memo((color: string) => {
  const [r, g, b] = (() => {
    const c = color.replace(/[^0-9a-fA-F]/g, '');
    switch (c.length) {
      // color with alpha channel
      case 8:
      case 6:
        return [parseInt(c.substring(0, 2), 16), parseInt(c.substring(2, 4), 16), parseInt(c.substring(4, 6), 16)];
      case 3:
        return [
          parseInt(c.substring(0, 1), 16) * 16,
          parseInt(c.substring(1, 2), 16) * 16,
          parseInt(c.substring(2, 3), 16) * 16,
        ];
      default:
        return [];
    }
  })();
  if (r === undefined || g === undefined || b === undefined) {
    return undefined;
  }
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq > 128;
});

export function getTextColorBasedOnHexColor(
  hexcolor: string,
  lightColor: string = 'gray.800',
  darkColor: string = 'white',
) {
  return colorIsLight(hexcolor) ? lightColor : darkColor;
}
