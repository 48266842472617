import { debounce } from 'radash';
import { useState, useLayoutEffect, useEffect, RefObject } from 'react';

export const useElementOverflow = (elementRef: RefObject<HTMLElement> | null, pause?: boolean) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const updateOverflow = () => {
    if (elementRef?.current) {
      setIsOverflowing(elementRef.current.scrollWidth > elementRef.current.clientWidth);
    }
  };

  useLayoutEffect(() => {
    if (pause) {
      return;
    }
    updateOverflow();
  }, [elementRef, pause]);

  useEffect(() => {
    const onResize = debounce({ delay: 100 }, () => {
      updateOverflow();
    });
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [elementRef]);

  return isOverflowing;
};
