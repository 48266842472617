import { TableBaseSkeleton } from '../components/TableBaseSkeleton';

export const BookingsTableSkeletons = () => {
  return (
    <div className="flex h-[480px] w-full overflow-hidden pl-2 pt-4">
      <div className="flex h-full basis-full flex-col space-y-6">
        <TableBaseSkeleton className="w-28" />
        <TableBaseSkeleton className="w-48" />
        <TableBaseSkeleton className="w-28" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-44 flex-col space-y-6 pl-3">
        <TableBaseSkeleton className="w-24" />
        <TableBaseSkeleton className="w-16" />
        <TableBaseSkeleton className="w-24" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-36 flex-col space-y-6 pl-3">
        <TableBaseSkeleton className="w-24" />
        <TableBaseSkeleton className="w-16" />
        <TableBaseSkeleton className="w-24" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-24 flex-col space-y-6 pl-3">
        <TableBaseSkeleton className="w-12" />
        <TableBaseSkeleton className="w-8" />
        <TableBaseSkeleton className="w-12" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-44 flex-col space-y-6 pl-3">
        <TableBaseSkeleton className="w-24" />
        <TableBaseSkeleton className="w-16" />
        <TableBaseSkeleton className="w-24" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-40 flex-col space-y-6 pl-3">
        <TableBaseSkeleton className="w-24" />
        <TableBaseSkeleton className="w-16" />
        <TableBaseSkeleton className="w-24" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-40 flex-col space-y-6 pl-3">
        <TableBaseSkeleton className="w-24" />
        <TableBaseSkeleton className="w-16" />
        <TableBaseSkeleton className="w-24" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-14 flex-col items-center space-y-3">
        <TableBaseSkeleton type="round" />
        <TableBaseSkeleton type="round" />
        <TableBaseSkeleton type="round" />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-14 flex-col items-center space-y-3">
        <TableBaseSkeleton type="round" />
        <TableBaseSkeleton type="round" />
        <TableBaseSkeleton type="round" />
      </div>
    </div>
  );
};
