import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const PhoneIcon: CreateIconType = createIcon({
  displayName: 'PhoneIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M7.99681 8.00001C6.99981 8.00001 5.99681 8.25301 5.50381 8.74701C5.33072 8.92014 5.23236 9.1542 5.2298 9.39901C5.22797 9.64244 5.13046 9.87538 4.95832 10.0475C4.78618 10.2197 4.55324 10.3172 4.3098 10.319L1.9768 10.329C1.73192 10.3259 1.49793 10.2272 1.32476 10.0541C1.15158 9.88088 1.05291 9.6469 1.0498 9.40201L1.0558 8.85601C1.0508 8.22001 1.2688 7.26001 1.6658 6.76401C2.0638 6.26801 2.6198 5.92401 3.2418 5.79001C4.80966 5.48672 6.40288 5.3337 7.9998 5.33301C9.5938 5.33301 11.1868 5.48601 12.7578 5.79001C13.3795 5.9237 13.9362 6.2677 14.3338 6.76401C14.7318 7.26001 14.9488 8.22001 14.9438 8.85601L14.9498 9.40201C14.9467 9.6469 14.848 9.88088 14.6749 10.0541C14.5017 10.2272 14.2677 10.3259 14.0228 10.329L11.6908 10.319C11.4475 10.3169 11.2147 10.2193 11.0426 10.0472C10.8705 9.87515 10.7729 9.64236 10.7708 9.39901C10.7683 9.1542 10.6699 8.92014 10.4968 8.74701C10.0028 8.25301 8.9998 8.00001 8.0028 8.00001"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  ),
});
