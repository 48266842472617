import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableRow } from '@askable/ui/components/ui/table';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { LabelGroup } from 'containers/Discover/components/LabelGroup';
import { ContactRound, MessageSquareQuote } from 'lucide-react';
import { ResultOf } from 'gql.tada';
import { DiscoverAnalysesInsightsQuery } from '../data/DiscoverAnalysesInsights.query';
import { cn } from '@askable/ui/lib/utils';

type Props = {
  selectedInsightId: string | null;
  insights: NonNullable<ResultOf<typeof DiscoverAnalysesInsightsQuery>['analysesInsights']['nodes']>;
  onInsightClick: (id: string) => void;
};

export const InsightsTable = ({ selectedInsightId, insights, onInsightClick }: Props) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  // Scroll screen to keep the selected row in view
  const scrollToRow = (rowId: string) => {
    const table = tableRef.current;

    if (table) {
      const row = (table as HTMLElement).querySelector(`tr[data-id="${rowId}"]`);
      if (row) {
        // Only scroll if the row is not in view
        const rect = row.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (!isInView) {
          row.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  useEffect(() => {
    if (selectedInsightId) {
      scrollToRow(selectedInsightId);
    }
  }, [selectedInsightId]);

  return (
    <div className="@container/table">
      {insights.length === 0 ? (
        <div className="flex items-center justify-center p-8">
          <div className="text-sm text-muted-foreground">{t('sections.discover.noInsights')}</div>
        </div>
      ) : (
        <Table ref={tableRef}>
          <TableBody className="border-t border-border">
            {insights.map((insight) => {
              if (!insight) {
                return null;
              }

              return (
                <TableRow
                  className={cn(
                    'flex cursor-pointer flex-wrap hover:bg-accent @2xl:!table-row',
                    selectedInsightId === insight._id ? 'bg-accent' : '',
                  )}
                  data-id={insight._id}
                  data-testid={insight._id}
                  key={insight._id}
                  onClick={() => onInsightClick(selectedInsightId === insight._id ? '' : insight._id)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      onInsightClick(insight._id);
                    }
                  }}
                  tabIndex={0}
                >
                  <TableCell className="h-full p-4 pb-0 @2xl:w-full @2xl:max-w-0 @2xl:py-4 2xl:px-6">
                    <div className="flex flex-col gap-1">
                      <p className="text-balance text-sm font-semibold @2xl:truncate" title={insight.title}>
                        {insight.title}
                      </p>
                      <p className="line-clamp-2 text-pretty text-xs leading-normal text-muted-foreground">
                        {insight.summary}
                      </p>
                    </div>
                  </TableCell>

                  <TableCell className="h-full p-4 text-xs 2xl:pr-6">
                    <div className="flex justify-end gap-3">
                      <LabelGroup themes={insight.themes} title={`${insight.themes.length} themes`} />
                      <Tooltip>
                        <TooltipTrigger className="flex items-center gap-1">
                          <MessageSquareQuote className="h-3 w-3" />
                          {insight.quote_count}
                        </TooltipTrigger>
                        <TooltipContent>{t('sections.discover.quotes')}</TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger className="flex items-center gap-1">
                          <ContactRound className="h-3 w-3" />
                          {insight.user_count}
                        </TooltipTrigger>
                        <TooltipContent>{t('sections.discover.interviews')}</TooltipContent>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
