// Email addresses from these domains cannot be used to join a team
const domainBlacklist = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'outlook.com',
  'comcast.net',
  'icloud.com',
  'msn.com',
  'hotmail.co.uk',
  'live.com',
  'yahoo.co.in',
  'me.com',
  'mail.ru',
  'bellsouth.net',
  'rediffmail.com',
  'yahoo.co.uk',
  'verizon.net',
  'ymail.com',
  'hotmail.it',
  'yahoo.com.tw',
  'mac.com',
  'live.se',
  'live.nl',
  'yahoo.com.br',
  'googlemail.com',
  'web.de',
  'allstate.com',
  'online.no',
  'yahoo.com.au',
  'live.dk',
  'earthlink.net',
  'yahoo.fr',
  'yahoo.it',
  'gmx.de',
  'hotmail.fr',
  'shawinc.com',
  'yahoo.de',
  '163.com',
  'bigpond.com',
  'rocketmail.com',
  'live.no',
  'yahoo.ca',
  'bigpond.net.au',
  'hotmail.se',
  'gmx.at',
  'live.co.uk',
  'mail.com',
  'yahoo.in',
  'yandex.ru',
  'qq.com',
  'charter.net',
  'alice.it',
  'hotmail.de',
  'wp.pl',
  'yahoo.es',
  'hotmail.no',
  'orange.fr',
  'live.it',
  'yahoo.co.id',
  'yahoo.no',
  'hotmail.es',
  'juno.com',
  'wanadoo.fr',
  'yahoo.se',
  'yahoo.com.hk',
  'live.com.au',
  'nic.in',
  'ubs.com',
  'uol.com.br',
  'shaw.ca',
  'yahoo.com.mx',
  'yahoo.com.sg',
  'yahoo.dk',
];

export { domainBlacklist };
