import { gql } from '@apollo/client';
import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';
import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export const bookingByIdFragment = gql`
  fragment bookingById on Booking {
    ...bookingFields
    total_amount(currency_code: $currency_code) {
      bookingPrice
      bookingPriceAfterTaxes
      bookingPriceTaxes
      creditsPerParticipant
      coupon {
        code
        discount_value
      }
    }
    config {
      contact {
        UserOrganiser(_booking_id: $id) {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
        UserFacilitator {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
      }
      recruitment {
        byo
        _client_share_link_id
        ClientShareLink {
          _id
          hits
          short_url
        }
      }
      participant_agreement {
        type
        _template_id
        recipients
        ESignatureTemplate {
          _id
          name
          provider {
            reference
          }
        }
      }
      countries
    }
    rating {
      overall
      worthwhile
      stress_free
    }
    team {
      ...teamFields
    }
    ParticipantSessionsCount {
      completed
      scheduled
      available
      invited
      waiting_list
      in_progress
      total
      total_eligible
      total_refunded
    }
    continuous
  }
  ${bookingFieldsFragment}
  ${teamFieldsFragment}
`;
