const settings = {
  stored: [],
  save(param: any) {
    settings.stored = param;
  },
  getAll() {
    return settings.stored;
  },
  getByKey(key: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return settings.stored.find((item) => item.key === key).value;
  },
};

export { settings };
