import { graphql } from 'src/graphql';

export const UsageTabCreditActivity = graphql(`
  query UsageTabCreditActivity($_team_id: ID!) {
    creditActivityByTeam(_team_id: $_team_id, type: [0, 1, 3, 4, 5, 6, 7, 8, 12]) {
      _id
      accounting_type
      created
      amount
      comment
      type
      User {
        _id
        displayName
      }
      Booking {
        _id
        name
        user {
          _id
          displayName
        }
      }
    }
  }
`);
