import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const VoiceActiveIcon: CreateIconType = createIcon({
  displayName: 'VoiceActiveIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M4.55176 5.70117V10.2989"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M1.10352 6.84961V9.14847"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.8965 5.70117V10.2989"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11.4482 3.40234V12.5978"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8 1.10352V14.8966"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
