import { gql } from '@apollo/client';
import { bookingByIdFragment } from 'data/fragments/booking/bookingById';

export default gql`
  subscription updateBookingSubscription($id: ID!, $currency_code: String) {
    bookingByID(id: $id) {
      ...bookingById
    }
  }
  ${bookingByIdFragment}
`;
