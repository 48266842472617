import { TeamSettingsSsoConnection } from 'generated/graphql';
import { ActiveConnection } from './ActiveConnection';

interface Props {
  connections: TeamSettingsSsoConnection[];
  handleSaveComplete: () => void;
  handleDeleteComplete: () => void;
}

export const ActiveConnections = ({ connections, handleDeleteComplete, handleSaveComplete }: Props) => {
  return (
    <div>
      {connections.map((connection) => {
        return (
          <ActiveConnection
            key={connection.name}
            handleDeleteComplete={handleDeleteComplete}
            connection={connection}
            handleSaveComplete={handleSaveComplete}
          />
        );
      })}
    </div>
  );
};
