import { graphql } from 'src/graphql';

export const ArchiveProjectMutation = graphql(`
  mutation archiveProject($project_id: ID!) {
    archiveProject(project_id: $project_id) {
      _id
      status
    }
  }
`);
