interface IResponse<T> {
  data?: T;
  error?: any;
}

export async function handleRequest<T>(
  fn: () => Promise<IResponse<T>>,
): Promise<[IResponse<T> | null, unknown | null]> {
  try {
    const result = await fn();
    if (result.error) {
      throw result.error;
    }
    return [result, null];
  } catch (e) {
    console.error(e);
    return [null, e];
  }
}
