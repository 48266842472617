import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useSettingsTeamQuery, TeamInvitations } from 'generated/graphql';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useAppContext } from 'components/common/Askable/Providers/appProvider';

import { Alert, AlertDescription } from '@askable/ui/components/ui/alert';
import { SettingsContainer } from '../SettingsContainer';
import { LoadingOverlay } from 'components/common';
import { TeamMembers } from './components/TeamMembers';
import { PendingInvitations } from './components/PendingInvitations';

import { teamUtils } from 'lib/teams';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
  recruitmentDescription: z.string().optional(),
  recruitmentTitle: z.string().min(1).max(255),
  teamName: z.string().min(1).max(255),
});

export const SettingsMembers = (props: any) => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const teamId = details?.team?.id;
  const isAdmin = teamUtils.isAdminByRoleId(details?.teamRole);
  const context = useAppContext();
  const {
    loading: loadingTeam,
    error,
    data,
    refetch,
  } = useSettingsTeamQuery({
    variables: {
      _id: teamId,
      isAdmin,
    },
    fetchPolicy: 'no-cache',
  });

  // @ts-expect-error types are ineffective when items in array are possibly null
  const invitations: TeamInvitations[] = data?.teamById?.TeamInvitations ?? [];

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      teamName: '',
      recruitmentTitle: 'Customer Feedback Program',
      recruitmentDescription: '',
    },
  });

  useEffect(() => {
    if (_.get(props.location, 'state.shouldOpenInviteTeammate')) {
      context.onOpenInviteTeamMemberModal();
    }
  }, []);

  useEffect(() => {
    form.setValue('teamName', _.get(data, 'teamById.name'));
    form.setValue('recruitmentTitle', _.get(data, 'teamById.settings.recruitment_page.title'));
    form.setValue('recruitmentDescription', _.get(data, 'teamById.settings.recruitment_page.blurb'));
  }, [data]);

  return (
    <SettingsContainer width="wide" title={t('sections.settings.team.members')}>
      {loadingTeam ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      {error ? (
        <Alert variant="destructive">
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      ) : null}

      <TeamMembers teamId={details?.ConnectedTeam?._id!} context={context} refetch={refetch} />
      <hr />
      <PendingInvitations invitations={invitations} fetching={loadingTeam} />
    </SettingsContainer>
  );
};
