import { gql } from '@apollo/client';

export default gql`
  query FetchTeamPaymentDetails($_id: ID) {
    teamById(_id: $_id) {
      _id
      settings {
        billing {
          registration
          company_name
          contact_first_name
          contact_last_name
          contact_email
          location {
            street1
            level
            city
            postal_code
            country
            state
          }
          stripe {
            customer_id
            payment(_id: $_id) {
              last4
              exp_month
              exp_year
            }
          }
          xero_id
        }
      }
    }
  }
`;
