import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { TickIcon } from '../Icons';

type Props = {
  status: number;
};

export const BOOKING_STATUS = {
  DRAFT: 0,
  ACTIVE: 1,
  PENDING_REVIEW: 2,
  IN_REVIEW: 3,
  REJECTED: 4,
  COMPLETED: 5,
  PENDING_PAYMENT: 6,
  ARCHIVED: 7,
};

export const BookingTag = ({ status }: Props): ReactElement | null => {
  switch (status) {
    case BOOKING_STATUS.DRAFT:
      return (
        <Tag colorScheme="gray" variant="subtle">
          Draft
        </Tag>
      );
    case BOOKING_STATUS.ACTIVE:
      return (
        <Tag colorScheme="green" variant="solid">
          Active
        </Tag>
      );
    case BOOKING_STATUS.PENDING_PAYMENT:
    case BOOKING_STATUS.PENDING_REVIEW:
    case BOOKING_STATUS.IN_REVIEW:
      return (
        <Tag colorScheme="yellow" variant="subtle">
          <TagLabel color="gray.800">In review</TagLabel>
        </Tag>
      );
    case BOOKING_STATUS.ARCHIVED:
      return (
        <Tag colorScheme="gray" variant="subtle">
          Archive
        </Tag>
      );
    case BOOKING_STATUS.REJECTED:
      return (
        <Tag colorScheme="gray" variant="subtle">
          Rejected
        </Tag>
      );
    case BOOKING_STATUS.COMPLETED:
      return (
        <Tag colorScheme="gray" variant="subtle">
          <TagLeftIcon as={TickIcon} color="green.500" />
          <TagLabel color="gray.800">Complete</TagLabel>
        </Tag>
      );
    default:
      return null;
  }
};
