import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const OverviewIcon: CreateIconType = createIcon({
  displayName: 'OverviewIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M1.33497 11.6066C1.50505 13.1963 2.78361 14.4749 4.37254 14.652C5.55273 14.7835 6.76449 14.8965 7.99995 14.8965C9.2354 14.8965 10.4472 14.7835 11.6273 14.652C13.2163 14.4749 14.4948 13.1963 14.6649 11.6066C14.7905 10.4331 14.8965 9.22825 14.8965 7.99995C14.8965 6.77163 14.7905 5.56682 14.6649 4.39329C14.4948 2.80361 13.2163 1.52503 11.6273 1.34793C10.4472 1.21638 9.2354 1.10339 7.99995 1.10339C6.76449 1.10339 5.55273 1.21638 4.37254 1.34793C2.78361 1.52503 1.50505 2.80361 1.33497 4.39329C1.20942 5.56682 1.10339 6.77163 1.10339 7.99995C1.10339 9.22825 1.20942 10.4331 1.33497 11.6066Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />,
    <path
      d="M4.69617 4.24988H6.61429"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M4.69617 7.19714H7.99995"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11.8274 8.63928L9.98705 11.3289L7.37005 10.0536L5.6687 12.161"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
