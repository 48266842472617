import React, { useState } from 'react';
import _ from 'lodash';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  VStack,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  FormControl,
  FormLabel,
  Button,
} from 'ui';
import { LoadingOverlay, BoxMessage } from 'components/common';

// Mutations

// Styles
import './styles/newCreditCardStyles.scss';
import fetchTeamPaymentDetails from 'data/queries/teams/fetchTeamPaymentDetails';
import { useMutation } from '@apollo/client';
import { UpdateTeamPaymentMethod } from './data/UpdateTeamPaymentMethod.mutation';

function ModalNewCreditCard(props: any) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [creditCardCompletedStatus, setCreditCardCompletedStatus] = useState(false);
  const initialRef = React.useRef(null);
  const stripe = useStripe();
  const elements = useElements();

  const [updateTeamPaymentMethod] = useMutation(UpdateTeamPaymentMethod);

  // Get the token from Stripe for the credit card and pass it along the new updateTeamPaymentMethod mutation
  const onUpdateCreditCard = async () => {
    setLoading(true);
    setError('');

    try {
      const cardElement = elements?.getElement(CardElement);

      if (cardElement) {
        const token = await stripe?.createToken(cardElement);

        await updateTeamPaymentMethod({
          variables: {
            _id: props.team_id,
            token: token?.token?.id || '',
          },
          refetchQueries: [
            {
              query: fetchTeamPaymentDetails,
              variables: {
                _id: props.team_id,
              },
            },
          ],
        });

        setLoading(false);
        setCreditCardCompletedStatus(false);
        props.onClose();
      }
    } catch (e: any) {
      setLoading(false);
      setError(_.get(e, 'graphQLErrors[0]'));
    }
  };

  return (
    <Modal isOpen={props.open} onClose={props.onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update credit card</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Card details</FormLabel>
              <Box rounded="md" border="1px solid" borderColor="inherit" h="10" p="2" pt="10px">
                <CardElement
                  id="__update-card"
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',
                        color: 'rgb(68, 68, 68)',
                        '::placeholder': {
                          color: 'rgba(0, 0, 0, 0.3)',
                        },
                      },
                    },
                  }}
                  onChange={(value: any) => setCreditCardCompletedStatus(value.complete)}
                />
              </Box>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            id="__btnSave"
            w="full"
            type="submit"
            colorScheme="brand"
            onClick={onUpdateCreditCard}
            disabled={!creditCardCompletedStatus}
          >
            Update credit card
          </Button>
        </ModalFooter>
      </ModalContent>
      {error && (
        <BoxMessage type="error mbottom0" error={error}>
          <p className="error">{_.get(error, 'message')}</p>
        </BoxMessage>
      )}
    </Modal>
  );
}

export default ModalNewCreditCard;
