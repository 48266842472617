import { gql } from '@apollo/client';

export default gql`
  query FetchUserByEmail($email: String!) {
    userByEmail(email: $email) {
      _id
      email
      _default_team
      type {
        client
        participant
        researcher
      }
      meta {
        identity {
          firstname
          lastname
        }
        social {
          google {
            id
          }
        }
        acquisition {
          source
          campaign
          medium
        }
      }
      email_verified
    }
  }
`;
