import { useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { Button } from '@askable/ui/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@askable/ui/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/components/ui/popover';
import countryCodeData from 'lib/data/phoneCountryCodes.json';

type Country = (typeof countryCodeData)[number];
const DEFAULT_COUNTRY: Country = countryCodeData.find((country) => country.region === 'AU')!;

type Props = {
  currentRegion?: string;
  onChange: (country: Country) => void;
};
export default function CountryCodeSelect({ currentRegion, onChange }: Props) {
  const [open, setOpen] = useState(false);

  const _current = useMemo(
    () => countryCodeData.find((country) => country.region === currentRegion) || DEFAULT_COUNTRY,
    [currentRegion],
  );

  const getCountryFromName = (name: string) =>
    countryCodeData.find((country) => country.name.toLowerCase() === name.toLowerCase());

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" role="combobox" aria-expanded={open} className="h-full px-2 pr-1">
          <img
            id="countryFlag"
            className="h-5 w-5"
            src={`https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/country_flags/${_current.region.toLowerCase()}.svg`}
            alt={`flag for ${_current?.region}`}
          />
          <ChevronDown className="h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[1401] p-0" align="start" side="bottom">
        <Command>
          <CommandInput placeholder="Filter countries" noBorder />
          <CommandEmpty>No country found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-y-scroll">
            {countryCodeData.map((country) => (
              <CommandItem
                className="cursor-pointer gap-2"
                key={country.name}
                value={country.name}
                onSelect={(currentValue) => {
                  const currentCountry = getCountryFromName(currentValue);
                  onChange(currentCountry || DEFAULT_COUNTRY);
                  setOpen(false);
                }}
              >
                <img
                  id="countryFlag"
                  className="h-5 w-5"
                  src={`https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/country_flags/${country.region.toLowerCase()}.svg`}
                  alt="flag"
                />
                <p className="text-xs text-gray-700">
                  {country.name} ({country.country_code})
                </p>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
