import { ActiveConnection } from './ActiveConnection';

interface Props {
  domains: string;
}

export const LegacySSOConnection = ({ domains }: Props) => {
  const noop = () => null;

  return (
    <ActiveConnection
      handleSaveComplete={noop}
      handleDeleteComplete={noop}
      connection={{
        _id: 'legacy',
        name: 'Legacy SSO connection',
        connection_id: 'legacy',
        enabled: true,
        options: {
          home_realm_domains: [domains],
        },
        strategy: 'legacy',
      }}
    />
  );
};
