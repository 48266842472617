import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const CameraIcon: CreateIconType = createIcon({
  displayName: 'CameraIcon',
  defaultProps: defaultOptions,
  path: (
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M10.1099 10.1018C10.0575 11.0661 9.29537 11.836 8.33129 11.8924C7.40404 11.9467 6.44821 11.975 5.47151 11.975C4.49481 11.975 3.53897 11.9467 2.61172 11.8924C1.64763 11.836 0.885512 11.0661 0.833131 10.1018C0.778558 9.09715 0.75 8.06027 0.75 7.00006C0.75 5.93983 0.778559 4.90293 0.833134 3.89827C0.885516 2.93396 1.64764 2.16405 2.61172 2.10763C3.53897 2.05338 4.49481 2.02502 5.47151 2.02502C6.44821 2.02502 7.40404 2.05338 8.33129 2.10763C9.29537 2.16404 10.0575 2.93396 10.1099 3.89826C10.1271 4.21579 10.1418 4.53653 10.1537 4.86021L13.25 3.48409V10.5226L10.1535 9.14639C10.1416 9.46786 10.127 9.78642 10.1099 10.1018Z"
    />
  ),
});
