import { gql } from '@apollo/client';

export const messagesFieldsFragment = gql`
  fragment messagesFields on Messages {
    _id
    type
    body
    seen
    created
    _to_user_id
    _from_user_id
    direction
  }
`;
