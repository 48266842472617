import { createIcon } from '@chakra-ui/icons';

import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const TeamIcon: CreateIconType = createIcon({
  displayName: 'TeamIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M2.704 5.1a3.29 3.29 0 116.58 0 3.29 3.29 0 01-6.58 0zm6.725 2.817c-.11.121-.097.313.043.395a2.92 2.92 0 101.057-5.393c-.161.024-.245.198-.189.351a4.62 4.62 0 01-.91 4.647h-.001zm2.989 6.01c-.057.12.025.263.158.263h.6c.945 0 1.731-.507 2.07-1.212.18-.365.22-.782.116-1.174-.112-.41-.382-.767-.789-1.038a7.605 7.605 0 00-3.204-1.21c-.211-.028-.295.25-.118.368.659.439 1.161 1.067 1.374 1.847.194.72.12 1.486-.207 2.156zM5.993 9.22a8.133 8.133 0 00-4.524 1.366c-.428.286-.713.662-.83 1.092a1.77 1.77 0 00.124 1.231c.357.744 1.185 1.281 2.184 1.281H9.04c.998 0 1.827-.537 2.184-1.281.18-.374.24-.807.123-1.23-.117-.43-.401-.808-.83-1.093A8.133 8.133 0 005.993 9.22z"
      fill="currentColor"
    />,
  ],
});
