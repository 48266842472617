// @ts-expect-error ts-migrate(2339) FIXME: Property 'gapi' does not exist on type 'Window & t... Remove this comment to see the full error message
const { gapi } = window;
const client_id = '86041926868-d726jum3k0dnoj99408a2atf5d697464.apps.googleusercontent.com';

const oauth = {
  init(onSuccessSignIn?: any) {
    gapi?.load('auth2', () => {
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      const params = {
        client_id,
        cookie_policy: 'single_host_origin',
      };
      gapi?.load('auth2', () => {
        if (!gapi.auth2.getAuthInstance()) {
          gapi.auth2.init(params).then((res: any) => {
            if (res.isSignedIn.get() && onSuccessSignIn) {
              onSuccessSignIn(this.parseResponse(res.currentUser.get()));
            }
          });
        }
      });
    });
  },
  parseResponse(res: any) {
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse();
    const newObj = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'googleId' does not exist on type '{}'.
    newObj.googleId = basicProfile.getId();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'tokenObj' does not exist on type '{}'.
    newObj.tokenObj = authResponse;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'tokenId' does not exist on type '{}'.
    newObj.tokenId = authResponse.id_token;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'accessToken' does not exist on type '{}'... Remove this comment to see the full error message
    newObj.accessToken = authResponse.access_token;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'profile' does not exist on type '{}'.
    newObj.profile = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      firstname: basicProfile.getGivenName(),
      lastname: basicProfile.getFamilyName(),
    };
    return newObj;
  },
  signIn(onSuccessSignIn: any) {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signIn({}).then((res: any) => {
      onSuccessSignIn(this.parseResponse(res));
    });
  },
  signOut(onSuccessSignOut: any) {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => onSuccessSignOut());
  },
};

export { oauth };
