import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const EllipsesHorizontalIcon: CreateIconType = createIcon({
  displayName: 'EllipsesHorizontalIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      fill="currentColor"
      d="M13.393 6.313c-1.097 0-1.714.617-1.714 1.714s.617 1.714 1.714 1.714 1.714-.617 1.714-1.714-.617-1.714-1.714-1.714zM8 6.313c-1.097 0-1.714.617-1.714 1.714S6.903 9.74 8 9.74s1.714-.617 1.714-1.714S9.097 6.313 8 6.313zM2.607 6.313C1.51 6.313.893 6.93.893 8.027S1.51 9.74 2.607 9.74s1.715-.617 1.715-1.714-.618-1.714-1.715-1.714z"
    />
  ),
});
