import { gql } from '@apollo/client';

export default gql`
  mutation changeOrganiserContact($booking_id: ID!, $_organiser_user_id: ID!) {
    changeOrganiserContact(booking_id: $booking_id, _organiser_user_id: $_organiser_user_id) {
      _id
      config {
        contact {
          _organiser_user_id
          _facilitator_user_id
          UserOrganiser(_booking_id: $booking_id) {
            _id
            email
            meta {
              identity {
                firstname
                lastname
              }
            }
            contact {
              phone {
                country_code
                mobile
              }
            }
          }
          UserFacilitator {
            _id
            email
            meta {
              identity {
                firstname
                lastname
              }
            }
            contact {
              phone {
                country_code
                mobile
              }
            }
          }
        }
      }
    }
  }
`;
