import PriceCardContainer from '../components/priceCardContainer';
import { AppContextType } from 'components/common/Askable/Providers/appProvider';
import { Booking } from 'generated/graphql';

type Props = {
  booking: Booking;
  bookingSteps: Booking['steps'];
  context: AppContextType;
};
const PriceCardRightContent = ({ context, booking, bookingSteps }: Props) => {
  return (
    <>
      <p className="cardContainerTitle">Pricing</p>
      <PriceCardContainer booking={booking} bookingSteps={bookingSteps} context={context} condensedCard />
    </>
  );
};

export default PriceCardRightContent;
