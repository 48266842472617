import type { ComponentStyleConfig } from '@chakra-ui/react';

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
export const Alert: ComponentStyleConfig = {
  parts: ['container', 'description', 'title', 'icon'],
  baseStyle: {
    icon: {
      color: 'inherit',
    },
    container: {
      borderRadius: 'md',
      alignItems: 'top',
    },
    title: {
      fontWeight: 'semibold',
    },
  },
};
