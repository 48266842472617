import { useEffect, useRef, useState } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useIntercom } from 'react-use-intercom';
import _ from 'lodash';
import { bookingUtils } from 'lib/booking';
import { X } from 'lucide-react';

// External components
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import CardContainer from 'components/createBooking/components/cardContainer';
import { findNextRoute } from 'utils/booking-utils';
import { QuotaInput, QuotaInputRef } from 'components/askablePlus/components/QuotaInput';

function Quota(props: any) {
  const quotaInputRef = useRef<QuotaInputRef>(null);
  const intercom = useIntercom();

  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'bookingSteps'));
  const [bookingData, setBookingData] = useState(null);
  const [showReplaceRefundTip, setShowReplaceRefundTip] = useState(true);

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/quota`,
      stepId: 'project_setup_quota',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    setBookingSteps(_.get(props, 'bookingSteps'));
  }, [props.bookingSteps]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    if (!quotaInputRef.current?.form.getValues('quota')) {
      quotaInputRef.current?.form.setError('quota', { message: 'At least one participant is required' });
    }
  }, [booking, bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      { cardText: 'Credits are recruitment fees, incentives and software costs bundled into one easy price.' },
    ];
    const cardTexts2 = [{ cardText: 'Our legendary sales team can get you whatever you need!' }];
    const cardTexts3 = [
      {
        cardText: (
          <span>
            Yes, you can increase the quota if you need later. On the flip side, if you decide to close your study
            early, you&apos;ll be given a full credit refund for any unused participants.
          </span>
        ),
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__quotaCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="What is a credit?"
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open('https://help.askable.com/en/articles/4254373-ways-of-buying-credits', '_blank');
          }}
        />
        <CardContainer
          additionalCard
          cardId="__quotaCard2"
          cardClass="slideInAnimationDelay160"
          cardTitle="Need a PDF quote?"
          cardTexts={cardTexts2}
          onLinkClick={() => {
            intercom.showNewMessages();
          }}
          linkText="Chat to us"
          hideArrow
        />
        <CardContainer
          additionalCard
          cardId="__quotaCard3"
          cardClass="slideInAnimationDelay240"
          cardTitle="Can I change the quota once the study is live?"
          cardTexts={cardTexts3}
          onLinkClick={() => {
            window.open('https://help.askable.com/en/articles/4257979-how-to-edit-extend-your-booking', '_blank');
          }}
        />
      </>
    );
  };

  const onSetNewQuota = (value: number) => {
    const bookingObj = { total_participants: value };
    const shouldUpdateBookingName =
      booking.name.includes(' participants') && booking.name.includes(_.get(props, 'booking.team.name'));
    if (shouldUpdateBookingName) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{ total_pa... Remove this comment to see the full error message
      bookingObj.name = `${_.get(props, 'booking.team.name')} - ${value} participants`;
    }

    if (bookingUtils.isOnlineTask(bookingState) || bookingUtils.isLongitudinal(bookingState)) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'config' does not exist on type '{ total_... Remove this comment to see the full error message
      bookingObj.config = {
        session: {
          slot_min: value,
          slot_max: value,
        },
      };
    }
    const bookingStateObj = {
      ...bookingState,
      ...bookingObj,
      config: {
        ...bookingState.config,
        session: {
          ...bookingState.config.session,
          ..._.get(bookingObj, 'config.session'),
        },
      },
    };
    setBookingState(bookingStateObj);

    if (
      (_.get(bookingSteps, 'project_setup_quota') === 'error' && value) ||
      (_.get(bookingSteps, 'project_setup_quota') === 'seen' && !value)
    ) {
      props.validateBooking(bookingStateObj, document.location.pathname, true);
    }

    if (value !== _.get(booking, 'total_participants')) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ total_participants: number; }'... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onQuotaChange = (value: number) => {
    onSetNewQuota(value);
  };

  const onClickNext = () => {
    let redirectTo = findNextRoute(booking, 'Quota', 'Project Setup');

    // let redirectTo = `/booking-setup/${booking._id}/project-setup/session-format`;
    if (bookingUtils.isAiModeration(booking)) {
      redirectTo = `/booking-setup/${booking._id}/project-setup/participant-selection`;
    } else if (bookingUtils.isOnlineTask(booking)) {
      redirectTo = `/booking-setup/${booking._id}/project-setup/task-duration`;
    } else if (bookingUtils.isLongitudinal(booking)) {
      redirectTo = `/booking-setup/${booking._id}/project-setup/study-duration`;
    }

    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <QuotaInput
        title="Quota"
        description="How many participants do you need?"
        onClickNext={onClickNext}
        ref={quotaInputRef}
        onQuotaValueChange={onQuotaChange}
        defaultValues={{ quota: _.get(booking, 'total_participants') }}
      />

      {showReplaceRefundTip && (
        <div className="additionalInfoContainer flipInAnimation">
          <img className="additionalInfoImage" src="/booking/illustrations/personWithFlag.svg" alt="illustration" />
          <div className="additionalInfoContent">
            <p className="additionalInfoTitle">Replace / Refund guarantee</p>
            <p className="additionalInfoText">
              If for any reason you&apos;re not happy with a participant, we guarantee a replacement at no extra cost or
              a full credit refund for that person.
            </p>
          </div>
          <X
            className="closeIcon"
            onClick={() => {
              setShowReplaceRefundTip(false);
            }}
            style={{ width: 20, height: 20 }}
          />
        </div>
      )}
    </div>
  );
}

export default deprecatedWithRouter(Quota);
