import { FC, useEffect } from 'react';
import { Flex, Text } from 'ui';

type Props = {
  unread: number;
  subscribeToNewUnreadNotifications: () => void;
};

const NotificationsBellContainer: FC<Props> = ({ subscribeToNewUnreadNotifications, unread }) => {
  useEffect(() => {
    subscribeToNewUnreadNotifications();
  }, []);

  const numberOfUnreadNotifications = unread;

  if (!numberOfUnreadNotifications) return null;

  const badgeMessage = numberOfUnreadNotifications >= 10 ? '9+' : numberOfUnreadNotifications;

  return (
    <Flex
      backgroundColor="brand.500"
      rounded="full"
      h="17px"
      w="17px"
      borderWidth="1px"
      borderColor="white"
      top={1}
      left={1}
      position="absolute"
      justifyContent="center"
      alignItems="center"
      userSelect="none"
      pointerEvents="none"
      textAlign="center"
    >
      <Text fontSize="xx-small" fontWeight="bold" color="white">
        {badgeMessage}
      </Text>
    </Flex>
  );
};

export default NotificationsBellContainer;
