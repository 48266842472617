import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ExpandIcon: CreateIconType = createIcon({
  displayName: 'ExpandIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M5.768.748H1.305a.558.558 0 00-.557.557v4.463m14.504 0V1.305a.558.558 0 00-.557-.557h-4.463m0 14.504h4.463a.557.557 0 00.557-.557v-4.463m-14.504 0v4.463a.558.558 0 00.557.557h4.463"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
