import { gql } from '@apollo/client';

export default gql`
  query FetchBookingsByTeam(
    $_team_id: ID
    $status: [Int]
    $sortBy: String
    $order: Int
    $excludeDemoBookings: Boolean
    $excludeBookingsWithNoQuestions: Boolean
    $excludeAskablePlusBookings: Boolean
  ) {
    bookingsByTeam(
      _team_id: $_team_id
      status: $status
      sortBy: $sortBy
      order: $order
      excludeDemoBookings: $excludeDemoBookings
      excludeBookingsWithNoQuestions: $excludeBookingsWithNoQuestions
      excludeAskablePlusBookings: $excludeAskablePlusBookings
    ) {
      _id
      name
      status
      created
      type
      total_participants
      config {
        demo
        question {
          _id
          title
          description
          config {
            display_participant
            multiple_selection
            none_of_the_above
            type
          }
          options {
            _id
            label
            screen_in
          }
        }
        recruitment {
          byo
        }
        online_task {
          type
        }
        project {
          project_type
        }
      }
      session {
        _id
        start
      }
      user {
        _id
        email
        meta {
          identity {
            firstname
            lastname
            middlename
            gender
          }
          social {
            google {
              id
            }
          }
          work {
            title
            company_name
          }
        }
      }
      ParticipantSessions(status: [1], cancel: [0]) {
        _id
        cancel
        no_show_request
        issue_request
        status
      }
      rating {
        overall
        worthwhile
        stress_free
        participant_quality
        customer_support_quality
        product_quality
      }
    }
  }
`;
