import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const InProgressHalfIcon: CreateIconType = createIcon({
  displayName: 'InProgressHalfIcon',
  defaultProps: {
    ...defaultOptions,
    color: 'yellow.500',
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="currentColor"
    />,
    <path
      // eslint-disable-next-line max-len
      d="M8.55272 11.9692C10.5264 11.7236 12.0537 10.0402 12.0537 8C12.0537 5.95985 10.5264 4.27643 8.55272 4.03082C8.27869 3.99672 8.05371 4.22386 8.05371 4.5V11.5C8.05371 11.7761 8.27869 12.0033 8.55272 11.9692Z"
      fill="currentColor"
    />,
  ],
});
