import { gql } from '@apollo/client';
import { manageBookingPageCalendarFragment } from 'data/fragments/booking/manageBookingPageCalendar';

export default gql`
  query FetchBookingSessionsWithParticipants($booking_id: ID!) {
    bookingSessionsWithParticipants(booking_id: $booking_id) {
      ...ManageBookingPageCalendar
    }
  }
  ${manageBookingPageCalendarFragment}
`;
