import { useTranslation } from 'react-i18next';
import { ResultOf } from 'gql.tada';
import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { FacilitatorList } from 'containers/Discover/components/FacilitatorList';
import { ContactRound } from 'lucide-react';
import { AnalysesMethod } from 'generated/graphql';
import { GetCountsQueries } from './data/GetInterviewCounts.query';
import type { FilterDateProps } from 'containers/Discover/DiscoverContainer';
import { Card, CardContent, CardHeader, CardTitle } from '@askable/ui/components/ui/card';

export type Interview = {
  [key: string]: {
    human: number;
    ai: number;
  };
};

type AnalysesInterviewsProps = {
  fetching: boolean;
  filterDate: FilterDateProps;
  interviews?: ResultOf<typeof GetCountsQueries>;
};

export const AnalysesInterviews = ({ interviews, fetching, filterDate }: AnalysesInterviewsProps) => {
  const { t } = useTranslation();

  const getCount = (method: AnalysesMethod) => {
    return (interviews?.analysesCount?.find(({ method: m }) => m === method)?.count as number) || 0;
  };

  const facilitators = (interviews?.analysesCountByFacilitator || []).map(({ counts, facilitator }) => ({
    id: facilitator._id,
    firstName: facilitator.first_name || '',
    lastName: facilitator.last_name || '',
    pictureLink: facilitator.picture_link || '',
    counts: counts as { method: AnalysesMethod; count: number }[],
  }));

  return (
    <Card className="flex flex-col gap-4 @container">
      <CardHeader>
        <div className="flex h-full flex-wrap items-center justify-between">
          <div className="flex items-center gap-2">
            <CardTitle>{t('sections.discover.interviews')}</CardTitle>
            {fetching ? (
              <Skeleton className="aspect-square h-6 w-6 rounded-full" />
            ) : (
              <FacilitatorList facilitators={facilitators} />
            )}
          </div>
          <ContactRound className="h-6 w-6 text-muted-foreground" />
        </div>
      </CardHeader>
      <CardContent className="flex h-full flex-col">
        {fetching ? (
          <div className="item flex h-full flex-col justify-center">
            <div className="grid grid-cols-1 gap-4 @sm:grid-cols-2">
              <div className="flex flex-col gap-2">
                <Skeleton className="h-6 w-4/5" />
                <Skeleton className="h-4 w-3/5" />
              </div>
              <div className="flex flex-col gap-2">
                <Skeleton className="h-6 w-4/5" />
                <Skeleton className="h-4 w-3/5" />
              </div>
            </div>
          </div>
        ) : (
          <div className="item flex h-full flex-col justify-center">
            <div className="grid grid-cols-1 gap-4 @sm:grid-cols-2">
              <div className="flex flex-col gap-1">
                <h4 className="text-base font-bold leading-tight lg:text-xl">
                  {t('sections.discover.humanModerated', { count: getCount(AnalysesMethod.Moderated) })}
                </h4>

                <div className="text-sm text-muted-foreground">
                  {filterDate.days > 0
                    ? t('sections.discover.inTheLastXDays', { count: Number(filterDate.days) })
                    : t('sections.discover.fromAllTime')}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <h4 className="text-base font-bold leading-tight lg:text-xl">
                  {t('sections.discover.aiModerated', { count: getCount(AnalysesMethod.AiModerated) })}
                </h4>
                <div className="text-sm text-muted-foreground">
                  {filterDate.days > 0
                    ? t('sections.discover.inTheLastXDays', { count: Number(filterDate.days) })
                    : t('sections.discover.fromAllTime')}
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
