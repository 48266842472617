import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const MicrophoneOffIcon: CreateIconType = createIcon({
  displayName: 'MicrophoneOffIcon',
  defaultProps: defaultOptions,
  path: [
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M6.99304 11.3713V13.25" />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M4.31485 7.21167C4.60415 7.89891 5.11015 8.43484 5.97925 8.65902"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.36036 7.06946C1.73957 9.46845 3.82421 11.3716 6.96806 11.3716C7.40569 11.3716 7.82029 11.3393 8.21082 11.2776"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5024 7.06958C12.3993 8.4231 11.7202 9.61612 10.5485 10.4004"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.9209 12.3106C7.91371 8.40165 5.40366 5.86452 1.36034 0.749999"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M8.38405 8.49551C10.2858 7.61853 9.94174 4.85832 9.89509 3.00215C9.86204 1.6869 8.50614 0.770508 6.96793 0.770508C5.42973 0.770508 4.03727 1.6869 4.00422 3.00215C3.99689 3.29388 3.98221 3.60795 3.97006 3.93353"
    />,
  ],
});
