import { gql } from '@apollo/client';

export default gql`
  query Settings {
    settings {
      _id
      key
      value
    }
  }
`;
