import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ScreenShareOffIcon: CreateIconType = createIcon({
  displayName: 'ScreenShareOffIcon',
  defaultProps: defaultOptions,
  path: [
    <path
      stroke="currentColor"
      d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284C5.92445 13.2749 8.07554 13.2749 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.1539 9.20499 13.25 8.11313 13.25 6.99997C13.25 5.88682 13.1539 4.79496 13.0401 3.73145C12.886 2.2908 11.7273 1.13209 10.2873 0.971585C8.07554 0.725048 5.92445 0.725048 3.71266 0.971585C2.2727 1.13209 1.114 2.2908 0.959867 3.73145C0.846083 4.79496 0.75 5.88682 0.75 6.99997C0.75 8.11313 0.846084 9.20499 0.959867 10.2685Z"
    />,
    <path stroke="currentColor" strokeLinecap="round" d="M9.25256 4.7478L4.74817 9.2522" />,
    <path stroke="currentColor" strokeLinecap="round" d="M9.25256 9.2522L4.74817 4.7478" />,
  ],
});
