import { gql } from '@apollo/client';
import { bookingByIdFragment } from 'data/fragments/booking/bookingById';

export default gql`
  query FetchBookingById($id: ID, $currency_code: String) {
    bookingByID(id: $id) {
      ...bookingById
      project {
        type
      }
    }
  }
  ${bookingByIdFragment}
`;
