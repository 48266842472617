import { forwardRef } from 'react';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import { Badge, BadgeProps } from './ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';
import { cn } from '../lib/utils';

type Props = BadgeProps & {
  align?: TooltipContentProps['align'];
  title?: string;
};

export const BetaBadge = forwardRef<HTMLDivElement, Props>(
  ({ align = 'center', className, title = 'Beta', ...props }, ref) => {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge {...props} className={cn(className)} ref={ref}>
            {title}
          </Badge>
        </TooltipTrigger>
        <TooltipContent side="bottom" align={align}>
          <p className="max-w-[288px]">This feature is available for early adopters and may change</p>
        </TooltipContent>
      </Tooltip>
    );
  },
);
