import { cn } from '@askable/ui/lib/utils';

type Props = {
  className?: string;
  color: string;
  text: string;
};

export const OutlineBadge = ({ color, text, className }: Props) => (
  <div
    className={cn(
      'flex items-center gap-1 whitespace-nowrap rounded-md border border-border px-2 py-1 leading-tight',
      className,
    )}
  >
    <div className="aspect-square h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
    {text}
  </div>
);
