import { graphql } from 'src/graphql';

export const UpdateSSOConnection = graphql(`
  mutation UpdateSSOConnection(
    $teamId: ID!
    $connectionId: String!
    $isEnabled: Boolean!
    $options: TeamSettingsSsoConnectionOptionsInput
  ) {
    teamUpdateSsoConnection(teamId: $teamId, connectionId: $connectionId, isEnabled: $isEnabled, options: $options) {
      name
      connection_id
      enabled
      strategy
      options {
        home_realm_domains
        saml_custom {
          saml_entity_id
          saml_idp_metadata_url
          saml_email_key_attr
        }
        azure_ad {
          client_id
          client_secret
          entra_id_domain
          is_use_common_endpoint
        }
      }
      connection_id
    }
  }
`);
