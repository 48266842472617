import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const RefreshIcon: CreateIconType = createIcon({
  displayName: 'RefreshIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M14.1794 9.15625C13.9178 10.549 13.1935 11.8126 12.1237 12.7421C11.054 13.6716 9.70164 14.2124 8.28598 14.277C6.87032 14.3416 5.47436 13.926 4.32446 13.0978C3.17456 12.2695 2.33815 11.0771 1.95087 9.71396"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M1.82056 6.84213C2.08215 5.44935 2.80655 4.18577 3.87628 3.25629C4.94601 2.32682 6.29836 1.78594 7.71402 1.72138C9.12968 1.65681 10.5256 2.07234 11.6755 2.90059C12.8254 3.72884 13.6618 4.92123 14.0491 6.28441"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M0.778282 4.64844C0.761139 5.55244 1.10971 6.28272 1.82171 6.84044C2.72685 6.85758 3.45828 6.51015 4.01485 5.79701"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M15.2251 11.3489C15.2434 10.4449 14.896 9.71457 14.1829 9.15685C13.2777 9.13971 12.5463 9.48714 11.9897 10.2003"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
