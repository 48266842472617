import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const CollectionIcon: CreateIconType = createIcon({
  displayName: 'CollectionIcon',

  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M1 3.455a18.56 18.56 0 01.055-1.52c.039-.472.607-.864 1.404-.902C2.848 1.015 3.357 1 4 1c.514-.001 1.028.01 1.541.033.797.038 1.365.43 1.405.902.028.343.054.843.054 1.52s-.026 2.268-.054 2.61c-.04.472-.608.864-1.405.902-.388.019-.898.033-1.54.033-.644 0-1.153-.014-1.542-.033-.796-.038-1.365-.43-1.404-.902C1.027 5.723 1 4.132 1 3.455zM1 11.455a18.56 18.56 0 01.055-1.52c.039-.472.607-.864 1.404-.902C2.848 9.015 3.357 9 4 9c.514-.001 1.028.01 1.541.033.797.038 1.365.43 1.405.902.028.343.054.843.054 1.52s-.026 2.268-.054 2.61c-.04.472-.608.864-1.405.902-.388.018-.898.033-1.54.033a32.32 32.32 0 01-1.542-.033c-.796-.038-1.365-.43-1.404-.902-.028-.342-.055-1.933-.055-2.61zM9 3.455c-.002-.507.016-1.014.055-1.52.039-.472.607-.864 1.404-.902C10.848 1.015 11.357 1 12 1c.514-.001 1.028.01 1.541.033.797.038 1.365.43 1.404.902.029.343.055.843.055 1.52s-.026 2.268-.055 2.61c-.039.472-.607.864-1.404.902-.388.019-.898.033-1.54.033-.644 0-1.153-.014-1.542-.033-.796-.038-1.365-.43-1.404-.902C9.027 5.723 9 4.132 9 3.455zM9 11.455c-.002-.507.016-1.014.055-1.52.039-.472.607-.864 1.404-.902C10.848 9.015 11.357 9 12 9c.514-.001 1.028.01 1.541.033.797.038 1.365.43 1.404.902.029.343.055.843.055 1.52s-.026 2.268-.055 2.61c-.039.472-.607.864-1.404.902-.388.018-.898.033-1.54.033-.644 0-1.153-.014-1.542-.033-.796-.038-1.365-.43-1.404-.902-.028-.342-.055-1.933-.055-2.61z"
      fill="currentColor"
    />
  ),
});
