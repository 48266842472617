import { graphql } from 'src/graphql';

export const QuotesByAnalysesIds = graphql(`
  query QuotesByAnalysesIds($_ids: [ID!]!) {
    analyses(first: 50, filter: { _id: { in: $_ids } }) {
      nodes {
        created
        method
        video_name
        video_started
        participant {
          _id
          first_name
          last_name
        }
        facilitator {
          _id
          picture_link
          first_name
          last_name
        }
        quotes {
          start_time
          text
        }
      }
    }
  }
`);
