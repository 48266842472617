import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { ChevronLeft } from 'lucide-react';
import { Progress } from '@askable/ui/components/ui/progress';
import { Button } from '@askable/ui/components/ui/button';

function ResearchPlan(props: any) {
  const onClickNext = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/match-to-a-researcher' });
  };

  const onClickBack = () => {
    props.history.push({ pathname: '/askable-plus/onboarding/submit-online' });
  };

  return (
    <>
      <div className="headerContainer">
        <div className="headerBack">
          <ChevronLeft className="backIcon h-7 w-7" onClick={onClickBack} />
        </div>
      </div>
      <div className="askablePlusOnboardingContent">
        <div className="contentDataContainer">
          <div className="contentTextData">
            <span className="textTitle width350 font--title1">Create an Askable+ research plan</span>
            <span className="descriptionText">
              Once your study is submitted, you&apos;ll receive a research plan template to collect all of the specifics
              for your Askable+ project.
            </span>
          </div>
          <div className="contentImageData">
            <div className="illustrationContainer">
              <img
                className="illustration"
                src="/askablePlus/illustrations/personWithResearchPlan.png"
                alt="personWithResearchPlan"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <Progress steps={7} currentStep={3} />

        <div className="buttonContainer pr-4">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default deprecatedWithRouter(ResearchPlan);
