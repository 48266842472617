import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const HangUpIcon: CreateIconType = createIcon({
  displayName: 'HangUpIcon',
  defaultProps: {
    ...defaultOptions,
    strokeWidth: 1.66,
    viewBox: '0 0 16 16',
  },
  path: (
    <path
      d="M7.997 8c-.997 0-2 .253-2.493.747a.936.936 0 0 0-.274.652.927.927 0 0 1-.92.92l-2.333.01a.939.939 0 0 1-.927-.927l.006-.546c-.005-.636.213-1.596.61-2.092.398-.496.954-.84 1.576-.974A25.11 25.11 0 0 1 8 5.333c1.594 0 3.187.153 4.758.457a2.764 2.764 0 0 1 1.576.974c.398.496.615 1.456.61 2.092l.006.546a.939.939 0 0 1-.927.927l-2.332-.01a.928.928 0 0 1-.92-.92.936.936 0 0 0-.274-.652C10.003 8.253 9 8 8.003 8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
