import { flat, group } from 'radash';

export const sortByStatusThenCreated = <P extends { status?: number | null; created?: number | null }>(
  objs: (P | null)[],
  order: { [status in number]: number },
) => {
  const groupedByStatus = group(objs, (p) => p?.status?.toString() || '');

  const sortedByCreated = Object.keys(groupedByStatus).map((key) => {
    const projects = groupedByStatus?.[key];

    return {
      status: key,
      values: !projects || projects?.length === 0 ? [] : projects.sort((a, b) => (b?.created || 0) - (a?.created || 0)),
    };
  });

  sortedByCreated.sort((a, b) => order[parseInt(a?.status, 10)] - order[parseInt(b?.status, 10)]);

  return flat(sortedByCreated.map((s) => s.values));
};
