import { debounce } from 'radash';
import { useMemo, useState } from 'react';

type UseDebouncedSearchProps = {
  // Try to avoid this so we have a consistent experience across the app
  delay?: number;
  defaultSearchTerm?: string;
  debounceFunction: (v: string) => void;
};

export function useDebouncedSearch(args: UseDebouncedSearchProps, deps: any = []) {
  const [searchTerm, setSearchTerm] = useState(args.defaultSearchTerm ?? '');

  const debouncedSearch = useMemo(() => {
    return debounce({ delay: args.delay ?? 300 }, (v: string) => {
      args.debounceFunction(v);
    });
    // eslint-disable-next-line
  }, deps);

  const handleChange = (v: string) => {
    setSearchTerm(v);

    debouncedSearch(v);
  };

  return {
    searchTerm,
    handleChange,
  };
}
