import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import fr from './translations/fr.json';
import jp from './translations/jp.json';

// For type safety and intellisense
const translationDefault = { translation: en } as const;

type SupportedLanguage = 'en' | 'fr' | 'jp';
type Translation = typeof translationDefault.translation;

// Fetch language from local storage, if available
const savedLanguage = window?.localStorage.getItem('lang') as SupportedLanguage;
const defaultLanguage = 'en';

// Ensure that other translations include all the keys from the default translation
const translationFR: Translation = fr;
const translationJP: Translation = jp;

// Labels for the language switcher
const supportedLanguages: { code: SupportedLanguage; label: string }[] = [
  { code: 'en', label: 'English' },
  { code: 'fr', label: 'Français' },
  { code: 'jp', label: '日本語' },
];

i18n.use(initReactI18next).init({
  resources: {
    en: translationDefault,
    fr: { translation: translationFR },
    jp: { translation: translationJP },
  },
  lng: savedLanguage && supportedLanguages.find((l) => l.code === savedLanguage) ? savedLanguage : defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
export { supportedLanguages, SupportedLanguage, translationDefault };
