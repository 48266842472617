import { useEffect, useState, Fragment } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Card, Dialog } from 'components/common';
import { Button } from '@askable/ui/components/ui/button';
import { bookingUtils } from 'lib/booking';
import { apolloFetch } from 'lib/http';
import { globalVariables } from 'lib/globalVariables';

// External components
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';

// Queries
import fetchDemoBooking from 'data/queries/booking/fetchDemoBooking';

// Styles
import './styles/participantSelectionStyles.scss';

function ParticipantSelection(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingData, setBookingData] = useState(null);
  const [bookingState, setBookingState] = useState(booking);
  const [participantType, setParticipantType] = useState(
    _.get(booking, 'config.options.review_submission', !bookingUtils.isOnlineTask(booking)),
  );
  const [openDemoBookingError, setOpenDemoBookingError] = useState(false);

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/participant-selection`,
      stepId: 'project_setup_participant_selection',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [participantType, bookingState]);

  const onClickDemoBooking = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
    const { data } = await apolloFetch.fetch(fetchDemoBooking, { _team_id: _.get(props, 'team._id') });

    if (data && _.get(data, 'demoBooking._id')) {
      const baseURL = globalVariables.getEnvironmentVariables().clientsAppURI;
      const demoBookingUrl = `${baseURL}/bookings/${data.demoBooking._id}/participants/all`;
      window.open(demoBookingUrl, '_blank');
    } else {
      setOpenDemoBookingError(true);
    }
  };

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: (
          <span>
            If you select hand picking, you&apos;ll get to individually choose which participants you want from a list
            of applicants.
          </span>
        ),
      },
      {
        cardText: (
          <span>
            You&apos;ll be able to review their demographic information, read their screener answers and shortlist the
            best people.
          </span>
        ),
        additionalText: true,
      },
      {
        cardText: (
          <span>
            This is great if you want more control over your demographics, and don&apos;t mind doing a bit more work to
            get exactly what you need.
          </span>
        ),
        additionalText: true,
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span>
            If you select automated, eligible participants can confirm themselves into your session times, without you
            needing to invite them manually. In other words, your calendar will fill up <i>automatically</i>.
          </span>
        ),
      },
      {
        cardText: (
          <span>This is great if your requirements are broad and want to keep your workload to a minimum.</span>
        ),
        additionalText: true,
      },
      {
        cardText: (
          <span>
            It&apos;s also more popular with the participants since they don&apos;t need to wait for an invitation,
            which means the turnaround time for your study will be much faster.
          </span>
        ),
        additionalText: true,
      },
    ];
    const cardTexts3 = [
      {
        cardText: (
          <span>
            If you&apos;re short on time and your requirements are fairly broad, we recommend the automated option.
          </span>
        ),
      },
      {
        cardText: (
          <span>
            If you want a more granular control or if you want to manage sub-quotas, then hand picking is the one for
            you.
          </span>
        ),
        additionalText: true,
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        {(bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) && (
          <CardContainer
            cardId="__participantSelectionCard1"
            cardClass="slideInAnimationDelay80"
            cardTitle="Automated vs Hand picking"
            cardTexts={cardTexts3}
            onLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4293041-the-difference-between-automated-and-hand-picked-surveys-and-tasks',
                '_blank',
              );
            }}
          />
        )}
        {!bookingUtils.isOnlineTask(booking) && !bookingUtils.isLongitudinal(booking) && participantType && (
          <CardContainer
            cardId="__participantSelectionCard1"
            cardClass="slideInAnimationDelay80"
            cardTitle="How does hand picking mode work?"
            cardTexts={cardTexts1}
            onLinkClick={onClickDemoBooking}
            linkText="Try a demo study"
            onAdditionalLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4849158-the-difference-between-automated-and-hand-picked-in-person-and-remote',
                '_blank',
              );
            }}
          />
        )}
        {!bookingUtils.isOnlineTask(booking) && !bookingUtils.isLongitudinal(booking) && !participantType && (
          <CardContainer
            cardId="__participantSelectionCard1"
            cardClass="slideInAnimationDelay80"
            cardTitle="How does automated mode work?"
            cardTexts={cardTexts2}
            onLinkClick={() => {
              window.open(
                'https://help.askable.com/en/articles/4849158-the-difference-between-automated-and-hand-picked-in-person-and-remote',
                '_blank',
              );
            }}
          />
        )}
      </>
    );
  };

  const onClickCard = (type: any) => {
    const bookingObj = {
      config: {
        options: {
          review_submission: type,
        },
      },
    };
    setParticipantType(type);

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        options: {
          ...bookingState.config.options,
          review_submission: type,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (type !== _.get(booking, 'config.options.review_submission')) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { options: { review_su... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/audience/panel`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        {booking.continuous
          ? 'We have disabled automated participant selection for this booking'
          : 'How much control do you need with participant selection?'}
      </h1>
      <div className="participantSelectionTypes">
        {bookingUtils.isOnlineTask(booking) && (
          <>
            {!booking.continuous && (
              <Card
                id="__automatedCard"
                title="Automated"
                className="participantSelectionCard"
                content={[
                  {
                    label: `Any fully eligible applicants will automatically get redirected to your ${bookingUtils.isSurvey(booking) ? 'survey' : 'online task'}.`,
                  },
                ]}
                selected={!participantType}
                image="/booking/illustrations/automated-online-type.svg"
                onClickCard={() => onClickCard(false)}
              />
            )}
            <Card
              id="__handPickingCard"
              title="Hand picking"
              className="participantSelectionCard"
              content={[
                {
                  label: `People apply and then you hand pick the ones you want to do the ${bookingUtils.isSurvey(_.get(props, 'booking')) ? 'survey' : 'online task'}.`,
                },
              ]}
              selected={participantType}
              image="/booking/illustrations/cherry-picking-type.svg"
              onClickCard={() => onClickCard(true)}
            />
          </>
        )}
        {!bookingUtils.isOnlineTask(booking) && (
          <>
            <Card
              id="__handPickingCard"
              title="Hand picking"
              className="participantSelectionCard"
              content={[{ label: 'People apply for your opportunity, then you hand pick the ones you like.' }]}
              selected={participantType}
              image="/booking/illustrations/cherry-picking-type.svg"
              onClickCard={() => onClickCard(true)}
            />
            {!booking.continuous && (
              <Card
                id="__automatedCard"
                title="Automated"
                className="participantSelectionCard"
                content={[
                  {
                    label: `Any fully eligible applicants can book themselves onto your ${bookingUtils.isLongitudinal(booking) ? 'study' : 'calendar'}.`,
                  },
                ]}
                selected={!participantType}
                image="/booking/illustrations/automated-type.svg"
                onClickCard={() => onClickCard(false)}
              />
            )}
          </>
        )}
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
      <Dialog
        title="Something went wrong!"
        open={openDemoBookingError}
        onRequestClose={() => setOpenDemoBookingError(false)}
        styles={{ width: '380px' }}
        bodyStyle={{ zIndex: 2200 }}
        customActions={[
          {
            label: 'Okay',
            buttonType: 'normal',
            containerButtonStyle: { marginLeft: '20px' },
            customFunction: () => {
              setOpenDemoBookingError(false);
            },
          },
        ]}
      >
        <h3 style={{ margin: '0px' }}>
          Sorry, it looks like you no longer have the demo study in your account, please contact support to resolve
          this.
        </h3>
      </Dialog>
    </div>
  );
}

export default deprecatedWithRouter(ParticipantSelection);
