import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import { apolloFetch } from 'lib/http';
import { Modal, LoadingOverlay, Button, CountDownTimer } from 'components/common';

// Queries
import findParticipantSessionById from 'data/queries/bookingSubmission/findParticipantSessionById';

// External components
import ConfirmationMessageInvitationDialog from './confirmationMessage';
import InvitationDialogContentContainer from './contentContainer';

class ReInviteParticipant extends Component {
  timer: any;

  constructor(props: any) {
    super(props);

    this.state = {
      coolDownTime: 0,
      isRunning: false,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.renderCooldownMessage = this.renderCooldownMessage.bind(this);
  }

  componentWillMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (this.props.open) {
      this.onCheckCountDownTimer(this.props);
    }
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.open) {
      this.onCheckCountDownTimer(newProps);
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'coolDownTime' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.state.coolDownTime <= 0 && this.timer) clearInterval(this.timer);
  }

  async onCheckCountDownTimer(props: any) {
    // Check whether it needs to render a countDownTimer
    // If it does, it should get the last time a participant got invited
    this.setState({ isRunning: true });
    const lastInvitedTime = await this.getLastInvitedTime(_.get(props, 'singleSession.participant.invitedSession'));
    if (!lastInvitedTime) {
      this.setState({ isRunning: false });
      return null;
    }

    // Get the difference in seconds
    const difference = momentTimezone().diff(lastInvitedTime) / 1000;

    // Check if the difference is less than 15 minutes
    if (difference <= 900) {
      this.setState(
        {
          coolDownTime: 900 - difference,
        },
        () => {
          this.timer = setInterval(() => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'coolDownTime' does not exist on type 'Re... Remove this comment to see the full error message
            const newCount = this.state.coolDownTime - 1;
            this.setState({ coolDownTime: newCount >= 0 ? newCount : 0 });
          }, 1000);
        },
      );
    }
    this.setState({ isRunning: false });
  }

  onCloseModal() {
    if (this.timer) clearInterval(this.timer);
    this.setState({ coolDownTime: 0 });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onCloseInvitationDialog' does not exist ... Remove this comment to see the full error message
    this.props.onCloseInvitationDialog();
  }

  async getLastInvitedTime(invitedSession: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'unknown'.
    const { data } = await apolloFetch.fetch(findParticipantSessionById, { _id: invitedSession._id });
    const history = _.get(data, 'findParticipantSessionById.history');
    return (
      _.get(history, 'session_invitation_notification_followup') ||
      _.get(history, 'session_invitation_notification') ||
      _.get(history, 'session_quant_invitation_notification')
    );
  }

  renderCooldownMessage() {
    // The last invitation can either be the first time the user had been invited or the last time he has been re-invited
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'coolDownTime' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.state.coolDownTime <= 0) return null;
    return (
      <div className="cooldownTime">
        <p>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'coolDownTime' does not exist on type 'Re... Remove this comment to see the full error message */}
          <CountDownTimer time={this.state.coolDownTime} />
        </p>
      </div>
    );
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'singleSession' does not exist on type 'R... Remove this comment to see the full error message
    const { participant, session, isAvailableForSession } = this.props.singleSession;
    const hasBeenInvited = _.has(participant, 'invitedSession');
    return (
      <Modal
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        open={this.props.open}
        modal={false}
        onClose={this.onCloseModal}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'styles' does not exist on type 'Readonly... Remove this comment to see the full error message
        styles={{ paddingTop: 'auto', ...this.props.styles }}
        className="inviteParticipantsContainer max300"
        repositionOnUpdate={false}
      >
        <>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message */}
          {this.props.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'showConfirmationMessage' does not exist ... Remove this comment to see the full error message */}
          {this.props.showConfirmationMessage ? (
            <ConfirmationMessageInvitationDialog
              {...this.props}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onFinishCustom: () => void; }' is not assi... Remove this comment to see the full error message
              onFinishCustom={this.onCloseModal}
            />
          ) : (
            <>
              <p>
                Resend invitation to {_.get(participant, 'user.meta.identity.firstname')}{' '}
                {_.get(participant, 'user.meta.identity.lastname')}?
              </p>
              <InvitationDialogContentContainer
                participant={participant}
                session={session}
                isAvailableForSession={isAvailableForSession}
                {...this.props}
              />
              <div className="buttonsContainer">
                <Button
                  label="Resend Invite"
                  variant="default"
                  size="default"
                  className="btnSendInvite mtop20"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onConfirm' does not exist on type 'Reado... Remove this comment to see the full error message
                  onClick={this.props.onConfirm}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'coolDownTime' does not exist on type 'Re... Remove this comment to see the full error message
                  disabled={(this.state.coolDownTime > 0 && this.state.isRunning === false) || this.state.isRunning}
                />
                {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1. */}
                {hasBeenInvited && this.renderCooldownMessage(participant.invitedSession)}
              </div>
            </>
          )}
        </>
      </Modal>
    );
  }
}

export default ReInviteParticipant;
