import { gql } from '@apollo/client';

export const eSignatureTemplateFieldsFragment = gql`
  fragment eSignatureTemplateFields on ESignatureTemplate {
    _id
    global
    active
    _team_id
    _organisation_id
    provider {
      name
      reference
    }
    name
    description
    recipients
    created
    updated
  }
`;
