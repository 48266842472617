import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { cn } from '@askable/ui/lib/utils';

type Props = {
  className?: string;
};

export const TableLoading = ({ className }: Props) => {
  return (
    <div className={cn('flex flex-col space-y-3', className)}>
      <div className="flex space-x-2">
        <Skeleton className="h-4 w-[100px]" />
        <Skeleton className="h-4 w-[250px]" />
      </div>
      <div className="flex space-x-2">
        <Skeleton className="h-4 w-[100px]" />
        <Skeleton className="h-4 w-[250px]" />
      </div>
      <div className="flex space-x-2">
        <Skeleton className="h-4 w-[100px]" />
        <Skeleton className="h-4 w-[100px]" />
      </div>
    </div>
  );
};
