import { gql } from '@apollo/client';
import { manageBookingPageCalendarFragment } from 'data/fragments/booking/manageBookingPageCalendar';

export default gql`
  mutation cancelSession($booking_id: ID!, $session_id: ID!) {
    cancelSession(booking_id: $booking_id, session_id: $session_id) {
      ...ManageBookingPageCalendar
    }
  }
  ${manageBookingPageCalendarFragment}
`;
