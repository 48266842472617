import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const HeadingThreeIcon: CreateIconType = createIcon({
  displayName: 'HeadingThreeIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path
      id="Vector"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5344 11.7792C10.6826 12.1984 11.0824 12.4988 11.5524 12.4988L12.2719 12.4988C12.868 12.4988 13.3512 12.0155 13.3512 11.4194L13.3512 11.2395C13.3512 10.6434 12.868 10.1602 12.2719 10.1602L11.9121 10.1602H12.182C12.7284 10.1602 13.1714 9.71722 13.1714 9.17079C13.1714 8.62436 12.7284 8.18139 12.182 8.1814L11.6423 8.1814C11.1894 8.18141 10.8076 8.48568 10.6901 8.90096"
    />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.648682 11.9961V1.50122M7.64526 1.50122V11.9961M0.648682 6.31137H7.64526"
    />,
  ],
});
