import React, { useEffect, useState, Fragment, useRef } from 'react';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { QuotaInput, QuotaInputRef } from '../components/QuotaInput';
import { askablePlusUtils } from 'lib/askablePlus';

// External components
import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';

function Quota(props: any) {
  const quotaInputRef = useRef<QuotaInputRef>(null);
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
  const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);
  const hasCompetitiveAnalysisType = askablePlusUtils.hasCompetitiveAnalysisType(askablePlusBrief);
  const hasSurveyType = askablePlusUtils.hasSurveyType(askablePlusBrief);
  const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(askablePlusBrief);
  let total_participants = 10;
  if (hasDiscoveryInterviewType) {
    total_participants = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.quota');
  } else if (hasUsabilityTestingType) {
    total_participants = _.get(askablePlusBrief, 'askable_plus.research_type.usability.quota');
  } else if (hasCompetitiveAnalysisType) {
    total_participants = _.get(askablePlusBrief, 'askable_plus.research_type.competitive_analysis.quota');
  } else if (hasSurveyType) {
    total_participants = _.get(askablePlusBrief, 'askable_plus.research_type.survey.quota');
  } else if (hasLongitudinalStudyType) {
    total_participants = _.get(askablePlusBrief, 'askable_plus.research_type.longitudinal.quota');
  }

  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected > 1) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/discovery-interview/quota`);
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/quota`,
        stepId: 'project_setup_quota',
      });
    }
  }, []);

  useEffect(() => {
    if (!quotaInputRef?.current?.form.getValues('quota')) {
      quotaInputRef?.current?.form.setError('quota', { message: 'This field is required' });
    }
    props.renderRightContent(rightContent());
  }, [askablePlusBrief, askablePlusBriefState, quotaInputRef?.current]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onChangeValue = (value: any) => {
    const newQuota = parseInt(value, 10);

    const researchTypeQuota = {};
    if (hasDiscoveryInterviewType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'discovery' does not exist on type '{}'.
      researchTypeQuota.discovery = {
        ...askablePlusBriefState.askable_plus.research_type.discovery,
        quota: newQuota,
      };
    } else if (hasUsabilityTestingType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'usability' does not exist on type '{}'.
      researchTypeQuota.usability = {
        ...askablePlusBriefState.askable_plus.research_type.usability,
        quota: newQuota,
      };
    } else if (hasCompetitiveAnalysisType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'competitive_analysis' does not exist on ... Remove this comment to see the full error message
      researchTypeQuota.competitive_analysis = {
        ...askablePlusBriefState.askable_plus.research_type.competitive_analysis,
        quota: newQuota,
      };
    } else if (hasSurveyType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'survey' does not exist on type '{}'.
      researchTypeQuota.survey = {
        ...askablePlusBriefState.askable_plus.research_type.survey,
        quota: newQuota,
      };
    } else if (hasLongitudinalStudyType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'longitudinal' does not exist on type '{}... Remove this comment to see the full error message
      researchTypeQuota.longitudinal = {
        ...askablePlusBriefState.askable_plus.research_type.longitudinal,
        quota: newQuota,
      };
    }

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          ...researchTypeQuota,
        },
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    let redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/session-duration`;
    if (hasCompetitiveAnalysisType) {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`;
    }
    props.history.push({ pathname: redirectTo });
  };

  return (
    <QuotaInput
      className="createAskablePlusBriefContent"
      ref={quotaInputRef}
      title="Quota"
      description="How many participants do you need?"
      onClickNext={onClickNext}
      onQuotaValueChange={onChangeValue}
      defaultValues={{ quota: total_participants }}
    />
  );
}

export default deprecatedWithRouter(Quota);
