import { Container, ContainerProps } from 'ui';
import React, { FC, PropsWithChildren } from 'react';

export const AskablePlusProjectContainer: FC<PropsWithChildren<ContainerProps>> = ({ children, ...props }) => {
  return (
    <Container maxW="full" w="full" p={[6, 6, 10]} {...props}>
      {children}
    </Container>
  );
};
