import { gql } from '@apollo/client';
import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';
import { teamFieldsFragment } from 'data/fragments/teams/teamFields';

export default gql`
  mutation updateBooking($booking_id: ID!, $booking: BookingInput!, $currency_code: String) {
    updateBooking(booking_id: $booking_id, booking: $booking) {
      ...bookingFields
      continuous
      total_amount(currency_code: $currency_code) {
        bookingPrice
        bookingPriceAfterTaxes
        bookingPriceTaxes
        creditsPerParticipant
        coupon {
          code
          discount_value
        }
      }
      config {
        contact {
          UserOrganiser(_booking_id: $booking_id) {
            _id
            email
            meta {
              identity {
                firstname
                lastname
              }
            }
            contact {
              phone {
                country_code
                mobile
              }
            }
          }
          UserFacilitator {
            _id
            email
            meta {
              identity {
                firstname
                lastname
              }
            }
            contact {
              phone {
                country_code
                mobile
              }
            }
          }
        }
      }
      team {
        ...teamFields
      }
    }
  }
  ${bookingFieldsFragment}
  ${teamFieldsFragment}
`;
