import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const ArrowLeftIcon: CreateIconType = createIcon({
  displayName: 'ArrowLeftIcon',
  defaultProps: defaultOptions,
  path: [
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.999999 7L13 7" />,
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.99612 3.00391C3.24285 4.44038 2.36677 5.31628 1.00003 7C2.36677 8.68372 3.24285 9.55961 4.99612 10.9961"
    />,
  ],
});
