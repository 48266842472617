import { graphql } from 'src/graphql';

export const CreateProjectMutation = graphql(`
  mutation createProject($project: ProjectInput!) {
    createProject(project: $project) {
      _id
      name
    }
  }
`);
