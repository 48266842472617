import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const BulletListIcon: CreateIconType = createIcon({
  displayName: 'BulletListIcon',
  defaultProps: {
    ...defaultOptions,
  },
  path: [
    <path id="Vector" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.09656 2.5H13.1859" />,
    <path id="Vector_2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.744873 2.5H1.06962" />,
    <path id="Vector_3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.09656 7H13.1859" />,
    <path id="Vector_4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M0.744873 7H1.06962" />,
    <path id="Vector_5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M4.09656 11.5H13.1859" />,
    <path
      id="Vector_6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0.744873 11.5H1.06962"
    />,
  ],
});
