import { graphql } from 'src/graphql';

export const ChangeUserRoleMutation = graphql(`
  mutation UpdateUserRole($_id: ID!, $userId: ID!, $role: Int!) {
    changeUserRoleOnTeam(_id: $_id, userId: $userId, role: $role) {
      _id
      users {
        _id
        role
        status
        added_date
        User {
          displayName
          _id
          email
        }
      }
    }
  }
`);
