import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers: ReturnType<typeof createMultiStyleConfigHelpers<'container' | 'badge'>> = createMultiStyleConfigHelpers([
  'container',
  'badge',
]);

export const ButtonBadgeGroup: ReturnType<typeof helpers.defineMultiStyleConfig> = helpers.defineMultiStyleConfig({
  baseStyle: ({ colorMode, shouldShow }) => ({
    container: {
      position: 'relative',
    },
    badge: {
      display: shouldShow ? 'flex' : 'none',
      pointerEvents: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      fontWeight: 'semibold',
      rounded: 'full',
      borderColor: colorMode === 'light' ? 'white' : 'gray.800',
      letterSpacing: 'tighter',
      borderWidth: '2px',
      color: 'gray.800',
    },
  }),
  sizes: {
    sm: (props) => {
      return {
        badge: {
          w: 3.5,
          h: 3.5,
          right: props.isRound ? 0 : -1,
          top: props.isRound ? 0 : 1,
          fontSize: 'xs',
        },
      };
    },
    md: {
      badge: {
        w: 5,
        h: 5,
        fontSize: 'xs',
        right: -1,
        top: -1,
      },
    },
    lg: {
      badge: {
        w: 7,
        h: 7,
        right: -3,
        top: -3,
      },
    },
  },
  variants: {
    info: {
      badge: {
        backgroundColor: 'gray.200',
        color: 'gray.900',
      },
    },
    error: {
      badge: {
        backgroundColor: 'red.200',
        color: 'red.900',
      },
    },
    success: {
      badge: {
        backgroundColor: 'green.200',
        color: 'green.900',
      },
    },
    warning: {
      badge: {
        backgroundColor: 'orange.200',
        color: 'orange.900',
      },
    },
  },
  defaultProps: {
    variant: 'info',
    size: 'md',
  },
});
