import { useEffect, useState } from 'react';
import { localStorage } from 'lib/storage';
import { userUtils } from 'lib/user';
import { utils } from 'lib/utils';
import { X } from 'lucide-react';
import moment from 'moment';
import { useConnectedClient } from 'context/ConnectedClientContext';

// Styles
import './HeaderCampaign.scss';
import { Text } from 'ui';

import { NotifyClickOnAdsMutation } from './data/NotifyClickOnAds.mutation';
import { useMutation } from 'urql';
import { OperationalOffices } from 'generated/graphql';

const showInOperationalOffices = [OperationalOffices.Au];

const Banner = () => {
  return (
    <div className="linkContainer">
      <img className="linkText" height="37" alt="Bulk credits" src="/assets/bulkCredits.svg" />
      <Text fontSize={['xs', 'md']} color="white" fontWeight="bold" ml={4} mr={6}>
        Purchase bulk credit pack & claim bonuses
      </Text>
      <img className="btnText" width="80" height="26" alt="Bulk credits button" src="/assets/btn-img.svg" />
    </div>
  );
};

function getCampaignURL() {
  return 'https://www.askable.com/eofy-24?utm_source=Product&utm_medium=product-banner&utm_campaign=eofy-2024';
}

function HeaderAds() {
  const [showHeaderAdsBar, setShowHeaderAdsBar] = useState(false);
  const [, notifyClickOnHeaderAds] = useMutation(NotifyClickOnAdsMutation);
  const { details: clientDetails } = useConnectedClient();

  useEffect(() => {
    let showingHeaderAds = utils.parseJSON(localStorage.get('showingHeaderAds'));
    if (showingHeaderAds === null || showingHeaderAds === undefined) {
      showingHeaderAds = true;
    }

    // Date when it shouldn't show anymore. Midnight Brisbane time
    if (Date.now() >= new Date('2024-07-01').valueOf()) {
      showingHeaderAds = false;
      localStorage.remove('showingHeaderAdsBarClosed');
      return;
    }

    if (!showingHeaderAds) {
      const showingHeaderAdsBarClosed = utils.parseJSON(localStorage.get('showingHeaderAdsBarClosed'));
      // if (!showingHeaderAdsBarClosed) {
      //   setShowHeaderAdsBar(false);
      //   return;
      // }
      // show again after 1 day
      if ((showingHeaderAdsBarClosed ?? 0) < moment().subtract(1, 'days').valueOf()) {
        showingHeaderAds = true;
        localStorage.remove('showingHeaderAdsBarClosed');
      }
    }

    showingHeaderAds =
      showingHeaderAds && showInOperationalOffices.includes(clientDetails?.ConnectedTeam?.operational_office!);

    if (userUtils.isResearcher(clientDetails)) showingHeaderAds = false;
    if (localStorage.get('connectedAsClient') === 'true') showingHeaderAds = false;

    setShowHeaderAdsBar(showingHeaderAds);
    localStorage.save('showingHeaderAds', showingHeaderAds);
  }, []);

  if (!showHeaderAdsBar) return null;
  return (
    <div
      className="headerCampaignContainer"
      onClick={(e) => {
        e.preventDefault();
        notifyClickOnHeaderAds({
          _id: clientDetails?.team?.id || '',
          userId: clientDetails?.id || '',
        });
        let baseEmail = '';
        if (clientDetails?.email) {
          baseEmail = `&email=${clientDetails.email}`;
        }

        /**
         * Campaign URL
         */
        const campaignUrl = getCampaignURL();

        window.open(`${campaignUrl}${baseEmail}`, '_blank');
      }}
    >
      <Banner />
      <div className="closeIconContainer">
        <X
          className="closeIcon"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowHeaderAdsBar(false);
            localStorage.save('showingHeaderAds', false);
            localStorage.save('showingHeaderAdsBarClosed', moment().valueOf());
          }}
          style={{ width: 24, height: 24, color: '#fff' }}
        />
      </div>
    </div>
  );
}

export default HeaderAds;
