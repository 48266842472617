import { graphql } from 'src/graphql';

export const CompleteOrderBooking = graphql(`
  mutation completeOrderBooking(
    $booking_id: ID!
    $participants_added: Int
    $participants_session_changed: Int
    $credits_per_participant_difference: Float
    $new_session_duration: Int
    $participants_reopen_booking: Int
  ) {
    completeOrderBooking(
      booking_id: $booking_id
      participants_added: $participants_added
      participants_session_changed: $participants_session_changed
      credits_per_participant_difference: $credits_per_participant_difference
      new_session_duration: $new_session_duration
      participants_reopen_booking: $participants_reopen_booking
    ) {
      _id
      team {
        _id
        name
        kinde_org_id
      }
    }
  }
`);
