import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const HiddenIcon: CreateIconType = createIcon({
  displayName: 'HiddenIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M4.06152 4.1697C5.17812 3.36111 6.53572 2.75781 7.99988 2.75781C11.8088 2.75781 14.8964 6.84046 14.8964 7.99919C14.8964 8.73668 13.6457 10.6586 11.7543 11.9585"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinejoin="round"
    />,
    <path
      d="M2.15522 5.98438C1.48886 6.81462 1.10352 7.58888 1.10352 7.99822C1.10352 9.15695 4.19121 13.2396 8.00007 13.2396C8.48365 13.2396 8.95562 13.1737 9.41111 13.0566"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M1.65527 1.10156C6.13251 6.83677 9.03851 9.71175 14.8967 14.3429"
      stroke="currentColor"
      strokeWidth="1.65517"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
