import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
// import { useIntercom } from 'react-use-intercom';
import { LoadingOverlay, Modal, Button } from 'components/common';
import { X } from 'lucide-react';

// Mutations
import markParticipantNoShowMutation from 'data/mutations/bookingSubmission/markParticipantNoShow';

// Styles
import './noShowStyles.scss';

function NoShowDialog(props: any) {
  // const intercom = useIntercom();

  const [state, setState] = useState({
    loading: false,
    confirmedAction: 0,
  });

  const [markParticipantNoShow] = useMutation(markParticipantNoShowMutation, {
    client: props.client,
  });

  const onNoShowConfirmed = (value: any) => {
    setState({ ...state, loading: true });
    const { _id } = props.participant;

    markParticipantNoShow({
      variables: {
        booking_id: props.booking._id,
        participant_session_id: _id,
        no_show_request: value,
      },
    })
      .then(() => {
        setState({
          ...state,
          loading: false,
          confirmedAction: value,
        });
        if (props.onConfirmNoShow) props.onConfirmNoShow();
      })
      .catch(() => {
        setState({
          ...state,
          loading: false,
        });
      });
  };

  const onCloseModal = () => {
    setState({
      ...state,
      loading: false,
      confirmedAction: 0,
    });
    props.onClose();
  };

  const refundCredits = (booking: any) => {
    // round the number of credits up to the nearest whole number
    const amount = Math.ceil(_.get(booking, 'total_amount.creditsPerParticipant', 1));
    return amount === 1 ? '1 credit' : `${amount} credits`;
  };

  const renderConfirmationMessage = () => {
    // 1 -> Refund
    // 2 -> Replace
    if (state.confirmedAction === 2) {
      return (
        <>
          <h2>Okay, we&apos;ve opened up another spot for this booking</h2>
          <p>
            You can now invite another participant as a replacement. You can also add additional session times by
            clicking on the calendar.
          </p>
        </>
      );
    }
    return (
      <>
        <h2>That&apos;s done</h2>
        <p>Askable automatically added {refundCredits(props.booking)} back to your account.</p>
      </>
    );
  };

  return (
    <Modal
      className="noShowDialogComponent"
      bodyClassName="content"
      open={props.open}
      onClose={onCloseModal}
      styles={{ zIndex: 2000 }}
      modal={!!state.confirmedAction}
    >
      {state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      {!state.confirmedAction ? (
        <>
          <X onClick={onCloseModal} className="defaultCloseIcon" style={{ width: 20, height: 20 }} />
          <div className="topContainer">
            <img className="illustration" src="/icons/noShowSadMail.png" alt="Sad mail" />
            <div className="contentTop">
              <h2>Bummer :(</h2>
              <p>
                We're sorry to hear that <strong>{_.get(props.participant, 'user.meta.identity.firstname')}</strong>{' '}
                didn't show up. You can choose one of the options below:
              </p>
            </div>
          </div>
          <div className="actionContainer">
            <div className="replacement">
              <div className="internalContent">
                <h3>Replacement person</h3>
                <p>
                  We'll open up a spot for you on this study and you'll be able to invite a different person, free of
                  charge.
                </p>
              </div>
              <Button label="Replace" onClick={() => onNoShowConfirmed(2)} className="actionButton" size="default" />
            </div>
            <div className="refund">
              <div className="internalContent">
                <h3>Refund credits</h3>
                <p>
                  Since you paid with credits, we'll add {refundCredits(props.booking)} back into your account
                  immediately.
                </p>
              </div>
              <Button label="Refund" onClick={() => onNoShowConfirmed(1)} className="actionButton" size="default" />
            </div>
          </div>
        </>
      ) : (
        <div className="confirmationMessageContainer">
          {renderConfirmationMessage()}
          <Button
            label="Done"
            onClick={() => {
              props.onClickDone();
            }}
            className="doneButton"
            size="default"
          />
        </div>
      )}
    </Modal>
  );
}

export default NoShowDialog;
