import { graphql } from 'gql.tada';

export default graphql(`
  query FetchNotificationsByTeam($_team_id: ID!, $offset: Int, $limit: Int) {
    notificationsByTeam(_team_id: $_team_id, offset: $offset, limit: $limit) {
      _id
      message
      link
      type
      subtype
      read
      created
      context {
        booking {
          _id
          name
          type
          config {
            timezone
            online_task {
              type
            }
          }
        }
        booking_participant {
          _id
          cancel_reason
          feedback {
            participant
          }
        }
        participant_session {
          _id
          start
          end
        }
        messages {
          _id
          body
        }
      }
    }
  }
`);
