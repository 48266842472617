import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const UnderlineIcon: CreateIconType = createIcon({
  displayName: 'UnderlineIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      d="M4.57153 1.03937V8.2055C4.57153 10.099 6.10656 11.6341 8.0001 11.6341C9.89365 11.6341 11.4287 10.099 11.4287 8.2055V1.03937"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M1.0542 14.9489C6.43569 15.236 9.59222 15.1609 14.9458 14.9489"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
