import { breadcrumbAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);

const baseStyle = definePartsStyle({
  link: {
    fontWeight: 'normal',
    fontSize: 'sm',
  },
});

export const breadcrumbTheme = defineMultiStyleConfig({ baseStyle }) as any;
