import { graphql } from 'src/graphql';

export const DiscoverAnalysesInsightsQuery = graphql(`
  query DiscoverAnalysesInsights($first: Int!, $filter: AnalysesInsightsFilter!) {
    analysesInsights(first: $first, filter: $filter) {
      totalCount
      nodes {
        _id
        title
        summary
        _analyses_ids
        quote_count
        user_count
        themes {
          name
          accent
        }
      }
    }
  }
`);
