import { ErrorBoundaryPssTable } from 'components/ErrorBoundaryPssTable/ErrorBoundaryPssTable';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { BookingContainer } from './BookingContainer';

const BookingCalendarContainer = lazy(() => import('./containers/BookingCalendarContainer'));
const BookingMessagesContainer = lazy(() => import('./containers/BookingMessagesContainer/BookingMessagesContainer'));
const BookingSettingsContainer = lazy(() => import('./containers/BookingSettingsContainer'));

const BookingParticipantsContainer = lazy(
  () => import('./containers/BookingParticipantsContainer/BookingParticipantsContainer'),
);
const BookingParticipantsTableContainer = lazy(
  () => import('./containers/BookingParticipantsContainer/BookingParticipantTableContainer'),
);

export const bookingRoutes: RouteObject[] = [
  {
    path: 'bookings/:bookingId',
    element: (
      <ErrorBoundaryPssTable location="BookingContainer">
        <BookingContainer />
      </ErrorBoundaryPssTable>
    ),
    children: [
      {
        path: 'participants',
        element: <BookingParticipantsContainer />,
        children: [
          {
            path: ':segmentId',
            element: <BookingParticipantsTableContainer />,
          },
          { index: true, element: <Navigate to="all" replace /> },
        ],
      },
      {
        path: 'messages',
        element: <BookingMessagesContainer />,
      },
      {
        path: 'calendar',
        element: <BookingCalendarContainer />,
      },
      {
        path: 'settings',
        element: <BookingSettingsContainer />,
      },
      {
        index: true,
        element: <Navigate to="participants/all" replace />,
      },
    ],
  },
];
