import { gql } from '@apollo/client';
import { bookingFieldsFragment } from 'data/fragments/booking/bookingFields';

export default gql`
  mutation createOnlineTaskLink($booking_id: ID!, $link: BookingInputConfigOnlineTaskLink!) {
    createOnlineTaskLink(booking_id: $booking_id, link: $link) {
      ...bookingFields
    }
  }
  ${bookingFieldsFragment}
`;
