import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { useConnectedClient } from 'context/ConnectedClientContext';

import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { toast } from '@askable/ui/components/ui/sonner';
import { TemplateItem } from './components/TemplateItem';
import { CreateTemplateForm } from './components/CreateTemplateForm';
import { SettingsContainer } from '../SettingsContainer';

import { BOOKING_ESIGNATURE_TEMPLATE_TYPE } from 'lib/constants';
import createESignatureTemplateMutation from 'src/data/mutations/esignature_templates/createESignatureTemplate';
import getTemplatesQuery from 'src/data/queries/esignature_templates/eSignatureTemplatesByTeam';

export const SettingsResources = () => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const getTemplates = useQuery(getTemplatesQuery, {
    variables: { _team_id: details?.ConnectedTeam?._id, global: false },
  });
  const [createTemplate] = useMutation(createESignatureTemplateMutation);
  const templatesList = getTemplates?.data?.eSignatureTemplatesByTeam ?? [];

  const onCreate = async ({ name }: { name: string }) => {
    const template = {
      name,
      type: BOOKING_ESIGNATURE_TEMPLATE_TYPE.PARTICIPANT_AGREEMENT,
      _team_id: details?.ConnectedTeam?._id,
    };

    const createTemplateResult = await createTemplate({ variables: { template } })
      .then((result) => {
        const newTemplate = result?.data?.createESignatureTemplate;
        getTemplates.refetch();
        return newTemplate;
      })
      .catch(() => {
        toast.error('Failed to create your new template. Please contact customer support');
      });

    return createTemplateResult;
  };

  return (
    <SettingsContainer title={t('sections.settings.resources.title')}>
      <CreateTemplateForm
        onCreate={onCreate}
        emailBody={{
          team: details?.team?.name,
        }}
      >
        {({ open, button: createTemplateButton, form: createTemplateInputGroup, createdTemplates }) => (
          <div className="flex flex-col gap-4">
            <header className="flex flex-col">
              <div className="flex items-center justify-between gap-2">
                <h3 className="text-md font-semibold">{t('sections.settings.resources.agreementTemplatesTitle')}</h3>
                {!open ? createTemplateButton : undefined}
              </div>
              <div className="max-w-sm text-pretty text-sm text-muted-foreground">
                {t('sections.settings.resources.agreementTemplatesDescription')}
              </div>
            </header>

            <div>{open ? createTemplateInputGroup : null}</div>
            {createdTemplates.length > 0 ? createdTemplates : null}
          </div>
        )}
      </CreateTemplateForm>

      {getTemplates?.loading ? <Skeleton className="h-6 w-full" /> : null}

      {templatesList.map((template: { [k: string]: any }) => (
        <TemplateItem
          key={template._id}
          template={template}
          onUpdate={async () => {
            await getTemplates.refetch();
          }}
        />
      ))}
    </SettingsContainer>
  );
};
