import { createIcon } from '@chakra-ui/icons';
import { defaultOptions } from './icon-options';
import { CreateIconType } from './icon-types';

export const HelpIcon: CreateIconType = createIcon({
  displayName: 'HelpIcon',
  defaultProps: {
    ...defaultOptions,
    viewBox: '0 0 16 16',
  },
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.353455 8.00001C0.353455 3.77693 3.77693 0.353455 8.00001 0.353455C12.2231 0.353455 15.6466 3.77693 15.6466 8.00001C15.6466 12.2231 12.2231 15.6466 8.00001 15.6466C3.77693 15.6466 0.353455 12.2231 0.353455 8.00001ZM8.00001 1.85345C4.60536 1.85345 1.85345 4.60536 1.85345 8.00001C1.85345 11.3947 4.60536 14.1466 8.00001 14.1466C11.3947 14.1466 14.1466 11.3947 14.1466 8.00001C14.1466 4.60536 11.3947 1.85345 8.00001 1.85345Z"
      fill="currentColor"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63847 6.35154C5.63847 4.8775 7.01581 3.74717 8.46152 4.03473C9.38451 4.21834 10.1339 4.96771 10.3175 5.89074C10.4098 6.35466 10.3563 6.79887 10.1532 7.19827C9.9569 7.58456 9.64776 7.87641 9.31306 8.10005C9.01464 8.29945 8.86129 8.41249 8.7749 8.50238C8.76367 8.51407 8.75567 8.52331 8.75013 8.53016C8.73492 8.93096 8.4052 9.25121 8.00068 9.25121C7.58647 9.25121 7.25068 8.91543 7.25068 8.50121C7.25068 8.05694 7.44702 7.71933 7.6934 7.46298C7.91194 7.23558 8.20645 7.03543 8.4797 6.85285C8.67527 6.72218 8.77111 6.60706 8.81607 6.5186C8.85437 6.44324 8.87848 6.34491 8.84634 6.18336C8.78111 5.85541 8.49687 5.57116 8.16889 5.50591M8.16889 5.50591C7.65093 5.40289 7.13847 5.82344 7.13847 6.35154C7.13847 6.76576 6.80269 7.10154 6.38847 7.10154C5.97426 7.10154 5.63847 6.76576 5.63847 6.35154"
      fill="currentColor"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 10.2338C8.41422 10.2338 8.75001 10.5696 8.75001 10.9838V11.2597C8.75001 11.6739 8.41422 12.0097 8.00001 12.0097C7.58579 12.0097 7.25001 11.6739 7.25001 11.2597V10.9838C7.25001 10.5696 7.58579 10.2338 8.00001 10.2338Z"
      fill="currentColor"
    />,
  ],
});
