import { gql } from '@apollo/client';
import { messagesFieldsFragment } from 'data/fragments/messages/messagesFields';

export default gql`
  mutation createMessage($message: MessagesInput!) {
    createMessage(message: $message) {
      ...messagesFields
    }
  }
  ${messagesFieldsFragment}
`;
