import { useClient } from 'urql';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import getLocationByAddress from 'data/queries/location/getLocationByAddress';

export const useGetLocationFromCountryCode = () => {
  const client = useClient();

  return async (countryCode: string) => {
    const countryData = countryCodeData.find((country) => country.region === countryCode);
    if (!countryData) {
      console.warn(`Could not determine location for ${countryCode}`);
      return null;
    }
    return client
      .query(getLocationByAddress, {
        address: countryData?.name,
        country: countryData?.country_code,
        types: ['(regions)'],
      })
      .toPromise();
  };
};
