import { gql } from '@apollo/client';

export const participantSessionsFieldsFragment = gql`
  fragment participantSessionsFields on SessionsType {
    _id
    _booking_id
    _session_id
    _user_id
    cancel
    status
    no_show_request
    issue_request
    created_context
    history {
      session_invitation_notification
    }
  }
`;
